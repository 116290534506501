import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import useComponentSize from '@rehooks/component-size'
import sizeOf from 'browser-image-size'

function clickEvent(e) {
    // e = Mouse click event.
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left; //x position within the element.
    let y = e.clientY - rect.top;  //y position within the element.
    return {
        x, y
    }
}

export default function PointSelector(props) {
    let {imageSource = require('../../assets/images/russia_clear.png')} = props;
    const {
        x, y, onChange = (pt) => {
            console.log(pt);
        },
        radius = 5,
        editable = true
    } = props;
    const [imageSize, setImageSize] = useState();
    const [selectedPoint, setSelectedPoint] = useState((props.x == undefined || props.y == undefined) ? undefined : {
        x: +props.x,
        y: +props.y
    });
    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;
    console.log(selectedPoint);

    useEffect(() => {
        async function getSize() {
            let imSize = await sizeOf(imageSource);
            setImageSize(imSize);
            return imSize;
        }

        getSize();
    }, []);
    const imageWidth = size.width;
    const imageHeight = (imageSize == undefined) ? undefined : (imageSize.height / imageSize.width * size.width);
    const pointSize = imageWidth * radius / 100.0;

    return (
        <Wrapper ref={ref} style={{height: (imageHeight == undefined) ? 'auto' : `${imageHeight}px`}}
                 onClick={(a, b, c) => {
                     if (editable == false) {
                         return;
                     }
                     let p = clickEvent(a);
                     p = {
                         x: 100.0 * p.x / imageWidth,
                         y: 100.0 * p.y / imageHeight
                     };
                     setSelectedPoint(p);
                     onChange(p);
                 }}>
            {imageSize == undefined ? null :
                <Image src={imageSource}/>
            }
            {selectedPoint == undefined ? null :
                <Point style={{
                    width: pointSize,
                    height: pointSize,
                    left: `calc(${selectedPoint.x}% - ${pointSize / 2}px)`,
                    top: `calc(${selectedPoint.y}% - ${pointSize / 2}px)`
                }}/>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    position: relative;
`;

const Image = styled.img`
    width: 100%;
`;


const Point = styled.div`
    border-radius: 1000px;
    background: rgba(255, 0, 0, 0.5);
    border: 2px solid whitesmoke;
    position: absolute;
`;
