import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import isEmail from 'validator/lib/isEmail'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";
import Spin from 'arui-feather/spin'

export default function ChangeUserEmailForm(props) {
    const {
        oldEmail, onSubmit = newEm => {

        },
        loading = false
    } = props;
    const [email, setEmail] = useState(props.oldEmail == undefined ? '' : props.oldEmail);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Старая почта
                </Label>
                {oldEmail}
            </Field>

            <Field>
                <Label>
                    Новая почта
                </Label>
                <Input value={email} onChange={evt => {
                    setEmail(evt.target.value.trim().toLowerCase());
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button style={{marginLeft: 0}} onClick={() => {
                        if (isEmail(email) == false) {
                            window.alert('Неверная почта');
                            return;
                        }
                        onSubmit(email);
                    }}>
                        Изменить почту
                    </Button>
                }
            </Field>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
