import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

export default function ViewTaskTool(props) {
    let {name, description, avatar, startTimestamp, deadlineTimestamp, taskUrl} = props;

    return (
        <Wrapper>

            <Name>
                {name}
            </Name>

            {description == undefined || description.trim() == '' ? null :
                <Description>
                    {description}
                </Description>
            }

            <Row>
                <Left>
                    Начало сдачи
                </Left>
                <Right>
                    {`${moment(startTimestamp).format('DD.MM.YYYY')} 00:00`}
                </Right>
            </Row>

            <Row>
                <Left>
                    Окончание сдачи
                </Left>
                <Right>
                    {`${moment(deadlineTimestamp).format('DD.MM.YYYY')} 23:59:59`}
                </Right>
            </Row>

            <Row>
                <Left>
                    Задание
                </Left>
                <Right>
                    <a href={taskUrl} target={'_blank'}>
                        скачать
                    </a>
                </Right>
            </Row>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Name = styled.div`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 42px;
`;

const Description = styled.div`
    font-size: 22px;
    margin-bottom: 30px;
    line-height: 30px;
    opacity: 0.5;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
    margin-bottom: 20px;
`;

const Left = styled.div`
    font-weight: bold;
`;

const Right = styled.div`
    
`;
