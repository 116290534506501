import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const GroupsAPI = {

    getAllGroups() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/groups/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createGroup(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/groups/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateGroup(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/groups/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteGroup(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/groups/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default GroupsAPI;
