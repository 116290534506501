import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import * as submissionsActions from '../../../redux/actions/SubmissionsActions'
import * as tasksActions from '../../../redux/actions/TasksActions'
import UpdateAttachmentsTool from "../tools/UpdateAttachmentsTool";

export default function StudentTaskSubmissionPanel(props) {
    const {studentId, taskId} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, userRole, allCenters, submission, student, tasks, stId} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let stId = (studentId == undefined) ? state.users.currentUserId : studentId;
        let submissions = state.submissions.submissionsMap.toArray().filter(a => (a.studentId == stId && a.taskId == taskId));
        let student = state.users.usersMap.get(stId);
        let submission = submissions[0];
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId || state.tasks.loading || state.submissions.loading,
            loading: state.tasks.loading || state.submissions.loading,
            student: student,
            submission: submission,
            task: state.tasks.tasksMap.get(taskId)
        }
    }, []));

    useEffect(() => {
        dispatch(tasksActions.loadAllTasks());
        dispatch(submissionsActions.getLazySubmission(studentId, taskId));
    }, []);

    if (submission == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    if (submission == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <UpdateAttachmentsTool
                loading={loading}
                items={submission.attachments}
                onChange={async newAttachments => {
                    await dispatch(submissionsActions.updateSubmission({
                        id: submission.id,
                        attachments: newAttachments
                    }));
                }}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
