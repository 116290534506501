import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";

import * as actions from '../../../redux/actions/SchoolsActions'
import CommonHelper from "../../../helpers/CommonHelper";
import UpdateSchoolForm from "../forms/UpdateSchoolForm";

export default function FranchiseeSchoolsPanel(props) {
    const {id} = props;
    const [addModalVisible, setAddModalVisible] = useState(false);
    const dispatch = useDispatch();
    let {currentUser, loading, schools} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.schools.loading,
        schools: state.schools.schoolsMap.toArray().filter(u => (u.franchiseeId == id))
    }), [id]));
    useEffect(() => {
        dispatch(actions.loadAllSchools());
    }, [id]);

    return (
        <Wrapper>


            <ContentPlaceholder>

                <TopPlaceholder>
                    <Button style={{marginLeft: 0}} onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        Добавить школу
                    </Button>
                </TopPlaceholder>

                <SchoolsList>

                    {schools.map((sc, k) => {
                        return (
                            <SchoolItem key={sc.id} onClick={() => {
                                CommonHelper.linkTo(`/school/${sc.id}`);
                            }}>
                                <NamePlaceholder>
                                    {sc.name}
                                </NamePlaceholder>
                                <DescriptionPlaceholder>
                                    {sc.description}
                                </DescriptionPlaceholder>
                            </SchoolItem>
                        )
                    })}

                </SchoolsList>



            </ContentPlaceholder>

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <Heading>
                            Добавление новой школы
                        </Heading>
                        <UpdateSchoolForm onSubmit={d => {
                            d.franchiseeId = id;
                            dispatch(actions.createSchool(d)).then(pld => {
                                setAddModalVisible(false)
                            })
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    
`;

const ContentPlaceholder = styled.div`
    padding-top: 10px;
`;

const SchoolsList = styled.div`
    
`;

const SchoolItem = styled.div`
    padding: 10px;
    background: white;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const NamePlaceholder = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const DescriptionPlaceholder = styled.div`
    
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
`;

const BottomPlaceholder = styled.div`
    
`;
