import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ChessTemplate from '../templates/ChessTemplate'
import TeacherPersonalPanel from "../teachers/panels/TeacherPersonalPanel";

export default function TeacherIndexApp(props) {
    const dispatch = useDispatch();
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    return (
        <ChessTemplate active={'index'}>

            <TeacherPersonalPanel id={currentUserId} />

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
