import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment-timezone';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import MonthCalendar from "../../calendars/MonthCalendar";
import {DEFAULT_TIMEZONE} from "../../../constants/config";
import {DARK_SIDE} from "../../ui/Colors";
import WeekCalendar from "../../calendars/WeekCalendar";
import CommonHelper from "../../../helpers/CommonHelper";


export default function LessonsWeekCalendar(props) {
    let {
        lessons = [], selectedId, onLessonClick = (lId, l) => {

        }, getRoom = a => undefined,
        getGroup = a => undefined,
        shouldShowRoom = true
    } = props;
    const getHourLessons = t => {
        const from = +moment(t).startOf('hour');
        const to = +from + 3600000;
        return lessons.filter(a => (+a.startTimestamp >= from && a.startTimestamp < to))
    }

    return (
        <Wrapper>

            <WeekCalendar hourContent={t => {
                let lsns = getHourLessons(t);
                if (lsns.length == 0){
                    return null;
                }
                return (
                    <Cell>
                        <LessonsList>
                            {lsns.map((l, k) => {
                                let {name, description, startTimestamp, duration} = l;
                                let dateString = moment(startTimestamp).format('HH:mm');
                                let endTimestamp = +startTimestamp + +duration;
                                let room = getRoom(l.roomId);
                                let group = getGroup(l.groupId);
                                let s = `${group == undefined ? '' : `${group.name}<br/>`}`;
                                if (shouldShowRoom == true){
                                    if (room != undefined){
                                        s = `${s}${room.name}<br/>`;
                                    }
                                }
                                s = `${s}${moment(startTimestamp).format('HH:mm')}-${moment(endTimestamp).format('HH:mm')}`;
                                return (
                                    <LessonItem selected={(selectedId == l.id)} key={l.id} onClick={() => {
                                        onLessonClick(l.id, l);
                                    }}>
                                        <span dangerouslySetInnerHTML={{__html: s}} ></span>
                                        {/*{dateString} {room == undefined ? null : ` ${room.name}`}*/}
                                    </LessonItem>
                                )
                            })}
                        </LessonsList>
                    </Cell>
                )
            }}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const Cell = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    //padding-left: 12px;
    //padding-top: 12px;
    //padding-right: 5px;
`;

const LessonsList = styled.div`
    
`;

const LessonItem = styled.div`
    padding: 3px;
    border-radius: 6px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    background: ${props => (props.selected == true ? `#EB131F` : DARK_SIDE)};
    color: white;
    margin: 3px;
    display: inline-block;
    font-size: 12px;
    :hover{
      opacity: 0.7;
    }
    @media print{
      background: none !important;
      color: black !important;
    }
`;
