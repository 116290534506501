import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import './style.css'

export default function ViewNewsItemPanel(props) {
    let {id} = props;
    let [loading, setLoading] = useState(true);
    let [data, setData] = useState(undefined);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/news_item/${id}`).then(d => d.data).then(d => {
            setLoading(false);
            setData(d);
        });
    }, [id]);

    if (data == undefined && loading == true) {
        return (
            <Code/>
        )
    }
    if (data == undefined) {
        return null;
    }

    let {content, avatar, name, description} = data;

    return (
        <Wrapper>

            <NamePlaceholder>
                {name}
            </NamePlaceholder>

            {/*<AvaPlaceholder>*/}
                {/*<Ava src={avatar}/>*/}
            {/*</AvaPlaceholder>*/}

            {content == undefined ? null :
                <ContentPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: content.replace(/\n/g, '<br/>')}}></div>
                </ContentPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ContentPlaceholder = styled.div`
    font-size: 18px;
    line-height: 24px;
`;

const AvaPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const Ava = styled.img`
    height: 200px;
`;

const NamePlaceholder = styled.div`
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 20px;
`;

