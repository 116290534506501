import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Map, Set} from 'immutable'

import * as actions from '../../../redux/actions/GroupsActions'

import Spin from 'arui-feather/spin'

export default function GroupStudentsPanel(props) {
    let {groupId, canEdit = true} = props;
    const {school, group, loading, franchisee, currentUserId, students, allStudents, allUsedStudentsSet} = useMappedState(useCallback(state => {
        let gr = state.groups.groupsMap.get(groupId);
        let sc = (gr == undefined) ? undefined : state.schools.schoolsMap.get(gr.schoolId);
        const allGroups = state.groups.groupsMap.toArray();
        let allUsedStudentsSet = Set();
        for (let i in allGroups){
            for (let j in allGroups[i].studentsIds){
                allUsedStudentsSet = allUsedStudentsSet.add(allGroups[i].studentsIds[j])
            }
        }
        return {
            group: gr,
            school: sc,
            loading: state.groups.loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            franchisee: state.users.usersMap.get(sc.franchiseeId),
            allUsedStudentsSet: allUsedStudentsSet,
            allStudents: state.users.usersMap.toArray().filter(a => (a.userRole == 'student' && franchisee != undefined && a.franchiseeId == franchisee.id)),
            students: state.users.usersMap.toArray().filter(st => ((gr != undefined) && (gr.studentsIds.indexOf(st.id) > -1)))
        }
    }, [groupId]));
    const dispatch = useDispatch();
    if (school == undefined) {
        return null;
    }
    console.log('GroupStudentsPanel: render: group, school = ', group, school);
    console.log('students, allStudents = ', students, allStudents);

    const rightStudents = allStudents.filter((a => ((students.map(st => st.id)).indexOf(a.id) == -1))).filter(a => !allUsedStudentsSet.has(a.id));
    console.log('render: rightStudents = ', rightStudents);

    return (
        <Wrapper>

            <Half>
                <Heading>
                    Ученики в группе ({students.length}) <Spin visible={loading}/>
                </Heading>
                <SelectedStudentsList>
                    {students.map((st, k) => {
                        let {firstName, lastName} = st;
                        let name = `${lastName} ${firstName}`;
                        return (
                            <StudentItem key={st.id}>
                                <Name>
                                    {name}
                                </Name>
                                {canEdit == false ? null :
                                    <CrossPlaceholder>
                                        <CrossImg src={require('../../../assets/images/cross_cancel.svg')}
                                                  onClick={() => {
                                                      let newStudentsIds = group.studentsIds.filter(id => (id != st.id));
                                                      let d = {
                                                          id: group.id,
                                                          studentsIds: newStudentsIds
                                                      };
                                                      dispatch(actions.updateGroup(d));
                                                  }}/>
                                    </CrossPlaceholder>
                                }
                            </StudentItem>
                        )
                    })}
                </SelectedStudentsList>
            </Half>

            {rightStudents.length == 0 || canEdit == false ? null :
                <Half>
                    <Heading>
                        Все ученики
                    </Heading>
                    <SubDescription>
                        Выберите учеников из списка
                    </SubDescription>
                    <AllStudentsList>
                        {rightStudents.map((st, k) => {
                            let {firstName, lastName} = st;
                            let name = `${lastName} ${firstName}`;
                            return (
                                <StudentItem style={{cursor: 'pointer'}} key={st.id} onClick={() => {
                                    let newStudentsIds = group.studentsIds.concat(st.id);
                                    let d = {
                                        id: group.id,
                                        studentsIds: newStudentsIds
                                    };
                                    dispatch(actions.updateGroup(d));
                                }}>
                                    <Name>
                                        {name}
                                    </Name>
                                </StudentItem>
                            )
                        })}
                    </AllStudentsList>
                </Half>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    display: flex;
`;

const SelectedStudentsList = styled.div`
    
`;

const AllStudentsList = styled.div`
    
`;

const StudentItem = styled.div`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;    
`;

const Half = styled.div`
    flex: 1;
    border-right: 1px solid whitesmoke;
    :first-of-type{
      padding-right: 5px;
    }
    :last-of-type{
      border-right: none;
      padding-left: 5px;
    }
`;

const CrossPlaceholder = styled.div`
    
`;

const CrossImg = styled.img`
    height: 18px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Heading = styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const SubDescription = styled.div`
    opacity: 0.7;
    font-size: 12px;
    font-style: italic;
`;
