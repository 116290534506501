import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {Input} from "../../ui/Input";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'

export default function UpdateItemTool(props) {
    let {
        name,
        buttonName,
        buttonUrl,
        avatar,
        videoUrl,
        videoUrl360p,
        videoUrl720p,
        videoUrl480p,
        videoUrl1080p,
        videoEmbedUrl,
        isExperiment = false,
        onChange = d => {

        },
        onDelete = () => {

        },
        onSave = () => {

        },
        canDelete = false,
        loading = false
    } = props;

    const fd = useRef({
        name: props.name,
        buttonName: props.buttonName,
        buttonUrl: props.buttonUrl,
        avatar: props.avatar,
        videoUrl: props.videoUrl,
        videoUrl360p: props.videoUrl360p,
        videoUrl720p: props.videoUrl720p,
        videoUrl480p: props.videoUrl480p,
        videoUrl1080p: props.videoUrl1080p,
        videoEmbedUrl: props.videoEmbedUrl,
        isExperiment: props.isExperiment
    });
    const [lastUpdated, setLastUpdated] = useState('sabir');

    // const updateData = (key, val) => setFormData({...formData, [key]: val});
    // console.log('render: formData = ', formData);
    const updateData = useCallback(d => {
        fd.current = {...fd.current, ...d};
        // console.log('updateData: fd.current = ', fd.current);
        setLastUpdated(+new Date());
    }, []);

    // console.log('render: fd = ', fd);
    const curr = fd.current;

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={curr.name} onChange={evt => {
                    // setFormData({...formData, name: evt.target.value});
                    updateData({name: evt.target.value});
                }}/>
            </Field>

            <Field>
                <Label>
                    Название кнопки
                </Label>
                <Input value={curr.buttonName} onChange={evt => {
                    // setFormData({...formData, buttonName: evt.target.value});
                    updateData({buttonName: evt.target.value});
                }}/>
            </Field>

            <Field>
                <Label>
                    Файл в кнопке
                </Label>
                {(curr.buttonUrl == undefined || curr.buttonUrl.trim() == '') ? null :
                    <div key={lastUpdated}>
                        <a href={curr.buttonUrl} target={'_blank'}>
                            скачать
                        </a>
                        <span style={{cursor: 'pointer', opacity: 0.5, marginLeft: 10,
                            color: 'red', fontSize: 12}} onClick={() => {
                            updateData({buttonUrl: undefined});
                        }}>
                            удалить файл
                        </span>
                    </div>
                }

                <SimpleUploadPanel onUploaded={url => {
                    // setFormData({...formData, buttonUrl: url});
                    updateData({buttonUrl: url});
                }}>
                    <div>
                        Загрузить файл
                    </div>
                </SimpleUploadPanel>
            </Field>

            <Field>
                <Label>
                    Обложка
                </Label>
                <ImagePlaceholder key={lastUpdated}>
                    {curr.avatar == undefined ? null :
                        <Image src={curr.avatar}/>
                    }
                    <SimpleUploadPanel onUploaded={url => {
                        setTimeout(() => {
                            // console.log('formData before: ', formData);
                            // setFormData({...formData, avatar: url});
                            updateData({avatar: url});
                        }, 200);
                    }}>
                        <div>
                            Загрузить обложку
                        </div>
                    </SimpleUploadPanel>
                </ImagePlaceholder>
            </Field>

            <Field>
                <Label>
                    Является экспериментом
                </Label>
                <div>
                    <ExpTogglerClickSpan onClick={() => {
                        // setFormData({...formData, isExperiment: !formData.isExperiment});
                        updateData({isExperiment: !curr.isExperiment});
                    }}>
                        {curr.isExperiment == true ? 'да' : 'нет'}
                    </ExpTogglerClickSpan>
                </div>
            </Field>

            <Field>
                <Label>
                    Прямая ссылка на видео
                </Label>
                <Input value={curr.videoUrl} onChange={evt => {
                    // setFormData({...formData, videoUrl: evt.target.value});
                    updateData({videoUrl: evt.target.value});
                }}/>
            </Field>

            <Field>
                <Label>
                    Embed URL
                </Label>
                <Input value={curr.videoEmbedUrl} onChange={evt => {
                    // setFormData({...formData, videoEmbedUrl: evt.target.value});
                    updateData({videoEmbedUrl: evt.target.value});
                }}/>
            </Field>

            <Field>
                <Label>
                    Прямая ссылка на видео 360p
                </Label>
                <Input value={curr.videoUrl360p} onChange={evt => {
                    // setFormData({...formData, videoUrl360p: evt.target.value});
                    updateData({videoUrl360p: evt.target.value});
                }}/>
            </Field>

            <Field>
                <Label>
                    Прямая ссылка на видео 480p
                </Label>
                <Input value={curr.videoUrl480p} onChange={evt => {
                    // setFormData({...formData, videoUrl480p: evt.target.value});
                    updateData({videoUrl480p: evt.target.value});
                }}/>
                <div style={{fontSize: 12}} >
                    {`https://os.mipt.ru/#/video/${window.btoa(curr.videoUrl480p || '')}`}
                </div>
            </Field>

            <Field>
                <Label>
                    Прямая ссылка на видео 720p
                </Label>
                <Input value={curr.videoUrl720p} onChange={evt => {
                    // setFormData({...formData, videoUrl720p: evt.target.value});
                    updateData({videoUrl720p: evt.target.value});
                }}/>
                <div style={{fontSize: 12}} >
                    {`https://os.mipt.ru/#/video/${window.btoa(curr.videoUrl720p || '')}`}
                </div>
            </Field>

            <Field>
                <Label>
                    Прямая ссылка на видео 1080p
                </Label>
                <Input value={curr.videoUrl1080p} onChange={evt => {
                    // setFormData({...formData, videoUrl1080p: evt.target.value});
                    updateData({videoUrl1080p: evt.target.value});
                }}/>
                <div style={{fontSize: 12}} >
                    {`https://os.mipt.ru/#/video/${window.btoa(curr.videoUrl1080p || '')}`}
                </div>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave(curr);
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

            {canDelete == false ? null :
                <Field>
                    <Label>
                        Удаление
                    </Label>

                    <div onClick={() => {
                        if (window.confirm('Вы уверены?') == false) {
                            return;
                        }
                    }}>
                        <span style={{color: 'red', cursor: 'pointer'}} onClick={() => {
                            onDelete();
                        }}>
                            удалить
                        </span>
                    </div>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const Image = styled.img`
    height: 80px;
`;

const ImagePlaceholder = styled.div`
    
`;

const ExpTogglerClickSpan = styled.span`
    cursor: pointer;
    opacity: 0.5;
    font-weight: bold;
    :hover{
      opacity: 0.8;
    }
`;
