import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled, {keyframes} from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import Parse from "parse";
import CommonHelper from "../../helpers/CommonHelper";
import {FACELESS_AVATAR} from "../../constants/config";

import {zoomIn} from 'react-animations'

const zoomInAnimation = keyframes`${zoomIn}`;

const renderCrumbs = (active) => {
    let links = LEFT_LINKS.filter(lnk => lnk.name == active);
    if (links.length == 0) {
        return null;
    }
    let label = links[0].label;
    return (
        <Crumb>
            {label}
        </Crumb>
    )
}

export default function DistengTemplate(props) {
    let {active, customMenuContent} = props;
    const dispatch = useDispatch();
    let {user, loading, franchiseeSchools} = useMappedState(useCallback(state => {
        let cU = state.users.usersMap.get(state.users.currentUserId);
        let uId = cU.id;
        let schoolsIds = [];
        if (cU != undefined && cU.userRole == 'manager' && cU.schoolsIds != undefined) {
            schoolsIds = cU.schoolsIds;
        }
        let franchiseeSchools = state.schools.schoolsMap.toArray().filter(sc => {
            if (cU.userRole == 'admin') {
                return true;
            }
            let f = (sc.franchiseeId == uId);
            if (schoolsIds.length > 0) {
                f = (schoolsIds.indexOf(sc.id) > -1);
            }
            return f;
        });
        return ({
            user: cU,
            loading: state.users.loading,
            franchiseeSchools: franchiseeSchools
        })
    }, []));
    let ava = (user == undefined) ? undefined : user.avatar;
    if (ava == undefined) {
        ava = FACELESS_AVATAR;
    }

    return (
        <Wrapper className={''}>

            <InnerPlaceholder>

                <Left>

                    <TopLeftPlaceholder>
                        <LogoPlaceholder>
                            <Logo src={require('../../assets/images/top_logo_chev.svg')}/>
                            <LogoText>
                                Физтех Регионам
                            </LogoText>
                        </LogoPlaceholder>
                    </TopLeftPlaceholder>

                    <LinksPlaceholder>
                        {LEFT_LINKS.map((item, k) => {
                            let {name, url, label, icon} = item;
                            let isActive = (name == active);
                            return (
                                <LinkItem selected={isActive} key={k} onClick={() => {
                                    CommonHelper.linkTo(url);
                                }}>
                                    <IconImage selected={isActive} src={icon}/>
                                    <LinkLabelText selected={isActive}>
                                        {label}
                                    </LinkLabelText>
                                </LinkItem>
                            )
                        })}

                        <LogoutLinkItem selected={false}>
                            <IconImage selected={false} src={require('../../assets/images/logout.png')}/>
                            <LinkLabelText selected={false} onClick={() => {
                                Parse.User.logOut().then(() => {
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 5);
                                    CommonHelper.linkTo('/');
                                })
                            }}>
                                Выход
                            </LinkLabelText>
                        </LogoutLinkItem>

                    </LinksPlaceholder>

                </Left>

                <Right>

                    <MainHeader>

                        <RightTopLeftContentPlaceholder>
                            {customMenuContent == undefined ? renderCrumbs(active) : customMenuContent}
                        </RightTopLeftContentPlaceholder>

                        <UserInfoPlaceholder>
                            <Avatar key={(user == undefined) ? 'key' : user.updatedTimestamp} avatar={ava}/>
                            {user == undefined ? null :
                                <UserName>
                                    {`${user.firstName} ${user.lastName}`}
                                </UserName>
                            }
                        </UserInfoPlaceholder>

                    </MainHeader>

                    {props.children}

                </Right>

            </InnerPlaceholder>

        </Wrapper>
    );
}


const LEFT_LINKS = [
    {
        label: 'Главная',
        url: '/',
        name: 'index',
        icon: require('../../assets/images/chinese-house.png')
    },
    {
        label: 'Курсы',
        url: '/courses',
        name: 'courses',
        icon: require('../../assets/images/instructions.png')
    },
    // {
    //     label: 'Ученики',
    //     url: '/students',
    //     name: 'students',
    //     icon: require('../../assets/images/students.png')
    // },
    // {
    //     label: 'Расписание',
    //     url: '/lessons',
    //     name: 'lessons',
    //     icon: require('../../assets/images/event_calendar.png')
    // },
    // {
    //     label: 'Чат',
    //     url: '/chat',
    //     name: 'chat',
    //     icon: require('../../assets/images/speech-bubble.png')
    // },
    // {
    //     label: 'Новости',
    //     url: '/news',
    //     name: 'news',
    //     icon: require('../../assets/images/ring.svg')
    // },
    // {
    //     label: 'Инструкции',
    //     url: '/instructions',
    //     name: 'instructions',
    //     icon: require('../../assets/images/instructions.png')
    // },

    {
        label: 'Настройки',
        url: '/settings',
        name: 'settings',
        icon: require('../../assets/images/settings-gears.png')
    },
    // {
    //     label: 'Поддержка',
    //     url: '/support',
    //     name: 'support',
    //     icon: require('../../assets/images/support.png')
    // }

];

const mainColor = '#F1F5FB';
const selectedColor = '#E3EBF8';

const mainWidth = 1200;

const MainHeader = styled.div`
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    margin-top: 10px;
    position: relative;
    height: 30px;
    margin-bottom: 10px;
`;

const avatarSize = 30;

const Avatar = styled.div`
    display: inline-block;
    border-radius: 1000px;
    height: ${avatarSize}px;
    width: ${avatarSize}px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('${props => (props.avatar)}');
    box-sizing: border-box;
    margin-left: 5px;
    float: right;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const UserBalanceSpanPlaceholder = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    margin-right: 5px;
    :hover{
      text-decoration: underline;
      opacity: 0.8;  
    }
`;

const UserName = styled.div`
    display: inline-block;
    vertical-align: middle;
    float: right;
`;

const UserInfoPlaceholder = styled.div`
    position: absolute;
    right: 10px;
    line-height: 30px;
`;

const RightTopLeftContentPlaceholder = styled.div`
    position: absolute;
    left: 10px;
    line-height: 30px;
    font-size: 20px;
`;


const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-color: ${mainColor};
    font-family: 'Noto Sans', sans-serif;
    position: relative;
`;

const ModalInner = styled.div`
    padding-bottom: 10px;
`;

const InnerPlaceholder = styled.div`
    width: ${mainWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: ${mainWidth}px){
      width: 100%;
    }
`;

const TopLeftPlaceholder = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;

const LogoPlaceholder = styled.div`
    line-height: 40px;
    animation: 1s ${zoomInAnimation};
`;

const Logo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: top;
    display: inline-block;
`;

const LogoText = styled.div`
    vertical-align: top;
    display: inline-block;
    font-size: 20px;
`;

const LinksPlaceholder = styled.div`
    position: relative;
    height: calc(100vh - 60px);  
`;

const Crumb = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const IconImage = styled.img`
    display: inline-block;
    margin-right: 7px;
    width: 24px;
    height: 24px;
    opacity: ${props => (props.selected == true ? 1 : 0.45)};
`;

const LinkLabelText = styled.div`
    display: inline-block;
    vertical-align: top;
    opacity: ${props => (props.selected == true ? 1 : 0.8)};
`;

const LinkItem = styled.div`
    padding: 7px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 18px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${props => (props.selected == false ? mainColor : selectedColor)};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    :hover {
      background-color: #E3EBF8;
    }
`;

const LogoutLinkItem = styled.div`
    position: absolute;
    bottom: 10px;
    width: 100%;
    left: 0px;
    padding: 7px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-size: 18px;
    line-height: 24px;
    background-color: ${props => (props.selected == false ? mainColor : selectedColor)};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    :hover {
      background-color: #E3EBF8;
    }
`;

const leftWidth = 200;

const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: ${leftWidth}px;
    box-sizing: border-box;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
`;

const Right = styled.div`
    position: absolute;
    
    display: inline-block;
    vertical-align: top;
    width: calc(100vw - ${leftWidth}px - (100vw - ${mainWidth}px) / 2.0);
    left: calc((100vw - ${mainWidth}px) / 2.0 + ${leftWidth}px);
    padding-right: calc((100vw - ${mainWidth}px) / 2);
    top: 0px;
    box-sizing: border-box;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    
    padding-left: 20px;
    
    @media(max-width: ${mainWidth}px){
      width: calc(100vw - ${leftWidth}px);
      padding-right: 0px;
      left: ${leftWidth}px;
    }
`;
