import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'

export default function UpdateProgramForm(props) {
    let {
        onSubmit = () => {
        }, loading = false,
        canEdit = true,
        canDelete = false,
        onDelete = () => {

        }
    } = props;
    console.log('render: UpdateProgramForm props = ', props);
    const [name, setName] = useState((props.name == undefined) ? '' : props.name);
    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    console.log('name, description = ', name, description);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                {canEdit == true ?
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value);
                    }}/> :
                    <div>
                        {name}
                    </div>
                }
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                {canEdit == true ?
                    <Textarea value={description} onChange={evt => {
                        setDescription(evt.target.value);
                    }}/> : <div dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}></div>
                }

            </Field>

            {canEdit == false ? null :
                <Field>
                    <div>
                        <Button onClick={() => {
                            if (loading == true) {
                                return;
                            }
                            onSubmit({name, description});
                        }}>
                            {loading == true ?
                                <Spin visible={true}/>
                                :
                                <span>
                                Сохранить
                            </span>
                            }

                        </Button>

                    </div>
                    {canDelete == false ? null :
                        <div style={{marginTop: 30}}>
                            <DangerSpan onClick={() => {
                                if (window.confirm('Вы уверены?') == false) {
                                    return;
                                }
                                onDelete();
                            }}>
                                удалить
                            </DangerSpan>
                        </div>
                    }

                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const DangerSpan = styled.span`
    color: red;
    :hover{
      opacity: 0.88;
    }
`;
