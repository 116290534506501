/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    tasksMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const TasksReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_TASK:
        case types.UPDATE_TASK:
        case types.DELETE_TASK:
        case types.LOAD_TASKS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_TASK_FAIL:
        case types.UPDATE_TASK_FAIL:
        case types.DELETE_TASK_FAIL:
        case types.LOAD_TASKS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_TASK_SUCCESS:
        case types.UPDATE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasksMap: state.tasksMap.set(action.task.id, action.task)
            }


        case types.DELETE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasksMap: state.tasksMap.delete(action.id),
            }


        case types.LOAD_TASKS_SUCCESS:
            return {
                ...state,
                loading: false,
                tasksMap: state.tasksMap
                    .merge(action.tasks.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default TasksReducer;
