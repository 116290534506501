import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import CentersViewPanel from "../russia/panels/CentersViewPanel";
import KeyValueAPI from "../../api/KeyValueAPI";
import {Code} from "react-content-loader";

export default function LandingAboutApp(props) {
    let {id, subj} = props.match.params;
    const bgImage = undefined; //todo: change

    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        KeyValueAPI.getParam('os_about').then(x => {
            setText(x);
            setLoading(false);
        });
    }, []);

    // if (loading == true) {
    //     return <Code/>;
    // }

    return (
        <LandingTemplate active={'about'} subj={subj}>

            <FullPageImageSection height={`220px`} hasTopLogo={false} image={bgImage}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel motto={(
                        <div>
                            О ПРОЕКТЕ
                        </div>
                    )}
                                       hasTopLogo={false}
                    />
                </div>
            </FullPageImageSection>

            <BottomContentPlaceholder>

                <RespContainer>


                    <Wrapper>

                        <MapPlaceholder>
                            <CentersViewPanel hasTable={false} hasLogo={true}/>
                        </MapPlaceholder>

                        {text == undefined ? <Code /> :
                            <div dangerouslySetInnerHTML={{__html: text}}></div>
                        }


                        {/*<P>*/}
                        {/*    С сентября 2019 года стартовал проект по работе со школьниками «Физтех - регионам».*/}
                        {/*</P>*/}

                        {/*<H>*/}
                        {/*    Цель проекта*/}
                        {/*</H>*/}

                        {/*<P>*/}
                        {/*    Поиск, сопровождение и поддержка талантливых школьников из регионов России и развитие*/}
                        {/*    олимпиадного движения.*/}
                        {/*</P>*/}
                        {/*<P>*/}
                        {/*    На сегодняшний день в проекте принимает участие более 30 регионов Российской Федерации.*/}
                        {/*</P>*/}

                        {/*<P>*/}
                        {/*    На портале проекта представлены:*/}
                        {/*</P>*/}

                        {/*<ol>*/}
                        {/*    <li>*/}
                        {/*        <b>Программы обучения</b> по классам (7 - 10) со списком тем и оптимальными сроками*/}
                        {/*        их прохождения для подготовки к различным этапам Всероссийской олимпиады школьников по*/}
                        {/*        физике.*/}
                        {/*    </li>*/}


                        {/*    <li>*/}
                        {/*        <b>Видеоконтент</b>, записанный для каждой темы программы, включающий:*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                видеолекции с изложением основных положений теории;*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                видеосеминары с примерами решения базовых задач;*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                видеосеминары с примерами решения олимпиадных задач;*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                видеозадания для подготовки к экспериментальному туру Всероссийской олимпиады*/}
                        {/*                школьников по физике с описанием используемого оборудования;*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                видео, содержащее решение одной или нескольких задач экспериментального тура.*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </li>*/}


                        {/*    <li>*/}
                        {/*        <b>Методические материалы</b> в формате pdf файлов, дополняющие видеозаписи занятий:*/}

                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                краткое методическое пособие, содержащее в себе основные теоретические положения*/}
                        {/*                и примеры;*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                условия задач, которые разбираются на видеосеминарах;*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                подборка задач к каждой теме для самостоятельного решения или решения под руководством преподавателя;*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                условия экспериментальных заданий, демонстрируемых на соответствующем видео.*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </li>*/}


                        {/*    <li>*/}
                        {/*        <b>*/}
                        {/*            Дополнительные видеоматериалы:*/}
                        {/*        </b>*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                Курс экспериментальной физики.*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                Культура построения графиков.*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                Математика в физике.*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </li>*/}
                        {/*</ol>*/}

                        {/*<P>*/}
                        {/*    Регионам, с которыми заключено Соглашение о сотрудничестве (партнерстве), дополнительно предоставляются следующие возможности:*/}
                        {/*</P>*/}

                        {/*<ul>*/}
                        {/*    <li>*/}
                        {/*        5-8 домашних заданий для школьников, решения которых нужно загружать в личный кабинет ученика в виде pdf – файла. После проверки с результатами и рецензией можно будет ознакомиться в личном кабинете;*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*        возможность взаимодействия с куратором класса (преподавателем МФТИ) по методическим и иным вопросам;*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*        выезды в регионы преподавателей МФТИ для проведения очных занятий с школьниками по предварительной договоренности.*/}
                        {/*    </li>*/}
                        {/*</ul>*/}

                        {/*<P>*/}
                        {/*    {' '}*/}
                        {/*</P>*/}


                        {/*<H>*/}
                        {/*    Как региону заключить Соглашение о сотрудничестве (партнерстве) с МФТИ для участия в проекте?*/}
                        {/*</H>*/}
                        {/*<P>*/}
                        {/*    <ol>*/}
                        {/*        <li>*/}
                        {/*            От регионального центра отправляется заявка на имя проректора по учебной работе*/}
                        {/*            А.А. Воронова для участия в проекте сетевой олимпиадной школы «Физтех -*/}
                        {/*            регионам» за подписью руководителя образовательного (дополнительного*/}
                        {/*            образовательного) учреждения, на базе которого организована площадка.*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            Данная заявка рассматривается руководящим составом проекта.*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            После утверждения заявки подписывается Соглашение о сотрудничестве (партнерстве) между образовательным (дополнительным образовательным) учреждением и МФТИ на основании которого ведется дальнейшее взаимодействие по проекту.*/}
                        {/*        </li>*/}
                        {/*    </ol>*/}
                        {/*</P>*/}

                        {/*<P>*/}
                        {/*    Региональный центр, получивший дополнительные возможности для работы с сайтом, обязан организовать своим*/}
                        {/*    школьникам факультатив по олимпиадной физике. У обучающихся должна быть возможность посещать*/}
                        {/*    занятия, которые проводятся по расписанию образовательного (дополнительного образовательного) учреждения (не менее 2-х часов в неделю).*/}
                        {/*</P>*/}

                        {/*<H>*/}
                        {/*    Информация для школьников, где не открыта площадка проекта*/}
                        {/*</H>*/}

                        {/*<P>*/}
                        {/*    Школьники, которые хотят стать участниками проекта, но на сегодняшний день проживают в тех регионах, где пока не открыта площадка, могут отправить письмо на электронную почту административного отдела проекта osop@mipt.ru, указав свой регион, место обучения, приложив скан справки из учебного учреждения, электронный адрес и контактный номер телефона.*/}
                        {/*</P>*/}


                        {/*<H>*/}
                        {/*    Общие рекомендации по организации занятий*/}
                        {/*</H>*/}

                        {/*<P>*/}
                        {/*    Все методические материалы, расположенные на сайте os.mipt.ru, носят вспомогательный характер и являются инструментом для изучения олимпиадной физики школьниками. Оптимальная схема организации занятий в рамках проекта выглядит так:*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            проводится собрание с родителями (законными представителями) и школьниками (далее –*/}
                        {/*            участники проекта), где сообщается информация о проекте;*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            формируются списки участников по классам с контактными данными (ФИО, номер телефона, e-mail), необходимыми для*/}
                        {/*            регистрации, которые направляются в администрацию проекта по электронной почте {' '}*/}
                        {/*            <a href="mailto:osop@mipt.ru">osop@mipt.ru</a>;*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            происходит регистрация участников проекта на портале;*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            участники имеют возможность просматривать лекции по теории, блокам базовых и олимпиадных задач;*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            раз в месяц участники выполняют большое домашнее задание (далее по тексту - БДЗ) и загружают в личный кабинет сканы/фото оформленных решений для проверки;*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            в течение недели в личных кабинетах участников появляются результаты проверки БДЗ с*/}
                        {/*            замечаниями и рекомендациями;*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            еженедельное посещение факультатива в региональном центре, который проводит учитель по дополнительной общеобразовательной программе "Олимпиадная физика".*/}
                        {/*        </li>*/}

                        {/*    </ul>*/}
                        {/*</P>*/}

                        {/*<P>*/}
                        {/*    В конце учебного года школьник, успешно прошедший обучение в сетевой олимпиадной школе по программе «Олимпиадная физика» в рамках проекта «Физтех-регионам», получает сертификат, а по результатам выполненных БДЗ проводится награждение лучших участников проекта.*/}
                        {/*</P>*/}

                    </Wrapper>

                </RespContainer>


            </BottomContentPlaceholder>

        </LandingTemplate>
    );
}

const P = styled.p`

`;

const H = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${BLUE};
  margin-bottom: -10px;
`;

const H2 = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${BLUE};
  margin-bottom: -10px;
`;

const H3 = styled.div`
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-bottom: -10px;
`;


const size = 100;

const BottomContentPlaceholder = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  background: white;
`;

const MapPlaceholder = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
`;

const RespContainer = styled.div`
  width: 920px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 24px;
  @media (max-width: 920px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  min-height: 40vh;
  font-size: 18px;
  line-height: 24px;
`;
