import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import useDebouncedCallback from 'use-debounce/lib/callback'

import {Input} from "../../ui/Input";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'
import * as usersActions from "../../../redux/actions/UsersActions";
import UpdateUserForm from "../../users/forms/UpdateUserForm";
import {Set} from "immutable";
import BulkUsersCreationTool from "../../automatic/tools/BulkUsersCreationTool";

import Select from 'react-select'
import UsersPassPanel from "./UsersPassPanel";

import ls from 'local-storage'

export default function AdminAllTeachersPanel(props) {
    const dispatch = useDispatch();
    const {isModerator} = props;

    const [centerId, setCenterId] = useState(ls('centerId'));
    const [bulkAddModalVisible, setBulkAddModalVisible] = useState(false);
    const [searchString, setSearchString] = useState(ls('cc-searchString') == undefined ? '' : ls('cc-searchString'));
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [passModalVisible, setPassModalVisible] = useState(false);
    let {
        currentUser,
        loading,
        userRole,
        teachers,
        currentUserCenterId,
        centersMap,
        allCenters,
        readyEmailsSet
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let teachers = state.users.usersMap.toArray().filter(a => (a.userRole == 'teacher'));
        if (isModerator != undefined) {
            teachers = teachers.filter(x => ((x.isModerator == isModerator) || (isModerator == false && x.isModerator == undefined)));
        }
        return {
            currentUserCenterId: (currentUser == undefined) ? undefined : currentUser.centerId,
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            teachers: teachers,
            centersMap: state.centers.centersMap,
            allCenters: state.centers.centersMap.toArray(),
            readyEmailsSet: teachers.reduce((st, a) => st.add(a.email.toLowerCase()), Set())
        }
    }, []));
    const [debouncedSearch] = useDebouncedCallback((a) => {
        setSearchString(a);
    }, 300, []);

    useEffect(() => {
        ls('cc-searchString', searchString);
    }, [searchString]);

    useEffect(() => {
        if (centerId != undefined) {
            ls('centerId', centerId);
        } else {
            ls.remove('centerId');
        }
    }, [centerId]);

    if (currentUser == undefined || (userRole != 'admin' && !(userRole == 'teacher' || currentUser.isModerator == true))) {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    let cId = (userRole == 'admin') ? centerId : currentUserCenterId;

    const sortedTeachers = teachers.filter(a => {
        let {firstName, lastName, email} = a;
        let s = `${lastName} ${firstName} ${email}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }).sort((a, b) => {
        let s1 = `${a.lastName} ${a.firstName}`.toLowerCase();
        let s2 = `${b.lastName} ${b.firstName}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    }).filter(a => (a.centerId == cId || undefined == cId));

    const centersOptions = allCenters.map(a => ({value: a.id, label: `${a.city}, ${a.name}`})).sort((a, b) => {
        if (a.label > b.label) {
            return 1;
        }
        if (a.label < b.label) {
            return -1;
        }
        return 0;
    });

    console.log('AdminAllTeachersPanel: currentUser = ', currentUser);

    return (
        <Wrapper>

            <Heading>
                <b>
                    {isModerator == true ? 'Координаторы' : 'Преподаватели'} ({sortedTeachers.length})
                </b>
                {(userRole != 'admin' && (currentUser != undefined && currentUser.isModerator == false)) ? null :
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        Добавить преподавателя
                    </Button>
                }
            </Heading>

            {userRole != 'admin' ? null :
                <React.Fragment>
                    {centerId == undefined ? null :
                        <div>
                    <span onClick={() => {
                        setBulkAddModalVisible(true);
                    }}>
                        массовая добавка учителей
                    </span>
                            <span style={{marginLeft: 10}} onClick={() => {
                                setPassModalVisible(true);
                            }}>
                        таблица с паролями
                    </span>
                        </div>
                    }
                </React.Fragment>
            }

            <TopSearchPlaceholder>
                <div>
                    <Input defaultValue={ls('cc-searchString') == undefined ? '' : ls('cc-searchString')}
                           placeholder={'Поиск'} style={{
                        height: 36,
                        lineHeight: `36px`,
                        fontSize: 16
                    }} onChange={evt => {
                        debouncedSearch(evt.target.value);
                    }}/>
                </div>
                {userRole != 'admin' ? null :
                    <div style={{marginTop: 10}}>
                        <Select options={centersOptions} value={centersOptions.filter(a => (a.value == centerId))[0]}
                                isClearable={true}
                                placeholder={'Фильтр по центру'}
                                onChange={a => {
                                    if (a == undefined) {
                                        setCenterId(undefined);
                                    } else {
                                        setCenterId(a.value);
                                    }
                                }}/>
                    </div>
                }

            </TopSearchPlaceholder>

            <PaginatorWrapper renderItems={items => items.map((c, k) => {
                let {id, firstName, lastName, centerId} = c;
                let center = centersMap.get(centerId);
                return (
                    <SchoolItem key={id} onClick={() => {
                        CommonHelper.linkTo(`/teacher/${id}`);
                    }}>
                        <Name>
                            {`${lastName} ${firstName}`}
                        </Name>
                    </SchoolItem>
                )
            })} items={sortedTeachers}/>


            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <UpdateUserForm
                            password={CommonHelper.getPwd()}
                            loading={loading}
                            avatarVisible={true}
                            passwordVisible={true}
                            emailVisible={true}
                            allCenters={allCenters}
                            isTeacher={true}
                            canChangeCenter={(currentUser != undefined && currentUser.userRole == 'admin')}
                            centerId={(currentUser != undefined && currentUser.userRole == 'teacher') ? currentUser.centerId : undefined}

                            onSave={async data => {
                                if (data.centerId == undefined) {
                                    window.alert('Не выбран центр');
                                    return;
                                }
                                let pld = await dispatch(usersActions.createUser({
                                    ...data,
                                    userRole: 'teacher'
                                }));
                                if (pld.error != undefined) {
                                    if (pld.error != undefined && pld.error.message != undefined && pld.error.message.indexOf('already exists') > -1) {
                                        window.alert('Аккаунт с данной почтой уже существует!');
                                    }
                                } else {
                                    setAddModalVisible(false);
                                }
                            }}
                        />
                    </div>
                }

            </Sidebar>

            <Sidebar width={1020} visible={bulkAddModalVisible && centerId != undefined} onCloserClick={() => {
                setBulkAddModalVisible(false);
            }}>

                {setBulkAddModalVisible == false || centerId == undefined ? null :
                    <div>
                        <BulkUsersCreationTool
                            readyEmailsSet={readyEmailsSet}
                            onAdd={async data => {
                                let pld = await dispatch(usersActions.createUser({
                                    ...data,
                                    userRole: 'teacher',
                                    centerId: centerId,
                                    password: CommonHelper.getPwd()
                                }));
                                if (pld.error != undefined) {
                                    // window.alert(JSON.stringify(pld.error));
                                    if (pld.error != undefined && pld.error.message != undefined && pld.error.message.indexOf('already exists') > -1) {
                                        window.alert('Аккаунт с данной почтой уже существует!');
                                    }
                                } else {
                                    // setBulkAddModalVisible(false);
                                }
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={1020} visible={passModalVisible && centerId != undefined} onCloserClick={() => {
                setPassModalVisible(false);
            }}>

                <UsersPassPanel centerId={centerId} userRole={'teacher'}/>

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SchoolsList = styled.div`

`;

const SchoolItem = styled.div`
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  :hover {
    opacity: 0.75;
  }
`;

const Name = styled.div`
  font-weight: bold;
`;

const Heading = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopSearchPlaceholder = styled.div`
  margin-bottom: 10px;
`;
