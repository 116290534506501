import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import KeyValueAPI from "../../../api/KeyValueAPI";

import Tabs, {TabItem} from "../../ui/Tabs";
import ClassDescriptionForm from "../forms/ClassDescriptionForm";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";

import ls from 'local-storage'

import UpdateButtonNameForm from '../forms/UpdateButtonNameForm'
import CommonHelper from "../../../helpers/CommonHelper";

const DEFAULT_VALUE = {
    description: '',
    programImageUrl: undefined,
    programFileUrl: undefined,
    programFileButtonName: ''
}

export default function UpdateClassInfoPanel(props) {
    const {classNumber = 8} = props;
    const [loading, setLoading] = useState(true);
    const [classInfo, setClassInfo] = useState(DEFAULT_VALUE);
    const key = `class_info_${classNumber}`;
    useEffect(() => {
        KeyValueAPI.getParam(key, DEFAULT_VALUE).then(d => {
            setClassInfo(d);
            setLoading(false);
        });
    }, [classNumber]);

    let {description, programImageUrl} = classInfo;

    return (
        <Wrapper>
            <Tabs tabs={[
                {
                    label: 'Картинка программы',
                    content: (
                        <TabItem>
                            {programImageUrl == undefined ? null :
                                <ProgramImage src={CommonHelper.getSafeUrl(programImageUrl)}/>
                            }
                            <div>
                                <SimpleUploadPanel onUploaded={async url => {
                                    setLoading(true);
                                    let newD = {...classInfo, programImageUrl: url};
                                    await KeyValueAPI.saveParam(key, newD);
                                    setClassInfo(newD);
                                    setLoading(false);
                                }}>
                                    загрузить картинку
                                </SimpleUploadPanel>
                            </div>
                        </TabItem>
                    )
                },
                {
                    label: 'Текст программы',
                    content: (
                        <TabItem>
                            <ClassDescriptionForm loading={loading} description={description} onSave={async d => {
                                setLoading(true);
                                let disc = ls('sel_discipline');
                                let newD = {...classInfo, ...d};
                                if (['phys', 'alg', 'geom', 'astro'].indexOf(disc) > -1){
                                    newD[disc] = d;
                                }
                                await KeyValueAPI.saveParam(key, newD);
                                setClassInfo(newD);
                                setLoading(false);
                            }}/>
                        </TabItem>
                    )
                },
                {
                    label: 'Прикрепляемый к программе файл',
                    content: (
                        <TabItem>
                            {(classInfo.programFileUrl == undefined || classInfo.programFileUrl.programFileUrl == '') ? null :
                                <div>
                                    <div>
                                        <a href={classInfo.programFileUrl} target={'_blank'}>
                                            скачать
                                        </a>
                                        <span style={{marginLeft: 10, opacity: 0.5, fontSzie: 12, color: 'red'}}
                                              onClick={async () => {
                                                  setLoading(true);
                                                  let newD = {...classInfo};
                                                  delete newD.programFileUrl;
                                                  await KeyValueAPI.saveParam(key, newD);
                                                  setClassInfo(newD);
                                                  setLoading(false);
                                              }}>
                                            удалить
                                        </span>
                                    </div>
                                </div>
                            }
                            <div>
                                <SimpleUploadPanel onUploaded={async url => {
                                    setLoading(true);
                                    let newD = {...classInfo, programFileUrl: url};
                                    await KeyValueAPI.saveParam(key, newD);
                                    setClassInfo(newD);
                                    setLoading(false);
                                }}>
                                    загрузить файл
                                </SimpleUploadPanel>
                            </div>
                            <div style={{marginTop: 20}}>
                                <UpdateButtonNameForm
                                    loading={loading}
                                    programFileButtonName={classInfo.programFileButtonName}
                                    onSave={async d => {
                                        setLoading(true);
                                        let newD = {...classInfo, ...d};
                                        await KeyValueAPI.saveParam(key, newD);
                                        setClassInfo(newD);
                                        setLoading(false);
                                    }}
                                />
                            </div>
                            {/*<ClassDescriptionForm l
                            oading={loading} description={description} onSave={async d => {*/}
                            {/*setLoading(true);*/}
                            {/*let newD = {...classInfo, ...d};*/}
                            {/*await KeyValueAPI.saveParam(key, newD);*/}
                            {/*setClassInfo(newD);*/}
                            {/*setLoading(false);*/}
                            {/*}}/>*/}
                        </TabItem>
                    )
                }
            ]}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ProgramImage = styled.img`
    height: 120px;
`;
