import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import {Container, Heading} from "../ui/Container";
import CommonHelper from "../../helpers/CommonHelper";

import CoursePanel from '../courses/panels/CoursePanel'
import DistengTemplate from "../templates/DistengTemplate";
import ViewCoursePanel from "../courses/panels/ViewCoursePanel";

import * as actions from '../../redux/actions/CoursesActions'
import UnitView from "../units/view/UnitView";

import {Code} from 'react-content-loader'

export default function StudentUnitApp(props) {
    let {courseId, unitId} = props.match.params;
    const dispatch = useDispatch();
    const {course, unit} = useMappedState(useCallback(state => ({
        course: state.courses.coursesMap.get(courseId),
        unit: state.courses.unitsMap.get(unitId)
    }), [courseId, unitId]));

    useEffect(() => {
        dispatch(actions.loadCourseUnits(courseId));
        dispatch(actions.loadCourseById(courseId));
    }, [courseId, unitId]);


    return (
        <DistengTemplate active={'courses'}>

            <Heading style={{marginBottom: 20}}>
                <TopLink onClick={() => {
                    CommonHelper.linkTo(`/courses`);
                }}>
                    ⬅ Все курсы
                </TopLink>

                {course == undefined ? null :
                    <TopLink style={{marginLeft: 5}} onClick={() => {
                        CommonHelper.linkTo(`/course/${course.id}`);
                    }} >
                        {` / ${course.name}`}
                    </TopLink>
                }
                {unit == undefined ? null :
                    <span>
                        {` / ${unit.name}`}
                    </span>
                }

            </Heading>

            <Container style={{marginBottom: 20}} >
                {unit == undefined ?
                    <Code/> :
                    <UnitView {...unit} canEdit={false}/>
                }

            </Container>

        </DistengTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const TopLink = styled.div`
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
