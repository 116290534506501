import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';

import TeacherProgressApp from './TeacherProgressApp'
import AdminIndexApp from './AdminIndexApp'
import DevApp from './DevApp'
import CourseApp from './CourseApp'
import ObserverIndexApp from './ObserverIndexApp'
import CoursesApp from "./CoursesApp";
import BrandedLoginApp from "./BrandedLoginApp";
import RecoverPasswordApp from "./RecoverPasswordApp";
import StudentsApp from "./StudentsApp";
import AdminNewsApp from "./AdminNewsApp";
import AdminSettingsApp from "./AdminSettingsApp";
import AdminClassProgramsApp from "./AdminClassProgramsApp";
import TeachersApp from "./TeachersApp";
import AdminFranchiseesApp from "./AdminFranchiseesApp";
import AdminSchoolsApp from "./AdminSchoolsApp";
import FranchiseeApp from "./FranchiseeApp";
import SchoolApp from "./SchoolApp";
import AdminSubmissionsApp from "./AdminSubmissionsApp";
import AdminUsersApp from "./AdminUsersApp";
import AdminStudentsApp from "./AdminStudentsApp";
import AdminObserversApp from "./AdminObserversApp";
import AdminTeachersApp from "./AdminTeachersApp";
import FranchiseeIndexApp from "./FranchiseeIndexApp";
import StudentApp from "./StudentApp";
import StudentCourseApp from "./StudentCourseApp";
import TeacherApp from "./TeacherApp";
import ObserverApp from "./ObserverApp";
import DocumentsApp from "./DocumentsApp";
import StudentSubscriptionsApp from "./StudentSubscriptionsApp";
import FranchiseeSubscriptionsApp from "./FranchiseeSubscriptionsApp";
import FranchiseeStaffApp from "./FranchiseeStaffApp";
import FranchiseeStudentsApp from "./FranchiseeStudentsApp";
import LandingApp from "./LandingApp";
import LandingContactsApp from "./LandingContactsApp";
import LandingClassApp from "./LandingClassApp";
import LandingClassProgramApp from "./LandingClassProgramApp";
import LandingAboutApp from "./LandingAboutApp";
import LandingNewsApp from "./LandingNewsApp";
import LandingProgrammsApp from "./LandingProgrammsApp";
import LandingCentersApp from "./LandingCentersApp";
import LandingThemeApp from "./LandingThemeApp";
import AdminTasksApp from "./AdminTasksApp";
import AdminStatsApp from "./AdminStatsApp";

import FullPagePreloader from '../preloader/FullPagePreloader'

import * as ticketsActions from '../../redux/actions/TicketsActions'

import * as centersActions from '../../redux/actions/CentersActions'
import * as usersActions from '../../redux/actions/UsersActions'
import * as transactionsActions from '../../redux/actions/TransactionsActions'
import * as coursesActions from '../../redux/actions/CoursesActions'
import * as schoolsActions from '../../redux/actions/SchoolsActions'
import * as roomsActions from '../../redux/actions/RoomsActions'
import * as groupsActions from '../../redux/actions/GroupsActions'
import * as lessonsActions from '../../redux/actions/LessonsActions'
import * as documentsActions from '../../redux/actions/DocumentsActions'
import * as subscriptionsActions from '../../redux/actions/SubscriptionsActions'
import * as newsItemsActions from '../../redux/actions/NewsItemsActions'
import * as programsActions from '../../redux/actions/ProgramsActions'
import * as themesActions from '../../redux/actions/ThemesActions'
import * as announcementsActions from '../../redux/actions/AnnouncementsActions'

import PreLandingApp from "./PreLandingApp";
import LandingProfileApp from "./LandingProfileApp";
import FranchiseeSchoolsApp from "./FranchiseeSchoolsApp";
import TeacherIndexApp from "./TeacherIndexApp";
import StudentIndexApp from "./StudentIndexApp";
import StudentCoursesApp from "./StudentCoursesApp";
import StudentUnitApp from "./StudentUnitApp";
import StudentSettingsApp from "./StudentSettingsApp";
import AdminThemeApp from "./AdminThemeApp";
import CommonHelper from "../../helpers/CommonHelper";

import AnnouncementModalPanel from '../announcements/panels/AnnouncementModalPanel'

import ls from 'local-storage'

import * as types from '../../redux/ActionTypes'
import UsersAPI from "../../api/UsersAPI";
import ObserverStudentsApp from "./ObserverStudentsApp";
import VideoApp from "./VideoApp";

const isForcedLanding = () => {
    return (ls('force_landing') == true);
}

const adminRoute = (
    <Switch>
        <Route exact path='/video/:hash' component={VideoApp}/>
        <Route exact path='/dev' component={DevApp}/>

        <Route exact path='/programs/number/:number' component={AdminClassProgramsApp}/>

        <Route exact path='/course/:id' component={CourseApp}/>
        <Route exact path='/franchisee/:id' component={FranchiseeApp}/>
        <Route exact path='/school/:id' component={SchoolApp}/>
        <Route exact path='/submissions' component={AdminSubmissionsApp}/>
        <Route exact path='/tasks' component={AdminTasksApp}/>
        <Route exact path='/news' component={AdminNewsApp}/>
        <Route exact path='/settings' component={AdminSettingsApp}/>

        <Route exact path='/teachers' component={AdminTeachersApp}/>
        <Route exact path='/teacher/:id' component={TeacherApp}/>

        <Route exact path='/franchisees' component={AdminFranchiseesApp}/>
        <Route exact path='/schools' component={AdminSchoolsApp}/>
        <Route exact path='/students' component={AdminStudentsApp}/>
        <Route exact path='/users' component={AdminUsersApp}/>

        <Route exact path='/stats' component={AdminStatsApp}/>

        <Route exact path='/observers' component={AdminObserversApp}/>
        <Route exact path='/documents' component={DocumentsApp}/>
        <Route exact path='/student/:id' component={StudentApp}/>
        <Route exact path='/observer/:id' component={ObserverApp}/>

        <Route exact path='/theme/:id' component={AdminThemeApp}/>
        <Route exact path='/theme/:id/experiment' component={AdminThemeApp}/>

        <Route exact path='/:subj/theme/:themeId' component={AdminThemeApp}/>
        <Route exact path='/:subj/theme/:themeId/experiment' component={AdminThemeApp}/>

        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route component={AdminIndexApp}/>
    </Switch>
);

const methodistRoute = (
    <Switch>
        <Route exact path='/video/:hash' component={VideoApp}/>
        <Route exact path='/dev' component={DevApp}/>

        <Route exact path='/programs/number/:number' component={AdminClassProgramsApp}/>

        <Route exact path='/course/:id' component={CourseApp}/>
        <Route exact path='/franchisee/:id' component={FranchiseeApp}/>
        <Route exact path='/school/:id' component={SchoolApp}/>
        <Route exact path='/submissions' component={AdminSubmissionsApp}/>
        <Route exact path='/tasks' component={AdminTasksApp}/>
        <Route exact path='/settings' component={AdminSettingsApp}/>
        <Route exact path='/teachers' component={AdminTeachersApp}/>
        <Route exact path='/franchisees' component={AdminFranchiseesApp}/>
        <Route exact path='/schools' component={AdminSchoolsApp}/>
        <Route exact path='/students' component={AdminStudentsApp}/>

        <Route exact path='/stats' component={AdminStatsApp}/>

        <Route exact path='/observers' component={AdminObserversApp}/>
        <Route exact path='/documents' component={DocumentsApp}/>
        <Route exact path='/student/:id' component={StudentApp}/>
        <Route exact path='/observer/:id' component={ObserverApp}/>

        <Route exact path='/theme/:id' component={AdminThemeApp}/>
        <Route exact path='/theme/:id/experiment' component={AdminThemeApp}/>

        <Route exact path='/teacher/:id' component={TeacherApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route component={AdminIndexApp}/>
    </Switch>
);

const observerRoute = (
    <Switch>
        <Route exact path='/video/:hash' component={VideoApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/submissions' component={AdminSubmissionsApp}/>
        <Route exact path='/students' component={ObserverStudentsApp}/>
        <Route exact path='/student/:id' component={StudentApp}/>
        <Route exact path='/observer/:id' component={ObserverApp}/>

        <Route exact path='/theme/:id' component={AdminThemeApp}/>
        <Route exact path='/theme/:id/experiment' component={AdminThemeApp}/>

        <Route exact path='/teacher/:id' component={TeacherApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route component={ObserverIndexApp}/>
    </Switch>
);

const teacherRoute = (
    <Switch>
        <Route exact path='/video/:hash' component={VideoApp}/>
        <Route exact path='/progress' component={TeacherProgressApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/course/:id' component={CourseApp}/>
        <Route exact path='/courses' component={CoursesApp}/>
        <Route exact path='/schools' component={FranchiseeSchoolsApp}/>
        <Route exact path='/school/:id' component={SchoolApp}/>
        <Route exact path='/student/:id' component={StudentApp}/>
        <Route exact path='/documents' component={DocumentsApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>

        <Route exact path='/teachers' component={AdminTeachersApp}/>
        <Route exact path='/teacher/:id' component={TeacherApp}/>

        <Route component={TeacherIndexApp}/>
    </Switch>
);

const studentRoute = (
    <Switch>
        <Route exact path='/video/:hash' component={VideoApp}/>
        <Route path="/dev" component={DevApp}/>
        <Route exact path='/theme/:themeId' component={LandingThemeApp}/>
        <Route exact path='/theme/:themeId/experiment' component={LandingThemeApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path="/login" component={BrandedLoginApp}/>
        <Route exact path="/class/:classNumber" component={LandingClassApp}/>
        <Route exact path="/class/:classNumber/program" component={LandingClassProgramApp}/>
        <Route exact path="/contacts" component={LandingContactsApp}/>
        <Route exact path="/about" component={LandingAboutApp}/>
        <Route exact path="/news" component={LandingNewsApp}/>
        <Route exact path="/news/:id" component={LandingNewsApp}/>
        <Route exact path="/programms" component={LandingProgrammsApp}/>
        <Route exact path="/centers" component={LandingCentersApp}/>
        <Route exact path="/profile" component={LandingProfileApp}/>

        <Route exact path="/:subj/contacts" component={LandingContactsApp}/>
        <Route exact path="/:subj/about" component={LandingAboutApp}/>
        <Route exact path="/:subj/news" component={LandingNewsApp}/>
        <Route exact path="/:subj/news/:id" component={LandingNewsApp}/>

        <Route exact path="/:subj/programms" component={LandingProgrammsApp}/>
        <Route exact path="/:subj/centers" component={LandingCentersApp}/>
        <Route exact path="/:subj/profile" component={LandingProfileApp}/>

        {/*<Route exact path="*" component={LandingApp}/>*/}


        <Route exact path="/:subj/profile" component={LandingProfileApp}/>
        {/*<Route exact path="/geom/profile" component={LandingProfileApp}/>*/}
        {/*<Route exact path="/phys/profile" component={LandingProfileApp}/>*/}
        {/*<Route exact path="/astro/profile" component={LandingProfileApp}/>*/}

        <Route exact path="/astro/" component={LandingApp}/>
        <Route exact path="/phys/" component={LandingApp}/>
        <Route exact path="/alg/" component={LandingApp}/>
        <Route exact path="/geom/" component={LandingApp}/>

        <Route exact path="/:subj/class/:classNumber" component={LandingClassApp}/>
        <Route exact path="/:subj/class/:classNumber/program" component={LandingClassProgramApp}/>

        <Route exact path='/:subj/theme/:themeId' component={LandingThemeApp}/>
        <Route exact path='/:subj/theme/:themeId/experiment' component={LandingThemeApp}/>


        <Route exact path="*" component={PreLandingApp}/>

    </Switch>
);


const guestRoute = (
    <Switch>
        <Route exact path='/video/:hash' component={VideoApp}/>
        <Route path="/dev" component={DevApp}/>

        <Route exact path='/theme/:themeId' component={LandingThemeApp}/>
        <Route exact path='/theme/:themeId/experiment' component={LandingThemeApp}/>

        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path="/login" component={BrandedLoginApp}/>
        <Route exact path="/class/:classNumber" component={LandingClassApp}/>
        <Route exact path="/class/:classNumber/program" component={LandingClassProgramApp}/>
        <Route exact path="/contacts" component={LandingContactsApp}/>
        <Route exact path="/about" component={LandingAboutApp}/>
        <Route exact path="/news" component={LandingNewsApp}/>
        <Route exact path="/news/:id" component={LandingNewsApp}/>
        <Route exact path="/programms" component={LandingProgrammsApp}/>
        <Route exact path="/centers" component={LandingCentersApp}/>
        {/*<Route exact path="*" component={LandingApp}/>*/}

        <Route exact path="/alg/profile" component={LandingProfileApp}/>
        <Route exact path="/geom/profile" component={LandingProfileApp}/>
        <Route exact path="/phys/profile" component={LandingProfileApp}/>
        <Route exact path="/astro/profile" component={LandingProfileApp}/>

        <Route exact path="/:subj/programms" component={LandingProgrammsApp}/>

        <Route exact path="/:subj/centers" component={LandingCentersApp}/>
        <Route exact path="/:subj/news" component={LandingNewsApp}/>
        <Route exact path="/:subj/contacts" component={LandingContactsApp}/>
        <Route exact path="/:subj/about" component={LandingAboutApp}/>

        <Route exact path="/:subj/class/:classNumber" component={LandingClassApp}/>
        <Route exact path="/:subj/class/:classNumber/program" component={LandingClassProgramApp}/>

        <Route exact path='/:subj/theme/:themeId' component={LandingThemeApp}/>
        <Route exact path='/:subj/theme/:themeId/experiment' component={LandingThemeApp}/>

        <Route exact path="/astro/" component={LandingApp}/>
        <Route exact path="/phys/" component={LandingApp}/>
        <Route exact path="/alg/" component={LandingApp}/>
        <Route exact path="/geom/" component={LandingApp}/>


        <Route exact path="*" component={PreLandingApp}/>

    </Switch>
)

const signedInGuestRoute = (
    <Switch>
        <Route exact path='/video/:hash' component={VideoApp}/>
        <Route path="/dev" component={DevApp}/>

        <Route exact path='/theme/:themeId' component={LandingThemeApp}/>
        <Route exact path='/theme/:themeId/experiment' component={LandingThemeApp}/>

        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path="/login" component={BrandedLoginApp}/>
        <Route exact path="/class/:classNumber" component={LandingClassApp}/>
        <Route exact path="/class/:classNumber/program" component={LandingClassProgramApp}/>
        <Route exact path="/contacts" component={LandingContactsApp}/>
        <Route exact path="/about" component={LandingAboutApp}/>
        <Route exact path="/news" component={LandingNewsApp}/>
        <Route exact path="/news/:id" component={LandingNewsApp}/>
        <Route exact path="/programms" component={LandingProgrammsApp}/>
        <Route exact path="/centers" component={LandingCentersApp}/>
        {/*<Route exact path="*" component={LandingApp}/>*/}

        <Route exact path="/alg/profile" component={LandingProfileApp}/>
        <Route exact path="/geom/profile" component={LandingProfileApp}/>
        <Route exact path="/phys/profile" component={LandingProfileApp}/>
        <Route exact path="/astro/profile" component={LandingProfileApp}/>

        <Route exact path="/:subj/programms" component={LandingProgrammsApp}/>
        <Route exact path="/:subj/centers" component={LandingCentersApp}/>
        <Route exact path="/:subj/news" component={LandingNewsApp}/>
        <Route exact path="/:subj/contacts" component={LandingContactsApp}/>

        <Route exact path="/:subj/class/:classNumber" component={LandingClassApp}/>
        <Route exact path="/:subj/class/:classNumber/program" component={LandingClassProgramApp}/>

        <Route exact path='/:subj/theme/:themeId' component={LandingThemeApp}/>
        <Route exact path='/:subj/theme/:themeId/experiment' component={LandingThemeApp}/>

        <Route exact path="/astro/" component={LandingApp}/>
        <Route exact path="/phys/" component={LandingApp}/>
        <Route exact path="/alg/" component={LandingApp}/>
        <Route exact path="/geom/" component={LandingApp}/>


        <Route exact path="*" component={PreLandingApp}/>

    </Switch>
)

export default function RouterApp(props) {
    const mapState = useCallback(
        state => {
            let currentUser = state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId);
            let isArchived = (currentUser != undefined && currentUser.status == 'archived');
            let uId = state.users.currentUserId;
            let center = (currentUser == undefined) ? undefined : state.centers.centersMap.get(currentUser.centerId);
            return {
                initialized: state.users.initialized,
                currentUser: currentUser,
                isArchived: isArchived,
                center: center
            }
        }, []
    );
    const {initialized, currentUser, isArchived, center} = useMappedState(mapState);
    const dispatch = useDispatch();
    console.log('currentUser = ', currentUser);

    let isVideoMode = (window.location.hash != undefined && window.location.hash.indexOf('video') > -1);

    useEffect(() => {
        if (window.location.hash != undefined && window.location.hash.indexOf('video') > -1){
            return;
        }
        dispatch(usersActions.initializeAuthorization()).then(pld => {
            console.log('initializeAuthorization: pld = ', pld);
            if (pld.type == types.INITIALIZE_AUTH_FAIL) {
                UsersAPI.forceLogout();
                window.location.reload();
                return;
            }
            if (pld.user != undefined) {
                try {
                    window.currentUserId = pld.user.id;
                    window.currentUser = pld.user;
                    window.localStorage.setItem(`os_current_user`, pld.user.id);
                } catch (ee) {

                }
            }
            dispatch(usersActions.loadAllUsers());
            dispatch(coursesActions.loadAllCourses());
            dispatch(schoolsActions.loadAllSchools());
            dispatch(roomsActions.loadAllRooms());
            dispatch(groupsActions.loadAllGroups());
            dispatch(lessonsActions.loadAllLessons());
            dispatch(documentsActions.loadAllDocuments());
            dispatch(subscriptionsActions.loadAllSubscriptions());
            dispatch(transactionsActions.getLatestTransactions());
            dispatch(newsItemsActions.loadAllNewsItems());
            dispatch(programsActions.loadAllPrograms());
            dispatch(themesActions.loadAllThemes());
            dispatch(centersActions.loadAllCenters());
            dispatch(announcementsActions.loadAllAnnouncements());
            dispatch(ticketsActions.loadAllTickets()); // todo: optimize
        });
    }, []);

    if (initialized == false && isVideoMode == false) {
        return (
            <FullPagePreloader visible={true}/>
        )
    }

    let isGuest = (currentUser == undefined);
    let isAdmin = (isGuest == false && currentUser.userRole == 'admin');
    let isTeacher = (isGuest == false && currentUser.userRole == 'teacher');
    let isStudent = (isGuest == false && currentUser.userRole == 'student');
    let isObserver = (isGuest == false && currentUser.userRole == 'observer');
    let isMethodist = (isGuest == false && currentUser.userRole == 'methodist');

    console.log('router: render: isStudent = ', isStudent);

    let route = isGuest == true ? guestRoute : (isAdmin == true ? adminRoute : null);

    if (isVideoMode == true){
        route = guestRoute;
    }else{
        if (isObserver == true) {
            route = observerRoute;
        }
        if (isTeacher == true) {
            route = teacherRoute;
        }
        if (isStudent == true) {
            route = studentRoute;
        }

        if (isMethodist == true) {
            route = methodistRoute;
        }

        if (isForcedLanding() == true) {
            route = signedInGuestRoute;
        }
    }

    console.log('isAdmin = ', isAdmin);

    if (isArchived == true && center != undefined) {
        return (
            <ArchivedPlaceholder>
                <InnerPlaceholder>
                    <MessageBox>
                        {'Ваш аккаунт находится в архиве.'}
                        <br/>
                        {'Свяжитесь по указанным контактам:'}
                        <br/>
                        {center.contacts}
                    </MessageBox>
                </InnerPlaceholder>
            </ArchivedPlaceholder>
        )
    }

    return (
        <Wrapper>

            <HashRouter>
                {route}
            </HashRouter>

            <AnnouncementModalPanel/>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const ArchivedPlaceholder = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
`;

const InnerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MessageBox = styled.div`
  padding: 20px;
  width: 620px;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 620px) {
    width: 100%;
  }
`;
