import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import useDebouncedCallback from 'use-debounce/lib/callback'

import {Input} from "../../ui/Input";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'
import * as usersActions from "../../../redux/actions/UsersActions";
import UpdateUserForm from "../../users/forms/UpdateUserForm";

export default function AdminAllObserversPanel(props) {
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState('');
    const [addModalVisible, setAddModalVisible] = useState(false);
    let {currentUser, loading, userRole, observers, centersMap, allCenters, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let observers = state.users.usersMap.toArray().filter(a => (a.userRole == 'observer'));
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            observers: observers,
            centersMap: state.centers.centersMap,
            allCenters: state.centers.centersMap.toArray(),
            isAdmin: (userRole == 'admin')
        }
    }, []));
    const [debouncedSearch] = useDebouncedCallback((a) => {
        setSearchString(a);
    }, 300, []);
    if (currentUser == undefined || userRole != 'admin') {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    const sortedTeachers = observers.filter(a => {
        let {firstName, lastName, email} = a;
        let s = `${lastName} ${firstName} ${email}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }).sort((a, b) => {
        let s1 = `${a.lastName} ${a.firstName}`.toLowerCase();
        let s2 = `${b.lastName} ${b.firstName}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    return (
        <Wrapper>

            <Heading>
                <b>
                    Проверяющие ({sortedTeachers.length})
                </b>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить проверяющего
                </Button>
            </Heading>

            <TopSearchPlaceholder>
                <Input placeholder={'Поиск'} style={{
                    height: 36,
                    lineHeight: `36px`,
                    fontSize: 16
                }} onChange={evt => {
                    debouncedSearch(evt.target.value);
                }}/>
            </TopSearchPlaceholder>

            <PaginatorWrapper renderItems={items => items.map((c, k) => {
                let {id, firstName, lastName, centerId} = c;
                let center = centersMap.get(centerId);
                return (
                    <SchoolItem key={id} onClick={() => {
                        CommonHelper.linkTo(`/observer/${id}`);
                    }}>
                        <Name>
                            {`${lastName} ${firstName}`}
                        </Name>
                    </SchoolItem>
                )
            })} items={sortedTeachers}/>


            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <UpdateUserForm
                            showTeacherType={false}
                            password={CommonHelper.getPwd()}
                            loading={loading}
                            avatarVisible={true}
                            passwordVisible={true}
                            emailVisible={true}
                            allCenters={allCenters}
                            hasCenter={false}
                            isTeacher={true}
                            isAdmin={isAdmin}
                            onSave={async data => {
                                let pld = await dispatch(usersActions.createUser({
                                    ...data,
                                    userRole: 'observer'
                                }));
                                if (pld.error != undefined) {
                                    if (pld.error != undefined && pld.error.message != undefined && pld.error.message.indexOf('already exists') > -1) {
                                        window.alert('Аккаунт с данной почтой уже существует!');
                                    }
                                } else {
                                    setAddModalVisible(false);
                                }
                            }}
                        />
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SchoolsList = styled.div`
    
`;

const SchoolItem = styled.div`
    margin-bottom: 10px;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const Heading = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopSearchPlaceholder = styled.div`
    margin-bottom: 10px;
`;
