import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import SchoolLessonsPanel from "../../lessons/panels/SchoolLessonsPanel";


export default function GroupLessonsPanel(props) {
    let {groupId} = props;
    const {school, group, loading, franchisee, currentUserId, students} = useMappedState(useCallback(state => {
        let gr = state.groups.groupsMap.get(groupId);
        let sc = (gr == undefined) ? undefined : state.schools.schoolsMap.get(gr.schoolId);
        return {
            group: gr,
            school: sc,
            loading: loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            franchisee: state.users.usersMap.get(sc.franchiseeId),
            allStudents: state.users.usersMap.toArray().filter(a => (a.userRole == 'student' && franchisee != undefined && a.franchiseeId == franchisee.id)),
            students: state.users.usersMap.toArray().filter(st => ((gr != undefined) && (gr.studentsIds.indexOf(st.id) > -1)))
        }
    }, [groupId]));
    const dispatch = useDispatch();
    if (school == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <SchoolLessonsPanelPlaceholder>

                <SchoolLessonsPanel groupId={groupId} schoolId={school.id}/>

            </SchoolLessonsPanelPlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SchoolLessonsPanelPlaceholder = styled.div`
    
`;
