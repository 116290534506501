import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import {Map, Set} from 'immutable'
import * as submissionsActions from "../../../redux/actions/SubmissionsActions";
import * as tasksActions from "../../../redux/actions/TasksActions";
import {Button} from "../../ui/Button";

const getFranchisee = (frId, allFranchisees) => {
    let arr = allFranchisees.filter(a => (a.id == frId));
    if (arr.length == 0) {
        return null;
    }
    return arr[0];
}


export default function AdminExportPanel(props) {
    const dispatch = useDispatch();
    let {currentUser, loading, schools, centersMap, userRole, teachers, students, lessons, tasks, submissionsMap} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let students = state.users.usersMap.toArray().filter(a => (a.userRole == 'student'))
        let teachers = state.users.usersMap.toArray().filter(a => (a.userRole == 'teacher'))
        return {
            userRole: userRole,
            centersMap: state.centers.centersMap,
            tasks: state.tasks.tasksMap.toArray(),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            schools: state.schools.schoolsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            teachers: teachers,
            students: students,
            submissionsMap: state.submissions.submissionsMap,
            lessons: state.lessons.lessonsMap.toArray().sort((a, b) => (+b.startTimestamp - +b.startTimestamp)),
        }
    }, []));
    useEffect(() => {
        dispatch(tasksActions.loadAllTasks());
        dispatch(submissionsActions.loadAllSubmissions());
    }, []);

    if (currentUser == undefined || userRole != 'admin') {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }
    let uSubMap = submissionsMap.toArray().reduce((mp, sub) => mp.set(`${sub.taskId}_${sub.studentId}`, sub), Map());

    let headString = `Центр;Класс;Имя;${tasks.sort((a, b) => (+a.classNumber - +b.classNumber)).map(a => `${a.classNumber} кл. ${a.name}`).join(';')}`;

    let s = students.map(a => {
        let c = centersMap.get(a.centerId);
        let cName = (c == undefined) ? '' : c.name;
        let marksString = tasks.map(tsk => uSubMap.get(`${tsk.id}_${a.id}`)).map(a => (a == undefined ? '-' : a.mark)).join(';');
        return `${cName};${a.classNumber} класс;${CommonHelper.getFullName(a)};${marksString}`;
    }).join('\n');

    s = `${headString}\n${s}`;

    return (
        <Wrapper>

            <Button onClick={() => {
                let csvContent = `data:text/csv;charset=utf-8,${s}`;
                let encodedUri = encodeURI(csvContent);
                let link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `${moment().format('DD.MM.YYYY')}_export.csv`);
                document.body.appendChild(link); // Required for FF

                link.click();
            }}>
                export
            </Button>

            {/*<textarea value={s}></textarea>*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Box = styled.div`
    flex: 1;
    padding: 5px;
    flex-basis: 25%;
`;

const ParamName = styled.div`
    font-size: 24px;
    line-height: 32px;
`;

const ParamValue = styled.div`
    font-size: 64px;
    line-height: 64px;
`;

const BoxInner = styled.div`
    background: white;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    align-content: space-between;
    cursor: ${props => (props.hoverable == true ? 'pointer' : 'default')};
    :hover{
      opacity: ${props => (props.hoverable == true ? 0.7 : 1)};
    }
`;
