import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import ClassProgramsPanel from "../programs/panels/ClassProgramsPanel";
import ClassProgramPanel from "../classes/panels/ClassProgramPanel";
import CommonHelper from "../../helpers/CommonHelper";

export default function LandingClassProgramApp(props) {
    let {classNumber, subj} = props.match.params;
    const bgImage = undefined; //todo: change

    return (
        <LandingTemplate active={`class/${classNumber}/program`} subj={subj} >

            <FullPageImageSection height={`220px`} hasTopLogo={false} image={bgImage}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel motto={(
                        <div>
                            {/*{classNumber == '0' ? 'ОБЩЕЕ' : (classNumber == '1' ? 'ЭКСПЕРИМЕНТ' : `${classNumber} КЛАСС`)}*/}
                            {classNumber == '0' ? 'ВсОШ' : (classNumber == '1' ? 'ЭКСПЕРИМЕНТ' : `${classNumber} КЛАСС`)}
                        </div>
                    )}
                                       hasTopLogo={false}
                    />
                </div>
            </FullPageImageSection>

            <BottomContentPlaceholder>

                <RespContainer>

                    <CrumbsPlaceholder>
                        <CrumbItem
                            onClick={() => {
                                CommonHelper.linkTo(`/class/${classNumber}`);
                            }}
                            style={{cursor: 'pointer', textDecoration: 'underline'}}>
                            {/*{classNumber == '0' ? 'Общее' : (classNumber == '1' ? 'Эксперимент' : `${classNumber} КЛАСС`)}*/}
                            {classNumber == '0' ? 'ВсОШ' : (classNumber == '1' ? 'Эксперимент' : `${classNumber} КЛАСС`)}
                        </CrumbItem>
                        <CrumbDelimiter>
                            /
                        </CrumbDelimiter>
                        <CrumbItem>
                            {`Программа`}
                        </CrumbItem>
                    </CrumbsPlaceholder>

                    <ClassProgramPanel classNumber={classNumber}
                                       subj={subj}

                    />

                </RespContainer>

            </BottomContentPlaceholder>

        </LandingTemplate>
    );
}


const size = 100;

const BottomContentPlaceholder = styled.div`
    padding-top: 20px;
    padding-bottom: 50px;
    background: white;
    @media(max-width: 820px){
      padding-top: 20px;
    }
`;

const RespContainer = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding: 24px;
    box-sizing: border-box;
    @media(max-width: 1200px){
      width: 100%;
    }
`;

const CrumbsPlaceholder = styled.div`
    color: ${BLUE};
    font-size: 16px;
    margin-bottom: 30px;
`;

const CrumbItem = styled.span`
    cursor: ${props => (props.clickable == true ? 'pointer' : 'default')};
    text-decoration: ${props => (props.clickable == true ? 'underline' : 'none')};
`;

const CrumbDelimiter = styled.span`
    margin-left: 5px;
    margin-right: 5px;
`;
