import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";


import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import {useMappedState, useDispatch} from "redux-react-hook";
import FranchiseePanel from "../franchisee/panels/FranchiseePanel";
import SchoolPanel from "../schools/panels/SchoolPanel";

export default function SchoolApp(props) {
    let {id} = props.match.params;

    return (
        <ChessTemplate active={`school/${id}`}>

            <SchoolPanel id={id} />

        </ChessTemplate>
    );
}

const TopPlaceholder = styled.div`
    
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
`;
