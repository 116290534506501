import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import axios from 'axios';


import styled from 'styled-components';

import {useDispatch, useMappedState} from 'redux-react-hook'

import * as announcementsActions from '../../../redux/actions/AnnouncementsActions'

import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import Sidebar from 'arui-feather/sidebar'
import AnnouncementForm from "../forms/AnnouncementForm";
import {API_ENDPOINT} from "../../../constants/config";


let getFriendlyRoleName = a => (a == 'teacher' ? 'Преподаватель' : 'Ученик');

export default function AnnouncementModalPanel() {

    const {loading, announcements, currentUser, isAdmin, userRole} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin'));
        let userRole = (currentUser == undefined) ? undefined : currentUser.userRole;
        let now = +new Date();
        return {
            loading: state.announcements.loading,
            announcements: state.announcements.announcementsMap.toArray()
                .filter(a => (a.roles != undefined && a.roles.indexOf(userRole) > -1))
                .filter(a => (a.usersIds == undefined || a.usersIds.indexOf(state.users.currentUserId) == -1))
                .filter(a => (+a.startTimestamp < +now && +now < +a.endTimestamp))
                .sort((a, b) => (+a.timestamp - +b.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin,
            userRole: userRole
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(announcementsActions.loadAllAnnouncements());
    }, []);


    if (announcements.length == 0) {
        return null;
    }

    let {text, id} = announcements[0];

    return (
        <Wrapper>
            <InnerPlaceholder>
                <InnerContent>
                    <div dangerouslySetInnerHTML={{__html: text}} ></div>
                </InnerContent>
                <InnerActionPlaceholder>
                    {loading == true ? null :
                        <Button onClick={async () => {
                            await dispatch(announcementsActions.viewAnnouncement(id));
                        }}>
                            Хорошо, спасибо
                        </Button>
                    }
                    <Spin visible={loading}/>

                </InnerActionPlaceholder>
            </InnerPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
`;

const InnerPlaceholder = styled.div`
    padding: 20px;
    background: white;
    border-radius: 6px;
    width: 840px;
    box-sizing: border-box;
    @media(max-width: 840px){
      width: 100%;
    }
`;

const InnerContent = styled.div`
    font-size: 28px;
    line-height: 36px;
    text-align: center;
`;

const InnerActionPlaceholder = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const Description = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 15px;
`;

const CoursesList = styled.div`
    
`;

const CourseItem = styled.div`
    padding: 10px;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    
    background: white;
    
    :hover{
      opacity: 0.8;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;
