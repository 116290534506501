import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import CommonHelper from "../../../helpers/CommonHelper";

import Select from 'react-select'

const ROLES_OPTIONS = [
    {
        label: 'Преподаватель',
        value: 'teacher'
    },
    {
        label: 'Ученик',
        value: 'student'
    }
]

export default function AnnouncementForm(props) {
    const {
        loading = false,
        onSave = d => {

        },
        onDelete = () => {

        },
        canDelete = false
    } = props;

    const [text, setText] = useState(props.text == undefined ? '' : props.text);
    const [startTimestamp, setStartTimestamp] = useState(props.startTimestamp == undefined ? +new Date() : props.startTimestamp);
    const [endTimestamp, setEndTimestamp] = useState(props.endTimestamp == undefined ? +new Date() : props.endTimestamp);
    const [roles, setRoles] = useState(props.roles == undefined ? [] : props.roles);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Текст оповещения
                </Label>
                <Textarea placeholder={'Текст'} value={text} onChange={evt => {
                    setText(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Дата начала показа
                </Label>
                <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={moment(startTimestamp).toDate()}
                    onChange={newDate => {
                        setStartTimestamp(+new Date(newDate));
                    }}
                />
            </Field>

            <Field>
                <Label>
                    Дата окончания показа
                </Label>
                <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={moment(endTimestamp).toDate()}
                    onChange={newDate => {
                        setEndTimestamp(+moment(+new Date(newDate)).endOf('day'));
                    }}
                />
            </Field>

            <Field>
                <Label>
                    Кому показывать
                </Label>
                <Select options={ROLES_OPTIONS}
                        value={ROLES_OPTIONS.filter(a => (roles.indexOf(a.value) > -1))}
                        isMulti={true}
                        placeholder={''}
                        onChange={(a, b) => {
                            setRoles(a.map(aa => (aa.value)));
                        }}
                />
            </Field>

            <Field>
                <Button onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onSave({
                        text,
                        startTimestamp,
                        endTimestamp,
                        roles
                    });
                }}>
                    {loading == true ? null :
                        'Сохранить'
                    }
                    <Spin visible={loading}/>
                </Button>
            </Field>

            {canDelete == false ? null :
                <Field style={{marginTop: 50}}>
                    <DelSpan onClick={() => {
                        onDelete();
                    }}>
                        Удалить
                    </DelSpan>
                </Field>
            }


        </Wrapper>
    );
}

const DelSpan = styled.span`
  cursor: pointer;
  color: red;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;
