import TransactionsAPI from '../../api/TransactionsAPI'

import * as types from '../ActionTypes'

let loadTransactions_ = () => {
    return {
        type: types.LOAD_TRANSACTIONS
    }
}
let loadTransactionsSuccess = (transactions) => {
    return {
        type: types.LOAD_TRANSACTIONS_SUCCESS,
        transactions: transactions
    }
}
let loadTransactionsFailed = (error) => {
    return {
        type: types.LOAD_TRANSACTIONS_FAIL,
        error: error
    }
}

//thunk
export function loadAllTransactions() {
    return (dispatch, getState) => {
        dispatch(loadTransactions_());
        return TransactionsAPI.getAllTransactions().then(
            transactions => dispatch(loadTransactionsSuccess(transactions)),
            error => dispatch(loadTransactionsFailed(error))
        )
    }
}

export function loadTransactionById(id) {
    return (dispatch, getState) => {
        dispatch(loadTransactions_());
        return TransactionsAPI.getTransactionById(id).then(
            transaction => dispatch(loadTransactionsSuccess([transaction])),
            error => dispatch(loadTransactionsFailed(error))
        )
    }
}

export function getStudentTransactions(studentId) {
    console.log('dispatched: getStudentTransactions: studentId = ', studentId);
    return (dispatch, getState) => {
        dispatch(loadTransactions_());
        return TransactionsAPI.getStudentTransactions(studentId).then(
            transactions => dispatch(loadTransactionsSuccess(transactions)),
            error => dispatch(loadTransactionsFailed(error))
        )
    }
}

export function getStudentsTransactionsByIdsAndStatus(usersIds = [], status = 'PENDING') {
    console.log('dispatched: getStudentsTransactionsByIdsAndStatus: usersIds = ', usersIds);
    return (dispatch, getState) => {
        dispatch(loadTransactions_());
        return TransactionsAPI.getStudentsTransactionsByIdsAndStatus(usersIds, status).then(
            transactions => dispatch(loadTransactionsSuccess(transactions)),
            error => dispatch(loadTransactionsFailed(error))
        )
    }
}

export function buySubscriptionForCash(studentId, subscriptionId) {
    return (dispatch, getState) => {
        dispatch(loadTransactions_());
        return TransactionsAPI.buyCashSubscription(studentId, subscriptionId).then(
            transaction => dispatch(loadTransactionsSuccess([transaction])),
            error => dispatch(loadTransactionsFailed(error))
        )
    }
}


export function getLatestTransactions() { // todo: optimize based on role
    return (dispatch, getState) => {
        dispatch(loadTransactions_());
        return TransactionsAPI.getLatestTransactions().then(
            transactions => dispatch(loadTransactionsSuccess(transactions)),
            error => dispatch(loadTransactionsFailed(error))
        )
    }
}

let createTransaction_ = () => {
    return {
        type: types.CREATE_TRANSACTION
    }
}
let createTransactionSuccess = (transaction) => {
    return {
        type: types.CREATE_TRANSACTION_SUCCESS,
        transaction: transaction
    }
}
let createTransactionFailed = (error) => {
    return {
        type: types.CREATE_TRANSACTION_FAIL,
        error: error
    }
}

//thunk
export function createTransaction(data) {
    return (dispatch, getState) => {
        dispatch(createTransaction_());
        return TransactionsAPI.createTransaction(data).then(
            transaction => dispatch(createTransactionSuccess(transaction)),
            error => dispatch(createTransactionFailed(error))
        )
    }
}

let updateTransaction_ = () => {
    return {
        type: types.UPDATE_TRANSACTION
    }
}
let updateTransactionSuccess = (transaction) => {
    return {
        type: types.UPDATE_TRANSACTION_SUCCESS,
        transaction: transaction
    }
}
let updateTransactionFailed = (error) => {
    return {
        type: types.UPDATE_TRANSACTION_FAIL,
        error: error
    }
}

//thunk
export function updateTransaction(data) {
    return (dispatch, getState) => {
        dispatch(updateTransaction_());
        return TransactionsAPI.updateTransaction(data).then(
            transaction => dispatch(updateTransactionSuccess(transaction)),
            error => dispatch(updateTransactionFailed(error))
        )
    }
}

export function acceptCashTransaction(transactionId) {
    return (dispatch, getState) => {
        dispatch(updateTransaction_());
        return TransactionsAPI.acceptCashTransaction(transactionId).then(
            transaction => dispatch(updateTransactionSuccess(transaction)),
            error => dispatch(updateTransactionFailed(error))
        )
    }
}

let deleteTransaction_ = () => {
    return {
        type: types.DELETE_TRANSACTION
    }
}
let deleteTransactionSuccess = (id) => {
    return {
        type: types.DELETE_TRANSACTION_SUCCESS,
        id: id
    }
}
let deleteTransactionFailed = (error) => {
    return {
        type: types.DELETE_TRANSACTION_FAIL,
        error: error
    }
}

//thunk
export function deleteTransaction(id) {
    return (dispatch, getState) => {
        dispatch(deleteTransaction_());
        return TransactionsAPI.deleteTransaction(id).then(
            () => dispatch(deleteTransactionSuccess(id)),
            error => dispatch(deleteTransactionFailed(error))
        )
    }
}
