import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Button} from '@blueprintjs/core'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import * as actions from "../../../redux/actions/TransactionsActions";
import * as subActions from "../../../redux/actions/SubscriptionsActions";
import CommonHelper from "../../../helpers/CommonHelper";


export default function StudentsTransactionsPanel(props) {
    const {status = 'PENDING', students = []} = props;
    const dispatch = useDispatch();
    const usersIds = students.map(st => st.id);
    let {currentUser, loading, transactions} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.transactions.loading,
        transactions: state.transactions.transactionsMap.toArray().filter(a => ((a.status == status) && (usersIds.indexOf(a.userId) > -1)))
    }), []));
    useEffect(() => {
        dispatch(actions.getStudentsTransactionsByIdsAndStatus(usersIds, status));
    }, []);

    const studentsWithTransactions = students.map(st => {
        let trs = transactions.filter(a => (a.userId == st.id));
        return {
            ...st,
            transactions: trs
        }
    }).filter(a => (a.transactions.length > 0));

    return (
        <Wrapper>

            <StudentsList>
                {studentsWithTransactions.map((st, j) => {
                    return (
                        <StudentItem key={st.id}>
                            <StudentName>
                                {`${st.firstName} ${st.lastName}`}
                            </StudentName>
                            <TransactionsList>
                                {st.transactions.map((tr, w) => (
                                    <TransactionItem key={tr.id}>
                                        <TransactionName>
                                            {`${tr.subscription.name} - ${tr.price} руб`}
                                        </TransactionName>
                                        <TransactionDetailsPlaceholder>
                                            {`${{CASH: 'Оплата наличными', CARD: 'Оплата по карте'}[tr.type]}`}
                                        </TransactionDetailsPlaceholder>
                                        {tr.status != 'PENDING' ? null :
                                            <TransactionControlsPlaceholder>
                                                <TransactionControlSpan onClick={() => {
                                                    if (window.confirm('Вы уверены?') == false) {
                                                        return;
                                                    }
                                                    dispatch(actions.deleteTransaction(tr.id));
                                                }}>
                                                    Удалить транзакцию
                                                </TransactionControlSpan>
                                                <TransactionControlSpan onClick={() => {
                                                    dispatch(actions.acceptCashTransaction(tr.id));
                                                }} >
                                                    Подтвердить транзакцию
                                                </TransactionControlSpan>
                                            </TransactionControlsPlaceholder>
                                        }
                                    </TransactionItem>
                                ))}
                            </TransactionsList>
                        </StudentItem>
                    )
                })}
            </StudentsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const StudentsList = styled.div`
    
`;

const StudentItem = styled.div`
    padding: 5px;
    background: white;
    border: 1px solid whitesmoke;
    margin-bottom: 10px;
`;

const StudentName = styled.div`
    
`;

const TransactionsList = styled.div`
    
`;

const TransactionItem = styled.div`
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid whitesmoke;
`;

const TransactionName = styled.div`
    font-weight: bold;
`;

const TransactionDetailsPlaceholder = styled.div`
    
`;

const TransactionControlsPlaceholder = styled.div`
    
`;

const TransactionControlSpan = styled.span`
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.75;
    font-size: 12px;
    margin-right: 10px;
    :hover{
      opacity: 1;
    }
`;
