import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../helpers/CommonHelper";
import {DEFAULT_TIMEZONE} from "../../constants/config";

const hours = [
    0,
    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
];
const dwArr = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

export default function WeekCalendar(props) {
    const {
        hourHeight = 75,
        selectedTimestamp,
        borderColor = 'whitesmoke',
        showYearMovers = false,
        hourContent = t => {

        },
        canInteract = true,
        canPrint = true
    } = props;

    const [weekTimestamp, setWeekTimestamp] = useState(+moment(props.selectedTimestamp).startOf('isoweek'));
    const start = +moment(weekTimestamp).startOf('isoweek').startOf('day');
    const end = +moment(weekTimestamp).endOf('isoweek').startOf('day');
    let days = [], t = start;
    while (t <= end) {
        days = days.concat(t);
        t = +t + 86400000;
    }

    return (
        <Wrapper>

            <TopPlaceholder borderColor={borderColor}>

                {canInteract == false ? null :
                    <SideArrowPlaceholder>
                        <SideInner>
                            <ArrImg src={require('./left_arr.svg')} onClick={() => {
                                if (canInteract == false) {
                                    return;
                                }
                                setWeekTimestamp(+moment(+moment(weekTimestamp).startOf('isoweek') - 2 * 86400000).startOf('isoweek'))
                            }}/>
                        </SideInner>
                    </SideArrowPlaceholder>
                }

                <WeekNamePlaceholder borderColor={borderColor}>
                    {`${moment(start).format('D')} ${CommonHelper.getRuMonth_s_ByTimestamp(start).toLowerCase()}  - ${moment(end).format('D')} ${CommonHelper.getRuMonth_s_ByTimestamp(end).toLowerCase()}`}
                </WeekNamePlaceholder>

                {canInteract == false ? null :
                    <SideArrowPlaceholder>
                        <SideInner>
                            <ArrImg src={require('./right_arr.svg')} onClick={() => {
                                if (canInteract == false) {
                                    return;
                                }
                                setWeekTimestamp(+moment(+moment(weekTimestamp).endOf('isoweek') + 2 * 86400000).startOf('isoweek'));
                            }}/>
                        </SideInner>
                    </SideArrowPlaceholder>
                }

                {canPrint == false ? null :
                    <PrintSectionPlaceholder onClick={() => {
                        try {
                            window.print();
                        } catch (exc) {
                            console.log('can not print');
                        }
                    }}>
                        <PrintButton src={require('../../assets/images/print.svg')}/>
                        <span>
                            распечатать
                        </span>
                    </PrintSectionPlaceholder>
                }

            </TopPlaceholder>

            <ContentPlaceholder>
                <DayNamesList>
                    {
                        [0].concat(days).map((dt, k) => {
                            let isToday = +moment().startOf('day') == +moment(dt).startOf('day');
                            return (
                                <DayName key={`${dt}_${k}`}
                                         today={isToday}
                                         style={{width: (k == 0 ? leftWidth : 'auto'), flex: k == 0 ? 'none' : 1}}>
                                    {k == 0 ? null :
                                        <span style={{fontSize: 12}}>
                                            {`${dwArr[k - 1].toUpperCase()}, ${moment(dt).format('DD.MM')}`}
                                        </span>
                                    }
                                </DayName>
                            )
                        })
                    }
                </DayNamesList>

                <HoursList>
                    {hours.map((h, k) => {
                        return (
                            <HourRow key={h}>
                                {
                                    [0].concat(days).map((dt, j) => {
                                        let t = +dt + (h * 3600000);
                                        let from = t, to = +t + 3600000;
                                        let cont = hourContent(t);
                                        return (
                                            <HourCell key={`${dt}_${j}`} style={{
                                                width: (j == 0 ? leftWidth : 'auto'),
                                                flex: j == 0 ? 'none' : 1,
                                                textAlign: (j == 0) ? 'right' : 'left',
                                                paddingRight: (j == 0) ? 3 : 0
                                            }}>

                                                {j != 0 ? null :
                                                    <HourNameSpan>
                                                        {CommonHelper.getHourString(h)}
                                                    </HourNameSpan>
                                                }

                                                {j == 0 ? null :
                                                    <InnerCell>
                                                        {cont}
                                                    </InnerCell>
                                                }

                                            </HourCell>
                                        )
                                    })
                                }
                            </HourRow>
                        )
                    })}
                </HoursList>

            </ContentPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    //box-sizing: border-box;
    border-bottom: 1px solid whitesmoke;
    //@media print{
    //  
    //}
`;

const topHeight = 40;
const leftWidth = 45;

const PrintSectionPlaceholder = styled.div`
    position: absolute;
    right: 5px;
    top: 0px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
    @media print {
      display: none;
    }
`;

const PrintButton = styled.img`
    cursor: pointer;
    opacity: 0.75;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    :hover{
      opacity: 1;
    }
`;

const TopPlaceholder = styled.div`
    height: ${topHeight}px;
    line-height: ${topHeight}px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding-left: ${leftWidth}px;
    position: relative;
`;

const SideArrowPlaceholder = styled.div`
    width: 90px;
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    text-align: center;
    height: ${topHeight}px;
    line-height: ${topHeight}px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const SideInner = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

const ArrImg = styled.img`
    display: inline-block;
    height: ${props => (props.double == true ? 16 : 20)}px;
    width: ${props => (props.double == true ? 16 : 20)}px;
    cursor: pointer;
    opacity: ${props => (props.double == true ? 0.4 : 1)};
    :hover{
      opacity: ${props => (props.double == true ? 1 : 0.7)};
    }
    @media print{
      display: none !important;
    }
`;

const WeekNamePlaceholder = styled.div`
    text-align: center;
    //width: 100px;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: ${topHeight}px;
    line-height: ${topHeight}px;
`;

const ContentPlaceholder = styled.div`
    width: 100%;
    box-sizing: border-box;
`;


const dayNamesListHeight = 24;


const DayNamesList = styled.div`
    width: 100%;
    height: ${dayNamesListHeight}px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
`;


const DayName = styled.div`
    box-sizing: border-box;
    flex: 1;
    //border-right: 1px solid whitesmoke;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
    padding-left: 3px;
    font-weight: ${props => (props.today == true ? 'bold' : 'normal')};
    text-decoration: ${props => (props.today == true ? 'underline' : 'none')};
    :first-of-type{
      //border-left: 1px solid whitesmoke;
    }
`;

const InnerCell = styled.div`
    min-height: 80px;
    height: 100%;
    box-sizing: border-box;
    @media print{
      min-height: 20px;
    }
`;

const HoursList = styled.div`
    box-sizing: border-box;
`;

const HourRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
`;

const HourCell = styled.div`
    align-self: stretch;
    border-left: 1px solid whitesmoke;
    border-top: 1px solid whitesmoke;
    box-sizing: border-box;
    :last-of-type{
      border-right: 1px solid whitesmoke;
    }
`;

const HourNameSpan = styled.span`
    font-size: 12px;
    opacity: 0.75;
    box-sizing: border-box;
`;
