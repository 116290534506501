import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import TimeTagsEditor from "../tools/TimeTagsEditor";

export default function VideoTimeTagsPanel(props) {
    const {id, videoUrl} = props;
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${API_ENDPOINT}/video/${id}/time_tags`).then(d => d.data.result).then(arr => {
            setTags(arr);
            setLoading(false);
        })
    }, [id]);


    if (loading == true) {
        return <Code/>
    }

    return (
        <Wrapper>

            <TimeTagsEditor {...props} loading={loading} tags={tags} onSave={async arr => {
                setLoading(true)
                await axios.post(`${API_ENDPOINT}/video/${id}/time_tags`, {
                    tags: arr
                });
                setTags((await axios.get(`${API_ENDPOINT}/video/${id}/time_tags`)).data.result);
                setLoading(false);
            }}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TagsList = styled.div`
    
`;
