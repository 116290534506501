import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Select from 'react-select'
import CommonHelper from "../../../helpers/CommonHelper";


export default function LessonsFilterPanel(props) {

    const {
        roomId = undefined,
        teacherId = undefined,
        allRooms = [],
        allTeachers = [],
        onTeacherChange = tId => {

        },
        onRoomChange = rId => {

        },
        canFilterTeacher = true
    } = props;

    const selectedRoomOption = CommonHelper.getSelectedOption(allRooms, roomId);
    const selectedTeacherOption = CommonHelper.getSelectedOption(allTeachers, teacherId, a => `${a.firstName} ${a.lastName}`);

    console.log('LessonsFilterPanel: render: allRooms = ', allRooms);

    return (
        <Wrapper>

            <Section>
                <Label>
                    Помещение
                </Label>
                <Select
                    isMulti={false}
                    isSearchable={true}
                    placeholder={'Выбрать помещение'}
                    value={selectedRoomOption}
                    options={CommonHelper.getDropDownItemsByNamedList(allRooms)}
                    isClearable={true}
                    onChange={a => {
                        if (a == undefined) {
                            onRoomChange(undefined)
                        } else {
                            onRoomChange(a.value);
                        }
                    }}
                />
            </Section>

            {canFilterTeacher == false ? null :
                <Section>
                    <Label>
                        Преподаватель
                    </Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        value={selectedTeacherOption}
                        placeholder={'Выбрать Преподавателя'}
                        options={CommonHelper.getDropDownItemsByUsers(allTeachers)}
                        isClearable={true}
                        onChange={a => {
                            if (a == undefined) {
                                onTeacherChange(undefined)
                            } else {
                                onTeacherChange(a.value);
                            }
                        }}
                    />
                </Section>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
`;

const Section = styled.div`
    margin-right: 10px;
    min-width: 240px;
`;

const Label = styled.div`
    
`;
