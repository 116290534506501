import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {DEFAULT_TIMEZONE, FACELESS_AVATAR} from "../../constants/config";

import CoveredUploadPanel from '../upload/CoveredUploadPanel'
import CommonHelper from "../../helpers/CommonHelper";

export default function BottomUserPanel(props) {
    const {
        user = undefined,
        onClick = () => {
        },
        onAvaClick = () => {

        },
        onNameClick = () => {

        },
        onEmailClick = () => {

        },
        onUpdate = upd => {

        },
        canUpdateAvatar = true
    } = props;
    if (user == undefined) {
        return null;
    }
    const {email, firstName, lastName, avatar, name} = user;
    const ava = (avatar == undefined) ? FACELESS_AVATAR : CommonHelper.getSafeUrl(avatar);
    let userName = (name == undefined) ? '' : name;
    if (userName == '') {
        userName = `${lastName} ${firstName}`;
    } else {
        userName = `${name} (${lastName} ${firstName})`;
    }

    return (
        <Wrapper>
            <Left>

                <Ava src={ava}>
                    {canUpdateAvatar == false ? null :
                        <CoveredUploadPanel url={ava} onChange={newAva => {
                            console.log('newAva = ', newAva);
                            onUpdate({
                                avatar: newAva
                            });
                        }}/>
                    }
                </Ava>

            </Left>
            <Right onClick={() => {
                onClick();
            }}>
                <RightInner>
                    <NamePlaceholder onClick={() => {
                        onNameClick();
                    }}>
                        <InnerName>
                            <VeryInnerName>
                                {`${userName}`}
                            </VeryInnerName>
                        </InnerName>
                    </NamePlaceholder>
                    <EmailPlaceholder onClick={() => {
                        onEmailClick();
                    }}>
                        {email}
                    </EmailPlaceholder>
                </RightInner>
            </Right>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const avaSize = 50;

const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: ${avaSize}px;
    box-sizing: border-box;
`;

const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100% - ${avaSize}px);
    box-sizing: border-box;
    padding-left: 10px;
    height: ${avaSize}px;
`;

const Ava = styled.div`
    width: ${avaSize}px;
    height: ${avaSize}px;
    background: whitesmoke;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => props.src}');
`;

const RightInner = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    line-height: 18px;
    height: 100%;
`;

const NamePlaceholder = styled.div`
    padding-right: 10px;
`;

const InnerName = styled.div`
    display: flex;
    width: 100%;
`;

const VeryInnerName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    flex-direction: row;  
`;

const EmailPlaceholder = styled.div`
    text-overflow: ellipsis;
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    font-size: 14px;
    opacity: 0.6;
`;
