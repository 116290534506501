import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import TexViewer from "../../katex/tools/TexViewer";

export default function UnitView(props) {
    let {
        items = [], canEdit = true, selectedItemId, onItemSelect = () => {

        }, onMove = (itemId, i1, i2) => {

        }
    } = props;
    console.log('UnitView: render: items = ', items);


    return (
        <Wrapper>

            <ItemsList>
                {items.map((item, k) => {
                    let {id, text, type, videoUrl, imageUrl, pieces = [], tex} = item;
                    return (
                        <Item key={`item_${id}_${k}`}>

                            {canEdit == false ? null :
                                <ControlsPlaceholder>
                                        <span style={{cursor: 'pointer'}} onClick={() => {
                                            onItemSelect(id);
                                        }}>
                                            📝
                                        </span>
                                    {k < items.length - 1 ?
                                        <ArrowSpan style={{padding: 3}} onClick={() => {
                                            let i1 = k, i2 = k + 1;
                                            onMove(id, i1, i2);
                                        }}>
                                            v
                                        </ArrowSpan> : null
                                    }
                                    {k == 0 ? null :
                                        <ArrowSpan style={{padding: 3}} onClick={() => {
                                            let i1 = k - 1, i2 = k;
                                            onMove(id, i1, i2);
                                        }}>
                                            ^
                                        </ArrowSpan>
                                    }
                                </ControlsPlaceholder>
                            }

                            {type != 'text' ? null :
                                <TextSection>
                                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                                </TextSection>

                            }

                            {type != 'video' ? null :
                                <VideoPlaceholder>
                                    <VideoInner>
                                        <Video controls={true} key={videoUrl}>
                                            <source src={videoUrl}/>
                                        </Video>
                                    </VideoInner>
                                </VideoPlaceholder>
                            }

                            {type != 'image' ? null :
                                <ImgItemPlaceholder>
                                    <ImageItem src={imageUrl}/>
                                </ImgItemPlaceholder>
                            }

                            {type != 'tex' ? null :
                                <TexPlaceholder>
                                    <TexViewer tex={tex}/>
                                </TexPlaceholder>
                            }

                        </Item>
                    )
                })}
            </ItemsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    font: normal 1em KaTeX_Main, Times New Roman, serif;
`;

const ItemsList = styled.div`
    
`;

const Item = styled.div`
    position: relative;
`;

const ControlsPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
`;

const TextSection = styled.div`
    font-size: 1.21em;
`;


const VideoPlaceholder = styled.div`
    display: block;
    width: 100%;
    background: whitesmoke;
    position: relative;
`;

const VideoInner = styled.div`
    display: block;
    height: 420px;
    width: 100%;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
`;

const TexPlaceholder = styled.div`
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('${props => props.src}');
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
`;


const ImgItemPlaceholder = styled.div`
    
`;
const ImageItem = styled.img`
    width: 80%;
    margin: 0 auto;
    display: block;
`;

const ImagePlaceholder = styled.div`
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('${props => props.src}');
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 200px;
`;


const BoardPlaceholder = styled.div`
    width: 100%;
    height: 420px;
`;

const ArrowSpan = styled.span`
    padding: 3px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;
