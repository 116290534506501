import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
} from "react-simple-maps"

const russia = require('../../../assets/images/svg/russia.svg')

export default function RussiaMap(props) {


    return (
        <Wrapper>
            <RussiaImage src={russia} onClick={evt => {
                console.log('onClick: evt = ', evt);
            }}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const RussiaImage = styled.img`
    
`;
