import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import ChessTemplate from '../templates/ChessTemplate'

import Tabs, {TabItem} from "../ui/Tabs";
import CentersPanel from "../russia/panels/CentersPanel";
import UpdateClassInfoPanel from "../classes/panels/UpdateClassInfoPanel";
import ClassTasksPanel from "../tasks/panels/ClassTasksPanel";

export default function AdminTasksApp() {

    return (
        <ChessTemplate active={'tasks'}>

            <Tabs tabs={[
                {
                    label: '7 класс',
                    content: (
                        <TabItem>
                            <ClassTasksPanel classNumber={7} />
                        </TabItem>
                    )
                },
                {
                    label: '8 класс',
                    content: (
                        <TabItem>
                            <ClassTasksPanel classNumber={8} />
                        </TabItem>
                    )
                },
                {
                    label: '9 класс',
                    content: (
                        <TabItem>
                            <ClassTasksPanel classNumber={9} />
                        </TabItem>
                    )
                },
                {
                    label: '10 класс',
                    content: (
                        <TabItem>
                            <ClassTasksPanel classNumber={10} />
                        </TabItem>
                    )
                },
                {
                    label: '11 класс',
                    content: (
                        <TabItem>
                            <ClassTasksPanel classNumber={11} />
                        </TabItem>
                    )
                }

            ]}/>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
