import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {DARK_SIDE} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";


export default function SimpleLessonsList(props) {
    let {lessons, teachers, groups, rooms, setSelectedId, selectedId} = props;

    return (
        <Wrapper>
            <LessonsList>
                {lessons.map((item, k) => {
                    let {teachersIds, startTimestamp, groupId, roomId, name} = item;
                    let tchrs = teachersIds.map(aa => CommonHelper.getItemById(teachers, aa));
                    let grp = CommonHelper.getItemById(groups, groupId);
                    let rm = CommonHelper.getItemById(rooms, roomId);
                    return (
                        <LessonItem
                            selected={(selectedId == item.id)}
                            key={`${item.id}_${k}`}
                            onClick={() => {
                                setSelectedId(item.id);
                            }}>

                            <ItemInfo>

                                <ItemName>
                                    {name}
                                </ItemName>

                                <DatePlaceholder>
                                    {moment(startTimestamp).format('DD.MM.YYYY HH:mm')}
                                </DatePlaceholder>
                            </ItemInfo>

                            <ItemSub>
                                                <span>
                                                    {`Занятие `}
                                                </span>
                                {grp == undefined ? null :
                                    <span>
                                                        {`c группой `}
                                        <B>
                                                            {grp.name}
                                                        </B>
                                                    </span>
                                }
                                {(tchrs == undefined || tchrs.length == 0) ? null :
                                    <span>{` ${tchrs.length > 1 ? 'ведут' : 'ведет '} `}
                                        <B>{tchrs.map(aa => `${aa.firstName} ${aa.lastName}`).join(', ')}</B> {' '}</span>
                                }
                                {rm == undefined ? null :
                                    <span>{`в помещении `}<B>{rm.name}</B></span>
                                }
                            </ItemSub>

                        </LessonItem>
                    )
                })}
            </LessonsList>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const LessonsList = styled.div`
    
`;

const LessonItem = styled.div`
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ItemName = styled.div`
    font-weight: bold;
`;

const ItemSub = styled.div`
    opacity: 0.8;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
`;

const B = styled.span`
    font-weight: bold;
    opacity: 0.7;
`;

const DatePlaceholder = styled.div`
    
`;
