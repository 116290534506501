import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";
import ClassProgramsPanel from "../programs/panels/ClassProgramsPanel";
import ThemePanel from "../programs/panels/ThemePanel";

export default function AdminThemeApp(props) {
    let {id, themeId} = props.match.params;

    console.log('AdminThemeApp: render: number = ', id);

    return (
        <ChessTemplate active={`theme/${id || themeId}`}>

            <div key={`theme_${id || themeId}`}>

                <ThemePanel id={id || themeId}/>

            </div>

        </ChessTemplate>
    );
}
