import SchoolsAPI from '../../api/SchoolsAPI'

import * as types from '../ActionTypes'

let loadSchools_ = () => {
    return {
        type: types.LOAD_SCHOOLS
    }
}
let loadSchoolsSuccess = (schools) => {
    return {
        type: types.LOAD_SCHOOLS_SUCCESS,
        schools: schools
    }
}
let loadSchoolsFailed = (error) => {
    return {
        type: types.LOAD_SCHOOLS_FAIL,
        error: error
    }
}

//thunk
export function loadAllSchools() {
    return (dispatch, getState) => {
        dispatch(loadSchools_());
        return SchoolsAPI.getAllSchools().then(
            schools => dispatch(loadSchoolsSuccess(schools)),
            error => dispatch(loadSchoolsFailed(error))
        )
    }
}

export function loadSchoolById(id) {
    return (dispatch, getState) => {
        dispatch(loadSchools_());
        return SchoolsAPI.getSchool(id).then(
            school => dispatch(loadSchoolsSuccess([school])),
            error => dispatch(loadSchoolsFailed(error))
        )
    }
}

let createSchool_ = () => {
    return {
        type: types.CREATE_SCHOOL
    }
}
let createSchoolSuccess = (school) => {
    return {
        type: types.CREATE_SCHOOL_SUCCESS,
        school: school
    }
}
let createSchoolFailed = (error) => {
    return {
        type: types.CREATE_SCHOOL_FAIL,
        error: error
    }
}

//thunk
export function createSchool(data) {
    return (dispatch, getState) => {
        dispatch(createSchool_());
        return SchoolsAPI.createSchool(data).then(
            school => dispatch(createSchoolSuccess(school)),
            error => dispatch(createSchoolFailed(error))
        )
    }
}

let updateSchool_ = () => {
    return {
        type: types.UPDATE_SCHOOL
    }
}
let updateSchoolSuccess = (school) => {
    return {
        type: types.UPDATE_SCHOOL_SUCCESS,
        school: school
    }
}
let updateSchoolFailed = (error) => {
    return {
        type: types.UPDATE_SCHOOL_FAIL,
        error: error
    }
}

//thunk
export function updateSchool(data) {
    return (dispatch, getState) => {
        dispatch(updateSchool_());
        return SchoolsAPI.updateSchool(data).then(
            school => dispatch(updateSchoolSuccess(school)),
            error => dispatch(updateSchoolFailed(error))
        )
    }
}

let deleteSchool_ = () => {
    return {
        type: types.DELETE_SCHOOL
    }
}
let deleteSchoolSuccess = (id) => {
    return {
        type: types.DELETE_SCHOOL_SUCCESS,
        id: id
    }
}
let deleteSchoolFailed = (error) => {
    return {
        type: types.DELETE_SCHOOL_FAIL,
        error: error
    }
}

//thunk
export function deleteSchool(id) {
    return (dispatch, getState) => {
        dispatch(deleteSchool_());
        return SchoolsAPI.deleteSchool(id).then(
            () => dispatch(deleteSchoolSuccess(id)),
            error => dispatch(deleteSchoolFailed(error))
        )
    }
}
