import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function SpeedControlTool(props) {
    const {
        speed = 1, dSpeed = 0.25, onChange = d => {

        }
    } = props;

    return (
        <Wrapper>
            <Side onClick={() => {
                onChange(Math.max(1, +speed - +dSpeed));
            }}>
                <SpeedImg src={require('../../../assets/images/next_sp.svg')} style={{transform: `rotate(180deg)`}}/>
            </Side>
            <CenterP>
                {`${speed.toFixed(2)}x`.replace('.00', '')}
            </CenterP>
            <Side onClick={() => {
                onChange(Math.min(2.5, +speed + +dSpeed));
            }}>
                <SpeedImg src={require('../../../assets/images/next_sp.svg')}/>
            </Side>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

const Side = styled.div`
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const CenterP = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40px;
`;

const SpeedImg = styled.img`
    height: 12px;
    cursor: pointer;
    opacity: 0.6;
    :hover{
      opacity: 0.8; 
    }
`;
