import AnnouncementsAPI from '../../api/AnnouncementsAPI'

import * as types from '../ActionTypes'

let loadAnnouncements_ = () => {
    return {
        type: types.LOAD_ANNOUNCEMENTS
    }
}
let loadAnnouncementsSuccess = (announcements) => {
    return {
        type: types.LOAD_ANNOUNCEMENTS_SUCCESS,
        announcements: announcements
    }
}
let loadAnnouncementsFailed = (error) => {
    return {
        type: types.LOAD_ANNOUNCEMENTS_FAIL,
        error: error
    }
}

//thunk
export function loadAllAnnouncements() {
    return (dispatch, getState) => {
        dispatch(loadAnnouncements_());
        return AnnouncementsAPI.getAllAnnouncements().then(
            announcements => dispatch(loadAnnouncementsSuccess(announcements)),
            error => dispatch(loadAnnouncementsFailed(error))
        )
    }
}

export function loadAnnouncementById(id) {
    return (dispatch, getState) => {
        dispatch(loadAnnouncements_());
        return AnnouncementsAPI.getAnnouncement(id).then(
            announcement => dispatch(loadAnnouncementsSuccess([announcement])),
            error => dispatch(loadAnnouncementsFailed(error))
        )
    }
}

let createAnnouncement_ = () => {
    return {
        type: types.CREATE_ANNOUNCEMENT
    }
}
let createAnnouncementSuccess = (announcement) => {
    return {
        type: types.CREATE_ANNOUNCEMENT_SUCCESS,
        announcement: announcement
    }
}
let createAnnouncementFailed = (error) => {
    return {
        type: types.CREATE_ANNOUNCEMENT_FAIL,
        error: error
    }
}

//thunk
export function createAnnouncement(data) {
    return (dispatch, getState) => {
        dispatch(createAnnouncement_());
        return AnnouncementsAPI.createAnnouncement(data).then(
            announcement => dispatch(createAnnouncementSuccess(announcement)),
            error => dispatch(createAnnouncementFailed(error))
        )
    }
}

let updateAnnouncement_ = () => {
    return {
        type: types.UPDATE_ANNOUNCEMENT
    }
}
let updateAnnouncementSuccess = (announcement) => {
    return {
        type: types.UPDATE_ANNOUNCEMENT_SUCCESS,
        announcement: announcement
    }
}
let updateAnnouncementFailed = (error) => {
    return {
        type: types.UPDATE_ANNOUNCEMENT_FAIL,
        error: error
    }
}

//thunk
export function updateAnnouncement(data) {
    return (dispatch, getState) => {
        dispatch(updateAnnouncement_());
        return AnnouncementsAPI.updateAnnouncement(data).then(
            announcement => dispatch(updateAnnouncementSuccess(announcement)),
            error => dispatch(updateAnnouncementFailed(error))
        )
    }
}

export function viewAnnouncement(id) {
    return (dispatch, getState) => {
        dispatch(updateAnnouncement_());
        return AnnouncementsAPI.viewAnnouncement(getState().users.currentUserId, id).then(
            announcement => dispatch(updateAnnouncementSuccess(announcement)),
            error => dispatch(updateAnnouncementFailed(error))
        )
    }
}

let deleteAnnouncement_ = () => {
    return {
        type: types.DELETE_ANNOUNCEMENT
    }
}
let deleteAnnouncementSuccess = (id) => {
    return {
        type: types.DELETE_ANNOUNCEMENT_SUCCESS,
        id: id
    }
}
let deleteAnnouncementFailed = (error) => {
    return {
        type: types.DELETE_ANNOUNCEMENT_FAIL,
        error: error
    }
}

//thunk
export function deleteAnnouncement(id) {
    return (dispatch, getState) => {
        dispatch(deleteAnnouncement_());
        return AnnouncementsAPI.deleteAnnouncement(id).then(
            () => dispatch(deleteAnnouncementSuccess(id)),
            error => dispatch(deleteAnnouncementFailed(error))
        )
    }
}
