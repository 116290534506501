import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'

export default function RecoverPasswordForm(props) {
    const {
        onSubmit = () => {

        },
        onSignUpClick = () => {
        },
        onLoginClick = () => {
        }, loading = false,
        recoveryError = ''
    } = props;
    const [email, setEmail] = useState('');

    return (
        <Wrapper>

            <TopPlaceholder>

                <LogoPlaceholder>
                    <Logo src={require('../../../assets/images/hv_s_no_bg.png')}/>
                </LogoPlaceholder>


                <LinedText text={'Восстановить пароль'}/>
            </TopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <Input value={email} type={'email'} placeholder={'Ваш E-Mail'} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                    {(recoveryError == undefined || recoveryError == '') ? null :
                        <ErrorMessage>
                            {recoveryError}
                        </ErrorMessage>
                    }
                </Field>

                <Field>
                    <Button onClick={() => {
                        onSubmit(email)
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'ВОССТАНОВИТЬ'
                        }

                    </Button>
                </Field>
            </ContentPlaceholder>

            <BottomPlaceholder>
                <Link onClick={() => {
                    onLoginClick();
                }}>
                    Вход
                </Link>
                <Link onClick={() => {
                    onSignUpClick();
                }}>
                    Регистрация
                </Link>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    //width: 100%;
    //height: 100%;
    padding: 20px;
    box-sizing: border-box;
    width: 400px;
    @media(max-width: 720px){
      width: 100vw;
    }
`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 40px;
`;

const ContentPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;

const LogoPlaceholder = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: -20px;
`;

const Logo = styled.img`
    display: inline-block;
    width: 120px;
`;

const ErrorMessage = styled.div`
    font-size: 12px;
    color: red;
    text-align: center;
`;
