import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import ClassProgramsPanel from "../programs/panels/ClassProgramsPanel";
import {useDispatch, useMappedState} from "redux-react-hook";

import * as themesActions from '../../redux/actions/ThemesActions'
import * as programsActions from '../../redux/actions/ProgramsActions'
import {Code} from 'react-content-loader'
import CommonHelper from "../../helpers/CommonHelper";
import ThemeItemViewTool from "../programs/tools/ThemeItemViewTool";
import FullScreenEmbedVideoModal from "../video/tools/FullScreenEmbedVideoModal";
import SmartVideoModal from "../video/tools/SmartVideoModal";
import ExpItem from "./tools/ExpItem";
import SmartCustomVideoModal from "../video/tools/SmartCustomVideoModal";

const ie = a => (a == undefined || a.trim() == '');

export default function LandingThemeInnerPanel(props) {
    let {themeId, isExperiment = false, subj = 'phys'} = props;
    const bgImage = undefined; //todo: change

    const [selectedVideoId, setSelectedVideoId] = useState(undefined);

    const {program, theme, classNumber, loading, currentUser} = useMappedState(useCallback(state => {
        let theme = state.themes.themesMap.get(themeId);
        let program = (theme == undefined) ? undefined : state.programs.programsMap.get(theme.programId);
        let currentUser = (state.users.currentUserId == undefined) ? undefined : state.users.usersMap.get(state.users.currentUserId);
        return {
            theme: theme,
            program: program,
            classNumber: (program == undefined) ? undefined : program.classNumber,
            loading: state.themes.loading || state.programs.loading,
            currentUser: currentUser
        }
    }, [themeId]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(themesActions.loadAllThemes());
        dispatch(programsActions.loadAllPrograms());
    }, [themeId]);

    let hasNothing = (program == undefined || classNumber == undefined || theme == undefined);
    if (hasNothing == true && loading == true) {
        return (
            <Code/>
        )
    }
    let selectedVideoItem = (selectedVideoId == undefined) ? undefined : theme.items.filter(a => (a.id == selectedVideoId))[0];

    let themeItems = (theme == undefined || theme.items == undefined) ? [] : theme.items.map(a => ((a.isExperiment == undefined ? {
        ...a,
        isExperiment: false
    } : a)));
    let themeHasAnyExperiments = ((themeItems.filter(a => (a.isExperiment != false))).length > 0);
    themeItems = themeItems.filter(a => (a.isExperiment == isExperiment));

    console.log('LandingThemeInnerPanel: render: theme = ', theme);
    console.log('LandingThemeInnerPanel: render: subj = ', subj);
    let isPublic = (theme == undefined) ? true : (theme.isPublic == undefined ? true : theme.isPublic);
    if (currentUser != undefined) {
        isPublic = true;
    }

    return (
        <div>

            <FullPageImageSection height={`220px`} hasTopLogo={false} image={bgImage}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel motto={(
                        <div>
                            {classNumber == undefined ? null :
                                <span>
                                    {classNumber == '0' ? 'ВсОШ' : (classNumber == '1' ? 'Эксперимент' : `${classNumber} КЛАСС`)}
                                </span>
                            }
                        </div>
                    )}
                                       subMotto={null}
                                       hasTopLogo={false}
                    />
                </div>
            </FullPageImageSection>

            <BottomContentPlaceholder>

                {hasNothing == true ? null :
                    <RespContainer>

                        <CrumbsPlaceholder>
                            <CrumbItem
                                onClick={() => {
                                    CommonHelper.linkTo(`/${subj}/class/${classNumber}`);
                                }}
                                style={{cursor: 'pointer', textDecoration: 'underline'}}>
                                {classNumber == '0' ? 'ВсОШ' : (classNumber == '1' ? 'Эксперимент' : `${classNumber} КЛАСС`)}
                            </CrumbItem>
                            <CrumbDelimiter>
                                /
                            </CrumbDelimiter>
                            <CrumbItem>
                                {`${program.name}`}
                            </CrumbItem>
                            <CrumbDelimiter>
                                /
                            </CrumbDelimiter>
                            <CrumbItem clickable={(isExperiment)} onClick={() => {
                                if (isExperiment) {
                                    CommonHelper.linkTo(`/theme/${themeId}`);
                                }
                            }}>
                                {`${theme.name}`}
                            </CrumbItem>
                            {isExperiment == false ? null :
                                <CrumbDelimiter>
                                    /
                                </CrumbDelimiter>
                            }
                            {isExperiment == false ? null :
                                <CrumbItem>
                                    Эксперименты
                                </CrumbItem>
                            }
                        </CrumbsPlaceholder>

                        <Heading>
                            {theme.name}
                        </Heading>

                        {isPublic != true ?
                            <ContentPlaceholder>
                                Для просмотра этой страницы необходимо авторизоваться на сайте
                            </ContentPlaceholder> :
                            <ContentPlaceholder>
                                {themeItems.map((it, k) => {
                                    return (
                                        <Item key={`k_${k}_${it.id}`}>
                                            <ThemeItemViewTool {...it} canEdit={false}
                                                               onVideoClick={() => {
                                                                   setSelectedVideoId(it.id);
                                                               }}
                                            />
                                        </Item>
                                    )
                                })}

                                {(isExperiment == true || themeHasAnyExperiments == false) ? null :
                                    <Item>
                                        <ExpItem onItemClick={() => {
                                            CommonHelper.linkTo(`/theme/${themeId}/experiment`);
                                        }}/>
                                    </Item>
                                }

                            </ContentPlaceholder>
                        }


                        {/*{((ie(theme.tasksUrl) == true) || (isExperiment == true)) ? null :*/}
                        {/*<ContentBottomPlaceholder>*/}
                        {/*<TasksUrlLink onClick={() => {*/}
                        {/*CommonHelper.downloadURI(theme.tasksUrl, `${theme.name}. Задачи для самостоятельного решения`)*/}
                        {/*}}>*/}
                        {/*Задачи для самостоятельного решения*/}
                        {/*</TasksUrlLink>*/}
                        {/*</ContentBottomPlaceholder>*/}
                        {/*}*/}

                    </RespContainer>
                }

            </BottomContentPlaceholder>

            {/*{(selectedVideoItem == undefined) ? null :*/}
            {/*<SmartVideoModal*/}
            {/*{...selectedVideoItem}*/}
            {/*onClose={() => {*/}
            {/*setSelectedVideoId(undefined);*/}
            {/*}}/>*/}
            {/*}*/}

            {(selectedVideoItem == undefined) ? null :
                <SmartCustomVideoModal
                    {...selectedVideoItem}
                    sdUrl={selectedVideoItem.videoUrl480p || selectedVideoItem.videoUrl720p || selectedVideoItem.videoUrl}
                    hdUrl={selectedVideoItem.videoUrl720p || selectedVideoItem.videoUrl480p || selectedVideoItem.videoUrl}
                    onClose={() => {
                        setSelectedVideoId(undefined);
                    }}/>
            }


        </div>
    );
}

const size = 100;

const CrumbsPlaceholder = styled.div`
    color: ${BLUE};
    font-size: 16px;
    margin-bottom: 30px;
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: ${BLUE};
`;

const CrumbItem = styled.span`
    cursor: ${props => (props.clickable == true ? 'pointer' : 'default')};
    text-decoration: ${props => (props.clickable == true ? 'underline' : 'none')};
`;

const CrumbDelimiter = styled.span`
    margin-left: 5px;
    margin-right: 5px;
`;

const BottomContentPlaceholder = styled.div`
    padding-top: 10px;
    padding-bottom: 50px;
    background: white;
`;

const RespContainer = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding: 24px;
    box-sizing: border-box;
    @media(max-width: 1200px){
      width: 100%;
    }
`;

const ContentPlaceholder = styled.div`
    
`;

const ContentBottomPlaceholder = styled.div`
    margin-top: 30px;
`;

const TasksUrlLink = styled.span`
    color: ${BLUE};
    font-size: 24px;
    cursor: pointer;
    border-bottom: 1px solid ${BLUE};
    line-height: 24px;
    :hover{
      color: ${YELLOW};
      border-bottom: 1px solid ${YELLOW};
    }
`;

const Item = styled.div`
    display: inline-block;
    vertical-align: top;
    //width: 50%;
    width: 33.33%;
    box-sizing: border-box;
    padding: 10px;
    //:nth-of-type(2n - 1){
    :nth-of-type(3n - 2){
      padding-left: 0px;
    }
    @media(max-width: 1020px){
      width: 50%;
      :nth-of-type(2n - 1){
        padding-left: 0px;
      }
    }
    @media(max-width: 920px){
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
`;

const ExperimentsLinkingBottomPlaceholder = styled.div`
    
`;
