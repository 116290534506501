import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SchoolsAPI = {

    getAllSchools() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/schools/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createSchool(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/schools/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateSchool(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/schools/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteSchool(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/schools/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default SchoolsAPI;
