import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {BLUE, YELLOW} from "../../ui/Colors";
import {API_ENDPOINT} from "../../../constants/config";
import ViewNewsItemPanel from "../../news/panels/ViewNewsItemPanel";
import CommonHelper from "../../../helpers/CommonHelper";

const defaulImage = require('../../../assets/images/child_bg_2.jpg');


export default function MainNewsSection(props) {
    const {hasMore = true} = props;
    const [selectedId, setSelectedId] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios.get(`${API_ENDPOINT}/news_items/all`).then(d => d.data).then(arr => {
            arr = arr.sort((a, b) => (+b.timestamp - +a.timestamp));
            setItems(arr.filter(a => (a.enabled == true)));
            setLoading(false);
        })
    }, []);
    if (loading == true && items.length == 0) {
        return (
            <Code/>
        )
    }
    let visibleItems = (hasMore == false) ? items : items.slice(0, 4);

    return (
        <Wrapper>

            {/*<NewsList>*/}
            {/*{visibleItems.map((item, k) => {*/}
            {/*let {avatar, description, name, dateString, timestamp} = item;*/}
            {/*return (*/}
            {/*<NewsItem key={k} onClick={() => {*/}
            {/*// setSelectedId(item.id);*/}
            {/*CommonHelper.linkTo(`/news/${item.id}`)*/}
            {/*}}>*/}
            {/*<AvatarPlaceholder src={avatar}>*/}

            {/*</AvatarPlaceholder>*/}
            {/*<InfoPlaceholder>*/}
            {/*<Name>*/}
            {/*{name}*/}
            {/*</Name>*/}
            {/*<Date>*/}
            {/*{moment(timestamp).format('DD.MM.YYYY')}*/}
            {/*</Date>*/}
            {/*<TextPlaceholder>*/}
            {/*{description}*/}
            {/*</TextPlaceholder>*/}
            {/*</InfoPlaceholder>*/}
            {/*</NewsItem>*/}
            {/*);*/}
            {/*})}*/}
            {/*</NewsList>*/}

            <NewsList>
                {visibleItems.map((item, k) => {
                    let {avatar, description, name, dateString, timestamp} = item;
                    let ava = CommonHelper.getSafeUrl(avatar);
                    return (
                        <NewsItem2 key={k} onClick={() => {
                            // setSelectedId(item.id);
                            CommonHelper.linkTo(`/news/${item.id}`)
                        }}>
                            <NewsItem2Inner>
                                <AvatarPlaceholder2 src={ava}>

                                </AvatarPlaceholder2>
                                <InfoPlaceholder>
                                    <Name2>
                                        {name}
                                    </Name2>
                                    <Date>
                                        {moment(timestamp).format('DD.MM.YYYY')}
                                    </Date>
                                    <SubDate/>
                                    <TextPlaceholder2>
                                        {description}
                                    </TextPlaceholder2>
                                </InfoPlaceholder>
                            </NewsItem2Inner>

                        </NewsItem2>
                    );
                })}
            </NewsList>


            {/*{(hasMore == false || items.length < 4) ? null :*/}
            {/*<BottomMorePlaceholder>*/}
            {/*<MoreSpan onClick={() => {*/}
            {/*CommonHelper.linkTo(`/news`);*/}
            {/*}}>*/}
            {/*Следующие новости...*/}
            {/*</MoreSpan>*/}
            {/*</BottomMorePlaceholder>*/}
            {/*}*/}

            {hasMore == false ? null :
                <BottomMorePlaceholder>
                    <MoreSpan onClick={() => {
                        CommonHelper.linkTo(`/news`);
                    }}>
                        Следующие новости
                    </MoreSpan>
                </BottomMorePlaceholder>
            }

            <Sidebar width={window.innerWidth} visible={(selectedId != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedId == undefined ? null :
                    <SelectedPlaceholder>
                        <ViewNewsItemPanel id={selectedId}/>
                    </SelectedPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const NewsItem = styled.div`
    box-sizing: border-box;
    cursor: pointer;
    margin: 15px;
    display: inline-block;
    width: calc(33.33% - 30px);
    vertical-align: top;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 920px){
      width: calc(50% - 30px);
    }
    @media(max-width: 720px){
      width: calc(100% - 30px);
    }
`;

const NewsItem2 = styled.div`
    box-sizing: border-box;
    cursor: pointer;
    margin: 15px;
    display: inline-block;
    width: calc(50% - 30px);
    vertical-align: top;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 720px){
      width: calc(100% - 30px);
    }
`;

const NewsItem2Inner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    @media(max-width: 720px){
      flex-direction: column;
    }
`;

const NewsList = styled.div`
    //display: flex;
    //flex-direction: row;
    //align-items: flex-start;
    //flex-wrap:wrap
    //justify-content: space-between;
    
`;

const BottomMorePlaceholder = styled.div`
    text-align: center;
    color: ${YELLOW};
    font-size: 24px;
    margin-top: 30px;
`;

const AvatarPlaceholder = styled.div`
    height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => (props.src)});
    margin-bottom: 7px;
`;

const AvatarPlaceholder2 = styled.div`
    height: 125px;
    width: 240px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => (props.src)});
    margin-bottom: 7px;
    margin-right: 20px;
    @media(max-width: 720px){
      width: 100%;
      height: 200px;
    }
`;

const InfoPlaceholder = styled.div`
    flex: 1;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
`;

const Name2 = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
`;

const Date = styled.div`
    opacity: 0.5;
`;

const SubDate = styled.div`
    background: whitesmoke;
    height: 2px;
    width: 120px;
    margin-top: 5px;
`;

const TextPlaceholder = styled.div`
    margin-top: 20px;
    text-align: justify;
`;

const TextPlaceholder2 = styled.div`
    margin-top: 15px;
    text-align: justify;
`;


const MoreSpan = styled.span`
    cursor: pointer;
    font-weight: bold;
    color: ${BLUE};
    :hover{
      opacity: 0.7;
      text-decoration: underline;
    }
`;

const SelectedPlaceholder = styled.div`
    width: 620px;
    margin: 0 auto;
    @media(max-width: 820px){
      width: 90vw;
    }
`;
