import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Tabs from '../../ui/Tabs'
import NameDescriptionForm from '../../common/forms/NameDescriptionForm'

import * as actions from '../../../redux/actions/CoursesActions'

import UnitItemsEditor from '../../units/editors/UnitItemsEditor'
import CommonHelper from "../../../helpers/CommonHelper";
import NameDescriptionImageForm from "../../common/forms/NameDescriptionImageForm";

export default function UnitEditorPanel(props) {
    let {id} = props;
    const {loading, unit, isAdmin} = useMappedState(useCallback(state => ({
        loading: state.courses.loading,
        unit: state.courses.unitsMap.get(id),
        isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state))
    }), []));
    const dispatch = useDispatch();
    if (unit == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: `Контент ${loading == true ? 'загрузка...' : ''}`,
                    content: (
                        <TabContent>
                            <UnitItemsEditor {...unit} canEdit={isAdmin} loading={loading} onChange={newItems => {
                                dispatch(actions.updateUnit({
                                    id: id,
                                    items: newItems
                                }));
                            }}/>
                        </TabContent>
                    )
                },
                {
                    label: 'Информация',
                    content: (
                        <TabContent>
                            <NameDescriptionImageForm loading={loading} {...unit} onSubmit={data => {
                                dispatch(actions.updateUnit({
                                    id: id,
                                    ...data
                                }));
                            }} canEdit={isAdmin}/>
                        </TabContent>
                    )
                }
            ]}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TabContent = styled.div`
    width: 100%;
    padding-top: 10px;
`;
