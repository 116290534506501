import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {BLUE, LIGHT_BLUE, YELLOW} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";

let defaultAvatar = require('../../../assets/images/exp_small_image.jpg');

export default function ExpItem(props) {
    let {
        avatar = undefined,
        onItemClick = () => {

        },
        text = 'Эксперименты'
    } = props;

    let ava = (avatar == undefined) ? defaultAvatar : CommonHelper.getSafeUrl(avatar);

    return (
        <Wrapper onClick={() => {
            onItemClick()
        }}>
            <LeftPlaceholder>
                <VideoAvatarPlaceholder src={ava}>
                    <AvaOverlay onClick={() => {

                    }}>
                    </AvaOverlay>
                </VideoAvatarPlaceholder>
            </LeftPlaceholder>
            <RightPlaceholder>
                <Name>
                    {text}
                </Name>
            </RightPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    //padding: 20px;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    flex-direction: row;
    justify-content: flex-start;
    //height: 200px;
    height: 140px;
    align-items: stretch;
    background: ${LIGHT_BLUE};
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const AvaOverlay = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      background: rgba(255, 255, 255, 0.05);
    }
`;

const PlayButton = styled.img`
    height: 60px;
    width: 60px;
`;

const LeftPlaceholder = styled.div`
    margin-right: 20px;
    //width: 300px;
    width: 200px;
    height: 100%;
`;

const RightPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;
    align-items: center;
`;

const VideoAvatarPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
`;


const buttonHeight = 32;


const Name = styled.div`
    font-size: 20px;
    //line-height: 32px;
    color: ${BLUE};
`;
