import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ChessTemplate from '../templates/ChessTemplate'

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import AdminStatsPanel from "../admin/panels/AdminStatsPanel";
import FranchiseePanel from "../franchisee/panels/FranchiseePanel";
import FranchiseeStaffPanel from "../staff/panels/FranchiseeStaffPanel";

export default function FranchiseeStaffApp(props) {
    const dispatch = useDispatch();
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    return (
        <ChessTemplate active={'staff'}>

            <FranchiseeStaffPanel/>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
