import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";

import validator from 'validator'

import Spin from 'arui-feather/spin'
import CoveredUploadPanel from "../../upload/CoveredUploadPanel";
import {DEFAULT_TIMEZONE, FACELESS_AVATAR} from "../../../constants/config";

import Select from 'react-select'

import InputMask from 'react-input-mask'
import generator from 'generate-password';

const ie = a => (a == undefined || a.trim() == '');

const ROLES_OPTIONS = [
    {
        label: 'Преподаватель',
        value: 'teacher'
    },
    {
        label: 'Администратор школы',
        value: 'school_admin'
    },
    {
        label: 'Администратор представителя',
        value: 'franchisee_admin'
    }
]

export default function UpdateStaffForm(props) {
    const [userRole, setUserRole] = useState(props.userRole == undefined ? 'teacher' : props.userRole);
    const {allSchools = []} = props;
    const [schoolsIds, setSchoolsIds] = useState(props.schoolsIds == undefined ? [] : props.schoolsIds);
    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);
    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [avatar, setAvatar] = useState(props.avatar);

    const [password, setPassword] = useState(props.password == undefined ? '' : props.password);
    const [passwordConfirmation, setPasswordConfirmation] = useState(props.password == undefined ? '' : props.password);

    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    console.log('ava = ', ava);

    const {
        emailVisible = true,
        passwordVisible = true,
        buttonText = 'Сохранить',
        loading = false,
        avatarVisible = false,
        onSave = () => {

        },
        canEdit = true
    } = props;

    let canSubmit =
        ((((emailVisible == true) && (passwordVisible == true) && (password != undefined) && password.length > 3 && validator.isEmail(email) == true && password == passwordConfirmation)) || (
            (emailVisible == false) && (passwordVisible == false)
        ));
    if (ie(firstName) == true || ie(lastName) == true) {
        canSubmit = false;
    }
    const schoolsOptions = allSchools.map(a => ({label: a.name, value: a.id}));

    return (
        <Wrapper>

            <Field>
                <Label>
                    Тип
                </Label>
                <Select
                    placeholder={'Выбрать тип'}
                    isSearchable={false}
                    options={ROLES_OPTIONS} value={ROLES_OPTIONS.filter(a => (a.value == userRole))[0]}
                    onChange={a => {
                        setUserRole(a.value);
                    }} isMulti={false}/>
            </Field>

            {userRole != 'school_admin' ? null :
                <Field key={`sc_${schoolsOptions.length}`}>
                    <Label>
                        Школы
                    </Label>
                    <Select
                        placeholder={'Выбрать школы'}
                        isSearchable={false}
                        options={schoolsOptions}
                        value={schoolsOptions.filter(a => (schoolsIds.indexOf(a.value) > -1))}
                        onChange={a => {
                            setSchoolsIds(a.map(aa => aa.value));
                        }} isMulti={true}/>
                </Field>
            }

            {avatarVisible == false ? null :
                <Field>
                    <Label>
                        Аватар
                    </Label>
                    <Ava src={ava}>
                        {canEdit == false ? null :
                            <CoveredUploadPanel url={ava} onChange={url => {
                                setAvatar(url);
                            }}/>
                        }
                    </Ava>
                </Field>
            }

            <Field>
                <Label>
                    Имя
                </Label>
                <Input disabled={!canEdit} value={firstName} onChange={evt => setFirstName(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Фамилия
                </Label>
                <Input disabled={!canEdit} value={lastName} onChange={evt => setLastName(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Телефон
                </Label>
                <InputMask className={'my_phone'}
                           mask="+7 (999) 999-99-99"
                           value={phone} onChange={evt =>
                    setPhone(evt.target.value)}/>
            </Field>


            {emailVisible == false ? null :
                <Field>
                    <Label>
                        Email
                    </Label>
                    <Input disabled={!canEdit} value={email} onChange={evt => setEmail(evt.target.value)}/>
                </Field>
            }


            {passwordVisible == false ? null :
                <Field>
                    <Label>
                        Пароль
                    </Label>
                    <Input disabled={!canEdit} value={password} onChange={evt => setPassword(evt.target.value)}/>
                    <span style={{opacity: 0.3, cursor: 'pointer', fontSize: 12}} onClick={() => {
                        let passwd = generator.generate({
                            length: 10,
                            numbers: true
                        });
                        setPassword(passwd);
                        setPasswordConfirmation(passwd);
                    }}>
                        сгенерировать
                    </span>
                </Field>
            }


            {passwordVisible == false ? null :
                <Field>
                    <Label>
                        Повторите пароль
                    </Label>
                    <Input disabled={!canEdit} value={passwordConfirmation}
                           onChange={evt => setPasswordConfirmation(evt.target.value)}/>
                </Field>
            }


            {canEdit == false ? null :
                <Field>
                    <Button
                        style={{marginLeft: 0}}
                        disabled={!canSubmit} onClick={() => {
                        if (canSubmit == false) {
                            return;
                        }
                        let d = {
                            firstName: firstName.trim(),
                            lastName: lastName.trim(),
                            phone,
                            avatar
                        };
                        if (password != passwordConfirmation) {
                            return;
                        }
                        if (emailVisible == true) {
                            d.email = email;
                        }
                        if (passwordVisible == true) {
                            d.password = password;
                        }
                        onSave(d);
                    }}>
                        <Spin visible={(loading)}/>
                        {loading == true ? null : buttonText}
                    </Button>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const AvaPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const avaSize = 80;

const Ava = styled.div`
    display: inline-block;
    vertical-align: top;
    width: ${avaSize}px;
    height: ${avaSize}px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
`;

const DatePickerPlaceholder = styled.div`
    height: 240px;
`;
