import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import SchoolLessonsPanel from "../../lessons/panels/SchoolLessonsPanel";


export default function GroupLessonsPanel(props) {
    let {roomId} = props;
    const {school, loading, franchisee, currentUserId, room} = useMappedState(useCallback(state => {
        let room = state.rooms.roomsMap.get(roomId);
        let sc = (room == undefined) ? undefined : state.schools.schoolsMap.get(room.schoolId);
        return {
            school: sc,
            room: room,
            loading: loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            franchisee: state.users.usersMap.get(sc.franchiseeId)
        }
    }, [roomId]));
    const dispatch = useDispatch();
    if (school == undefined || room == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <SchoolLessonsPanelPlaceholder>

                <SchoolLessonsPanel roomId={roomId} schoolId={school.id}/>

            </SchoolLessonsPanelPlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SchoolLessonsPanelPlaceholder = styled.div`
    
`;
