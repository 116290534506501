import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import ViewNewsItemPanel from "../news/panels/ViewNewsItemPanel";

export default function LandingNewsApp(props) {
    let {id, subj} = props.match.params;
    const bgImage = undefined; //todo: change

    return (
        <LandingTemplate active={'news'} subj={subj} >

            <FullPageImageSection height={`220px`} hasTopLogo={false} image={bgImage}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel motto={(
                        <div style={{paddingLeft: 9}}>
                            НОВОСТИ
                        </div>
                    )}
                                       hasTopLogo={false}
                    />
                </div>
            </FullPageImageSection>

            <BottomContentPlaceholder>

                {id == undefined ?
                    <RespContainer id={'f_news'}>

                        <MainNewsSection hasMore={false}/>

                    </RespContainer>
                    :
                    <RespContainer id={'f_news'}>

                        <ViewNewsItemPanel id={id}/>

                    </RespContainer>
                }


            </BottomContentPlaceholder>

        </LandingTemplate>
    );
}


const size = 100;

const BottomContentPlaceholder = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    background: white;
`;

const RespContainer = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 24px;
    @media(max-width: 1200px){
      width: 100%;
    }
`;

const TripletsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    @media(max-width: 720px){
      display: block;
    }
`;

const Triplet = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    @media(max-width: 720px){
      margin-bottom: 30px;
    }
`;

const TrImage = styled.img`
    width: 40px;
    height: 40px;
`;

const TrName = styled.div`
    text-align: center;
    font-size: 18px;
    color: ${BLUE};
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
`;

const TrText = styled.div`
    color: black;
    text-align: center;
`;
