import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import Player from 'react-background-video-player'

export default function VideoBackground(props) {
    const {
        width = window.innerWidth,
        height = window.innerHeight * 0.7,
        onReady = () => {

        },
        // src = 'https://www.englishpatient.org/e-patient-videos-converted/bc92e3e3-e188-4fcb-a098-5272eec029eb/bc92e3e3-e188-4fcb-a098-5272eec029eb_480p.mp4'
        src = 'https://www.englishpatient.org/english-patient-files/d372a58b-8914-4692-b2f8-ce51a1bfef88.mp4'
    } = props;

    return (
        <Wrapper style={{width: width, height: height}} >
            <Player src={src}
                    style={{width, height}}
                    containerWidth={window.innerWidth} containerHeight={height}
                    onReady={pld => {
                        onReady();
                    }}
            >
            </Player>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
