import {combineReducers} from 'redux';

import UsersReducer from './UsersReducer.js';
import CoursesReducer from './CoursesReducer.js';
import GroupsReducer from './GroupsReducer.js';
import RoomsReducer from './RoomsReducer.js';
import LessonsReducer from './LessonsReducer.js';
import SchoolsReducer from './SchoolsReducer.js';
import DocumentsReducer from './DocumentsReducer.js';
import SubscriptionsReducer from './SubscriptionsReducer.js';
import TransactionsReducer from './TransactionsReducer.js';
import NewsItemsReducer from './NewsItemsReducer.js';
import ProgramsReducer from './ProgramsReducer.js';
import ThemesReducer from './ThemesReducer.js';
import TasksReducer from './TasksReducer.js';
import CentersReducer from './CentersReducer.js';
import SubmissionsReducer from './SubmissionsReducer.js';
import AnnouncementsReducer from './AnnouncementsReducer.js';
import TicketsReducer from './TicketsReducer.js';


export const reducer = combineReducers({
    users: UsersReducer,
    courses: CoursesReducer,
    groups: GroupsReducer,
    rooms: RoomsReducer,
    schools: SchoolsReducer,
    documents: DocumentsReducer,
    subscriptions: SubscriptionsReducer,
    lessons: LessonsReducer,
    transactions: TransactionsReducer,
    programs: ProgramsReducer,
    themes: ThemesReducer,
    tasks: TasksReducer,
    submissions: SubmissionsReducer,
    centers: CentersReducer,
    news: NewsItemsReducer,
    tickets: TicketsReducer,
    announcements: AnnouncementsReducer
});
