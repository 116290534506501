import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Button, DangerButton} from "../../ui/Button";
import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import TeacherSelectorTool from "../tools/TeacherSelectorTool";

import Select from 'react-select'

import Spin from 'arui-feather/spin'
import DateTimePicker from "../../ui/DateTimePicker";

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import CommonHelper from "../../../helpers/CommonHelper";

const getSelectedItem = (arr, id) => {
    if (id == undefined) {
        return undefined;
    }
    let aArr = arr.filter(a => (a.id == id));
    if (aArr.length == 0) {
        return undefined;
    }
    return aArr[0];
};

const getDurationsOptions = () => {
    let max = 3 * 60 * 60 * 1000;
    let delta = 15 * 60 * 1000;
    let arr = [];
    let t = 15 * 60 * 1000;
    while (t <= max) {
        let hours = Math.floor(+t / (3600 * 1000));
        let mins = Math.floor((+t % (3600 * 1000)) / 60000.0);
        let s = `${hours == 0 ? '' : `${hours} ч.`} ${mins == 0 ? '' : `${mins} мин.`}`
        arr.push({
            label: s,
            value: t
        });
        t = +t + +delta;
    }
    return arr;
};

const TYPES_OPTIONS = [
    {
        label: 'Занятие',
        value: 'training'
    },
    {
        label: 'Игра',
        value: 'game'
    },
    {
        label: 'Турнир',
        value: 'tournament'
    }
];

const CONTROL_OPTIONS = [
    {
        label: 'Да',
        value: true
    },
    {
        label: ' Нет',
        value: false
    }
]

const DURATIONS_OPTIONS = getDurationsOptions();


export default function UpdateLessonForm(props) {
    const {
        shouldSelectTeacher = true,
        shouldSelectGroup = true,
        shouldSelectRoom = true,

        allTeachers = [],
        allRooms = [],
        allGroups = [],

        teachersIds = [],

        type = 'training',
        control = false,

        groupId,
        duration,
        roomId,
        loading,
        startTimestamp = +moment(+new Date() + 86400000).startOf('day'),
        onSave = newData => {

        },
        name = '',
        description = '',
        canDelete = false,
        onDelete = () => {
        }
    } = props;
    const [formData, setFormData] = useState({
        name: name,
        description: description,
        teachersIds: teachersIds,
        groupId: groupId,
        roomId: roomId,
        duration: duration,
        startTimestamp: startTimestamp,
        type: type,
        control: control
    });

    const getTeacherById = useCallback((tId) => {
        let arr = allTeachers.filter(a => (a.id == tId));
        if (arr.length == 0) {
            return undefined;
        }
        return arr[0];
    }, []);

    const getGroupById = useCallback((tId) => {
        let arr = allGroups.filter(a => (a.id == tId));
        if (arr.length == 0) {
            return undefined;
        }
        return arr[0];
    }, []);


    // let selectedTeacher = getSelectedItem(allTeachers, formData.teacherId);
    let selectedRoom = getSelectedItem(allRooms, formData.roomId);
    let selectedGroup = getSelectedItem(allGroups, formData.groupId);

    const selectedTeachers = formData.teachersIds.map(tId => getTeacherById(tId)).filter(a => (a != undefined)).map(a => ({
        value: a.id,
        label: `${a.firstName} ${a.lastName}`
    }))

    console.log('UpdateLessonForm: render: teachersIds = ', formData.teachersIds);
    console.log('UpdateLessonForm: render: selectedTeachers = ', selectedTeachers);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Тип
                </Label>
                <Select
                    placeholder={'Выбрать тип'}
                    isSearchable={false}
                    options={TYPES_OPTIONS} value={TYPES_OPTIONS.filter(a => (a.value == formData.type))[0]}
                    onChange={a => {
                        setFormData({
                            ...formData,
                            type: a.value
                        });
                    }} isMulti={false}/>
            </Field>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={formData.name} onChange={evt => {
                    let s = evt.target.value;
                    setFormData({
                        ...formData,
                        name: s
                    });
                }}/>
            </Field>

            {shouldSelectGroup == false ? null :
                <Field>
                    <Label>
                        Группа
                    </Label>
                    <Select options={CommonHelper.getDropDownItemsByNamedList(allGroups)}
                            value={(selectedGroup == undefined) ? undefined : {
                                value: formData.groupId,
                                label: `${selectedGroup.name}`
                            }}
                            isMulti={false}
                            isSearchable={CommonHelper.isMobile() == false}
                            placeholder={'Выбрать группу'}
                            onChange={(a, b) => {
                                console.log('onChange: a, b = ', a, b);
                                const gr = getGroupById(a.value);
                                let oldTeachersIds = formData.teachersIds;
                                for (let i in gr.teachersIds) {
                                    let tId = gr.teachersIds[i];
                                    if (oldTeachersIds.indexOf(tId) == -1) {
                                        oldTeachersIds = oldTeachersIds.concat([tId]);
                                    }
                                }
                                setFormData({
                                    ...formData,
                                    groupId: gr.id,
                                    teachersIds: oldTeachersIds
                                });
                            }}
                    />
                </Field>
            }

            {shouldSelectTeacher == false ? null :
                <Field>
                    <Label>
                        Преподаватели
                    </Label>
                    <Select options={CommonHelper.getDropDownItemsByUsers(allTeachers, true)}
                            value={selectedTeachers}
                            isMulti={true}
                            isSearchable={CommonHelper.isMobile() == false}
                            placeholder={'Выбрать Преподавателей'}
                            onChange={(a, b) => {
                                console.log('onChange: a, b = ', a, b);
                                setFormData({
                                    ...formData,
                                    teachersIds: a.map(aa => (aa.value))
                                });
                            }}
                    />
                </Field>
            }


            {shouldSelectRoom == false ? null :
                <Field>
                    <Label>
                        Помещение
                    </Label>
                    <Select options={CommonHelper.getDropDownItemsByNamedList(allRooms)}
                            value={(selectedRoom == undefined) ? undefined : {
                                value: formData.roomId,
                                label: `${selectedRoom.name}`
                            }}
                            isMulti={false}
                            isSearchable={CommonHelper.isMobile() == false}
                            placeholder={'Выбрать помещение'}
                            onChange={(a, b) => {
                                console.log('onChange: a, b = ', a, b);
                                setFormData({
                                    ...formData,
                                    roomId: a.value
                                });
                            }}
                    />
                </Field>
            }

            <Field>
                <Label>
                    Время занятия
                </Label>
                <DatePicker
                    locale={'ru'}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeCaption="Время"
                    selected={new Date(formData.startTimestamp)}
                    onChange={newDate => {
                        setFormData({
                            ...formData,
                            startTimestamp: +new Date(newDate)
                        });
                    }}
                />
                {/*<CalendarPlaceholder>*/}
                {/*<DateTimePicker timestamp={formData.startTimestamp} onChange={t => {*/}
                {/*setFormData({*/}
                {/*...formData,*/}
                {/*startTimestamp: t*/}
                {/*});*/}
                {/*}}/>*/}
                {/*</CalendarPlaceholder>*/}

            </Field>

            <Field>
                <Label>
                    Продолжительность
                </Label>
                <Select options={DURATIONS_OPTIONS}
                        value={(formData.duration == undefined) ? undefined : DURATIONS_OPTIONS.filter(a => (+a.value == +formData.duration))[0]}
                        isMulti={false}
                        isSearchable={false}
                        placeholder={'Продолжительность'}
                        onChange={(a, b) => {
                            console.log('onChange: a, b = ', a, b);
                            setFormData({
                                ...formData,
                                duration: a.value
                            });
                        }}
                />
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={formData.description} onChange={evt => {
                    let s = evt.target.value;
                    setFormData({
                        ...formData,
                        description: s
                    });
                }}/>
            </Field>

            <Field>
                <Label>
                    Является ли контрольным
                </Label>
                <Select
                    placeholder={''}
                    isSearchable={false}
                    options={CONTROL_OPTIONS} value={CONTROL_OPTIONS.filter(a => (a.value == formData.control))[0]}
                    onChange={a => {
                        setFormData({
                            ...formData,
                            control: a.value
                        });
                    }} isMulti={false}/>
            </Field>

            <Field>
                <BottomButtonsPlaceholder>
                    <Button onClick={() => {
                        onSave(formData);
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            <span>
                            Сохранить
                        </span>
                        }
                    </Button>

                    {canDelete == false ? null :
                        <DangerButton loading={loading} onClick={() => {
                            onDelete();
                        }}>
                            Удалить
                        </DangerButton>
                    }

                </BottomButtonsPlaceholder>

            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

const CalendarPlaceholder = styled.div`
    height: 300px;
`;

const BottomButtonsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
