import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CenterProgressTool from "../tools/CenterProgressTool";
import * as usersActions from "../../../redux/actions/UsersActions";
import CommonHelper from "../../../helpers/CommonHelper";
import UpdateUserForm from "../../users/forms/UpdateUserForm";
import Tabs from "../../ui/Tabs";
import AchievementsListEditor from "../../achievements/tools/AchievementsListEditor";

export default function TeacherEducationPanel(props) {
    let {currentUser, getUser, loading, allCenters} = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            getUser: x => state.users.usersMap.get(x),
            loading: state.users.loading,
            allCenters: state.centers.centersMap.toArray()
        }
    }, []));
    const dispatch = useDispatch();
    const [updT, setUpdT] = useState(0);

    const [saving, setSaving] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(undefined);
    let selectedStudent = (selectedUserId == undefined) ? undefined : getUser(selectedUserId);

    console.log('TeacherEducationPanel: render: selectedUserId = ', selectedUserId);

    if (currentUser == undefined) {
        return null;
    }

    console.log('TeacherEducationPanel: render: currentUser = ', currentUser);
    let isModerator = (currentUser != undefined && currentUser.isModerator == true);

    return (
        <Wrapper>
            <div key={`upd_t${updT}`}>
                <CenterProgressTool centerId={currentUser.centerId} onStudentSelect={x => {
                    setSelectedUserId(x);
                }}/>
            </div>


            <Sidebar width={Math.min(window.innerWidth, 820)} visible={(selectedStudent != undefined)}
                     onCloserClick={() => {
                         setSelectedUserId(undefined);
                         // dispatch(usersActions.loadAllUsers()); // todo: optimize
                     }}>
                {selectedUserId == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Информация',
                                content: (
                                    <div>
                                        <UpdateUserForm
                                            {...selectedStudent}
                                            loading={loading}
                                            showStudentInfo={true}
                                            showParentInfo={true}
                                            avatarVisible={true}
                                            passwordVisible={false}
                                            emailVisible={false}
                                            allCenters={allCenters}
                                            onSave={async data => {
                                                await dispatch(usersActions.updateUser({
                                                    ...data,
                                                    id: selectedStudent.id
                                                }));
                                                setSelectedUserId(undefined);
                                                setUpdT(+new Date());
                                            }}
                                            onDelete={async () => {
                                                await dispatch(usersActions.deleteUser(selectedStudent.id));
                                                CommonHelper.linkTo(`/students`);
                                            }}
                                            canDelete={(currentUser != undefined && (currentUser.userRole == 'admin'))}
                                        />
                                    </div>
                                )
                            },
                            {
                                label: 'Олимпиады',
                                content: isModerator == true ? null : (
                                    <div>
                                        <AchievementsListEditor {...selectedStudent} saving={saving}
                                                                onSave={async arr => {
                                                                    console.log('onSave: arr = ', arr);
                                                                    setSaving(true);
                                                                    await dispatch(usersActions.updateUser({
                                                                        id: selectedStudent.id,
                                                                        achievements: arr
                                                                    }));
                                                                    setSaving(false);
                                                                    // onSave({achievements: arr})
                                                                }}/>
                                    </div>
                                )
                            }
                        ].filter(xx => (xx.content != undefined))}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 10px;
  background: white;
  margin: 5px;
  border-radius: 6px;
`;
