import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import KeyValueAPI from "../../../api/KeyValueAPI";
import UpdateNameForm from "../tools/UpdateNameForm";
import {Button} from "../../ui/Button";

export default function AdminUpdateAnyListPanel(props) {
    const {
        buttonName = 'Добавить'
    } = props;

    const {listName = 'list'} = props;
    const [arr, setArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        KeyValueAPI.getParam(listName, []).then(a => {
            setArr(a);
            setLoading(false);
        });
    }, [listName]);

    const selectedItem = arr.filter(a => (a.id == selectedId))[0];
    let sortedArr = arr.sort((a, b) => (+a.number - +b.number));

    return (
        <Wrapper>

            <ListPlaceholder>
                {sortedArr.map((a, i) => {
                    return (
                        <Item key={a.id} selected={(a.id == selectedId)}>
                            <Name onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                {a.name}
                            </Name>
                            <ItemControls>
                                <DeleteSpan onClick={async () => {
                                    if (window.confirm('Вы уверены?') == false) {
                                        return;
                                    }
                                    let newArr = arr.filter(x => (x.id != a.id));
                                    setLoading(true);
                                    await KeyValueAPI.saveParam(listName, newArr);
                                    setArr(newArr);
                                    setLoading(false);
                                }}>
                                    удалить
                                </DeleteSpan>
                            </ItemControls>
                        </Item>
                    )
                })}
            </ListPlaceholder>

            <div>
                <Button onClick={async () => {
                    setLoading(true);
                    let newId = uuid();
                    let newArr = arr.concat([{id: newId, name: 'Без названия', number: +new Date()}]);
                    setLoading(true);
                    await KeyValueAPI.saveParam(listName, newArr);
                    setArr(newArr);
                    setSelectedId(newId);
                    setLoading(false);
                }}>
                    <Spin visible={loading}/>
                    {buttonName}
                </Button>
            </div>

            <Sidebar visible={(selectedItem != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedId == undefined ? null :
                    <div>
                        <UpdateNameForm {...selectedItem} loading={loading} onSave={async d => {
                            let newArr = arr.map(a => (a.id == selectedItem.id ? {...selectedItem, ...d} : a));
                            setLoading(true);
                            await KeyValueAPI.saveParam(listName, newArr);
                            setArr(newArr);
                            setLoading(false);
                            setSelectedId(undefined);
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const ItemControls = styled.div`
    text-align: left;
    font-size: 12px;
    margin-top: 5px;
`;

const DeleteSpan = styled.span`
    cursor: pointer;
    color: red;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
`;

const Name = styled.div`
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
`;
