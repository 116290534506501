import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import MySidebar from './MySidebar'

import {useDispatch, useMappedState} from 'redux-react-hook';
import * as usersActions from '../../redux/actions/UsersActions'

import FullPagePreloader from '../preloader/FullPagePreloader'
import CommonHelper from "../../helpers/CommonHelper";

import {Container} from "../ui/Container";
import {Button} from "../ui/Button";

import Sidebar from 'arui-feather/sidebar'

import UpdateUserInfoForm from '../users/forms/UpdateUserInfoForm'

export default function ChessTemplate(props) {
    let {children, active} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, franchiseeSchools} = useMappedState(useCallback(state => {
        let cU = state.users.usersMap.get(state.users.currentUserId);
        let uId = cU.id;
        let schoolsIds = [];
        if (cU != undefined && cU.userRole == 'manager' && cU.schoolsIds != undefined) {
            schoolsIds = cU.schoolsIds;
        }
        let franchiseeSchools = state.schools.schoolsMap.toArray().filter(sc => {
            if (cU.userRole == 'admin') {
                return true;
            }
            let f = (sc.franchiseeId == uId);
            if (schoolsIds.length > 0) {
                f = (schoolsIds.indexOf(sc.id) > -1);
            }
            return f;
        });
        return ({
            currentUser: cU,
            loading: state.users.loading,
            franchiseeSchools: franchiseeSchools
        })
    }, []));
    const [sidebarVisible, setSidebarVisible] = useState(false);
    if (currentUser == undefined) {
        return null;
    }
    let addLinks = [];

    addLinks = franchiseeSchools.map(sc => {
        return {
            name: `school/${sc.id}`,
            label: sc.name,
            url: `school/${sc.id}`
        }
    });

    let extraLinksContent = {
        afterIndex: 'schools',
        links: addLinks
    }

    return (
        <Wrapper>
            <Left>
                <MySidebar {...currentUser}
                           active={active}
                           extraLinksContent={extraLinksContent}
                           currentUser={currentUser}
                           loading={loading}
                           onLogout={() => {
                               dispatch(usersActions.logOut()).then(pld => {
                                   if (pld.error == undefined) {
                                       CommonHelper.linkTo('/');
                                       setTimeout(() => {
                                           window.location.reload();
                                       }, 100);
                                   }
                               });
                           }}
                           onCurrentUserClick={() => {
                               setSidebarVisible(true);
                           }}
                           onUserUpdate={updD => {
                               dispatch(usersActions.updateUser({id: currentUser.id, ...updD})).then(pld => {
                                   setSidebarVisible(false);
                               });
                           }}
                />
            </Left>

            <Right>

                {children}

            </Right>

            <FullPagePreloader visible={loading}/>

            <Sidebar width={Math.min(740, window.innerWidth)} visible={sidebarVisible} onCloserClick={() => {
                setSidebarVisible(false);
            }}>

                {sidebarVisible == false ? null :
                    <div>

                        <Heading>
                            Редактирование профиля
                        </Heading>

                        <UpdateUserInfoForm loading={loading} {...currentUser} onSave={data => {
                            let d = {
                                id: currentUser.id,
                                ...data
                            };
                            dispatch(usersActions.updateUser(d)).then(pld => {
                                // setSidebarVisible(false);
                            });
                        }}

                        />
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const leftWidth = 250;

const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 100vh;
  width: ${leftWidth}px;
  background: #151B25;
`;

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  width: calc(100% - ${leftWidth}px);
  background: whitesmoke;
  box-sizing: border-box;
  padding: 20px;
`;

const Heading = styled.div`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
`;


