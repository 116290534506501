import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import AnnouncementsAPI from "../../../api/AnnouncementsAPI";
import CommonHelper from "../../../helpers/CommonHelper";

export default function AnnouncementViewedUsersTool(props) {
    const {id} = props;
    const [users, setUsers] = useState([]);

    useEffect(() => {
        AnnouncementsAPI.getAnnouncementUsers(id).then(arr => {
            setUsers(arr);
        })
    }, [id]);

    return (
        <Wrapper>
            <UsersList>
                {users.map((u, i) => {
                    return (
                        <UserItem key={id}>
                            <Name>
                                {CommonHelper.getShortNameByUser(u)}
                            </Name>
                        </UserItem>
                    )
                })}
            </UsersList>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const UsersList = styled.div`
    
`;

const UserItem = styled.div`
    margin-bottom: 10px;
`;

const Name = styled.div`
    font-weight: bold;
`;
