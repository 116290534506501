import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import KeyValueAPI from "../../../api/KeyValueAPI";

import ls from 'local-storage'

const DEFAULT_VALUE = {
    description: '',
    programImageUrl: undefined,
    programFileUrl: undefined,
    programFileButtonName: ''
}

export default function ClassProgramPanel(props) {
    let {classNumber} = props;
    const {subj = 'phys'} = props;
    const [loading, setLoading] = useState(true);
    const [classInfo, setClassInfo] = useState(DEFAULT_VALUE);
    console.log('ClassProgramPanel: render: classInfo = ', classInfo);

    useEffect(() => {
        KeyValueAPI.getParam(`class_info_${classNumber}`, DEFAULT_VALUE).then(d => {
            setClassInfo(d[subj]);
            setLoading(false);
        });
    }, [classNumber]);

    let {description, programFileUrl, programFileButtonName} = classInfo;
    if (loading == true) {
        return (
            <Code/>
        )
    }


    return (
        <Wrapper>
            {description == undefined || description.trim() == '' ? null :
                <div dangerouslySetInnerHTML={{__html: description}}></div>
            }
            <div>
                {(programFileUrl == undefined || programFileUrl == '' || programFileButtonName == undefined || programFileButtonName == '') ? null :
                    <div>
                        <a href={programFileUrl} target={'_blank'}>
                            {programFileButtonName}
                        </a>
                    </div>
                }
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
