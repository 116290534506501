import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ProgramsAPI = {

    getAllPrograms() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/programs/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createProgram(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/programs/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateProgram(data) {
        console.log('updateProgram: data = ', data);
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/programs/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteProgram(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/programs/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default ProgramsAPI;
