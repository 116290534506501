/**
 * Created by sabir on 19.07.17.
 */

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const LOAD_FORMS = 'LOAD_FORMS'
export const LOAD_FORMS_SUCCESS = 'LOAD_FORMS_SUCCESS'
export const LOAD_FORMS_FAIL = 'LOAD_FORMS_FAIL'

export const CREATE_FORM = 'CREATE_FORM'
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS'
export const CREATE_FORM_FAIL = 'CREATE_FORM_FAIL'

export const UPDATE_FORM = 'UPDATE_FORM'
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS'
export const UPDATE_FORM_FAIL = 'UPDATE_FORM_FAIL'

export const DELETE_FORM = 'DELETE_FORM'
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS'
export const DELETE_FORM_FAIL = 'DELETE_FORM_FAIL'

export const SELECT_FORM = 'SELECT_FORM'
export const UNSELECT_FORM = 'UNSELECT_FORM'

export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION'
export const UNSELECT_ORGANIZATION = 'UNSELECT_ORGANIZATION'

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL'

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS'
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL'

export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS'
export const LOAD_ORGANIZATIONS_SUCCESS = 'LOAD_ORGANIZATIONS_SUCCESS'
export const LOAD_ORGANIZATIONS_FAIL = 'LOAD_ORGANIZATIONS_FAIL'

//upload
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'

export const LOAD_USER_LINKS = 'LOAD_USER_LINKS'
export const LOAD_USER_LINKS_SUCCESS = 'LOAD_USER_LINKS_SUCCESS'
export const LOAD_USER_LINKS_FAIL = 'LOAD_USER_LINKS_FAIL'

export const CREATE_USER_LINK = 'CREATE_USER_LINK'
export const CREATE_USER_LINK_SUCCESS = 'CREATE_USER_LINK_SUCCESS'
export const CREATE_USER_LINK_FAIL = 'CREATE_USER_LINK_FAIL'

export const UPDATE_USER_LINK = 'UPDATE_USER_LINK'
export const UPDATE_USER_LINK_SUCCESS = 'UPDATE_USER_LINK_SUCCESS'
export const UPDATE_USER_LINK_FAIL = 'UPDATE_USER_LINK_FAIL'

export const DELETE_USER_LINK = 'DELETE_USER_LINK'
export const DELETE_USER_LINK_SUCCESS = 'DELETE_USER_LINK_SUCCESS'
export const DELETE_USER_LINK_FAIL = 'DELETE_USER_LINK_FAIL'


//users
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH'
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS'
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL'

export const SEARCH_USERS = 'SEARCH_USERS'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL'


//stuff
export const LOAD_STUFFS = 'LOAD_STUFFS'
export const LOAD_STUFFS_SUCCESS = 'LOAD_STUFFS_SUCCESS'
export const LOAD_STUFFS_FAIL = 'LOAD_STUFFS_FAIL'

export const CREATE_STUFF = 'CREATE_STUFF'
export const CREATE_STUFF_SUCCESS = 'CREATE_STUFF_SUCCESS'
export const CREATE_STUFF_FAIL = 'CREATE_STUFF_FAIL'

export const UPDATE_STUFF = 'UPDATE_STUFF'
export const UPDATE_STUFF_SUCCESS = 'UPDATE_STUFF_SUCCESS'
export const UPDATE_STUFF_FAIL = 'UPDATE_STUFF_FAIL'

export const DELETE_STUFF = 'DELETE_STUFF'
export const DELETE_STUFF_SUCCESS = 'DELETE_STUFF_SUCCESS'
export const DELETE_STUFF_FAIL = 'DELETE_STUFF_FAIL'

export const SELECT_STUFF = 'SELECT_STUFF'
export const UNSELECT_STUFF = 'UNSELECT_STUFF'

//questions
export const CREATE_QUESTION = 'CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL'

export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL'

export const DELETE_QUESTION = 'DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL'

export const LOAD_QUESTIONS = 'LOAD_QUESTIONS'
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS'
export const LOAD_QUESTIONS_FAIL = 'LOAD_QUESTIONS_FAIL'

export const SELECT_QUESTION = 'SELECT_QUESTION'
export const UNSELECT_QUESTION = 'UNSELECT_QUESTION'

// tournaments

export const CREATE_TOURNAMENT = 'CREATE_TOURNAMENT'
export const CREATE_TOURNAMENT_SUCCESS = 'CREATE_TOURNAMENT_SUCCESS'
export const CREATE_TOURNAMENT_FAIL = 'CREATE_TOURNAMENT_FAIL'

export const UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT'
export const UPDATE_TOURNAMENT_SUCCESS = 'UPDATE_TOURNAMENT_SUCCESS'
export const UPDATE_TOURNAMENT_FAIL = 'UPDATE_TOURNAMENT_FAIL'

export const LOAD_TOURNAMENTS = 'LOAD_TOURNAMENTS'
export const LOAD_TOURNAMENTS_SUCCESS = 'LOAD_TOURNAMENTS_SUCCESS'
export const LOAD_TOURNAMENTS_FAIL = 'LOAD_TOURNAMENTS_FAIL'

export const DELETE_TOURNAMENT = 'DELETE_TOURNAMENT'
export const DELETE_TOURNAMENT_SUCCESS = 'DELETE_TOURNAMENT_SUCCESS'
export const DELETE_TOURNAMENT_FAIL = 'DELETE_TOURNAMENT_FAIL'

export const SELECT_TOURNAMENT = 'SELECT_TOURNAMENT'
export const UNSELECT_TOURNAMENT = 'UNSELECT_TOURNAMENT'

//stations
export const CREATE_SCHOOL = 'CREATE_SCHOOL'
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS'
export const CREATE_SCHOOL_FAIL = 'CREATE_SCHOOL_FAIL'

export const UPDATE_SCHOOL = 'UPDATE_SCHOOL'
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS'
export const UPDATE_SCHOOL_FAIL = 'UPDATE_SCHOOL_FAIL'

export const LOAD_SCHOOLS = 'LOAD_SCHOOLS'
export const LOAD_SCHOOLS_SUCCESS = 'LOAD_SCHOOLS_SUCCESS'
export const LOAD_SCHOOLS_FAIL = 'LOAD_SCHOOLS_FAIL'

export const DELETE_SCHOOL = 'DELETE_SCHOOL'
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS'
export const DELETE_SCHOOL_FAIL = 'DELETE_SCHOOL_FAIL'

export const SELECT_SCHOOL = 'SELECT_SCHOOL'
export const UNSELECT_SCHOOL = 'UNSELECT_SCHOOL'

//pages
export const CREATE_PAGE = 'CREATE_PAGE'
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS'
export const CREATE_PAGE_FAIL = 'CREATE_PAGE_FAIL'

export const UPDATE_PAGE = 'UPDATE_PAGE'
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS'
export const UPDATE_PAGE_FAIL = 'UPDATE_PAGE_FAIL'

export const DELETE_PAGE = 'DELETE_PAGE'
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS'
export const DELETE_PAGE_FAIL = 'DELETE_PAGE_FAIL'

export const LOAD_PAGES = 'LOAD_PAGES'
export const LOAD_PAGES_SUCCESS = 'LOAD_PAGES_SUCCESS'
export const LOAD_PAGES_FAIL = 'LOAD_PAGES_FAIL'

export const SELECT_PAGE = 'SELECT_PAGE'
export const UNSELECT_PAGE = 'UNSELECT_PAGE'


//groups
export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL'

export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL'

export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL'

export const LOAD_GROUPS = 'LOAD_GROUPS'
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS'
export const LOAD_GROUPS_FAIL = 'LOAD_GROUPS_FAIL'

export const SELECT_GROUP = 'SELECT_GROUP'
export const UNSELECT_GROUP = 'UNSELECT_GROUP'

export const UNSELECT_PAGE_GROUP_QUESTION = 'UNSELECT_PAGE_GROUP_QUESTION'

//edit mode
export const SET_CONFIG_EDIT_MODE = 'SET_CONFIG_EDIT_MODE'

export const SELECT_CONFIG_USER = 'SELECT_CONFIG_USER'
export const SELECT_CONFIG_VERSION_TIMESTAMP = 'SELECT_CONFIG_VERSION_TIMESTAMP'
export const SELECT_CONFIG_VERSION_USER_AND_TIMESTAMP = 'SELECT_CONFIG_VERSION_USER_AND_TIMESTAMP'

//answers
//answers
export const CREATE_ANSWER = 'CREATE_ANSWER'
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS'
export const CREATE_ANSWER_FAIL = 'CREATE_ANSWER_FAIL'

export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS'
export const UPDATE_ANSWER_FAIL = 'UPDATE_ANSWER_FAIL'

export const DELETE_ANSWER = 'DELETE_ANSWER'
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS'
export const DELETE_ANSWER_FAIL = 'DELETE_ANSWER_FAIL'

export const LOAD_ANSWERS = 'LOAD_ANSWERS'
export const LOAD_ANSWERS_SUCCESS = 'LOAD_ANSWERS_SUCCESS'
export const LOAD_ANSWERS_FAIL = 'LOAD_ANSWERS_FAIL'

//records

//records
export const CREATE_RECORD = 'CREATE_RECORD'
export const CREATE_RECORD_SUCCESS = 'CREATE_RECORD_SUCCESS'
export const CREATE_RECORD_FAIL = 'CREATE_RECORD_FAIL'

export const UPDATE_RECORD = 'UPDATE_RECORD'
export const UPDATE_RECORD_SUCCESS = 'UPDATE_RECORD_SUCCESS'
export const UPDATE_RECORD_FAIL = 'UPDATE_RECORD_FAIL'

export const DELETE_RECORD = 'DELETE_RECORD'
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS'
export const DELETE_RECORD_FAIL = 'DELETE_RECORD_FAIL'

export const LOAD_RECORDS = 'LOAD_RECORDS'
export const LOAD_RECORDS_SUCCESS = 'LOAD_RECORDS_SUCCESS'
export const LOAD_RECORDS_FAIL = 'LOAD_RECORDS_FAIL'

export const SELECT_RECORD = 'SELECT_RECORD'
export const UNSELECT_RECORD = 'UNSELECT_RECORD'

export const SELECT_SECOND_RECORD = 'SELECT_SECOND_RECORD'
export const UNSELECT_SECOND_RECORD = 'UNSELECT_SECOND_RECORD'

// courses

export const LOAD_COURSES = 'LOAD_COURSES'
export const LOAD_COURSES_SUCCESS = 'LOAD_COURSES_SUCCESS'
export const LOAD_COURSES_FAIL = 'LOAD_COURSES_FAIL'

export const CREATE_COURSE = 'CREATE_COURSE'
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAIL = 'CREATE_COURSE_FAIL'

export const UPDATE_COURSE = 'UPDATE_COURSE'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL'

export const DELETE_COURSE = 'DELETE_COURSE'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL'

export const LOAD_UNITS = 'LOAD_UNITS'
export const LOAD_UNITS_SUCCESS = 'LOAD_UNITS_SUCCESS'
export const LOAD_UNITS_FAIL = 'LOAD_UNITS_FAIL'

export const CREATE_UNIT = 'CREATE_UNIT'
export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS'
export const CREATE_UNIT_FAIL = 'CREATE_UNIT_FAIL'

export const UPDATE_UNIT = 'UPDATE_UNIT'
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS'
export const UPDATE_UNIT_FAIL = 'UPDATE_UNIT_FAIL'

export const DELETE_UNIT = 'DELETE_UNIT'
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS'
export const DELETE_UNIT_FAIL = 'DELETE_UNIT_FAIL'

// exercises

export const LOAD_EXERCISES = 'LOAD_EXERCISES'
export const LOAD_EXERCISES_SUCCESS = 'LOAD_EXERCISES_SUCCESS'
export const LOAD_EXERCISES_FAIL = 'LOAD_EXERCISES_FAIL'

export const CREATE_EXERCISE = 'CREATE_EXERCISE'
export const CREATE_EXERCISE_SUCCESS = 'CREATE_EXERCISE_SUCCESS'
export const CREATE_EXERCISE_FAIL = 'CREATE_EXERCISE_FAIL'

export const UPDATE_EXERCISE = 'UPDATE_EXERCISE'
export const UPDATE_EXERCISE_SUCCESS = 'UPDATE_EXERCISE_SUCCESS'
export const UPDATE_EXERCISE_FAIL = 'UPDATE_EXERCISE_FAIL'

export const DELETE_EXERCISE = 'DELETE_EXERCISE'
export const DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS'
export const DELETE_EXERCISE_FAIL = 'DELETE_EXERCISE_FAIL'

// rooms

export const CREATE_ROOM = 'CREATE_ROOM'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_FAIL = 'CREATE_ROOM_FAIL'

export const UPDATE_ROOM = 'UPDATE_ROOM'
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS'
export const UPDATE_ROOM_FAIL = 'UPDATE_ROOM_FAIL'

export const DELETE_ROOM = 'DELETE_ROOM'
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS'
export const DELETE_ROOM_FAIL = 'DELETE_ROOM_FAIL'

export const LOAD_ROOMS = 'LOAD_ROOMS'
export const LOAD_ROOMS_SUCCESS = 'LOAD_ROOMS_SUCCESS'
export const LOAD_ROOMS_FAIL = 'LOAD_ROOMS_FAIL'

//lessons

export const CREATE_LESSON = 'CREATE_LESSON'
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS'
export const CREATE_LESSON_FAIL = 'CREATE_LESSON_FAIL'

export const UPDATE_LESSON = 'UPDATE_LESSON'
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS'
export const UPDATE_LESSON_FAIL = 'UPDATE_LESSON_FAIL'

export const DELETE_LESSON = 'DELETE_LESSON'
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS'
export const DELETE_LESSON_FAIL = 'DELETE_LESSON_FAIL'

export const LOAD_LESSONS = 'LOAD_LESSONS'
export const LOAD_LESSONS_SUCCESS = 'LOAD_LESSONS_SUCCESS'
export const LOAD_LESSONS_FAIL = 'LOAD_LESSONS_FAIL'

// documents

export const CREATE_DOCUMENT = 'CREATE_DOCUMENT'
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
export const CREATE_DOCUMENT_FAIL = 'CREATE_DOCUMENT_FAIL'

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS'
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL'

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL'

export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS'
export const LOAD_DOCUMENTS_SUCCESS = 'LOAD_DOCUMENTS_SUCCESS'
export const LOAD_DOCUMENTS_FAIL = 'LOAD_DOCUMENTS_FAIL'

// subscriptions
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS'
export const CREATE_SUBSCRIPTION_FAIL = 'CREATE_SUBSCRIPTION_FAIL'

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_FAIL = 'UPDATE_SUBSCRIPTION_FAIL'

export const LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS'
export const LOAD_SUBSCRIPTIONS_SUCCESS = 'LOAD_SUBSCRIPTIONS_SUCCESS'
export const LOAD_SUBSCRIPTIONS_FAIL = 'LOAD_SUBSCRIPTIONS_FAIL'

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS'
export const DELETE_SUBSCRIPTION_FAIL = 'DELETE_SUBSCRIPTION_FAIL'

// transactions

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS'
export const CREATE_TRANSACTION_FAIL = 'CREATE_TRANSACTION_FAIL'

export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION'
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS'
export const UPDATE_TRANSACTION_FAIL = 'UPDATE_TRANSACTION_FAIL'

export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS'
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS'
export const LOAD_TRANSACTIONS_FAIL = 'LOAD_TRANSACTIONS_FAIL'

export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS'
export const DELETE_TRANSACTION_FAIL = 'DELETE_TRANSACTION_FAIL'

//news

export const LOAD_NEWS_ITEMS = 'LOAD_NEWS_ITEMS'
export const LOAD_NEWS_ITEMS_SUCCESS = 'LOAD_NEWS_ITEMS_SUCCESS'
export const LOAD_NEWS_ITEMS_FAIL = 'LOAD_NEWS_ITEMS_FAIL'

export const CREATE_NEWS_ITEM = 'CREATE_NEWS_ITEM'
export const CREATE_NEWS_ITEM_SUCCESS = 'CREATE_NEWS_ITEM_SUCCESS'
export const CREATE_NEWS_ITEM_FAIL = 'CREATE_NEWS_ITEM_FAIL'

export const UPDATE_NEWS_ITEM = 'UPDATE_NEWS_ITEM'
export const UPDATE_NEWS_ITEM_SUCCESS = 'UPDATE_NEWS_ITEM_SUCCESS'
export const UPDATE_NEWS_ITEM_FAIL = 'UPDATE_NEWS_ITEM_FAIL'

export const DELETE_NEWS_ITEM = 'DELETE_NEWS_ITEM'
export const DELETE_NEWS_ITEM_SUCCESS = 'DELETE_NEWS_ITEM_SUCCESS'
export const DELETE_NEWS_ITEM_FAIL = 'DELETE_NEWS_ITEM_FAIL'

//programs

export const LOAD_PROGRAMS = 'LOAD_PROGRAMS'
export const LOAD_PROGRAMS_SUCCESS = 'LOAD_PROGRAMS_SUCCESS'
export const LOAD_PROGRAMS_FAIL = 'LOAD_PROGRAMS_FAIL'

export const CREATE_PROGRAM = 'CREATE_PROGRAM'
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS'
export const CREATE_PROGRAM_FAIL = 'CREATE_PROGRAM_FAIL'

export const UPDATE_PROGRAM = 'UPDATE_PROGRAM'
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS'
export const UPDATE_PROGRAM_FAIL = 'UPDATE_PROGRAM_FAIL'

export const DELETE_PROGRAM = 'DELETE_PROGRAM'
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS'
export const DELETE_PROGRAM_FAIL = 'DELETE_PROGRAM_FAIL'

//themes

export const CREATE_THEME = 'CREATE_THEME'
export const CREATE_THEME_SUCCESS = 'CREATE_THEME_SUCCESS'
export const CREATE_THEME_FAIL = 'CREATE_THEME_FAIL'

export const UPDATE_THEME = 'UPDATE_THEME'
export const UPDATE_THEME_SUCCESS = 'UPDATE_THEME_SUCCESS'
export const UPDATE_THEME_FAIL = 'UPDATE_THEME_FAIL'

export const LOAD_THEMES = 'LOAD_THEMES'
export const LOAD_THEMES_SUCCESS = 'LOAD_THEMES_SUCCESS'
export const LOAD_THEMES_FAIL = 'LOAD_THEMES_FAIL'

export const DELETE_THEME = 'DELETE_THEME'
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS'
export const DELETE_THEME_FAIL = 'DELETE_THEME_FAIL'

// tasks
export const LOAD_TASKS = 'LOAD_TASKS'
export const LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS'
export const LOAD_TASKS_FAIL = 'LOAD_TASKS_FAIL'

export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL'

export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL'

export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL'

//centers

export const LOAD_CENTERS = 'LOAD_CENTERS'
export const LOAD_CENTERS_SUCCESS = 'LOAD_CENTERS_SUCCESS'
export const LOAD_CENTERS_FAIL = 'LOAD_CENTERS_FAIL'

export const CREATE_CENTER = 'CREATE_CENTER'
export const CREATE_CENTER_SUCCESS = 'CREATE_CENTER_SUCCESS'
export const CREATE_CENTER_FAIL = 'CREATE_CENTER_FAIL'

export const UPDATE_CENTER = 'UPDATE_CENTER'
export const UPDATE_CENTER_SUCCESS = 'UPDATE_CENTER_SUCCESS'
export const UPDATE_CENTER_FAIL = 'UPDATE_CENTER_FAIL'

export const DELETE_CENTER = 'DELETE_CENTER'
export const DELETE_CENTER_SUCCESS = 'DELETE_CENTER_SUCCESS'
export const DELETE_CENTER_FAIL = 'DELETE_CENTER_FAIL'

//submissions

export const LOAD_SUBMISSIONS = 'LOAD_SUBMISSIONS'
export const LOAD_SUBMISSIONS_SUCCESS = 'LOAD_SUBMISSIONS_SUCCESS'
export const LOAD_SUBMISSIONS_FAIL = 'LOAD_SUBMISSIONS_FAIL'

export const CREATE_SUBMISSION = 'CREATE_SUBMISSION'
export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS'
export const CREATE_SUBMISSION_FAIL = 'CREATE_SUBMISSION_FAIL'

export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION'
export const UPDATE_SUBMISSION_SUCCESS = 'UPDATE_SUBMISSION_SUCCESS'
export const UPDATE_SUBMISSION_FAIL = 'UPDATE_SUBMISSION_FAIL'

export const DELETE_SUBMISSION = 'DELETE_SUBMISSION'
export const DELETE_SUBMISSION_SUCCESS = 'DELETE_SUBMISSION_SUCCESS'
export const DELETE_SUBMISSION_FAIL = 'DELETE_SUBMISSION_FAIL'

//announcements

export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT'
export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS'
export const CREATE_ANNOUNCEMENT_FAIL = 'CREATE_ANNOUNCEMENT_FAIL'

export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT'
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS'
export const UPDATE_ANNOUNCEMENT_FAIL = 'UPDATE_ANNOUNCEMENT_FAIL'

export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS'
export const DELETE_ANNOUNCEMENT_FAIL = 'DELETE_ANNOUNCEMENT_FAIL'

export const LOAD_ANNOUNCEMENTS = 'LOAD_ANNOUNCEMENTS'
export const LOAD_ANNOUNCEMENTS_SUCCESS = 'LOAD_ANNOUNCEMENTS_SUCCESS'
export const LOAD_ANNOUNCEMENTS_FAIL = 'LOAD_ANNOUNCEMENTS_FAIL'

// tickets

export const LOAD_TICKETS = 'LOAD_TICKETS'
export const LOAD_TICKETS_SUCCESS = 'LOAD_TICKETS_SUCCESS'
export const LOAD_TICKETS_FAIL = 'LOAD_TICKETS_FAIL'

export const CREATE_TICKET = 'CREATE_TICKET'
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS'
export const CREATE_TICKET_FAIL = 'CREATE_TICKET_FAIL'

export const UPDATE_TICKET = 'UPDATE_TICKET'
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS'
export const UPDATE_TICKET_FAIL = 'UPDATE_TICKET_FAIL'

export const DELETE_TICKET = 'DELETE_TICKET'
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS'
export const DELETE_TICKET_FAIL = 'DELETE_TICKET_FAIL'

