import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import Tabs, {TabItem} from "../../ui/Tabs";
import VideoStatsChartPanel from "../VideoStatsChartPanel";

const renderPrograms = (arr, onItemSelect = x => {
}) => {
    return (
        <ProgramsItems>
            {arr.map((a, i) => {
                return (
                    <ProgramItem key={a.id}>
                        <ProgramName>
                            {a.name}
                        </ProgramName>
                        <ProgramContent>
                            <ThemesList>
                                {a.themes.map((q, j) => {
                                    return (
                                        <ThemeItem key={q.id}>
                                            <ThemeName>
                                                {q.name}
                                            </ThemeName>
                                            <SimpleItemsList>
                                                {q.items.map((w, p) => {
                                                    let viewsNumber = (w.stats == undefined || w.stats.viewsNumber == undefined) ? 0 : w.stats.viewsNumber;
                                                    return (
                                                        <SimpleItem key={w.id} onClick={() => {
                                                            console.log('selecting w = ', w);
                                                            onItemSelect(w);
                                                        }}>
                                                            <span>
                                                                {`${w.name}: `}
                                                            </span>
                                                            {`${viewsNumber}`}
                                                        </SimpleItem>
                                                    )
                                                })}
                                            </SimpleItemsList>
                                        </ThemeItem>
                                    )
                                })}
                            </ThemesList>
                        </ProgramContent>
                    </ProgramItem>
                )
            })}
        </ProgramsItems>
    )
}

export default function ProgramThemesItemsStatsPanel(props) {
    const [programs, setPrograms] = useState([]);
    const [selectedItem, setSelectedItem] = useState(undefined);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/programs/with_stats`).then(d => d.data).then(pld => {
            setPrograms(pld.result);
        });
    }, []);


    return (
        <Wrapper>

            <Tabs
                tabs={[
                    {
                        label: '7 класс',
                        content: (
                            <TabItem>
                                {renderPrograms(programs.filter(a => (+a.classNumber == 7)), x => {
                                    setSelectedItem(x);
                                })}
                            </TabItem>
                        )
                    },
                    {
                        label: '8 класс',
                        content: (
                            <TabItem>
                                {renderPrograms(programs.filter(a => (+a.classNumber == 8)), x => {
                                    setSelectedItem(x);
                                })}
                            </TabItem>
                        )
                    },
                    {
                        label: '9 класс',
                        content: (
                            <TabItem>
                                {renderPrograms(programs.filter(a => (+a.classNumber == 9)), x => {
                                    setSelectedItem(x);
                                })}
                            </TabItem>
                        )
                    },
                    {
                        label: '10 класс',
                        content: (
                            <TabItem>
                                {renderPrograms(programs.filter(a => (+a.classNumber == 10)), x => {
                                    setSelectedItem(x);
                                })}
                            </TabItem>
                        )
                    },

                ]}
            />

            <Sidebar width={720} visible={(selectedItem != undefined)} onCloserClick={() => {
                setSelectedItem(undefined);
            }}>

                {selectedItem == undefined ? null :
                    <div>
                        <VideoStatsChartPanel id={selectedItem.id}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ProgramsItems = styled.div`
    
`;


const ProgramItem = styled.div`
    margin-bottom: 30px;
    padding: 10px;
    
`;

const ProgramName = styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
`;

const ProgramContent = styled.div`
    padding-left: 20px;
`;

const ThemeName = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
`;

const ThemesList = styled.div`
    
`;

const ThemeItem = styled.div`
    margin-bottom: 10px;
`;

const SimpleItemsList = styled.div`
    padding-left: 30px;
`;

const SimpleItem = styled.div`
    margin-bottom: 5px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
