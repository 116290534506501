import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../constants/config";
import SimpleChart from "./tools/SimpleChart";

export default function VideoStatsChartPanel(props) {
    let {id} = props;
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${API_ENDPOINT}/video/${id}/stats_summary`).then(d => d.data).then(pld => {
            let d = (pld == undefined || pld.result == undefined) ? undefined : pld.result;
            setData(d);
            setLoading(false);
        })
    }, []);

    if (data == undefined) {
        return null;
    }

    let {viewsNumber = 0, statsArr = []} = data;

    let oldPoints = statsArr.map((a, i) => ({x: i * 5, k: a}));

    let accArr = statsArr.map(a => 0);
    for (let i = statsArr.length - 1; i >= 0; i--) {
        if (i < accArr.length - 1) {
            accArr[i] = accArr[i + 1] + statsArr[i];
        } else {
            accArr[i] = statsArr[i];
        }
    }

    let points = accArr.map((a, i) => ({x: i * 5, k: a}));
    console.log('');

    return (
        <Wrapper>
            <TopPlaceholder>
                {`Количество просмотров: ${viewsNumber}`}
            </TopPlaceholder>

            <p>
                Гистограмма
            </p>

            <BottomPlaceholder>
                <SimpleChart points={oldPoints}/>
            </BottomPlaceholder>

            <p>
                Нарастающим итогом
            </p>

            <BottomPlaceholder>
                <SimpleChart points={points}/>
            </BottomPlaceholder>

            <p>
                По горизонтально оси - минуты просмотра, по вертикальной - количество просмотров
            </p>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    
`;

const ChartPlaceholder = styled.div`
    
`;
