/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    themesMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const ThemesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_THEME:
        case types.UPDATE_THEME:
        case types.DELETE_THEME:
        case types.LOAD_THEMES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_THEME_FAIL:
        case types.UPDATE_THEME_FAIL:
        case types.DELETE_THEME_FAIL:
        case types.LOAD_THEMES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_THEME_SUCCESS:
        case types.UPDATE_THEME_SUCCESS:
            return {
                ...state,
                loading: false,
                themesMap: state.themesMap.set(action.theme.id, action.theme)
            }


        case types.DELETE_THEME_SUCCESS:
            return {
                ...state,
                loading: false,
                themesMap: state.themesMap.delete(action.id),
            }


        case types.LOAD_THEMES_SUCCESS:
            return {
                ...state,
                loading: false,
                themesMap: state.themesMap
                    .merge(action.themes.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ThemesReducer;
