import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {Textarea} from "../../ui/Textarea";
import TexViewer from "./TexViewer";

import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'

export default function SimpleTextEditor(props) {
    let [tex, setTex] = useState(props.tex == undefined ? '' : props.tex);
    let {
        onSave = newTex => {

        },
        loading = false
    } = props;


    return (
        <Wrapper>

            <Field>
                <Label>
                    LaTex
                </Label>
                <Textarea value={tex} placeholder={''} onChange={evt => {
                    setTex(evt.target.value);
                }}/>
            </Field>

            {(tex == undefined || tex == '') ? null :
                <PreviewPlaceholder>
                    <Label>
                        Превью
                    </Label>
                    <div>
                        <TexViewer tex={tex}/>
                    </div>
                </PreviewPlaceholder>
            }

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave(tex);
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    
`;


const Label = styled.div`
    
`;

const PreviewPlaceholder = styled.div`
    
`;
