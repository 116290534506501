import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {YELLOW} from "../../ui/Colors";

import {Button} from "../../ui/Button";
import NewsListView from "../tools/NewsListView";
import * as actions from "../../../redux/actions/NewsItemsActions";
import NewsItemForm from "../forms/NewsItemForm";

import Tabs, {TabItem} from '../../ui/Tabs'
import ViewNewsItemPanel from "./ViewNewsItemPanel";

export default function AllNewsItemsPanel(props) {
    const {canCreate = true} = props;
    const [addVisible, setAddVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    const {loading, newsItems, currentUser, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin'));
        return {
            loading: state.news.loading,
            newsItems: state.news.newsItemsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllNewsItems());
    }, []);

    let selectedItem = (selectedId == undefined) ? undefined : newsItems.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            {canCreate == false ? null :
                <TopPlaceholder>
                    <Button onClick={() => {
                        setAddVisible(true);
                    }}>
                        Добавить новость
                    </Button>
                </TopPlaceholder>
            }

            <NewsListPlaceholder>
                <NewsListView items={newsItems} canEditItem={canCreate} onEditClick={selId => setSelectedId(selId)}/>
            </NewsListPlaceholder>

            <Sidebar width={Math.min(1200, window.innerWidth)} visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>
                {addVisible == false ? null :
                    <div>
                        <NewsItemForm onSave={async d => {
                            await dispatch(actions.createNewsItem(d));
                            setAddVisible(false);
                        }}/>
                    </div>
                }
            </Sidebar>

            <Sidebar width={Math.min(1200, window.innerWidth)} visible={(selectedItem != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedItem == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Редактирование',
                                content: (
                                    <TabItem>
                                        <NewsItemForm {...selectedItem} canDelete={true} onDelete={async () => {
                                            if (window.confirm('Вы уверены?') == false) {
                                                return;
                                            }
                                            await dispatch(actions.deleteNewsItem(selectedItem.id));
                                            setSelectedId(undefined);
                                        }} onSave={async d => {
                                            await dispatch(actions.updateNewsItem({...d, id: selectedItem.id}));
                                            setSelectedId(undefined);
                                        }}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Просмотр',
                                content: (
                                    <TabItem>
                                        <ViewNewsItemPanel id={selectedItem.id}/>
                                    </TabItem>
                                )
                            }
                        ]}/>

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}


const Wrapper = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const NewsListPlaceholder = styled.div`
    
`;

const NewsItem = styled.div`
    width: 30%;
`;

const NewsList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;

const BottomMorePlaceholder = styled.div`
    text-align: right;
    color: ${YELLOW};
    font-size: 24px;
    margin-top: 30px;
`;

const AvatarPlaceholder = styled.div`
    height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => (props.src)});
`;

const InfoPlaceholder = styled.div`
    
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
`;

const Date = styled.div`
    opacity: 0.5;
`;

const TextPlaceholder = styled.div`
    margin-top: 20px;
`;

const MoreSpan = styled.span`
    cursor: pointer;
    :hover{
      opacity: 0.7;
      text-decoration: underline;
    }
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;
