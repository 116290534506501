import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import YouTube from 'react-youtube';

export default function YoutubePlayer(props) {
    const opts = {
        height: '390',
        width: '640',
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            showinfo: 0
        }
    };

    return (
        <Wrapper>

            <YouTube
                videoId="M_4wONoIyW4"
                opts={opts}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
