import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default function SimpleChart(props) {
    let {points = []} = props;

    return (
        <Wrapper>

            <ChartPlaceholder>
                <BarChart
                    width={600}
                    height={300}
                    data={points}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="x"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="k" fill="#8884d8"/>
                </BarChart>
            </ChartPlaceholder>



        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-bottom: 30px;
`;

const ChartPlaceholder = styled.div`
    padding: 10px;
    background-color: white;
`;
