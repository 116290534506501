import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {YELLOW} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";

export default function NewsListView(props) {
    let {
        items = [], canEditItem = false, onEditClick = itemId => {

        }
    } = props;

    return (
        <Wrapper>

            <NewsList>
                {items.map((item, k) => {
                    let {avatar, description, name, dateString} = item;
                    return (
                        <NewsItem key={`${k}_${item.id}`}>
                            <AvatarPlaceholder src={CommonHelper.getSafeUrl(avatar)}>

                            </AvatarPlaceholder>
                            <InfoPlaceholder>
                                <Name>
                                    {name}
                                </Name>
                                <Date>
                                    {dateString}
                                </Date>
                                <TextPlaceholder>
                                    {description}
                                </TextPlaceholder>

                                {canEditItem == false ? null :
                                    <ItemControlsPlaceholder>
                                        <EditSpan onClick={() => {
                                            onEditClick(item.id)
                                        }}>
                                            редактировать
                                        </EditSpan>
                                    </ItemControlsPlaceholder>
                                }

                            </InfoPlaceholder>
                        </NewsItem>
                    );
                })}
            </NewsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const NewsItem = styled.div`
    width: 30%;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    background: white;
    border-radius: 3px;
`;

const NewsList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const BottomMorePlaceholder = styled.div`
    text-align: right;
    color: ${YELLOW};
    font-size: 24px;
    margin-top: 30px;
`;

const AvatarPlaceholder = styled.div`
    height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => (props.src)});
`;

const InfoPlaceholder = styled.div`
    
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
`;

const Date = styled.div`
    opacity: 0.5;
`;

const TextPlaceholder = styled.div`
    margin-top: 20px;
`;

const MoreSpan = styled.span`
    cursor: pointer;
    :hover{
      opacity: 0.7;
      text-decoration: underline;
    }
`;

const ItemControlsPlaceholder = styled.div`
    
`;

const EditSpan = styled.span`
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 0.9;
    }
`;
