import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Map, Set} from 'immutable'

import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

export default function BulkUsersCreationTool(props) {
    const [text, setText] = useState('');
    const [users, setUsers] = useState([]);
    const {
        readyEmailsSet = Set(),
        onAdd = user => {
            window.alert();
        }
    } = props;

    return (
        <Wrapper>
            <Field>
                <Label>
                    Данные из экселя
                </Label>
                <Textarea value={text} onChange={evt => {
                    setText(evt.target.value)
                }}/>
            </Field>

            <Field>
                <Button onClick={() => {
                    let items = text.split(`\n`);
                    let arr = items.map(it => {
                        let opts = it.split('\t');
                        let ph = opts[5].toLowerCase();
                        if (ph[0] == '8') {
                            ph = `+7${ph.slice(1)}`
                        }
                        return {
                            lastName: opts[0],
                            firstName: opts[1],
                            middleName: opts[2],
                            classNumber: +opts[3].trim(),
                            email: opts[4].toLowerCase().trim(),
                            phone: ph,
                        }
                    });
                    setUsers(arr);
                }}>
                    Спарсить!
                </Button>
            </Field>

            <ListPlaceholder>
                {users.map((u, k) => {
                    let {firstName, lastName, middleName, phone, classNumber, email} = u;
                    let isReady = readyEmailsSet.has(email);
                    return (
                        <Item key={k} onClick={() => {

                        }}>
                            <Left>
                                <div>
                                    {`${lastName} ${firstName} ${middleName}`}
                                </div>
                                <div>
                                    {email}
                                </div>
                                <div>
                                    {phone}
                                </div>
                                <div>
                                    {`${classNumber} класс`}
                                </div>
                            </Left>

                            <Right>
                                {isReady == true ? 'готово' :
                                    <Button onClick={() => {
                                        onAdd(u);
                                    }}>
                                        Добавить
                                    </Button>
                                }
                            </Right>

                        </Item>
                    )
                })}
            </ListPlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    
`;

const Label = styled.div`
    font-weight: bold;
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    margin-bottom: 10px;
    padding: 7px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Left = styled.div`
    
`;

const Right = styled.div`
    
`;
