import SubmissionsAPI from '../../api/SubmissionsAPI'

import * as types from '../ActionTypes'

let loadSubmissions_ = () => {
    return {
        type: types.LOAD_SUBMISSIONS
    }
}
let loadSubmissionsSuccess = (submissions) => {
    return {
        type: types.LOAD_SUBMISSIONS_SUCCESS,
        submissions: submissions
    }
}
let loadSubmissionsFailed = (error) => {
    return {
        type: types.LOAD_SUBMISSIONS_FAIL,
        error: error
    }
}

//thunk
export function loadAllSubmissions() {
    return (dispatch, getState) => {
        dispatch(loadSubmissions_());
        return SubmissionsAPI.getAllSubmissions().then(
            submissions => dispatch(loadSubmissionsSuccess(submissions)),
            error => dispatch(loadSubmissionsFailed(error))
        )
    }
}

export function loadSubmissionById(id) {
    return (dispatch, getState) => {
        dispatch(loadSubmissions_());
        return SubmissionsAPI.getSubmission(id).then(
            submission => dispatch(loadSubmissionsSuccess([submission])),
            error => dispatch(loadSubmissionsFailed(error))
        )
    }
}


export function getLazySubmission(studentId, taskId) {
    return (dispatch, getState) => {
        dispatch(loadSubmissions_());
        return SubmissionsAPI.getLazySubmission(studentId, taskId).then(
            submission => dispatch(loadSubmissionsSuccess([submission])),
            error => dispatch(loadSubmissionsFailed(error))
        )
    }
}


let createSubmission_ = () => {
    return {
        type: types.CREATE_SUBMISSION
    }
}
let createSubmissionSuccess = (submission) => {
    return {
        type: types.CREATE_SUBMISSION_SUCCESS,
        submission: submission
    }
}
let createSubmissionFailed = (error) => {
    return {
        type: types.CREATE_SUBMISSION_FAIL,
        error: error
    }
}

//thunk
export function createSubmission(data) {
    return (dispatch, getState) => {
        dispatch(createSubmission_());
        return SubmissionsAPI.createSubmission(data).then(
            submission => dispatch(createSubmissionSuccess(submission)),
            error => dispatch(createSubmissionFailed(error))
        )
    }
}

let updateSubmission_ = () => {
    return {
        type: types.UPDATE_SUBMISSION
    }
}
let updateSubmissionSuccess = (submission) => {
    return {
        type: types.UPDATE_SUBMISSION_SUCCESS,
        submission: submission
    }
}
let updateSubmissionFailed = (error) => {
    return {
        type: types.UPDATE_SUBMISSION_FAIL,
        error: error
    }
}

//thunk
export function updateSubmission(data) {
    return (dispatch, getState) => {
        dispatch(updateSubmission_());
        return SubmissionsAPI.updateSubmission(data).then(
            submission => dispatch(updateSubmissionSuccess(submission)),
            error => dispatch(updateSubmissionFailed(error))
        )
    }
}

let deleteSubmission_ = () => {
    return {
        type: types.DELETE_SUBMISSION
    }
}
let deleteSubmissionSuccess = (id) => {
    return {
        type: types.DELETE_SUBMISSION_SUCCESS,
        id: id
    }
}
let deleteSubmissionFailed = (error) => {
    return {
        type: types.DELETE_SUBMISSION_FAIL,
        error: error
    }
}

//thunk
export function deleteSubmission(id) {
    return (dispatch, getState) => {
        dispatch(deleteSubmission_());
        return SubmissionsAPI.deleteSubmission(id).then(
            () => dispatch(deleteSubmissionSuccess(id)),
            error => dispatch(deleteSubmissionFailed(error))
        )
    }
}
