import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const CentersAPI = {

    getAllCenters() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/centers/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createCenter(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/centers/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateCenter(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/centers/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteCenter(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/centers/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default CentersAPI;
