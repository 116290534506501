import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const AnnouncementsAPI = {

    getAllAnnouncements() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/announcements`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getAnnouncement(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/announcements/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getAnnouncementUsers(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/announcements/${id}/viewed_users`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result == undefined ? [] : pld.result);
            }).catch(err => reject(err));
        });
    },

    createAnnouncement(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/announcements`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateAnnouncement(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/announcements`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    viewAnnouncement(userId, id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/user/${userId}/announcements/${id}/view`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteAnnouncement(id) {
        console.log('deleteAnnouncement occured! id = ', id);
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/announcements`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default AnnouncementsAPI;
