import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ChessTemplate from '../templates/ChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import DistengTemplate from "../templates/DistengTemplate";

import * as actions from '../../redux/actions/CoursesActions'
import CommonHelper from "../../helpers/CommonHelper";
import UpdateUserForm from "../users/forms/UpdateUserForm";

export default function StudentCoursesApp(props) {
    const dispatch = useDispatch();
    const {currentUserId, loading} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId),
    }), []));

    useEffect(() => {
        dispatch(actions.loadAllCourses());
    }, []);

    return (
        <DistengTemplate active={'courses'}>

            <Container>
                Раздел находится в разработке
            </Container>

        </DistengTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const Container = styled.div`
    background: white;
    padding: 10px;
    border-radius: 4px
`;

const CoursesList = styled.div`
    
`;

const CourseItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid whitesmoke;
    margin-bottom: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const CourseName = styled.div`
    font-weight: bold;
`;

const CourseDescription = styled.div`
    
`;
