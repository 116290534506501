import {Map} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    centersMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const CentersReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_CENTER:
        case types.UPDATE_CENTER:
        case types.DELETE_CENTER:
        case types.LOAD_CENTERS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_CENTER_FAIL:
        case types.UPDATE_CENTER_FAIL:
        case types.DELETE_CENTER_FAIL:
        case types.LOAD_CENTERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_CENTER_SUCCESS:
        case types.UPDATE_CENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                centersMap: state.centersMap.set(action.center.id, action.center)
            }


        case types.DELETE_CENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                centersMap: state.centersMap.delete(action.id),
            }


        case types.LOAD_CENTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                centersMap: state.centersMap
                    .merge(action.centers.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default CentersReducer;
