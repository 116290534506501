import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Container} from "../../ui/Container";

import {useMappedState, useDispatch} from "redux-react-hook";
import BottomUserPanel from "../../templates/BottomUserPanel";

import * as usersActions from '../../../redux/actions/UsersActions'

import Sidebar from 'arui-feather/sidebar'
import UpdateUserForm from "../../users/forms/UpdateUserForm";
import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";
import Tabs from "../../ui/Tabs";
import FranchiseeSchoolsPanel from "../../schools/panels/FranchiseeSchoolsPanel";
import FranchiseeTeachersPanel from "../../users/panels/FranchiseeTeachersPanel";
import {Map, Set} from 'immutable'
import FranchiseeStatsView from "../tools/FranchiseeStatsView";
import FranchiseeSubscriptionsPanel from "../../subscriptions/panels/FranchiseeSubscriptionsPanel";
import ChangeUserEmailForm from "../../users/forms/ChangeUserEmailForm";
import ChangeUserEmailPanel from "../../users/panels/ChangeUserEmailPanel";
import PointSelector from "../../tools/PointSelector";
import CenterProgressTool from "../../education/tools/CenterProgressTool";


export default function FranchiseePanel(props) {
    let {id} = props;
    const [modalVisible, setModalVisible] = useState(false);
    const {franchisee, loading, groups, schools, lessons, teachers, students, rooms, isAdmin, isMe} = useMappedState(useCallback(state => {
        let schools = state.schools.schoolsMap.toArray().filter(sc => (sc.franchiseeId == id));
        let schoolsSet = schools.reduce((stt, a) => stt.add(a.id), Set());
        let lessons = state.lessons.lessonsMap.toArray().filter(less => (schoolsSet.has(less.schoolId)));
        let groups = state.groups.groupsMap.toArray().filter(gr => (schoolsSet.has(gr.schoolId)));
        let rooms = state.rooms.roomsMap.toArray().filter(rm => (schoolsSet.has(rm.schoolId)));
        let {currentUserId, usersMap} = state.users;
        let currentUser = usersMap.get(currentUserId);
        let isAdmin = (currentUser != undefined && currentUser.userRole == 'admin');
        return {
            franchisee: state.users.usersMap.get(id),
            schools: schools,
            lessons: lessons,
            groups: groups,
            rooms: rooms,
            currentUser: currentUser,
            isAdmin: isAdmin,
            isMe: (currentUserId == id),
            students: state.users.usersMap.toArray().filter(u => ((u.userRole == 'student') && (u.franchiseeId == id))),
            teachers: state.users.usersMap.toArray().filter(u => ((u.userRole == 'teacher') && (u.franchiseeId == id))),
            loading: (state.users.loading || state.lessons.loading || state.rooms.loading || state.groups.loading)

        }
    }, [id]));
    const dispatch = useDispatch();
    if (id == undefined || franchisee == undefined) {
        return null;
    }
    // if (isMe == true) {
    //     return (
    //         <Wrapper>
    //             <FranchiseeStatsView
    //                 groups={groups}
    //                 students={students}
    //                 schools={schools}
    //                 lessons={lessons}
    //                 teachers={teachers}
    //                 rooms={rooms}
    //             />
    //         </Wrapper>
    //     )
    // }

    return (
        <Wrapper>

            <TopPlaceholder>
                <UserPlaceholder>
                    <BottomUserPanel user={franchisee} onUpdate={updD => {
                        dispatch(usersActions.updateUser({
                            ...updD,
                            id: id
                        }))
                    }}/>
                    {isAdmin == false ? null :
                        <EditButtonPlaceholder>
                            <Button style={{backgroundColor: DARK_SIDE, fontSize: 12}} onClick={() => {
                                setModalVisible(true);
                            }}>
                                Редактировать
                            </Button>
                        </EditButtonPlaceholder>
                    }
                </UserPlaceholder>
            </TopPlaceholder>

            <ContentPlaceholder>
                <Tabs tabs={[
                    {
                        label: 'Школы',
                        content: (
                            <TabItem>
                                <FranchiseeSchoolsPanel id={id}/>
                            </TabItem>
                        )
                    },

                    {
                        label: 'Статистика',
                        content: (
                            <TabItem>
                                <FranchiseeStatsView
                                    groups={groups}
                                    students={students}
                                    schools={schools}
                                    lessons={lessons}
                                    teachers={teachers}
                                    rooms={rooms}
                                />
                            </TabItem>
                        )
                    },
                ]}/>
            </ContentPlaceholder>

            <Sidebar visible={modalVisible} width={Math.min(920, window.innerWidth)} onCloserClick={() => {
                setModalVisible(false);
            }}>

                {modalVisible == false ? null :
                    <div>
                        <Heading>
                            Редактирование представителя
                        </Heading>
                        <Tabs tabs={[
                            {
                                label: 'Общая информация',
                                content: (
                                    <TabItem>
                                        <UpdateUserForm emailVisible={false}
                                                        nameVisible={true}
                                                        passwordVisible={false}
                                                        {...franchisee}
                                                        loading={loading} onSave={data => {
                                            dispatch(usersActions.updateUser({
                                                ...data,
                                                id: id
                                            })).then(pld => {
                                                setModalVisible(false);
                                            });
                                        }}
                                                        shouldShowBirthdayCalendar={false}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Смена почты',
                                content: (
                                    <TabItem>
                                        <ChangeUserEmailPanel userId={franchisee.id} onSuccess={() => {
                                            setModalVisible(false);
                                        }}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Точка на карте',
                                content: (
                                    <TabItem>
                                        <PointSelector {...franchisee.mapCoords}
                                                       radius={100.0 * 12 / 920}
                                                       onChange={async pt => {
                                            await dispatch(usersActions.updateUser({
                                                mapCoords: pt,
                                                id: id
                                            }));
                                        }}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Успеваемость',
                                content: (
                                    <TabItem>
                                        <CenterProgressTool centerId={id} />
                                    </TabItem>
                                )
                            },
                        ]}/>

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
    
`;

const UserPlaceholder = styled.div`
    padding: 10px;
    background: white;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
`;

const EditButtonPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5px;
`;

const Wrapper = styled.div`
    
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
`;

const ContentPlaceholder = styled.div`
    padding-top: 10px;
    margin-top: 20px;
`;

const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;
