import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TasksAPI = {

    getAllTasks() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/tasks/all-optimized`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createTask(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tasks/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateTask(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tasks/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteTask(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tasks/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default TasksAPI;
