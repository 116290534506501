import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/DocumentsActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import * as centersActions from "../../../redux/actions/CentersActions";

function save(filename, data) {
    const blob = new Blob([data], {type: 'text/csv'});
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}

export default function ExportPanel(props) {
    const {loading, users, centers, currentUser, isAdmin, getCenter} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin'));
        return {
            users: state.users.usersMap.toArray().filter(x => (['teacher', 'student'].indexOf(x.userRole) > -1)),
            centers: state.centers.centersMap.toArray(),
            loading: state.courses.loading || state.users.loading,
            documents: state.documents.documentsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin,
            getCenter: x => state.centers.centersMap.get(x)
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(usersActions.loadAllUsers());
        dispatch(centersActions.loadAllCenters());
    }, []);

    console.log('render: users = ', users);

    let csv = `Центр;Класс;ФИО;email;пароль;Роль\n`;
    csv = csv + users.map((u, i) => {
        let centerS = getCenter(u.centerId) == undefined ? '' : getCenter(u.centerId).name;
        let classS = (u.classNumber == undefined) ? '' : u.classNumber;
        let fio = `${u.lastName} ${u.firstName} ${u.middleName}`;
        return `${centerS};${classS};${fio};${u.email};${u.userPassword};${u.userRole}`;
    }).join('\n');

    console.log('render: csv = ', csv);

    return (
        <Wrapper>

            <p>
                Выгрузка контактов пользователей
            </p>

            <p>
                {loading == true ? 'loading ... ' :
                    <ExportSpan onClick={() => {
                        // let csvContent = `data:text/csv;charset=utf-8,${csv}`;
                        // let encodedUri = encodeURI(csvContent);
                        // let link = document.createElement("a");
                        // link.setAttribute("href", encodedUri);
                        // link.setAttribute("download", `${moment().format('DD-MM-YYYY')}.csv`);
                        // document.body.appendChild(link); // Required for FF
                        // link.click();
                        save('contacts.csv', csv);
                    }}>
                        выгрузить контакты в csv
                    </ExportSpan>
                }
            </p>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const ExportSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
