import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import {Container, Heading} from "../ui/Container";
import CommonHelper from "../../helpers/CommonHelper";

import CoursePanel from '../courses/panels/CoursePanel'

export default function CourseApp(props) {
    let {id} = props.match.params;
    const {course} = useMappedState(useCallback(state => ({
        course: state.courses.coursesMap.get(id)
    }), [id]));

    return (
        <ChessTemplate active={'courses'}>

            <Heading style={{marginBottom: 20}}>
                <TopLink onClick={() => {
                    CommonHelper.linkTo(`/courses`);
                }}>
                    ⬅ Все курсы
                </TopLink>

                {course == undefined ? null :
                    <span>
                        {` / ${course.name}`}
                </span>
                }

            </Heading>

            <Container>
                <CoursePanel id={id}/>
            </Container>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const TopLink = styled.div`
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
