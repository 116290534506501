import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Button} from '@blueprintjs/core'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import * as actions from "../../../redux/actions/CoursesActions";
import CommonHelper from "../../../helpers/CommonHelper";

export default function ViewCoursePanel(props) {
    let {id} = props;

    const {course, loading, isAdmin, units} = useMappedState(useCallback(state => ({
        course: state.courses.coursesMap.get(id),
        loading: state.courses.loading,
        isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state)),
        units: state.courses.unitsMap.toArray().filter(u => (u.courseId == id)).sort((a, b) => (+a.timestamp - +b.timestamp)),
    }), [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadCourseById(id));
        dispatch(actions.loadCourseUnits(id));
    }, [id]);

    if (course == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    if (course == undefined) {
        return null;
    }

    let {name, description} = course;

    return (
        <Wrapper>

            <Name>
                {name}
            </Name>
            <Description>
                {description}
            </Description>

            <UnitsPlaceholder>
                <UnitsList>
                    {units.map((unit, k) => {
                        return (
                            <UnitItem key={unit.id} onClick={() => {
                                CommonHelper.linkTo(`/course/${course.id}/unit/${unit.id}`);
                            }} >
                                <UnitName>
                                    {unit.name}
                                </UnitName>
                                <UnitDescription>
                                    {unit.description}
                                </UnitDescription>
                            </UnitItem>
                        )
                    })}
                </UnitsList>
            </UnitsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 10px;
`;

const Description = styled.div`
    font-size: 14px;
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;
`;

const UnitsPlaceholder = styled.div`
    
`;

const UnitsList = styled.div`
    
`;

const UnitItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid whitesmoke;
    margin-bottom: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const UnitName = styled.div`
    font-weight: bold;
`;

const UnitDescription = styled.div`
    
`;
