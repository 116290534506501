import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import * as actions from "../../../redux/actions/SchoolsActions";
import * as subActions from "../../../redux/actions/SubscriptionsActions";
import CommonHelper from "../../../helpers/CommonHelper";

import Sidebar from 'arui-feather/sidebar'
import {Button} from "../../ui/Button";
import UpdateSubscriptionForm from "../forms/UpdateSubscriptionForm";
import FranchiseePaymentInstructionsPanel from "../../franchisee/panels/FranchiseePaymentInstructionsPanel";


export default function FranchiseeSubscriptionsPanel(props) {
    const {id} = props;
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(false);
    const dispatch = useDispatch();
    let {currentUser, loading, schools, subscriptions} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.schools.loading,
        schools: state.schools.schoolsMap.toArray().filter(u => (u.franchiseeId == id)),
        subscriptions: state.subscriptions.subscriptionsMap.toArray().filter(u => (u.franchiseeId == id))
    }), [id]));
    useEffect(() => {
        dispatch(actions.loadAllSchools());
        dispatch(subActions.loadAllSubscriptions());
    }, [id]);

    const getSchool = useCallback((scId) => {
        let fSchools = schools.filter(a => (a.id == scId));
        if (fSchools.length > 0) {
            return fSchools[0];
        }
        return undefined;
    }, []);

    if (currentUser == undefined) {
        return null;
    }

    const selectedSubscription = (selectedId == undefined) ? null : subscriptions.filter(sc => (sc.id == selectedId))[0];

    console.log('render: subscriptions = ', subscriptions);

    return (
        <Wrapper>

            <ContentPlaceholder>

                <PaymentInfoPlaceholder>
                    <FranchiseePaymentInstructionsPanel id={id}/>
                </PaymentInfoPlaceholder>

                <TopPlaceholder>
                    <Button style={{marginLeft: 0}} onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        Добавить абонемент
                    </Button>
                </TopPlaceholder>

                <SchoolsList>

                    {subscriptions.map((sc, k) => {
                        let isSelected = (sc.id == selectedId);
                        let {type, amount, duration} = sc;
                        const schls = sc.schoolsIds.map(aa => getSchool(aa)).filter(a => a != undefined);
                        return (
                            <SubscriptionItem selected={isSelected} key={sc.id} onClick={() => {
                                setSelectedId(sc.id);
                            }}>
                                <NamePlaceholder>
                                    {sc.name}
                                </NamePlaceholder>
                                {sc.description == undefined || sc.description.trim() == '' ? null :
                                    <DescriptionPlaceholder>
                                        <div>
                                            {`Описание: ${sc.description}`}
                                        </div>
                                    </DescriptionPlaceholder>
                                }
                                <PricePlaceholder>
                                    {`Стоимость: ${sc.price} руб.`}
                                </PricePlaceholder>
                                {schls.length == 0 ? null :
                                    <SchoolsPlaceholder>
                                        Школы: {`${schls.map(a => a.name).join(', ')}`}
                                    </SchoolsPlaceholder>
                                }
                                {type != 'amount' ? null :
                                    <div>
                                        По количеству занятий - {`${amount} занятий`}
                                    </div>
                                }
                                {type != 'duration' ? null :
                                    <div>
                                        По сроку действия - {`${duration} дней`}
                                    </div>
                                }
                            </SubscriptionItem>
                        )
                    })}

                </SchoolsList>


            </ContentPlaceholder>

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <Heading>
                            Добавление нового абонемента
                        </Heading>
                        <UpdateSubscriptionForm onSubmit={d => {
                            d.franchiseeId = id;
                            dispatch(subActions.createSubscription(d)).then(pld => {
                                setAddModalVisible(false)
                            })
                        }}
                                                schools={schools}
                        />
                    </div>
                }

            </Sidebar>

            <Sidebar visible={(selectedSubscription != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedSubscription == undefined ? null :
                    <div>
                        <Heading>
                            Редактирование абонемента
                        </Heading>
                        <UpdateSubscriptionForm {...selectedSubscription} canDelete={true} onSubmit={d => {
                            let data = {
                                ...d,
                                id: selectedSubscription.id
                            }
                            dispatch(subActions.updateSubscription(data)).then(pld => {
                                setSelectedId(undefined);
                            })
                        }}
                                                onDelete={() => {
                                                    dispatch(subActions.deleteSubscription(selectedSubscription.id)).then(pld => {
                                                        setSelectedId(undefined);
                                                    })
                                                }}

                                                schools={schools}
                        />
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    
`;

const ContentPlaceholder = styled.div`
    padding-top: 10px;
`;

const SchoolsList = styled.div`
    
`;

const SubscriptionItem = styled.div`
    padding: 10px;
    background: white;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid whitesmoke;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    :hover{
      opacity: 0.7;
    }
`;

const NamePlaceholder = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const DescriptionPlaceholder = styled.div`
    
`;

const PricePlaceholder = styled.div`
    
`;

const SchoolsPlaceholder = styled.div`
    
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
`;

const BottomPlaceholder = styled.div`
    
`;

const PaymentInfoPlaceholder = styled.div`
    margin-top: 10px;
    padding-top: 10px;
`;
