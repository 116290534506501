import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";
import UpdateTagForm from "./UpdateTagForm";

import {Button} from "../../ui/Button";

export default function TimeTagsEditor(props) {
    const {
        videoUrl = undefined,
        t = undefined,
        onSave = arr => {

        },
        loading = false
    } = props;

    const videoRef = useRef();

    const [tags, setTags] = useState(props.tags == undefined ? [] : props.tags);
    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);

    if (videoUrl == undefined) {
        return null;
    }

    const selectedItem = (selectedId == undefined) ? undefined : tags.filter(a => (a.id == selectedId))[0];

    let sortedTags = tags.sort((a, b) => {
        return (+a.t - b.t);
    })

    return (
        <Wrapper>
            <VidePlaceholder>
                <Video controls={true} ref={videoRef}>
                    <source src={videoUrl}/>
                </Video>
            </VidePlaceholder>

            <ItemsPlaceholder>
                {sortedTags.map((a, i) => {
                    return (
                        <Item key={`${a.id}_${i}`}>
                            <Left onClick={() => {
                                try {
                                    videoRef.current.currentTime = a.t;
                                    videoRef.current.play();
                                } catch (err) {

                                }
                            }}>
                                {CommonHelper.convertSecondstoTime(a.t)}
                            </Left>
                            <Right>
                                <Name onClick={() => {
                                    try {
                                        videoRef.current.currentTime = a.t;
                                        videoRef.current.pause();
                                    } catch (err) {

                                    }
                                    setSelectedId(a.id);
                                }}>
                                    {a.name}
                                </Name>
                                <Description>
                                    {a.description}
                                </Description>
                                <div>
                                    <DelSpan onClick={() => {
                                        if (window.alert('Вы уверены?') == false) {
                                            return;
                                        }
                                        let newTags = tags.filter(x => (a.id != x.id));
                                        setTags(newTags);
                                        onSave(newTags);
                                    }}>
                                        удалить
                                    </DelSpan>
                                </div>
                            </Right>
                        </Item>
                    )
                })}
            </ItemsPlaceholder>

            <BottomPlaceholder>
                <AddTagSpan onClick={() => {
                    setAddVisible(true);
                }}>
                    добавить метку
                </AddTagSpan>
            </BottomPlaceholder>

            <div>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave(tags);
                    }}>
                        Сохранить
                    </Button>
                }
            </div>

            <Sidebar visible={(selectedItem != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedItem == undefined ? null :
                    <div>
                        <UpdateTagForm {...selectedItem} onSave={d => {
                            let da = {
                                ...selectedItem,
                                t: videoRef.current.currentTime,
                                ...d
                            };
                            let newTags = tags.map(a => (a.id == selectedItem.id ? da : a))
                            setTags(newTags);
                        }}/>
                    </div>
                }

            </Sidebar>


            <Sidebar visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <UpdateTagForm loading={loading} onSave={d => {
                            let da = {
                                id: uuid(),
                                t: videoRef.current.currentTime,
                                ...d
                            };
                            let newTags = tags.concat([da]);
                            setTags(newTags);
                            onSave(newTags);
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const DelSpan = styled.div`
    color: red;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const AddTagSpan = styled.div`
    cursor: pointer;
    :hover{
      opacity: 0.6;
    }
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Wrapper = styled.div`
    
`;

const VidePlaceholder = styled.div`
    width: 100%;
    height: 420px;
    margin-bottom: 10px;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
`;

const ItemsPlaceholder = styled.div`
    
`;

const Item = styled.div`
    color: ${props => (props.selected == true ? 'white' : 'black')};
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 5px;
    
`;

const Left = styled.div`
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    cursor: pointer;
`;

const Right = styled.div`
    flex: 1;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    :hover{
      opacity: 0.6;
      text-decoration: underline;
    }
`;

const Description = styled.div`
    font-size: 14px;
`;

