import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ThemesAPI = {

    getAllThemes() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/themes/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createTheme(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/themes/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateTheme(data) {
        console.log('updateTheme: data = ', data);
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/themes/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteTheme(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/themes/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default ThemesAPI;
