import React, {Component} from 'react';
import './App.css';
import styled from 'styled-components'
import {createStore, applyMiddleware, compose} from 'redux';
import logger from 'redux-logger'
import {reducer} from './redux/reducers'
import {default as ReduxThunk} from 'redux-thunk';
import RouterApp from "./components/apps/RouterApp";

import moment from 'moment'

import ParseAPI from './api/ParseAPI'

import {StoreContext} from 'redux-react-hook';

import {registerLocale, setDefaultLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru'
import fi from 'date-fns/locale/fi'

// const store = createStore(
//     reducer,
//     undefined,
//     compose(
//         applyMiddleware(ReduxThunk), applyMiddleware(logger)
//     )
// )

const store = createStore(
    reducer,
    undefined,
    compose(
        applyMiddleware(ReduxThunk)
    )
)

ParseAPI.initParse();
registerLocale('ru', ru);
setDefaultLocale('ru');

moment.locale('ru');

class App extends Component {
    render() {

        return (
            <StoreContext.Provider value={store}>
                <RouterApp/>
            </StoreContext.Provider>
        );
    }
}

export default App;
