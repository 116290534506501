import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {Button} from "../../ui/Button";

import * as actions from '../../../redux/actions/TransactionsActions'


import Spin from 'arui-feather/spin'

const getStatusString = (status) => {
    return {
        PENDING: 'Ожидает подтверждения - не оплачен',
        ACTIVE: 'Активен - оплачен',
        RESERVED: 'Не активирован',
    }[status];
}

const getTypeString = (type) => {
    return {
        CASH: 'Олата наличными',
        CARD: 'Оплата по карте'
    }[type];
}

export default function StudentSubscriptionsPanel(props) {
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(false);
    const {currentUserId, currentUser, subscriptions, school, allSchools, loading, transactions, franchisee} = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let a = undefined, scId = (currentUserId == undefined || currentUser.schoolId);
        let schools = state.schools.schoolsMap.toArray().filter(a => (a.id == scId));
        let sc = (schools.length == 0) ? undefined : schools[0];
        let frId = (currentUser == undefined) ? undefined : currentUser.franchiseeId;
        let franchisee = (frId == undefined) ? undefined : state.users.usersMap.get(frId);
        return {
            currentUserId: currentUserId,
            currentUser: currentUser,
            school: sc,
            franchisee: franchisee,
            transactions: state.transactions.transactionsMap.toArray().filter(a => (a.userId == currentUserId)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            loading: state.subscriptions.loading || state.transactions.loading,
            allSchools: state.schools.schoolsMap.toArray(),
            subscriptions: (currentUser == undefined) ? [] : state.subscriptions.subscriptionsMap.toArray().filter(a => (a.franchiseeId == currentUser.franchiseeId))
        }
    }, []));

    useEffect(() => {
        dispatch(actions.getStudentTransactions(currentUserId));
    }, [currentUserId]);

    const getSchool = useCallback((scId) => {
        let fSchools = allSchools.filter(a => (a.id == scId));
        if (fSchools.length > 0) {
            return fSchools[0];
        }
        return undefined;
    }, []);

    const selectedSubscription = (selectedId == undefined) ? undefined : subscriptions.filter(s => (s.id == selectedId))[0];
    console.log('user transactions = ', transactions);
    let franchiseePaymentInformation = (franchisee == undefined) ? '' : franchisee.franchiseePaymentInformation;
    if (franchiseePaymentInformation == undefined) {
        franchiseePaymentInformation = '';
    }

    console.log('render: subscriptions = ', subscriptions);

    return (
        <Wrapper>

            {franchiseePaymentInformation == '' ? null :
                <div>
                    <p dangerouslySetInnerHTML={{__html: franchiseePaymentInformation.replace(/\n/g, '<br/>')}}></p>
                </div>
            }

            <SubscriptionsList>
                {subscriptions.map((sc, k) => {
                    let isSelected = (sc.id == selectedId);
                    let {type, amount, duration} = sc;
                    const schls = sc.schoolsIds.map(aa => getSchool(aa)).filter(a => a != undefined);
                    return (
                        <SubscriptionItem selected={isSelected} key={sc.id} onClick={() => {
                            setSelectedId(sc.id);
                        }}>
                            <SubscriptionInfo>
                                <NamePlaceholder>
                                    {sc.name}
                                </NamePlaceholder>
                                {sc.description == undefined || sc.description.trim() == '' ? null :
                                    <DescriptionPlaceholder>
                                        <div>
                                            {`Описание: ${sc.description}`}
                                        </div>
                                    </DescriptionPlaceholder>
                                }
                                <PricePlaceholder>
                                    {`Стоимость: ${sc.price} руб.`}
                                </PricePlaceholder>
                                {schls.length == 0 ? null :
                                    <SchoolsPlaceholder>
                                        Школы: {`${schls.map(a => a.name).join(', ')}`}
                                    </SchoolsPlaceholder>
                                }

                                {type != 'amount' ? null :
                                    <div>
                                        По количеству занятий - {`${amount} занятий`}
                                    </div>
                                }
                                {type != 'duration' ? null :
                                    <div>
                                        По сроку действия - {`${duration} дней`}
                                    </div>
                                }
                            </SubscriptionInfo>
                            <SubscriptionActionPlaceholder>
                                <Button onClick={() => {

                                }}>
                                    Купить
                                </Button>
                            </SubscriptionActionPlaceholder>
                        </SubscriptionItem>
                    )
                })}
            </SubscriptionsList>

            <TransactionsList>

                {transactions.map((tr, k) => {
                    let {subscription, status} = tr;
                    let {duration, amount, type} = subscription;

                    return (
                        <TransactionItem key={tr.id}>
                            <TransactionLeftInfo>
                                <TransactionName>
                                    {`${subscription.name} - ${subscription.price} руб.`}
                                </TransactionName>
                                <TransactionDate>
                                    {moment(tr.timestamp).format('DD.MM.YYYY HH:mm')}
                                </TransactionDate>
                                {type != 'amount' ? null :
                                    <div>
                                        По количеству занятий - {`${amount} занятий`}
                                    </div>
                                }
                                {type != 'duration' ? null :
                                    <div>
                                        По сроку действия - {`${duration} дней`}
                                    </div>
                                }

                                <TransactionDetailsPlaceholder>
                                    {`Статус: ${getStatusString(status)}`}

                                    {status != 'PENDING' ? null :
                                        <DelSpan onClick={() => {
                                            if (loading == false) {
                                                dispatch(actions.deleteTransaction(tr.id));
                                            }
                                        }}>
                                            <Spin visible={loading}/>
                                            {loading == true ? null :
                                                <span>
                                                Удалить
                                            </span>
                                            }
                                        </DelSpan>
                                    }

                                </TransactionDetailsPlaceholder>
                            </TransactionLeftInfo>

                            <TransactionRightInfo>
                                {getTypeString(type)}
                            </TransactionRightInfo>
                        </TransactionItem>
                    )
                })}

            </TransactionsList>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={(selectedSubscription != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedSubscription == undefined ? null :
                    <SidebarInner>

                        <SelectedPlaceholder>

                            <SelectedName>
                                {selectedSubscription.name}
                            </SelectedName>

                            <SelectedDescription>
                                {selectedSubscription.description}
                            </SelectedDescription>


                            <Spin visible={loading}/>

                            {loading == true ? null :
                                <SelectedControls>

                                    <SelButtonItem onClick={() => {
                                        dispatch(actions.buySubscriptionForCash(currentUserId, selectedSubscription.id)).then(pld => {
                                            setSelectedId(undefined);
                                        });
                                    }}>
                                        <SelButtonImage src={require('../../../assets/images/cash.svg')}/>
                                        <span>
                                        Купить за наличные
                                    </span>
                                    </SelButtonItem>

                                    <SelButtonItem onClick={() => {
                                        window.alert('В разработке');
                                    }}>
                                        <SelButtonImage
                                            src={require('../../../assets/images/credit-cards-payment.svg')}/>
                                        <span>
                                        Купить через интернет-платеж
                                    </span>
                                    </SelButtonItem>

                                </SelectedControls>
                            }

                        </SelectedPlaceholder>

                    </SidebarInner>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
   font-weight: bold;
   margin-bottom: 10px;
`;


const SubscriptionsList = styled.div`
    
`;

const SubscriptionItem = styled.div`
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    :hover{
      opacity: 0.5;
    }
`;

const SubscriptionInfo = styled.div`
    
`;

const SubscriptionActionPlaceholder = styled.div`
    
`;


const NamePlaceholder = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const DescriptionPlaceholder = styled.div`
    
`;

const PricePlaceholder = styled.div`
    
`;

const SchoolsPlaceholder = styled.div`
    
`;

const SidebarInner = styled.div`
    
`;

const SelectedPlaceholder = styled.div`
    text-align: center;
`;

const SelectedName = styled.div`
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
`;

const SelectedDescription = styled.div`
    font-size: 16px;
    opacity: 0.75;
    font-style: italic;
`;

const SelectedControls = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

const SelButtonItem = styled.div`
    padding: 15px;
    border-radius: 10px;
    border: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: lightgoldenrodyellow;
    margin: 10px;
    width: 330px;
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const SelButtonImage = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 10px;
`;

const TransactionsHeading = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 30px;
`;

const TransactionsList = styled.div`
    
`;

const TransactionItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    background: white;
    border: 1px solid whitesmoke;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TransactionLeftInfo = styled.div`
    
`;

const TransactionDate = styled.div`
    opacity: 0.75;
    font-size: 12px;
`;

const TransactionDetailsPlaceholder = styled.div`
    
`;

const TransactionName = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
`;

const TransactionRightInfo = styled.div`
    
`;

const DelSpan = styled.span`
    cursor: pointer;
    color: red;
    opacity: 0.75;
    text-decoration: underline;
    margin-left: 10px;
    :hover{
      opacity: 0.9;
    }
`;
