import CentersAPI from '../../api/CentersAPI'

import * as types from '../ActionTypes'

let loadCenters_ = () => {
    return {
        type: types.LOAD_CENTERS
    }
}
let loadCentersSuccess = (centers) => {
    return {
        type: types.LOAD_CENTERS_SUCCESS,
        centers: centers
    }
}
let loadCentersFailed = (error) => {
    return {
        type: types.LOAD_CENTERS_FAIL,
        error: error
    }
}

//thunk
export function loadAllCenters() {
    return (dispatch, getState) => {
        dispatch(loadCenters_());
        return CentersAPI.getAllCenters().then(
            centers => dispatch(loadCentersSuccess(centers)),
            error => dispatch(loadCentersFailed(error))
        )
    }
}

export function loadCenterById(id) {
    return (dispatch, getState) => {
        dispatch(loadCenters_());
        return CentersAPI.getCenter(id).then(
            center => dispatch(loadCentersSuccess([center])),
            error => dispatch(loadCentersFailed(error))
        )
    }
}

let createCenter_ = () => {
    return {
        type: types.CREATE_CENTER
    }
}
let createCenterSuccess = (center) => {
    return {
        type: types.CREATE_CENTER_SUCCESS,
        center: center
    }
}
let createCenterFailed = (error) => {
    return {
        type: types.CREATE_CENTER_FAIL,
        error: error
    }
}

//thunk
export function createCenter(data) {
    return (dispatch, getState) => {
        dispatch(createCenter_());
        return CentersAPI.createCenter(data).then(
            center => dispatch(createCenterSuccess(center)),
            error => dispatch(createCenterFailed(error))
        )
    }
}

let updateCenter_ = () => {
    return {
        type: types.UPDATE_CENTER
    }
}
let updateCenterSuccess = (center) => {
    return {
        type: types.UPDATE_CENTER_SUCCESS,
        center: center
    }
}
let updateCenterFailed = (error) => {
    return {
        type: types.UPDATE_CENTER_FAIL,
        error: error
    }
}

//thunk
export function updateCenter(data) {
    return (dispatch, getState) => {
        dispatch(updateCenter_());
        return CentersAPI.updateCenter(data).then(
            center => dispatch(updateCenterSuccess(center)),
            error => dispatch(updateCenterFailed(error))
        )
    }
}

let deleteCenter_ = () => {
    return {
        type: types.DELETE_CENTER
    }
}
let deleteCenterSuccess = (id) => {
    return {
        type: types.DELETE_CENTER_SUCCESS,
        id: id
    }
}
let deleteCenterFailed = (error) => {
    return {
        type: types.DELETE_CENTER_FAIL,
        error: error
    }
}

//thunk
export function deleteCenter(id) {
    return (dispatch, getState) => {
        dispatch(deleteCenter_());
        return CentersAPI.deleteCenter(id).then(
            () => dispatch(deleteCenterSuccess(id)),
            error => dispatch(deleteCenterFailed(error))
        )
    }
}
