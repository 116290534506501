import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SubscriptionsAPI = {

    getAllSubscriptions() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/subscriptions/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createSubscription(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/subscriptions/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateSubscription(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/subscriptions/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteSubscription(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/subscriptions/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default SubscriptionsAPI;
