import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import ChessTemplate from '../templates/ChessTemplate'

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import AdminStatsPanel from "../admin/panels/AdminStatsPanel";

export default function AdminIndexApp() {

    return (
        <ChessTemplate active={'index'}>

            <AdminStatsPanel/>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
