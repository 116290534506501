import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import UsersList from "../lists/UsersList";

import * as usersActions from '../../../redux/actions/UsersActions'

import Sidebar from 'arui-feather/sidebar'
import UpdateUserForm from "../forms/UpdateUserForm";

import {Button} from "../../ui/Button";
import CommonHelper from "../../../helpers/CommonHelper";

export default function FranchiseeTeachersPanel(props) {
    const {franchiseeId} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, teachers} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading,
        teachers: state.users.usersMap.toArray().filter(u => ((u.franchiseeId == franchiseeId) && (u.userRole == 'teacher')))
    }), [franchiseeId]));
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(undefined);
    const selectedUser = (setSelectedUserId == undefined) ? undefined : teachers.filter(u => (u.id == selectedUserId))[0];



    return (
        <Wrapper>

            <UsersList users={teachers}
                       selectedUserId={selectedUserId}
                       onUserClick={(u, uId) => {
                           setSelectedUserId(uId);
                       }}
            />

            {/*<BottomPlaceholder hasTopBorder={(teachers.length > 0)}>*/}
                {/*<Button onClick={() => {*/}
                    {/*setAddModalVisible(true);*/}
                {/*}}>*/}
                    {/*Добавить Преподавателя*/}
                {/*</Button>*/}
            {/*</BottomPlaceholder>*/}

            {/*<Sidebar visible={addModalVisible}*/}
                     {/*onCloserClick={() => {*/}
                         {/*setAddModalVisible(false);*/}
                     {/*}}*/}
            {/*>*/}
                {/*{addModalVisible == false ? null :*/}
                    {/*<div>*/}

                        {/*<Heading>*/}
                            {/*Добавление Преподавателя*/}
                        {/*</Heading>*/}

                        {/*<UpdateUserForm loading={loading} onSave={data => {*/}
                            {/*let d = {*/}
                                {/*...data,*/}
                                {/*franchiseeId: franchiseeId,*/}
                                {/*userRole: 'teacher'*/}
                            {/*}*/}
                            {/*dispatch(usersActions.createUser(d)).then(pld => {*/}
                                {/*setAddModalVisible(false);*/}
                            {/*})*/}
                        {/*}}/>*/}
                    {/*</div>*/}
                {/*}*/}
            {/*</Sidebar>*/}

            <Sidebar visible={(selectedUser != undefined)} onCloserClick={() => {
                setSelectedUserId(undefined);
            }}>

                {selectedUser == undefined ? null :
                    <div>
                        <Heading>
                            Редактирование Преподавателя
                        </Heading>
                        <UpdateUserForm emailVisible={false}
                                        passwordVisible={false}
                                        {...selectedUser}
                                        avatarVisible={true}
                                        loading={loading} onSave={data => {
                            dispatch(usersActions.updateUser({
                                ...data,
                                id: selectedUserId
                            })).then(pld => {
                                setSelectedUserId(undefined);
                            })
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    margin-top: ${props => (props.hasTopBorder == true ? '10px' : 0)};
    padding-top: ${props => (props.hasTopBorder == true ? '10px' : 0)};
    border-top: ${props => (props.hasTopBorder == true ? '1px solid whitesmoke' : 'none')};
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
`;
