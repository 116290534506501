import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

const TEACHER_OPTIONS = [
    {
        label: 'По фамилиии',
        type: 'lastName'
    },
    {
        label: 'По количеству групп',
        type: 'groupsNumber'
    },
    {
        label: 'По количеству занятий в неделю',
        type: 'lessonsNumber'
    },

]

export default function TeachersSortingTool(props) {
    let {
        onChange = (d) => {

        },
        mode = 'asc',
        type = 'lastName',
        options = TEACHER_OPTIONS
    } = props;

    console.log('TeachersSortingTool: render: mode, type = ', mode, type);

    return (
        <Wrapper>

            {options.map((item, k) => {
                let selected = (type == item.type);
                return (
                    <Option key={item.type}>
                        {selected == false ? null :
                            <ArrowImg
                                src={require('../../../assets/images/arrow-point-to-down.svg')}
                                style={{transform: `rotate(${(mode == 'desc') ? '180' : '0'}deg)`}}
                                onClick={() => {
                                    let newMode = {'asc': 'desc', 'desc': 'asc'}[mode];
                                    onChange({mode: newMode});
                                }}
                            />
                        }
                        <Name selected={selected} onClick={() => {
                            if (selected == true) {
                                let newMode = {'asc': 'desc', 'desc': 'asc'}[mode];
                                onChange({mode: newMode, type: item.type});
                            } else {
                                onChange({mode: 'asc', type: item.type});
                            }
                        }}>
                            {item.label}
                        </Name>
                    </Option>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
`;

const Option = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Name = styled.div`
    cursor: pointer;
    text-decoration: ${props => (props.selected == true ? 'underline' : 'none')};
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    opacity: 0.75;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;

const ArrowPlaceholder = styled.div`
    
`;

const ArrowImg = styled.img`
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-right: 5px;
    opacity: 0.75;
    :hover{
      opacity: 1;
    }
`;
