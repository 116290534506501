import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import ChessTemplate from '../templates/ChessTemplate'

import Tabs, {TabItem} from "../ui/Tabs";
import CentersPanel from "../russia/panels/CentersPanel";
import UpdateClassInfoPanel from "../classes/panels/UpdateClassInfoPanel";
import AdminExportPanel from "../admin/panels/AdminExportPanel";
import AdminUpdateAnyListPanel from "../achievements/panels/AdminUpdateAnyListPanel";

import AnnouncementsPanel from '../announcements/panels/AnnouncementsPanel'
import ExportPanel from "../export/panels/ExportPanel";
import AssessorsStatsPanel from "../assessors/panels/AssessorsStatsPanel";
import UpdateAboutPanel from "../about/panels/UpdateAboutPanel";

export default function AdminIndexApp() {

    return (
        <ChessTemplate active={'settings'}>

            <Tabs tabs={[
                {
                    label: 'Центры',
                    content: (
                        <TabItem>
                            <CentersPanel/>
                        </TabItem>
                    )
                },
                {
                    label: 'Классы',
                    content: (
                        <TabItem>
                            <Tabs tabs={[
                                {
                                    label: '7 класс',
                                    content: (
                                        <TabItem>
                                            <UpdateClassInfoPanel classNumber={7}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: '8 класс',
                                    content: (
                                        <TabItem>
                                            <UpdateClassInfoPanel classNumber={8}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: '9 класс',
                                    content: (
                                        <TabItem>
                                            <UpdateClassInfoPanel classNumber={9}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: '10 класс',
                                    content: (
                                        <TabItem>
                                            <UpdateClassInfoPanel classNumber={10}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: '11 класс',
                                    content: (
                                        <TabItem>
                                            <UpdateClassInfoPanel classNumber={11}/>
                                        </TabItem>
                                    )
                                }

                            ]}/>
                        </TabItem>
                    )
                },
                {
                    label: 'Выгрузка',
                    content: (
                        <TabItem>
                            <AdminExportPanel/>

                            <ExportPanel/>

                        </TabItem>
                    )
                },
                {
                    label: 'Настройка портфолио',
                    content: (
                        <TabItem>
                            <Tabs tabs={[
                                {
                                    label: 'Название олипиад',
                                    content: (
                                        <TabItem>
                                            <AdminUpdateAnyListPanel listName={'all_olympiads'}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Название достижений',
                                    content: (
                                        <TabItem>
                                            <AdminUpdateAnyListPanel listName={'all_achievements'}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Годы',
                                    content: (
                                        <TabItem>
                                            <AdminUpdateAnyListPanel listName={'all_olympiads_years'}/>
                                        </TabItem>
                                    )
                                }

                            ]}/>
                        </TabItem>
                    )
                },
                {
                    label: 'Уведомления',
                    content: (
                        <TabItem>
                            <AnnouncementsPanel/>
                        </TabItem>
                    )
                },
                {
                    label: 'О проекте',
                    content: (
                        <TabItem>
                            <UpdateAboutPanel

                            />
                        </TabItem>
                    )
                },

            ]}/>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`

`;
