import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import Spin from 'arui-feather/spin'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

export default function ClassDescriptionForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;
    const [programFileButtonName, setProgramFileButtonName] = useState(props.programFileButtonName == undefined ? '' : props.programFileButtonName);


    return (
        <Wrapper>

            <Field>
                <Input
                    placeholder={'Текст на кнопке скачивания прикрепленного файла'}
                    value={programFileButtonName} onChange={evt => {
                    setProgramFileButtonName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({programFileButtonName: programFileButtonName})
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;
