import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TagsList from "./tools/TagsList";
import {API_ENDPOINT} from "../../constants/config";
import QualitySwitcher from "./tools/QualitySwitcher";
import PlayerProgress from "./tools/PlayerProgress";
import ReactHelper from "../../helpers/ReactHelper";
import SpeedControlTool from "./tools/SpeedControlTool";
import CommonHelper from "../../helpers/CommonHelper";
import VolumeControl from "./tools/VolumeControl";

const ie = a => (a == undefined || a.trim() == '');

const getInitialVideoUrl = (props) => {
    let url = ['videoUrl', 'videoUrl720p', 'videoUrl480p', 'videoUrl1080p', 'videoUrl360p'].map(a => props[a]).filter(a => !ie(a))[0];
    return url;
};


export default function CustomPlayer(props) {
    const {
        hdUrl,
        sdUrl,
        videoEmbedUrl,
        autoPlay = true,
        onClose = () => {

        },
        id,
        expanded = false,
        onExpandChange = (newExpanded) => {

        }
    } = props;

    const [tags, setTags] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState(hdUrl || sdUrl);
    const [playing, setPlaying] = useState(autoPlay);
    const [speed, setSpeed] = useState(1);
    const [volume, setVolume] = useState(1);
    const [rightVisible, setRightVisible] = useState(false);

    useEffect(() => {
        if (id == undefined) {
            return;
        }
        axios.get(`${API_ENDPOINT}/video/${id}/time_tags`).then(d => d.data.result).then(arr => {
            setTags(arr);
            if (arr.length > 0) {
                setRightVisible(true);
            }
        });
    }, [id]);

    useEffect(() => {
        try {
            let t0 = videoRef.current.currentTime;
            videoRef.current.load();
            videoRef.current.currentTime = t0;
            videoRef.current.playbackRate = +speed;
            videoRef.current.volume = +volume;
            if (playing == true) {
                try {
                    videoRef.current.play();
                } catch (exc) {
                }
            } else {
                try {
                    videoRef.current.pause();
                } catch (exc1) {
                }

            }
        } catch (e) {

        }

    }, [selectedUrl]);

    useEffect(() => {
        try {
            videoRef.current.playbackRate = +speed;
        } catch (eexc) {

        }
    }, [speed]);

    useEffect(() => {
        try {
            videoRef.current.volume = +volume;
        } catch (eexc) {

        }
    }, [volume]);

    useEffect(() => {
        try {
            if (playing) {
                try {
                    videoRef.current.play();
                } catch (exc) {

                }

            } else {
                try {
                    videoRef.current.pause();
                } catch (exc) {

                }
            }
        } catch (ee) {

        }
    }, [playing]);

    ReactHelper.useInterval(() => {
        try {
            // console.log();
            window.currVideoDuration = videoRef.current.duration;

            try {
                document.getElementById('c_time_span').innerText = CommonHelper.convertSecondstoTime(Math.floor(videoRef.current.currentTime));
                document.getElementById('c_total_time_span').innerText = CommonHelper.convertSecondstoTime(Math.floor(videoRef.current.duration));
            } catch (eee) {

            }

            let progress = 100.0 * videoRef.current.currentTime / videoRef.current.duration;
            console.log('progress = ', progress);
            document.getElementById('current_time_bar').style.left = `${progress}%`;
        } catch (e) {

        }
    }, 500);


    const videoRef = useRef();

    function downHandler({key}) {
        try {
            console.log('downHandler: key = ', key);
            if (key == 'ArrowRight') {
                videoRef.current.currentTime = videoRef.current.currentTime + 10.0;
            }
            if (key == 'ArrowLeft') {
                videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 10.0);
            }
            if (key == ' ') {
                setPlaying(videoRef.current.paused);
            }
            if (key == 'Escape') {
                onExpandChange(false);
            }

        } catch (e) {

        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, []);


    if (selectedUrl == undefined) {
        return null;
    }

    // if (selectedUrl.indexOf('embed') > -1) {
    //     return (
    //         <div style={{
    //             boxSizing: 'border-box',
    //             width: '100vw',
    //             height: '100vh'
    //         }}>
    //             <iframe style={{border: 'none', width: '100%', height: '100%'}} src={selectedUrl}
    //                     frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen
    //                     allowFullScreen></iframe>
    //         </div>
    //     )
    // }

    return (
        <Wrapper id={'sabir'}>

            <TopPlaceholder>
                <PlayerPlaceholder>
                    <VideoPlaceholder>
                        {selectedUrl.indexOf('embed') > -1 ?

                            <iframe style={{border: 'none', width: '100%', height: '100%'}} src={selectedUrl}
                                    frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen
                                    allowFullScreen></iframe>
                            :
                            <>
                                <Video controls={false} ref={videoRef} onClick={() => {
                                    setPlaying(!playing);
                                }}>
                                    <source src={selectedUrl.replace('http://', 'https://')}/>
                                </Video>
                                <DumbOverlay className={'d_overlay'}></DumbOverlay>
                            </>
                        }

                    </VideoPlaceholder>


                </PlayerPlaceholder>
                {tags.length == 0 ? null :
                    <RightListPlaceholder width={rightVisible == true ? 310 : 10}>
                        <RightListToggler onClick={() => {
                            setRightVisible(!rightVisible);
                        }}>
                            <LeftChevImg src={require('./images/chev_left.svg')}/>
                        </RightListToggler>
                        {rightVisible == false ? null :
                            <ListPlaceholder>
                                <TagsList tags={tags} onGo={t => {
                                    try {
                                        videoRef.current.currentTime = t;
                                    } catch (err) {

                                    }
                                }}/>
                            </ListPlaceholder>
                        }
                    </RightListPlaceholder>
                }
            </TopPlaceholder>

            {selectedUrl.indexOf('embed') > -1 ? null :
                <ControlsPlaceholder>
                    <LeftPlayPlaceholder>
                        <PlayButton onClick={() => {
                            setPlaying(!playing);
                        }}>
                            <PlayImg
                                src={(playing == true) ? require('../../assets/images/pause-f.svg') : require('../../assets/images/play-button-f.svg')}/>
                        </PlayButton>

                        <VolumePlaceholder>
                            <VolumeControl volume={volume} onChange={newVolume => {
                                setVolume(newVolume);
                            }}/>
                        </VolumePlaceholder>

                        <QualityPlaceholder>
                            <QualitySwitcher {...props} selectedUrl={selectedUrl} onSelect={x => {
                                setSelectedUrl(x);
                            }}/>
                        </QualityPlaceholder>


                        <CurrentTimePlaceholder>
                            <CurrentTimeSpan id={'c_time_span'}>

                            </CurrentTimeSpan>
                        </CurrentTimePlaceholder>

                    </LeftPlayPlaceholder>

                    <ProgressbarPlaceholder>
                        <ProgressBarInnerPlaceholder>
                            <PlayerProgress onSeek={frac => {
                                try {
                                    let pos = videoRef.current.duration * frac;
                                    videoRef.current.currentTime = pos;
                                } catch (e) {

                                }
                            }}/>
                        </ProgressBarInnerPlaceholder>
                    </ProgressbarPlaceholder>

                    <CurrentTimePlaceholder>
                        <CurrentTimeSpan id={'c_total_time_span'}>

                        </CurrentTimeSpan>
                    </CurrentTimePlaceholder>

                    <RightSpeedPlaceholder>
                        <SpeedControlTool speed={speed} onChange={a => {
                            setSpeed(a);
                        }}/>
                    </RightSpeedPlaceholder>

                    <FullScreenButtonPlaceholder>
                        <FullScreenButtonImg
                            src={(expanded == true) ? require('../../assets/images/minimize.svg') : require('../../assets/images/expand.svg')}
                            onClick={() => {
                                let newExpanded = !expanded;
                                onExpandChange(newExpanded);
                                // let elem = ``;
                                let elem = document.getElementById('sabir');
                                console.log('!!!=>>>> newExpanded = ', newExpanded);


                                try {
                                    if (newExpanded == true) {
                                        if (elem.requestFullscreen) {
                                            elem.requestFullscreen();
                                        } else if (elem.msRequestFullscreen) {
                                            elem.msRequestFullscreen();
                                        } else if (elem.mozRequestFullScreen) {
                                            elem.mozRequestFullScreen();
                                        } else if (elem.webkitRequestFullscreen) {
                                            elem.webkitRequestFullscreen();
                                        }
                                    } else {
                                        document.exitFullscreen();
                                    }
                                } catch (e) {
                                    document.exitFullscreen();
                                }
                            }}
                        />
                    </FullScreenButtonPlaceholder>


                </ControlsPlaceholder>
            }

        </Wrapper>
    );
}

const bottomHeight = 48;

const FullScreenButtonImg = styled.img`
  cursor: pointer;
  height: 16px;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;

const LeftChevImg = styled.img`
  width: 7px;
  opacity: 0.5;
`;

const RightSpeedPlaceholder = styled.div`
  margin-left: 10px;
`;

const FullScreenButtonPlaceholder = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CurrentTimePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CurrentTimeSpan = styled.span`
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.7;
`;

const QualityPlaceholder = styled.div`
  height: 30px;
  width: 70px;
`;

const VolumePlaceholder = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: white;
`;

const PlayerPlaceholder = styled.div`
  height: 100%;
  width: 100%;
`;

const PlayImg = styled.img`
  height: 16px;
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const DumbOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  background: black;
  cursor: pointer;
`;

const ControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: ${bottomHeight}px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  background: whitesmoke;
  border-top: 1px solid lightgrey;
`;

const LeftPlayPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;


const PlayButton = styled.div`
  width: 50px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #015F9B;
  cursor: pointer;
  margin-right: 10px;

  :hover {
    opacity: 0.8;
  }
`;

const ProgressbarPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProgressBarInnerPlaceholder = styled.div`
  width: 100%;
  height: 20px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100% - ${bottomHeight}px);
`;

const ListPlaceholder = styled.div`
  width: 300px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: inline-block;
  vertical-align: top;
`;

const RightListPlaceholder = styled.div`
  width: ${props => (props.width == undefined ? 310 : props.width)}px;
  max-width: ${props => (props.width == undefined ? 310 : props.width)}px;

  height: 100%;
  background: whitesmoke;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;

`;

const RightListToggler = styled.div`
  height: 100%;
  vertical-align: top;
  width: 9px;
  background: lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
