import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {DARK_SIDE} from "../../ui/Colors";

import {Button} from "../../ui/Button";
import Tabs from '../../ui/Tabs'

import Sidebar from 'arui-feather/sidebar'
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";

import * as actions from '../../../redux/actions/SchoolsActions'
import CommonHelper from "../../../helpers/CommonHelper";
import SchoolTeachersPanel from "../../users/panels/SchoolTeachersPanel";
import SchoolGroupsPanel from "../../groups/panels/SchoolGroupsPanel";
import SchoolRoomsPanel from "../../rooms/panels/SchoolRoomsPanel";
import SchoolLessonsPanel from "../../lessons/panels/SchoolLessonsPanel";
import SchoolStudentsPanel from "../../users/panels/SchoolStudentsPanel";
import UpdateSchoolForm from "../forms/UpdateSchoolForm";


export default function SchoolPanel(props) {
    let {id} = props;
    const [modalVisible, setModalVisible] = useState(false);
    const {school, loading, franchisee, currentUserId, canEdit, currentUserIsTeacher} = useMappedState(useCallback(state => {
        const sc = state.schools.schoolsMap.get(id);
        const canEdit = ('admin' == CommonHelper.getCurrentUserRole(state) || (sc != undefined && sc.franchiseeId == state.users.currentUserId));
        return {
            school: sc,
            loading: loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            franchisee: (state.schools.schoolsMap.get(id) == undefined) ? undefined : state.users.usersMap.get(state.schools.schoolsMap.get(id).franchiseeId),
            canEdit: canEdit,
            currentUserIsTeacher: ('teacher' == CommonHelper.getCurrentUserRole(state))
        };
    }, [id]));
    const dispatch = useDispatch();
    const isMe = (franchisee != undefined && franchisee.id == currentUserId);
    if (id == undefined || school == undefined) {
        return null;
    }
    const {name, description, franchiseeId} = school;

    return (
        <Wrapper key={id} >

            <TopPlaceholder>
                <NamePlaceholder>
                    {name}
                </NamePlaceholder>
                <DescriptionPlaceholder>
                    {description}
                </DescriptionPlaceholder>
                {franchisee == undefined || isMe == true ? null :
                    <SubDescription>
                        {'Владелец школы: '}
                        <FraLink onClick={() => {
                            CommonHelper.linkTo(`/franchisee/${franchisee.id}`);
                        }}>
                            {`${franchisee.firstName} ${franchisee.lastName}`}
                        </FraLink>
                    </SubDescription>
                }

                {canEdit == false ? null :
                    <EditButtonPlaceholder>
                        <Button style={{backgroundColor: DARK_SIDE, fontSize: 12}} onClick={() => {
                            setModalVisible(true);
                        }}>
                            Редактировать
                        </Button>
                    </EditButtonPlaceholder>
                }

            </TopPlaceholder>

            <ContentPlaceholder>
                <Tabs tabs={[
                    {
                        label: 'Занятия',
                        content: (
                            <TabItem>
                                <SchoolLessonsPanel schoolId={id}
                                                    hasFilter={true}
                                                    teacherId={(currentUserIsTeacher == true) ? currentUserId : undefined}/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Ученики',
                        content: (
                            <TabItem>
                                <SchoolStudentsPanel schoolId={school.id}/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Преподаватели',
                        content: (
                            <TabItem>
                                <SchoolTeachersPanel franchiseeId={franchiseeId} schoolId={school.id}/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Группы',
                        content: (
                            <TabItem>
                                <SchoolGroupsPanel schoolId={school.id}/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Помещения',
                        content: (
                            <TabItem>
                                <SchoolRoomsPanel schoolId={school.id}/>
                            </TabItem>
                        )
                    },
                ]}/>
            </ContentPlaceholder>

            <Sidebar visible={modalVisible} onCloserClick={() => {
                setModalVisible(false);
            }}>

                {modalVisible == false ? null :
                    <div>
                        <UpdateSchoolForm {...school} onSubmit={d => {
                            dispatch(actions.updateSchool({
                                id: id,
                                ...d
                            })).then(pld => {
                                setModalVisible(false);
                            })
                        }}/>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    position: relative;
`;

const NamePlaceholder = styled.div`
    font-size: 24px;
    line-height: 32px;
`;

const DescriptionPlaceholder = styled.div`
    
`;

const SubDescription = styled.div`
    margin-top: 10px;
    opacity: 0.8;
    font-size: 12px;
`;

const EditButtonPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5px;
`;

const FraLink = styled.div`
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    :hover{
      opacity: 0.8;
    }
`;

const ContentPlaceholder = styled.div`
    margin-top: 30px;
`;

const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;
