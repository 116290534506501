import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";

import validator from 'validator'

import Spin from 'arui-feather/spin'
import CoveredUploadPanel from "../../upload/CoveredUploadPanel";
import {DEFAULT_TIMEZONE, FACELESS_AVATAR} from "../../../constants/config";
// import DatePicker from "../../ui/DatePicker";
import DatePicker from 'react-datepicker'

import Select from 'react-select'

import InputMask from 'react-input-mask'
import CommonHelper from "../../../helpers/CommonHelper";

export const CLASSES_OPTIONS = [
    {
        label: '7 класс',
        value: 7
    },
    {
        label: '8 класс',
        value: 8
    },
    {
        label: '9 класс',
        value: 9
    },
    {
        label: '10 класс',
        value: 10
    },
    {
        label: '11 класс',
        value: 11
    }
];

export const STUDENT_STATUS_OPTIONS = [
    {
        label: 'Активный',
        value: 'active'
    },
    {
        label: 'В архиве',
        value: 'archived'
    }
];


export const IS_MODERATOR_OPTIONS = [
    {
        label: 'Да, координатор',
        value: true
    },
    {
        label: 'Не, обычный преподаватель',
        value: false
    }
];


const ie = a => (a == undefined || a.trim() == '');

const isCiryllic = s => {
    if (s == undefined) {
        return false;
    }
    if (s == '') {
        return true;
    }
    let re = /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ ]+$/
    return re.test(s);
}

export default function UpdateUserForm(props) {

    const {
        showTeacherType = true,
        userPassword = '',
        isAdmin = true,
        canChangeCenter = true,
        isObserver = false,

        emailVisible = true,
        passwordVisible = true,
        buttonText = 'Сохранить',
        loading = false,
        avatarVisible = false,
        shouldShowBirthdayCalendar = true,
        onSave = () => {

        },
        onDelete = () => {

        },
        canEdit = true,
        isTeacher = false,
        allCenters = [],
        canDelete = false,
        hasCenter = true
    } = props;

    const [status, setStatus] = useState(props.status == undefined ? 'active' : props.status);
    const [isModerator, setIsModerator] = useState(props.isModerator == undefined ? false : props.isModerator);

    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);
    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [middleName, setMiddleName] = useState(props.middleName == undefined ? '' : props.middleName);

    const [centerId, setCenterId] = useState(props.centerId);

    const [avatar, setAvatar] = useState(props.avatar);
    const [birthdayTimestamp, setBirthdayTimestamp] = useState(props.birthdayTimestamp);

    const [classNumber, setClassNumber] = useState(props.classNumber == undefined ? 8 : props.classNumber);

    const [password, setPassword] = useState(props.password == undefined ? '' : props.password);
    const [passwordConfirmation, setPasswordConfirmation] = useState(props.password == undefined ? '' : props.password);

    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    console.log('ava = ', ava);

    let canSubmit =
        ((((emailVisible == true) && (passwordVisible == true) && (password != undefined) && password.length > 3 && validator.isEmail(email) == true && password == passwordConfirmation)) || (
            (emailVisible == false) && (passwordVisible == false)
        ));
    if (ie(firstName) == true || ie(lastName) == true) {
        canSubmit = false;
    }

    // const centersOptions = allCenters
    // const centersOptions = CommonHelper.getDropDownItemsByNamedList(allCenters);
    const centersOptions = allCenters.map(a => ({value: a.id, label: `${a.city}, ${a.name}`}));
    let selectedCenter = (centerId == undefined) ? undefined : centersOptions.filter(a => (a.value == centerId))[0];

    return (
        <Wrapper>

            {avatarVisible == false ? null :
                <Field>
                    <Label>
                        Аватар
                    </Label>
                    <Ava src={ava}>
                        {canEdit == false ? null :
                            <CoveredUploadPanel url={ava} onChange={url => {
                                setAvatar(url);
                            }}/>
                        }
                    </Ava>
                </Field>
            }

            <Field>
                <Label>
                    Фамилия
                </Label>
                <Input disabled={!canEdit} value={lastName} onChange={evt => {
                    let s = evt.target.value;
                    if (isCiryllic(s) == false) {
                        return;
                    }
                    setLastName(s);
                }}/>
            </Field>

            <Field>
                <Label>
                    Имя
                </Label>
                <Input disabled={!canEdit} value={firstName} onChange={evt => {
                    let s = evt.target.value;
                    if (isCiryllic(s) == false) {
                        return;
                    }
                    setFirstName(s);
                }}/>
            </Field>

            <Field>
                <Label>
                    Отчество
                </Label>
                <Input disabled={!canEdit} value={middleName} onChange={evt => {
                    let s = evt.target.value;
                    if (isCiryllic(s) == false) {
                        return;
                    }
                    setMiddleName(s);
                }}/>
            </Field>
            Дата рождения
            {isAdmin == false || canChangeCenter == false ? null :
                <Field>
                    <Label>
                        Пароль
                    </Label>
                    {userPassword}
                </Field>
            }

            {hasCenter == false || canChangeCenter == false ? null :
                <Field>
                    <Label>
                        Центр
                    </Label>
                    <Select options={centersOptions}
                            placeholder={'Выберите центр'}
                            value={selectedCenter} onChange={a => {
                        setCenterId(a.value);
                    }}/>
                </Field>
            }

            {isTeacher == true ? null :
                <Field>
                    <Label>
                        Класс
                    </Label>
                    <Select options={CLASSES_OPTIONS}
                            placeholder={'Выберите класс'}
                            value={CLASSES_OPTIONS.filter(a => (a.value == classNumber))[0]}
                            onChange={a => {
                                setClassNumber(a.value);
                            }}/>
                </Field>
            }

            {isTeacher == true || isObserver == true ? null :
                <Field>
                    <Label>
                        Статус
                    </Label>
                    <Select options={STUDENT_STATUS_OPTIONS}
                            value={STUDENT_STATUS_OPTIONS.filter(a => (a.value == status))[0]}
                            placeholder={'Выберите статус'}
                            onChange={a => {
                                setStatus(a.value);
                            }}/>
                </Field>
            }


            {(isTeacher == false || canChangeCenter == false || showTeacherType == false) ? null :
                <Field>
                    <Label>
                        Координатор
                    </Label>
                    <Select options={IS_MODERATOR_OPTIONS}
                            value={IS_MODERATOR_OPTIONS.filter(a => (a.value ==
                                isModerator))[0]}
                            placeholder={'Является ли координатором?'}
                            onChange={a => {
                                setIsModerator(a.value);
                            }}/>
                </Field>
            }


            <Field>
                <Label>
                    Телефон
                </Label>
                <InputMask className={'my_phone'}
                           mask="+7 (999) 999-99-99"
                           value={phone} onChange={evt =>
                    setPhone(evt.target.value)}/>
            </Field>

            {shouldShowBirthdayCalendar == false ? null :
                <Field>
                    <Label>
                        Дата рождения
                    </Label>
                    {canEdit == true ?
                        <DatePicker
                            locale={'ru'}
                            dateFormat="dd.MM.yyyy"
                            selected={birthdayTimestamp == undefined ? undefined : new Date(birthdayTimestamp)}
                            onChange={newDate => {
                                if (canEdit == false) {
                                    return;
                                }
                                setBirthdayTimestamp(+new Date(newDate));
                            }}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={60}
                        /> :
                        <div>
                            {moment(birthdayTimestamp).tz(DEFAULT_TIMEZONE).format('DD.MM.YYYY')}
                        </div>
                    }

                </Field>
            }


            {emailVisible == false ? null :
                <Field>
                    <Label>
                        Email
                    </Label>
                    <Input disabled={!canEdit} value={email} onChange={evt => setEmail(evt.target.value)}/>
                </Field>
            }


            {passwordVisible == false ? null :
                <Field>
                    <Label>
                        Пароль
                    </Label>
                    <Input disabled={!canEdit} value={password} onChange={evt => setPassword(evt.target.value)}/>
                </Field>
            }


            {passwordVisible == false ? null :
                <Field>
                    <Label>
                        Повторите пароль
                    </Label>
                    <Input disabled={!canEdit} value={passwordConfirmation}
                           onChange={evt => setPasswordConfirmation(evt.target.value)}/>
                </Field>
            }


            {canEdit == false ? null :
                <Field>
                    <Button
                        style={{marginLeft: 0}}
                        disabled={!canSubmit} onClick={() => {
                        if (canSubmit == false) {
                            return;
                        }
                        if (shouldShowBirthdayCalendar == true && birthdayTimestamp == undefined) {
                            window.alert('Не указана дата рождения!');
                            return;
                        }
                        let d = {
                            firstName: firstName.trim(),
                            lastName: lastName.trim(),
                            middleName: middleName.trim(),
                            phone,
                            avatar,
                            birthdayTimestamp,
                            classNumber,
                            status,
                            centerId,
                            isModerator
                        };
                        if (password != passwordConfirmation) {
                            return;
                        }
                        if (emailVisible == true) {
                            d.email = email;
                        }
                        if (passwordVisible == true) {
                            d.password = password;
                        }
                        onSave(d);
                    }}>
                        <Spin visible={(loading)}/>
                        {loading == true ? null : buttonText}
                    </Button>
                </Field>
            }

            {canDelete == false ? null :
                <Field style={{marginTop: 300}}>
                    <span style={{color: 'red', opacity: 0.5, cursor: 'pointer'}} onClick={() => {
                        if (window.confirm('Вы уверены, что хотите удалить пользователя?') == false) {
                            return;
                        }
                        if (window.confirm('Назад дороги нет! Это необратимое действие. Пользователь будет удален навсегда!') == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        удалить пользователя
                    </span>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const AvaPlaceholder = styled.div`
  margin-bottom: 10px;
`;

const avaSize = 80;

const Ava = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${avaSize}px;
  height: ${avaSize}px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
`;

const DatePickerPlaceholder = styled.div`
  height: 240px;
`;
