import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import LandingThemeInnerPanel from "../landing/LandingThemeInnerPanel";

export default function LandingThemeApp(props) {
    let {themeId, subj} = props.match.params;
    let isExperiment = window.location.href.indexOf(`/experiment`) > 0;

    return (
        <LandingTemplate active={`theme/${themeId}${isExperiment == true ? '/experiment' : ''}`} subj={subj} >

            <LandingThemeInnerPanel themeId={themeId}
                                    subj={subj}
                                    isExperiment={isExperiment} />

        </LandingTemplate>
    );
}

const RespContainer = styled.div`
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1200px){
      width: 100%;
    }
`;
