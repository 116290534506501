import axios from 'axios'
import {API_ENDPOINT, PARSE_APP_ID} from "../constants/config";

const UsersAPI = {

    forceLogout() {
        window.localStorage.removeItem(`Parse/${PARSE_APP_ID}/currentUser`);
        window.localStorage.removeItem(`Parse/${PARSE_APP_ID}/installationId`);
    },

    createUser(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/create`, data).then(d => d.data).then(
                user => {
                    if (user.error != undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    updateUser(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/update`, data).then(d => d.data).then(
                user => {
                    if (user.error != undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    changeUserEmail(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/update/email`, data).then(d => d.data).then(
                user => {
                    if (user.error != undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    deleteUser(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/delete/${id}`).then(d => d.data).then(
                () => resolve(id)
            ).catch(err => reject(err))
        });
    },

    getUsersByQueryMap(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/query`, {
                query: queryMap
            }).then(d => d.data).then(
                users => resolve(users)
            ).catch(err => reject(err))
        });
    },

    getAllUsers(queryMap = {}) {
        return new Promise((resolve, reject) => {
            console.log('getAllUsers occured!');
            axios.get(`${API_ENDPOINT}/users/all`).then(d => d.data).then(
                users => {
                    // console.log('loaded all users = ', users);
                    resolve(users);
                }
            ).catch(err => reject(err))
        });
    },

    getAllStudents() {
        return new Promise((resolve, reject) => {
            let queryMap = {
                equalTo: [['userRole', 'student']]
            };
            UsersAPI.getUsersByQueryMap(queryMap).then(users => {
                resolve(users.sort((a, b) => (+b.tiemsatmp - +a.timestamp)));
            }).catch(err => {
                reject(err);
            });
        });
    },

    getAllTeachers() {
        return new Promise((resolve, reject) => {
            let queryMap = {
                equalTo: [['userRole', 'teacher']]
            };
            UsersAPI.getUsersByQueryMap(queryMap).then(users => {
                resolve(users.sort((a, b) => (+b.timestamp - +a.timestamp)));
            }).catch(err => {
                reject(err);
            });
        });
    },


    getUserById(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}`).then(d => d.data).then(user => {
                if (user.error != undefined) {
                    return reject(user.error);
                }
                resolve(user);
            }).catch(err => {
                reject(err);
            })
        })
    }

}

export default UsersAPI;
