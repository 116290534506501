import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";


import {Container} from "../ui/Container";

import AdminAllStudentsPanel from "../admin/panels/AdminAllStudentsPanel";
import ProgramThemesItemsStatsPanel from "../stats/panels/ProgramThemesItemsStatsPanel";
import Tabs, {TabItem} from "../ui/Tabs";
import AssessorsStatsPanel from "../assessors/panels/AssessorsStatsPanel";

export default function AdminStatsApp(props) {

    return (
        <ChessTemplate active={'stats'}>

            <Tabs tabs={[
                {
                    label: 'Статистика по видео ',
                    content: (
                        <TabItem>
                            <ProgramThemesItemsStatsPanel/>
                        </TabItem>
                    )
                },
                {
                    label: 'Статистика проверяющих',
                    content: (
                        <TabItem>
                            <AssessorsStatsPanel/>
                        </TabItem>
                    )
                },
                {
                    label: 'Статистика по олипиадам',
                    content: (
                        <TabItem>
                            Under construction
                        </TabItem>
                    )
                }
            ]}/>

        </ChessTemplate>
    );
}

