import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import Spin from 'arui-feather/spin'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {MAX_MARK, MIN_MARK} from "../../../constants/config";
import AttachmentsEditor from "../../documents/tools/AttachmentsEditor";

import {Button} from "../../ui/Button";

export default function ObserverSubmissionForm(props) {
    const {
        loading, onSave = d => {

        }
    } = props;
    const [teacherAttachments, setTeacherAttachments] = useState(props.teacherAttachments == undefined ? [] : props.teacherAttachments);
    const [teacherComment, setTeacherComment] = useState(props.teacherComment == undefined ? '' : props.teacherComment);
    const [mark, setMark] = useState(props.mark);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Оценка
                </Label>
                <Input value={mark}
                       placeholder={`от ${MIN_MARK} до ${MAX_MARK}`}
                       onChange={evt => {
                           setMark(evt.target.value);
                       }}
                />
            </Field>

            <Field>
                <Label>
                    Комментарий
                </Label>
                <Textarea value={teacherComment}
                          style={{minHeight: '12em'}}
                          placeholder={`Впечатление от работы ученика`}
                          onChange={evt => {
                              setTeacherComment(evt.target.value);
                          }}
                />
            </Field>

            <Field>
                <Label>
                    Файлы (пре необходимости)
                </Label>
                <AttachmentsEditor attachments={teacherAttachments} onChange={newAtts => {
                    setTeacherAttachments(newAtts);
                    onSave({teacherAttachments: newAtts})
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        let mrk = (window.isNaN(mark) == true) ? undefined : +mark;
                        if (mrk != undefined && (mrk < MIN_MARK || mrk > MAX_MARK)) {
                            window.alert(`Оценка должна быть в пределаях от ${MIN_MARK} до ${MAX_MARK}`);
                            return;
                        }
                        onSave({
                            mark: mrk,
                            teacherAttachments: teacherAttachments,
                            teacherComment: teacherComment
                        });
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 3px;
`;
