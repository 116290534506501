import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import {Container, Heading} from "../ui/Container";
import CommonHelper from "../../helpers/CommonHelper";

import CoursePanel from '../courses/panels/CoursePanel'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";

import Sidebar from 'arui-feather/sidebar'
import UpdateUserForm from "../users/forms/UpdateUserForm";
import * as usersActions from "../../redux/actions/UsersActions";
import AdminUserSubmissionsPanel from "../submissions/panels/AdminUserSubmissionsPanel";
import Tabs, {TabItem} from "../ui/Tabs";
import AchievementsListEditor from "../achievements/tools/AchievementsListEditor";

export default function StudentApp(props) {
    let {id} = props.match.params;
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const {student, currentUser, currentUserId, loading, allCenters, center} = useMappedState(useCallback(state => {
        let student = state.users.usersMap.get(id);
        let cId = (student == undefined) ? undefined : student.centerId;
        let center = (cId == undefined) ? undefined : state.centers.centersMap.get(cId);
        return {
            student: student,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            allCenters: state.centers.centersMap.toArray(),
            center: center
        };
    }, [id]));
    let canEdit = (currentUser != undefined && (currentUser.userRole == 'admin' || currentUser.userRole == 'teacher'));
    if (student != undefined && student.franchiseeId == currentUserId) {
        canEdit = true;
    }

    return (
        <ChessTemplate active={'student'}>

            {student == undefined ? null :
                <StudentName>

                    <div>
                        {`Ученик ${student.firstName} ${student.lastName}`}

                        <sup style={{fontWeight: 'default', opacity: 0.5, fontSize: 12, marginLeft: 5}}>
                            {`${student.classNumber} класс, ${student.email}`}
                        </sup>

                        {canEdit == false ? null :
                            <EditImg src={require('../../assets/images/edit.svg')} onClick={() => {
                                setModalVisible(true);
                            }}/>
                        }

                    </div>

                    {center == undefined ? null :
                        <div style={{fontWeight: 'normal', opacity: 0.5, fontSize: 12, fontStyle: 'italic'}}>
                            {`${center.city}, ${center.name}`}
                        </div>
                    }

                </StudentName>
            }

            <AdminUserSubmissionsPanel studentId={id}/>

            <Sidebar visible={modalVisible} onCloserClick={() => {
                setModalVisible(false);
            }}>

                {modalVisible == false || student == undefined ? null :
                    <div>

                        <Tabs tabs={[
                            {
                                label: 'Info',
                                content: (
                                    <TabItem>
                                        <UpdateUserForm
                                            {...student}
                                            loading={loading}
                                            showStudentInfo={true}
                                            showParentInfo={true}
                                            avatarVisible={true}
                                            passwordVisible={false}
                                            emailVisible={false}
                                            allCenters={allCenters}
                                            canChangeCenter={(currentUser != undefined && currentUser.userRole == 'admin')}
                                            onSave={data => {
                                                dispatch(usersActions.updateUser({
                                                    ...data,
                                                    id: student.id
                                                })).then(pld => {
                                                    setModalVisible(false);
                                                })
                                            }}
                                            onDelete={async () => {
                                                await dispatch(usersActions.deleteUser(student.id));
                                                CommonHelper.linkTo(`/students`);
                                            }}
                                            canDelete={(currentUser != undefined && (currentUser.userRole == 'admin'))}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Достижения',
                                content: (
                                    <TabItem>
                                        <AchievementsListEditor {...student} canEdit={true} onSave={async arr => {
                                            let pld = await dispatch(usersActions.updateUser({
                                                id: student.id,
                                                achievements: arr
                                            }))
                                        }} />
                                    </TabItem>
                                )
                            }
                        ]} />

                    </div>
                }

            </Sidebar>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const StudentName = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;

const TopLink = styled.div`
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const EditImg = styled.img`
    height: 18px;
    width: 18px;
    margin-left: 4px;
    display: inline-block;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
