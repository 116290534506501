import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import RussiaAPI from "../../../api/RussiaAPI";
import CenterForm from "../forms/CenterForm";

import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";

import Tabs, {TabItem} from "../../ui/Tabs";
import * as usersActions from "../../../redux/actions/UsersActions";
import PointSelector from "../../tools/PointSelector";
import CentersMapView from "../view/CentersMapView";
import CenterInfoTool from "../view/CenterInfoTool";
import CentersTable from "../view/CentersTable";
import GrouppedCentersTable from "../view/GrouppedCentersTable";


export default function CentersViewPanel(props) {
    let {hasTable = true, hasLogo = false} = props;
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        RussiaAPI.getItems().then(arr => {
            setItems(arr.sort((a, b) => (+a.number - +b.number)));
            setLoading(false);
        });
    }, []);

    let selectedItem = (selectedId == undefined) ? undefined : items.filter(a => (a.id == selectedId))[0];
    console.log('selectedItem = ', selectedItem);
    if (items.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }


    return (
        <Wrapper>

            {hasLogo == false ? null :
                <LogoPlaceholder>
                    <FrLogo src={require('../../../assets/images/fr_small_logo.png')}/>
                </LogoPlaceholder>
            }

            <MapPlaceholder>
                <CentersMapView selectedId={selectedId} points={items} onCenterClick={id => {
                    console.log('CentersMapView: onCenterClick: id = ', id);
                    setSelectedId(id);
                }}/>
            </MapPlaceholder>

            {hasTable == false ? null :
                <TablePlaceholder>

                    <GrouppedCentersTable points={items}
                                          selectedId={selectedId}
                                          onPointClick={id => {
                                              setSelectedId(id);
                                          }}/>

                    {/*<CentersTable points={items}*/}
                    {/*              selectedId={selectedId}*/}
                    {/*              onPointClick={id => {*/}
                    {/*                  setSelectedId(id);*/}
                    {/*              }}/>*/}

                </TablePlaceholder>
            }


            <Sidebar visible={(selectedItem != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>
                {selectedItem == undefined ? null :
                    <div>
                        <CenterInfoTool {...selectedItem} />
                    </div>
                }
            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const LogoPlaceholder = styled.div`
  width: 15%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
`;

const FrLogo = styled.img`
  width: 100%;
`;

const MapPlaceholder = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  width: 80%;
`;

const Item = styled.div`
  padding: 10px;
  background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const TablePlaceholder = styled.div`
  width: 620px;
  margin: 0 auto;
  box-sizing: border-box;
  @media(max-width: 620px){
    width: 100%;
  }
`;


const TopPlaceholder = styled.div`
  margin-bottom: 10px;
`;
