import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";
import UpdateAchievementForm from "./UpdateAchievementForm";

import {Button} from "../../ui/Button";

export default function AchievementsListEditor(props) {
    const {
        onSave = arr => {

        },
        canEdit = true,
        saving = false,
        loading = false
    } = props;


    const [updated, setUpdated] = useState(false);

    const [achievements, setAchievements] = useState(props.achievements == undefined ? [] : props.achievements);
    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);

    const selectedItem = (selectedId == undefined) ? undefined : achievements.filter(a => (a.id == selectedId))[0];


    return (
        <Wrapper>

            <ItemsPlaceholder>
                {achievements.map((a, i) => {
                    return (
                        <Item key={`${a.id}_${i}`}>
                            <Right>
                                <Name onClick={() => {
                                    if (canEdit == false){
                                        return;
                                    }
                                    setSelectedId(a.id);
                                }}>
                                    {`${a.year} - ${a.name}`}
                                </Name>
                                <Description>
                                    {a.honor}
                                </Description>
                            </Right>
                            <Left>
                                {canEdit == false ? null :
                                    <CrossImg src={require('../../../assets/images/cross_cancel.svg')} onClick={() => {
                                        let newTags = achievements.filter(x => (a.id != x.id));
                                        setAchievements(newTags);
                                        onSave(newTags);
                                        setUpdated(true);
                                    }}/>
                                }
                            </Left>
                        </Item>
                    )
                })}
            </ItemsPlaceholder>

            {canEdit == false ? null :
                <BottomPlaceholder>
                    <AddTagSpan onClick={() => {
                        setAddVisible(true);
                    }}>
                        {'+ добавить олимпиаду'}
                    </AddTagSpan>
                </BottomPlaceholder>
            }

            {updated == false ? null :
                <div>
                    <Spin visible={loading || saving}/>
                    {loading == true || saving == true ? null :
                        <Button onClick={() => {
                            onSave(achievements);
                        }}>
                            Сохранить
                        </Button>
                    }
                </div>
            }

            <Sidebar visible={(selectedItem != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedItem == undefined ? null :
                    <div>
                        <UpdateAchievementForm {...selectedItem} onSave={d => {
                            let da = {
                                ...selectedItem,
                                ...d
                            };
                            let newAchievements = achievements.map(a => (a.id == selectedItem.id ? da : a))
                            setAchievements(newAchievements);
                            setUpdated(true);
                            setSelectedId(undefined);
                        }}/>
                    </div>
                }

            </Sidebar>


            <Sidebar visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <UpdateAchievementForm loading={loading} onSave={d => {
                            let da = {
                                id: uuid(),
                                ...d
                            };
                            let newAchievements = achievements.concat([da]);
                            setAchievements(newAchievements);
                            setUpdated(true);
                            onSave(newAchievements);
                            setAddVisible(false);
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const DelSpan = styled.div`
  color: red;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const CrossImg = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;

const AddTagSpan = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`

`;

const VidePlaceholder = styled.div`
  width: 100%;
  height: 420px;
  margin-bottom: 10px;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

const ItemsPlaceholder = styled.div`

`;

const Item = styled.div`
  color: ${props => (props.selected == true ? 'white' : 'black')};
  background: ${props => (props.selected == true ? 'blue' : 'white')};
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 5px;

`;

const Left = styled.div`
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Right = styled.div`
  flex: 1;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
    text-decoration: underline;
  }
`;

const Description = styled.div`
  font-size: 14px;
`;

