import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button, DangerButton} from "../../ui/Button";

import Spin from 'arui-feather/spin'

import Select from 'react-select'
import CommonHelper from "../../../helpers/CommonHelper";

const TYPES_OPTIONS = [
    {
        label: 'По количеству занятий',
        value: 'amount'
    },
    {
        label: 'По сроку действия',
        value: 'duration'
    },
];

const DAYS_OPTIONS = [30, 60, 90].map(a => ({label: `${a}`, value: a}));

export default function UpdateSubscriptionForm(props) {
    let {
        onSubmit = () => {
        }, loading = false,
        canEdit = true,
        schools = [],
        canDelete = false,
        onDelete = () => {

        }
    } = props;
    console.log('render: UpdateSubscriptionForm props = ', props);
    const [name, setName] = useState((props.name == undefined) ? '' : props.name);

    const [type, setType] = useState((props.type == undefined) ? 'amount' : props.type);
    const [amount, setAmount] = useState((props.amount == undefined) ? undefined : props.amount);
    const [duration, setDuration] = useState((props.duration == undefined) ? undefined : props.duration);

    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    const [price, setPrice] = useState((props.price == undefined) ? '' : props.price);
    const [schoolsIds, setSchoolsIds] = useState(props.schoolsIds == undefined ? [] : props.schoolsIds);

    console.log('name, description = ', name, description);

    const getSchool = useCallback((scId) => {
        let fSchools = schools.filter(a => (a.id == scId));
        if (fSchools.length > 0) {
            return fSchools[0];
        }
        return undefined;
    }, []);

    const selectedSchools = schoolsIds.map(a => (getSchool(a))).filter(a => (a != undefined));
    const selectedSchoolsOptions = CommonHelper.getDropDownItemsByNamedList(selectedSchools);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                {canEdit == true ?
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value);
                    }}/> :
                    <div>
                        {name}
                    </div>
                }
            </Field>

            <Field>
                <Label>
                    Школы
                </Label>
                {canEdit == false ?
                    <div>
                        {CommonHelper.getDropDownItemsByNamedList(schools).map(a => a.label).join(', ')}
                    </div>
                    :
                    <Select value={selectedSchoolsOptions}
                            options={CommonHelper.getDropDownItemsByNamedList(schools)}
                            isMulti={true}
                            isSearchable={true}
                            placeholder={'Выбрать школы'}
                            onChange={a => {
                                setSchoolsIds(a.map(aa => aa.value));
                            }}/>
                }
            </Field>

            <Field>
                <Label>
                    Тип
                </Label>
                {canEdit == false ?
                    <div>
                        {{'amount': 'По количеству', 'duration': 'По продолжительности'}}
                    </div>
                    :
                    <Select value={(type == undefined) ? undefined : TYPES_OPTIONS.filter(a => (a.value == type))[0]}
                            options={TYPES_OPTIONS}
                            isSearchable={false}
                            placeholder={'Выбрать  тип'}
                            onChange={a => {
                                setType(a.value);
                            }}/>
                }
            </Field>

            {type != 'duration' ? null :
                <Field>
                    <Label>
                        Продолжительность (дней)
                    </Label>
                    {canEdit == false ?
                        <div>
                            {duration}
                        </div>
                        :
                        <Select
                            value={(duration == undefined) ? undefined : DAYS_OPTIONS.filter(a => (a.value == duration))[0]}
                            options={DAYS_OPTIONS}
                            isSearchable={false}
                            placeholder={'Выбрать продолжительность (дни)'}
                            onChange={a => {
                                setDuration(a.value);
                            }}/>
                    }
                </Field>
            }

            {type != 'amount' ? null :
                <Field>
                    <Label>
                        Количество занятий
                    </Label>
                    {canEdit == true ?
                        <Input value={`${amount == undefined ? '0' : amount}`} onChange={evt => {
                            setAmount(evt.target.value);
                        }}/> :
                        <div>
                            {amount}
                        </div>
                    }
                </Field>
            }

            <Field>
                <Label>
                    Стоимость
                </Label>
                {canEdit == true ?
                    <Input value={price} onChange={evt => {
                        setPrice(evt.target.value);
                    }}/> :
                    <div>
                        {price}
                    </div>
                }
            </Field>


            <Field>
                <Label>
                    Описание
                </Label>
                {canEdit == true ?
                    <Textarea value={description} onChange={evt => {
                        setDescription(evt.target.value);
                    }}/> : <div dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}></div>
                }

            </Field>

            {canEdit == false ? null :
                <Field>
                    <BottomPlaceholder>
                        <Button onClick={() => {
                            if (loading == true) {
                                return;
                            }
                            let dd = {name, description, price: +price, schoolsIds: schoolsIds, type: type};
                            if (type == 'amount') {
                                dd.amount = +amount;
                            }
                            if (type == 'duration') {
                                dd.duration = +duration;
                            }
                            onSubmit(dd);
                        }}>
                            {loading == true ?
                                <Spin visible={true}/>
                                :
                                <span>
                                Сохранить
                            </span>
                            }

                        </Button>

                        {canDelete == false ? null :
                            <DangerButton loading={loading} onClick={() => {
                                onDelete();
                            }}>
                                Удалить
                            </DangerButton>
                        }

                    </BottomPlaceholder>

                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
`;
