import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import ThemeItemViewTool from "./ThemeItemViewTool";
import UpdateItemTool from "./UpdateItemTool";

import Spin from 'arui-feather/spin'

import {Button} from "../../ui/Button";
import FullScreenEmbedVideoModal from "../../video/tools/FullScreenEmbedVideoModal";
import SmartVideoModal from "../../video/tools/SmartVideoModal";

import Tabs, {TabItem} from "../../ui/Tabs";
import VideoStatsChartPanel from "../../stats/VideoStatsChartPanel";
import TimeTagsEditor from "../../time/tools/TimeTagsEditor";
import VideoTimeTagsPanel from "../../time/panels/VideoTimeTagsPanel";
import SmartCustomVideoModal from "../../video/tools/SmartCustomVideoModal";

export default function UpdateItemsTool(props) {
    const {
        onChange = newItems => {

        },
        loading = false
    } = props;
    const [selectedVideoId, setSelectedVideoId] = useState(undefined);
    const [selectedId, setSelectedId] = useState(undefined);
    const [hasNotSaved, setHasNotSaved] = useState(false);
    const [items, setItems] = useState(props.items == undefined ? [] : props.items);


    let selectedItem = (selectedId == undefined) ? undefined : items.filter(a => (a.id == selectedId))[0];
    let selectedVideoItem = (selectedVideoId == undefined) ? undefined : items.filter(a => (a.id == selectedVideoId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    let newItems = items.concat([
                        {
                            id: uuid(),
                            number: +new Date(),
                            name: moment().format('DD.MM.YYYY HH:mm:ss'),
                            buttonName: 'Текст кнопки'
                        }
                    ]);
                    console.log('newItems = ', newItems);
                    setItems(newItems);
                    setHasNotSaved(true);
                }}>
                    Добавить плашку
                </Button>
            </TopPlaceholder>

            {hasNotSaved == false ? null :
                <BottomPlaceholder>
                    <div style={{color: 'red'}}>
                        Внимание, есть несохраненные изменения
                    </div>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <Button onClick={() => {
                            onChange(items);
                            setHasNotSaved(false);
                        }}>
                            Сохранить
                        </Button>
                    }
                </BottomPlaceholder>
            }

            <ListPlaceholder>

                {items.map((it, k) => {
                    return (
                        <Item key={`k_${k}_${it.id}`}>
                            <ThemeItemViewTool {...it} canEdit={true} onEdit={() => {
                                setSelectedId(it.id);
                            }}
                                               onVideoClick={() => {
                                                   console.log('onVideoClick!');
                                                   setSelectedVideoId(it.id);
                                               }}
                            />
                        </Item>
                    )
                })}

            </ListPlaceholder>


            <Sidebar
                width={920}
                visible={(selectedItem != undefined)}
                onCloserClick={() => {
                    setSelectedId(undefined);
                }}>
                {selectedItem == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Редактирование',
                                content: (
                                    <TabItem>
                                        <UpdateItemTool {...selectedItem}
                                                        loading={loading}
                                                        canDelete={true}
                                                        onChange={d => {
                                                            console.log('UpdateItemTool: onChange: d = ', d);
                                                            console.log('oldItem: selectedItem = ', selectedItem);
                                                            let newItem = {...selectedItem, ...d};
                                                            console.log('newItem = ', newItem);
                                                            setItems(items.map((it, k) => (it.id == selectedItem.id ? newItem : it)));
                                                            setHasNotSaved(true);
                                                        }}
                                                        onDelete={() => {
                                                            setItems(items.filter((it, k) => (it.id != selectedId)));
                                                            setSelectedId(undefined);
                                                            setHasNotSaved(true);
                                                        }}
                                                        onSave={d => {
                                                            console.log('UpdateItemTool: onChange: d = ', d);
                                                            console.log('oldItem: selectedItem = ', selectedItem);
                                                            let newItem = {...selectedItem, ...d};
                                                            console.log('newItem = ', newItem);
                                                            let newItems = items.map((it, k) => (it.id == selectedItem.id ? newItem : it));
                                                            setItems(newItems);
                                                            // setHasNotSaved(true);
                                                            onChange(newItems);
                                                            setHasNotSaved(false);
                                                            setSelectedId(undefined);
                                                        }}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Редактор меток',
                                content: (
                                    <TabItem>
                                        <VideoTimeTagsPanel
                                            videoUrl={selectedItem.videoUrl || selectedItem.videoUrl720p || selectedItem.videoUrl480p || selectedItem.videoUrl360p}
                                            id={selectedItem.id}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Статистика',
                                content: (
                                    <TabItem>
                                        <VideoStatsChartPanel id={selectedItem.id}/>
                                    </TabItem>
                                )
                            }

                        ]}/>

                    </div>
                }

            </Sidebar>

            {/*{(selectedVideoItem == undefined) ? null :*/}
                {/*<SmartVideoModal*/}
                    {/*{...selectedVideoItem}*/}
                    {/*onClose={() => {*/}
                        {/*setSelectedVideoId(undefined);*/}
                    {/*}}/>*/}
            {/*}*/}

            {(selectedVideoItem == undefined) ? null :
                <SmartCustomVideoModal
                    {...selectedVideoItem}
                    sdUrl={selectedVideoItem.videoUrl480p || selectedVideoItem.videoUrl720p || selectedVideoItem.videoUrl}
                    hdUrl={selectedVideoItem.videoUrl720p || selectedVideoItem.videoUrl480p || selectedVideoItem.videoUrl}
                    onClose={() => {
                        setSelectedVideoId(undefined);
                    }}/>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;  
`;

const ListPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const BottomPlaceholder = styled.div`
    
`;

const Item = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    padding: 10px;
`;
