import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {BLUE, YELLOW} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";

let defaultAvatar = require('../../../assets/images/mu_default.jpg');

export default function ThemeItemViewTool(props) {
    let {
        avatar = undefined,
        name = 'Базовые задачи',
        buttonName = 'Текст',
        canEdit = false,
        buttonUrl = undefined,
        onButtonClick = () => {

        },
        onVideoClick = () => {
            
        },
        onEdit = () => {

        }
    } = props;
    let hasVideo = CommonHelper.hasVideoUrl(props);
    const safeButtonUrl = CommonHelper.getSafeUrl(buttonUrl);

    let ava = (avatar == undefined) ? defaultAvatar : CommonHelper.getSafeUrl(avatar);

    return (
        <Wrapper>
            <LeftPlaceholder>
                <VideoAvatarPlaceholder src={ava}>
                    <AvaOverlay onClick={() => {
                        if (hasVideo == true) {
                            onVideoClick();
                        } else {
                            if (safeButtonUrl != undefined) {
                                // downloadURI(safeButtonUrl);
                                CommonHelper.openInNewTab(safeButtonUrl);
                            }
                            onButtonClick();
                        }
                    }}>
                        {hasVideo == false ? null :
                            <PlayButton src={require('../../../assets/images/white_play.svg')}/>
                        }
                    </AvaOverlay>
                </VideoAvatarPlaceholder>
            </LeftPlaceholder>
            <RightPlaceholder>
                <NameOuter>
                    <Name>
                        {name}
                    </Name>
                </NameOuter>
                <ButtonPlaceholder>
                    {/*{hasVideo == false ? null :*/}
                    {/**/}
                    {/*}*/}
                    <Button onClick={() => {
                        if (safeButtonUrl != undefined) {
                            // downloadURI(safeButtonUrl);
                            CommonHelper.openInNewTab(safeButtonUrl);
                        }
                        onButtonClick();
                    }}>
                        {buttonName}
                    </Button>
                </ButtonPlaceholder>
                {canEdit == false ? null :
                    <div style={{cursor: 'pointer', marginTop: 10}} onClick={() => {
                        onEdit();
                    }}>
                        редактировать
                        {props.isExperiment == true ? '(Э)' : null}
                    </div>
                }
            </RightPlaceholder>
        </Wrapper>
    );
}

const AvaOverlay = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      background: rgba(255, 255, 255, 0.05);
    }
`;

const PlayButton = styled.img`
    height: 60px;
    width: 60px;
`;

const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    //padding: 20px;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    flex-direction: row;
    justify-content: flex-start;
    //height: 200px;
    height: 140px;
    align-items: stretch;
    background: white;
`;

const LeftPlaceholder = styled.div`
    margin-right: 20px;
    //width: 300px;
    width: 200px;
    height: 100%;
`;

const RightPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;
    align-items: center;
`;

const VideoAvatarPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
`;


const buttonHeight = 32;

const Button = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    min-height: ${buttonHeight}px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    background: ${BLUE};
    cursor: pointer;
    border-radius: 4px;
    //font-size: 18px;
    font-size: 14px;
    font-weight: bold;
    :hover{
      background: ${YELLOW};
    }
`;

const NameOuter = styled.div`
    position: relative;
    height: 56px;
    margin-bottom: 20px;
    width: 100%;
`;

const Name = styled.div`
    //font-size: 24px;
    //font-size: 20px;
    font-size: 16px;
    //line-height: 32px; 
    line-height: 17px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
`;

const ButtonPlaceholder = styled.div`
    
`;

function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
}
