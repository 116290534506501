import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

export default function CenterInfoTool(props) {
    let {name, address, region, city, contactName, contacts, teacherName, phone, curator, email, website} = props;

    return (
        <Wrapper>
            <Name>
                {name}
            </Name>
            <Row>
                <b>
                    Адрес:
                </b>
                {` ${address}`}
            </Row>
            <Row>
                <b>
                    Регион:
                </b>
                {` ${region}`}
            </Row>
            <Row>
                <b>
                    Город:
                </b>
                {` ${city}`}
            </Row>
            <Row>
                <b>
                    Контактное лицо:
                </b>
                {` ${contactName}`}
            </Row>
            <Row>
                <b>
                    Контакты:
                </b>
                {` ${contacts}`}
            </Row>
            {(teacherName == undefined || teacherName.trim() == '') ? null :
                <Row>
                    <b>
                        ФИО преподавателя:
                    </b>
                    {` ${teacherName}`}
                </Row>
            }
            {/*{phone == undefined ? null :*/}
                {/*<Row>*/}
                    {/*<b>*/}
                        {/*Телефон:*/}
                    {/*</b>*/}
                    {/*{` ${phone}`}*/}
                {/*</Row>*/}
            {/*}*/}
            {/*{curator == undefined ? null :*/}
                {/*<Row>*/}
                    {/*<b>*/}
                        {/*Куратор:*/}
                    {/*</b>*/}
                    {/*{` ${curator}`}*/}
                {/*</Row>*/}
            {/*}*/}
            {/*{email == undefined ? null :*/}
                {/*<Row>*/}
                    {/*<b>*/}
                        {/*Почта:*/}
                    {/*</b>*/}
                    {/*{` ${email}`}*/}
                {/*</Row>*/}
            {/*}*/}

            {(website == undefined || website.trim() == '') ? null :
                <Row style={{marginTop: 30}}>
                    <a href={website} target={'_blank'}>
                        перейти на сайт
                    </a>
                </Row>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 15px;
`;

const Row = styled.div`
    margin-bottom: 10px;
`;
