import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import SubjectCommentsPanel from "../../comments/panels/SubjectCommentsPanel";

const renderAttachments = attachments => {
    return (
        <AttachmentsList>
            {attachments.map((att, k) => {
                return (
                    <AttItem key={k}>
                        <a href={att.url} target={'_blank'}>
                            {`файл ${+k + 1}`}
                        </a>
                    </AttItem>
                )
            })}
        </AttachmentsList>
    )
}

export default function ViewSubmissionTool(props) {
    let {
        teacherComment, mark, observerId,
        id,
        attachments, teacherAttachments, hasTeacherSection = true, task, currentUser
    } = props;
    console.log('ViewSubmissionTool: render: props = ', props);
    let deadlineTimestamp = (task == undefined) ? undefined : task.deadlineTimestamp;
    let currentUserIsTeacherOrStudent = (currentUser != undefined && (currentUser.userRole == 'student' || currentUser.userRole == 'student'));
    let shouldNotSeeFeedback = ((deadlineTimestamp != undefined) && (+moment(deadlineTimestamp).endOf('day') > +new Date()) && (currentUserIsTeacherOrStudent == true));

    return (
        <Wrapper>

            {attachments == undefined || attachments.length == 0 ? null :
                <StudentInfoPlaceholder>
                    <SubHeading>
                        Прикрепленные файлы ученика ({attachments.length})
                    </SubHeading>
                    <div>
                        {renderAttachments(attachments)}
                    </div>
                </StudentInfoPlaceholder>
            }

            {hasTeacherSection == false ? null :
                <div>

                    <MarkPlaceholder>
                        {mark == undefined ?
                            <span>
                        Проверяющий еще не проверил работу
                        </span>
                            :
                            <span>

                                {shouldNotSeeFeedback == true ? <div>
                                        {`Результат проверки будет доступен после ${moment(deadlineTimestamp).format('DD.MM.YYYY')}`}
                                    </div> :
                                    <span>
                                    <b>
                                        {'Оценка: '}
                                    </b>
                                        {mark}
                                        <span style={{
                                            marginLeft: 4,
                                            fontSize: 10,
                                            opacity: 0.6,
                                            color: 'black'
                                        }}>из 50</span>
                                    </span>
                                }
                            </span>
                        }
                    </MarkPlaceholder>

                    {shouldNotSeeFeedback == true ? null :
                        <div>
                            {(teacherComment == undefined || teacherComment.trim() == '') ? null :
                                <CommentPlaceholder>
                                    <div
                                        dangerouslySetInnerHTML={{__html: teacherComment.replace(/\n/g, '<br/>')}}></div>
                                </CommentPlaceholder>
                            }

                            {teacherAttachments == undefined || teacherAttachments.length == 0 ? null :
                                <AttachmentsPlaceholder>
                                    <SubHeading>
                                        Прикрепленные файлы преподавателя ({teacherAttachments.length})
                                    </SubHeading>
                                    <div>
                                        {renderAttachments(teacherAttachments)}
                                    </div>
                                </AttachmentsPlaceholder>
                            }
                        </div>
                    }

                </div>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`
    
`;

const MarkPlaceholder = styled.div`
    font-size: 18px;
    margin-top: 30px;
`;

const CommentPlaceholder = styled.div`
    
`;

const AttachmentsList = styled.div`
    
`;

const AttItem = styled.div`
    
`;

const AttachmentsPlaceholder = styled.div`
    
`;

const SubHeading = styled.div`
    font-weight: bold;
`;

const StudentInfoPlaceholder = styled.div`
    
`;
