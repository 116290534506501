import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import * as actions from "../../../redux/actions/DocumentsActions";

import Sidebar from 'arui-feather/sidebar'
import UpdateDocumentForm from "../forms/UpdateDocumentForm";

import {Button} from '../../ui/Button'
import DocumentView from "../view/DocumentView";

export default function DocumentsPanel(props) {
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const {loading, documents, currentUser, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin'));
        return {
            loading: state.courses.loading,
            documents: state.documents.documentsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllDocuments());
    }, []);
    let selectedDocument = (selectedId == undefined) ? undefined : documents.filter(d => (d.id == selectedId))[0];

    return (
        <Wrapper>

            <DocumentsList>
                {documents.map((doc, k) => {
                    let {name, description, avatar, attachments} = doc;
                    return (
                        <DocumentItem key={doc.id}>
                            <DocumentInner onClick={() => {
                                setSelectedId(doc.id);
                            }}>
                                <DocumentAvatar src={avatar}/>
                                <DocumentContent>
                                    <Name>
                                        {name}
                                    </Name>
                                    <Description>
                                        {description}
                                    </Description>
                                </DocumentContent>
                            </DocumentInner>
                        </DocumentItem>
                    )
                })}
            </DocumentsList>

            {isAdmin == false ? null :
                <BottomPlaceholder>
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        Добавить документ
                    </Button>
                </BottomPlaceholder>
            }

            <Sidebar width={Math.min(window.innerWidth, 920)} visible={(selectedDocument != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedDocument == undefined ? null :
                    <div>
                        {isAdmin == true ?
                            <UpdateDocumentForm {...selectedDocument} onSave={d => {
                                dispatch(actions.updateDocument({...d, id: selectedId})).then(pld => {
                                    if (pld.error != undefined) {
                                        window.alert(JSON.stringify(pld.error));
                                    } else {
                                        setSelectedId(undefined);
                                    }
                                });
                            }}
                                                canDelete={true}
                                                loading={loading}
                                                onDelete={() => {
                                                    dispatch(actions.deleteDocument(selectedId)).then(pld => {
                                                        if (pld.error != undefined) {
                                                            window.alert(JSON.stringify(pld.error));
                                                        } else {
                                                            setSelectedId(undefined);
                                                        }
                                                    });
                                                }}
                            /> :
                            <DocumentView {...selectedDocument} />
                        }

                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(window.innerWidth, 920)} visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <UpdateDocumentForm loading={loading} onSave={d => {
                            dispatch(actions.createDocument(d)).then(pld => {
                                if (pld.error != undefined) {
                                    window.alert(JSON.stringify(pld.error));
                                } else {
                                    setAddModalVisible(false);
                                }
                            });
                        }} canDelete={false}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const DocumentsList = styled.div`
    display: flex;
    flex-direction: row;
`;

const DocumentItem = styled.div`
    flex-basis: 33.333%;
    box-sizing: border-box;
    padding: 10px;
`;

const DocumentInner = styled.div`
    background: white;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const DocumentContent = styled.div`
    padding: 5px;
    box-sizing: border-box;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
`;

const Description = styled.div`
    
`;

const DocumentAvatar = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.src});
    background-color: whitesmoke;
    width: 100%;
    height: 220px;
`;

const BottomPlaceholder = styled.div`
    margin-top: 10px;
`;
