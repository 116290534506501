import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import * as submissionsActions from "../../../redux/actions/SubmissionsActions";
import * as tasksActions from "../../../redux/actions/TasksActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import * as centersActions from "../../../redux/actions/CentersActions";
import ObserverSubmissionForm from "../forms/ObserverSubmissionForm";

export default function ObserverSubmissionPanel(props) {
    const dispatch = useDispatch();
    const {id, observerId} = props;
    let {currentUser, loading, submission, observer, task} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let submission = state.submissions.submissionsMap.get(id);
        let obId = (observerId == undefined) ? state.users.currentUserId : observerId;
        let observer = state.users.usersMap.get(obId);
        let task = (submission == undefined) ? undefined : state.tasks.tasksMap.get(submission.taskId);
        return {
            submission: submission,
            task: task,
            observer: observer,
            loading: state.tasks.loading || state.submissions.loading || state.users.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId || state.tasks.loading || state.submissions.loading,
        }
    }, []));

    useEffect(() => {
        dispatch(centersActions.loadAllCenters());
        dispatch(tasksActions.loadAllTasks());
        if (submission == undefined) {
            dispatch(submissionsActions.loadSubmissionById(id));
        }
    }, [id]);

    if (loading == true && (submission == undefined || task == undefined)) {
        return (
            <Code/>
        )
    }

    if (submission == undefined || observer == undefined || task == undefined) {
        return null;
    }


    return (
        <Wrapper key={submission.updatedTimestamp}>

            <ObserverSubmissionForm loading={loading} {...submission} onSave={async d => {
                await dispatch(submissionsActions.updateSubmission({...d, id: submission.id, observerId: observer.id}))
            }}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
