import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import ClassProgramsPanel from "../programs/panels/ClassProgramsPanel";
import {getLabelBySubjAndClassNumber} from "./LandingApp";

export default function LandingClassApp(props) {
    let {classNumber, subj} = props.match.params;
    const bgImage = undefined; //todo: change
    console.log('LandingClassApp: render');
    let mottoLabel = getLabelBySubjAndClassNumber(subj, classNumber);

    return (
        <LandingTemplate active={`class/${classNumber}`} subj={subj} >

            <FullPageImageSection height={`220px`} hasTopLogo={false} image={bgImage}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel motto={(
                        <div>
                            {mottoLabel}
                            {/*{classNumber == '0' ? 'ОБЩЕЕ' : (classNumber == '1' ? 'Эксперимент' : `${classNumber} КЛАСС`)}*/}
                        </div>
                    )}
                                       hasTopLogo={false}
                    />
                </div>
            </FullPageImageSection>

            <BottomContentPlaceholder>

                <RespContainer>
                    <ClassProgramsPanel
                        subj={subj}
                        classNumber={classNumber} safeMode={true}
                        isGuestMode={true}
                    />
                </RespContainer>

            </BottomContentPlaceholder>

        </LandingTemplate>
    );
}


const BottomContentPlaceholder = styled.div`
    padding-top: 20px;
    padding-bottom: 50px;
    background: white;
    @media(max-width: 820px){
      padding-top: 20px;
    }
`;

const RespContainer = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding: 24px;
    box-sizing: border-box;
    @media(max-width: 1200px){
      width: 100%;
    }
`;

