import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ChessTemplate from '../templates/ChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import DistengTemplate from "../templates/DistengTemplate";

export default function StudentIndexApp(props) {
    const dispatch = useDispatch();
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    return (
        <DistengTemplate active={'index'}>

            <Container>
                <StudentPersonalPanel id={currentUserId} />
            </Container>

        </DistengTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const Container = styled.div`
    background: white;
    padding: 10px;
    border-radius: 4px;
`;
