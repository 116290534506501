import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import AdminAllTeachersPanel from "../admin/panels/AdminAllTeachersPanel";
import AllNewsItemsPanel from "../news/panels/AllNewsItemsPanel";

export default function AdminNewsApp(props) {

    return (
        <ChessTemplate active={'news'}>

            <AllNewsItemsPanel canCreate={true} />

        </ChessTemplate>
    );
}

