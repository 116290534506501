/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    subscriptionsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error}
}

const SubscriptionsReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_SUBSCRIPTION:
        case types.UPDATE_SUBSCRIPTION:
        case types.DELETE_SUBSCRIPTION:
        case types.LOAD_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_SUBSCRIPTION_FAIL:
        case types.UPDATE_SUBSCRIPTION_FAIL:
        case types.DELETE_SUBSCRIPTION_FAIL:
        case types.LOAD_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_SUBSCRIPTION_SUCCESS:
        case types.UPDATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                subscriptionsMap: state.subscriptionsMap.set(action.subscription.id, action.subscription)
            }


        case types.DELETE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                subscriptionsMap: state.subscriptionsMap.delete(action.id),
            }



        case types.LOAD_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                subscriptionsMap: state.subscriptionsMap
                    .merge(action.subscriptions.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default SubscriptionsReducer;
