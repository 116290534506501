import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import {Container, Heading} from "../ui/Container";
import CommonHelper from "../../helpers/CommonHelper";

import CoursePanel from '../courses/panels/CoursePanel'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";

import Sidebar from 'arui-feather/sidebar'
import UpdateUserForm from "../users/forms/UpdateUserForm";
import * as usersActions from "../../redux/actions/UsersActions";

import Tabs from '../ui/Tabs'
import SchoolLessonsPanel from "../lessons/panels/SchoolLessonsPanel";

export default function ObserverApp(props) {
    let {id} = props.match.params;
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const {observer, currentUser, currentUserId, loading, allCenters, center} = useMappedState(useCallback(state => {
        let observer = state.users.usersMap.get(id);
        let cId = (observer == undefined) ? undefined : observer.centerId;
        let center = (cId == undefined) ? undefined : state.centers.centersMap.get(cId);
        return {
            observer: observer,
            center: center,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            allCenters: state.centers.centersMap.toArray()
        }
    }, [id]));
    let canEdit = (currentUser != undefined && currentUser.userRole == 'admin');
    if (observer != undefined) {
        canEdit = true;
    }
    if (observer == undefined) {
        return null;
    }

    return (
        <ChessTemplate active={'teacher'}>

            {observer == undefined ? null :
                <StudentName>

                    <div>
                        {`Проверяющий ${observer.firstName} ${observer.lastName}`}

                        {canEdit == false ? null :
                            <EditImg src={require('../../assets/images/edit.svg')} onClick={() => {
                                setModalVisible(true);
                            }}/>
                        }
                    </div>

                    <div style={{fontWeight: 'normal', opacity: 0.5, fontSize: 12, fontStyle: 'italic'}}>
                        {`${observer.email}`}
                    </div>

                    {center == undefined ? null :
                        <div style={{fontWeight: 'normal', opacity: 0.5, fontSize: 12, fontStyle: 'italic'}}>
                            {`${center.city}, ${center.name}`}
                        </div>
                    }

                </StudentName>
            }

            <Container>
                тут будет необходимая информация о проверяющем
            </Container>

            <Sidebar visible={modalVisible} onCloserClick={() => {
                setModalVisible(false);
            }}>

                {modalVisible == false || observer == undefined ? null :
                    <div>
                        <UpdateUserForm
                            showTeacherType={false}
                            {...observer}
                            loading={loading}
                            showStudentInfo={true}
                            showParentInfo={true}
                            avatarVisible={true}
                            passwordVisible={false}
                            emailVisible={false}
                            isTeacher={false}
                            isObserver={true}
                            hasCenter={false}
                            shouldShowBirthdayCalendar={false}
                            allCenters={allCenters}
                            onSave={data => {
                                dispatch(usersActions.updateUser({
                                    ...data,
                                    id: observer.id
                                })).then(pld => {
                                    setModalVisible(false);
                                })
                            }}
                            onDelete={async () => {
                                await dispatch(usersActions.deleteUser(observer.id));
                                CommonHelper.linkTo(`/observers`);
                            }}
                            canDelete={true}
                        />
                    </div>
                }

            </Sidebar>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const StudentName = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;

const TopLink = styled.div`
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const EditImg = styled.img`
    height: 18px;
    width: 18px;
    margin-left: 4px;
    display: inline-block;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
