import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UsersAPI from "../../../api/UsersAPI";
import CentersAPI from "../../../api/CentersAPI";

import Select from 'react-select'
import SubmissionsAPI from "../../../api/SubmissionsAPI";
import TasksAPI from "../../../api/TaksAPI";
import {CLASSES_OPTIONS} from "../../users/forms/UpdateUserForm";
import {Button} from "../../ui/Button";

const FROM = +moment().startOf('year').add(-1, 'years').add(8, 'months').startOf('month');

const getStudentsWithSubmissions = (students, submissions, centers) => {
    let subsMap = Map();
    let centersMap = centers.reduce((mp, c) => mp.set(c.id, c), Map());
    for (let i in submissions) {
        let sub = submissions[i];
        let aa = subsMap.get(sub.studentId);
        if (aa == undefined) {
            aa = [];
        }
        aa = aa.concat([sub]);
        subsMap = subsMap.set(sub.studentId, aa);
    }
    return students.map(st => {
        return {
            ...st,
            submissions: (subsMap.get(st.id) == undefined) ? [] : subsMap.get(st.id),
            center: centersMap.get(st.centerId)
        }
    }).map(a => ({
        ...a,
        centerName: ((a.center == undefined) ? 'Без центра' : `${a.center.city} ${a.center.name}`).replace(/\n/g, '')
    }))
}

export default function AssessorsStatsPanel(props) {

    const [users, setUsers] = useState([]);
    const [centers, setCenters] = useState([]);
    const [students, setStudents] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [submissions, setSubmissions] = useState([]);

    const [classNumber, setClassNumber] = useState(8);

    const [selectedObserverId, setSelectedObserverId] = useState(undefined);
    const [observers, setObservers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        UsersAPI.getAllUsers().then(x => {
            setUsers(x);
            setObservers(x.filter(a => (a.userRole == 'observer')));
            setStudents(x.filter(a => (a.userRole == 'student')));
            CentersAPI.getAllCenters().then(z => {
                setCenters(z);
                if (observers.length > 0) {
                    setSelectedObserverId(observers[0].id);
                }
                SubmissionsAPI.getAllSubmissions().then(aa => {
                    setSubmissions(aa.filter(xxx => (xxx.timestamp > +FROM && xxx.mark != undefined)));
                    TasksAPI.getAllTasks().then(zz => {
                        setTasks(zz);
                        setLoading(false);
                    });
                })
            })
        })
    }, []);

    if (loading == true) {
        return (
            <Code/>
        )
    }

    const observersOptions = observers.map(x => ({value: x.id, label: x.lastName}));

    if (observers.length == 0) {
        return null;
    }

    const filteredStudents = students.filter(a => (a.classNumber == classNumber));
    let enhStudents = getStudentsWithSubmissions(filteredStudents, submissions, centers);
    console.log('render: enhStudents = ', enhStudents);
    console.log('render: enhStudents: Кузнецова = ', enhStudents.filter(xx => (xx.lastName == 'Кузнецова' && xx.firstName == 'Александра')));

    let tasksForSelectedClassForThisYear = tasks.filter(x => ((x.timestamp > FROM) && (x.classNumber == classNumber)));
    let sortedTasks = tasksForSelectedClassForThisYear.sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    });

    console.log('sortedTasks = ', sortedTasks);

    let csv = `name;center;${sortedTasks.map(x => x.name).join(';')}`;
    for (let i in enhStudents) {
        let st = enhStudents[i];
        let s = `${st.firstName == undefined ? 'Без имени' : st.firstName} ${st.lastName == undefined ? 'Без фамилии' : st.lastName};${st.centerName}`;
        // console.log('sss = ', s);
        let xArr = [];
        for (let j in sortedTasks) {
            let task = sortedTasks[j];
            let submission = st.submissions.filter(xx => (xx.taskId == task.id))[0];
            xArr.push(`${submission == undefined || submission.mark == undefined ? '-' : `${submission.mark}`}`);
        }
        s = `${s};${xArr.join(';')}`;
        csv = `${csv}\n${s}`;
    }
    // console.log('--->>> csv = ', csv);

    return (
        <Wrapper>

            <Field>
                <Select options={CLASSES_OPTIONS}
                        placeholder={'Выберите класс'}
                        value={CLASSES_OPTIONS.filter(a => (a.value == classNumber))[0]}
                        onChange={a => {
                            setClassNumber(a.value);
                        }}/>
            </Field>

            <Button onClick={() => {
                let csvContent = `data:text/csv;charset=utf-8,${csv}`;
                let encodedUri = encodeURI(csvContent);
                let link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `${classNumber}_export.csv`);
                document.body.appendChild(link); // Required for FF

                link.click();
            }}>
                export
            </Button>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 10px;
`;