import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Select from 'react-select'
import KeyValueAPI from "../../../api/KeyValueAPI";

export default function KvdbSelect(props) {
    const {
        listName = 'all_olympiads', value, onChange = a => {

        }
    } = props;
    const [options, setOptions] = useState([]);
    useEffect(() => {
        KeyValueAPI.getParam(listName, []).then(arr => {
            setOptions(arr.map(a => ({label: a.name, value: a.name})));
        })
    }, []);

    if (options.length == 0) {
        return null;
    }

    return (
        <Select options={options}
                placeholder={'Выбрать'}
                value={options.filter(a => (a.value == value))[0]}
                onChange={a => {
                    onChange(a.value);
                }}/>
    );
}

const Wrapper = styled.div`
    
`;
