import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

export default function TagsList(props) {
    const {
        tags = [],
        onGo = t => {

        }
    } = props;

    let sortedItems = tags.sort((a, b) => (+a.t - +b.t));

    return (
        <Wrapper>

            {sortedItems.map((a, i) => {
                return (
                    <Item key={a.id} onClick={() => {
                        onGo(a.t);
                    }}>
                        <TimeLeft>
                            {CommonHelper.convertSecondstoTime(a.t)}
                        </TimeLeft>
                        <ItemRight>
                            <Name>
                                {a.name}
                            </Name>
                            {(a.description == undefined || a.description == '') ? null :
                                <Description>
                                    {a.description}
                                </Description>
                            }
                        </ItemRight>
                    </Item>
                )
            })}


        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 14px;
`;

const Description = styled.div`
    font-size: 12px;
`;

const Item = styled.div`
    box-sizing: border-box;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    
    cursor: pointer;
    :hover{
      opacity: 0.8;
      background: white;
    }
    
`;

const TimeLeft = styled.div`
    width: 60px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    opacity: 0.7;
`;

const ItemRight = styled.div`
    flex: 1;
`;
