import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {BLUE} from "../../ui/Colors";

import CommonHelper from '../../../helpers/CommonHelper'


export default function CentersTable(props) {
    const {
        points = [], onPointClick = id => {
        },
        selectedId = undefined,
        hasRegionColumn = true,
        hasHead = false
    } = props;

    let sortedPoints = points.sort((a, b) => {
        let s1 = (a.region == undefined) ? '' : a.region.toLowerCase();
        let s2 = (b.region == undefined) ? '' : b.region.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    return (
        <Wrapper>

            <Table>
                {hasHead == false ? null :
                    <Thead>
                        <Tr>
                            {hasRegionColumn == false ? null :
                                <Th>
                                    Регион
                                </Th>
                            }
                            <Th>
                                Адрес
                            </Th>
                            <Th>
                                Центр
                            </Th>

                        </Tr>
                    </Thead>
                }


                <Tbody>
                    {sortedPoints.map((a, k) => {
                        let selected = (selectedId == a.id);
                        return (
                            <Tr selected={selected} key={a.id} onClick={() => {
                                onPointClick(a.id);
                            }}>
                                {hasRegionColumn == false ? null :
                                    <Td>
                                        {CommonHelper.gnc(a.region)}
                                    </Td>
                                }
                                {/*<Td>*/}
                                {/*{a.city}*/}
                                {/*</Td>*/}
                                <AddressTd>
                                    {CommonHelper.gnc(a.address)}
                                </AddressTd>
                                <Td>
                                    {CommonHelper.gnc(a.name)}
                                </Td>
                                {/*<Td>*/}
                                {/*{CommonHelper.gnc(a.curator)}*/}
                                {/*</Td>*/}
                                {/*<Td>*/}
                                {/*{CommonHelper.gnc(a.phone)}*/}
                                {/*</Td>*/}
                                {/*<Td>*/}
                                {/*{CommonHelper.gnc(a.email)}*/}
                                {/*</Td>*/}
                            </Tr>
                        )
                    })}
                </Tbody>


            </Table>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  text-align: left;
  font-weight: normal;
  background: white;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;

const Tr = styled.tr`
  cursor: pointer;
  background: ${props => (props.selected == true ? BLUE : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};

  :hover {
    opacity: 0.8;
  }
`;

const Th = styled.th`
  padding: 5px;
  border: 1px solid lightgrey;
`;

const Td = styled.td`
  padding: 5px;
  border: 1px solid lightgrey;
  font-size: 14px;
`;

const AddressTd = styled.td`
  padding: 5px;
  border: 1px solid lightgrey;
  font-size: 14px;
  min-width: 300px;
`;
