import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import {Map, Set} from 'immutable'

import useDebouncedCallback from 'use-debounce/lib/callback'

import {Input} from "../../ui/Input";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'
import * as usersActions from "../../../redux/actions/UsersActions";
import UpdateUserForm from "../../users/forms/UpdateUserForm";

import Select from 'react-select'
import BulkUsersCreationTool from "../../automatic/tools/BulkUsersCreationTool";

import {CLASSES_OPTIONS} from "../../users/forms/UpdateUserForm";
import {ARCH_OPTIONS} from "../../admin/panels/AdminAllStudentsPanel";
import ls from "local-storage";

export default function TeacherPersonalPanel(props) {
    let {id} = props;
    const dispatch = useDispatch();
    const [filterClassNumber, setFilterClassNumber] = useState(undefined);
    const [searchString, setSearchString] = useState('');
    let {teacher, students, center, userRole, currentUser, loading, allCenters} = useMappedState(useCallback(state => {
        let teacher = state.users.usersMap.get(id);
        let centerId = (teacher == undefined) ? undefined : teacher.centerId;
        let students = state.users.usersMap.toArray().filter(a => (a.centerId == centerId))
            .filter(a => (a.userRole == 'student'));

        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;

        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            userRole: userRole,
            teacher: teacher,
            students: students,
            center: state.centers.centersMap.get(centerId),
            allCenters: state.centers.centersMap.toArray(),
        }
    }, [id]));

    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('will set ', a);
        setSearchString(a);
    }, 300, []);

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [status, setStatus] = useState(ls('is_active_student') == undefined ? 'active' : ls('is_active_student'));

    useEffect(() => {
        ls('cc-is_active_student', status);
    }, [status]);

    const sortedStudents = students.filter(a => {
        let {firstName, lastName, email} = a;
        let s = `${lastName} ${firstName} ${email}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }).sort((a, b) => {
        let s1 = `${a.lastName} ${a.firstName}`.toLowerCase();
        let s2 = `${b.lastName} ${b.firstName}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    }).filter(a => (a.classNumber == filterClassNumber || filterClassNumber == undefined))
        .filter(a => (status == undefined || a.status == status));

    return (
        <Wrapper>

            {center == undefined ? null :
                <Heading>
                    <b>
                        {center.name}
                    </b>
                    <p style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={{marginRight: 5}}>
                            {`${sortedStudents.length} учеников`}
                        </span>

                        {(userRole != 'admin' && (currentUser != undefined && userRole != 'teacher')) ? null :
                            <Button onClick={() => {
                                setAddModalVisible(true);
                            }}>
                                Добавить ученика
                            </Button>
                        }
                    </p>
                </Heading>
            }

            <TopSearchPlaceholder>
                <div>
                    <Input placeholder={'Поиск'} style={{
                        height: 36,
                        lineHeight: `36px`,
                        fontSize: 16
                    }} onChange={evt => {
                        debouncedSearch(evt.target.value);
                    }}/>
                </div>
                <div style={{marginTop: 10}}>
                    <Select options={CLASSES_OPTIONS}
                            value={CLASSES_OPTIONS.filter(a => (a.value == filterClassNumber))[0]}
                            isClearable={true}
                            placeholder={'Фильтр по классу'}
                            onChange={a => {
                                if (a == undefined) {
                                    setFilterClassNumber(undefined);
                                } else {
                                    setFilterClassNumber(a.value);
                                }
                            }}/>
                </div>

                <div style={{marginTop: 10}}>
                    <Select options={ARCH_OPTIONS} value={ARCH_OPTIONS.filter(a => (a.value == status))[0]}
                            isClearable={true}
                            placeholder={'Фильтр "Активный/Архивный"'}
                            onChange={a => {
                                if (a == undefined) {
                                    setStatus(undefined);
                                } else {
                                    setStatus(a.value);
                                }
                            }}/>
                </div>

            </TopSearchPlaceholder>

            <PaginatorWrapper renderItems={items => items.map((c, k) => {
                let {id, firstName, lastName, centerId} = c;
                return (
                    <SchoolItem key={id} onClick={() => {
                        CommonHelper.linkTo(`/student/${id}`);
                    }}>
                        <Name>
                            {`${lastName} ${firstName}`}
                            <sup style={{opacity: 0.4, fontSize: 10, marginLeft: 5}}>
                                {`${c.classNumber} класс`}
                            </sup>
                        </Name>
                    </SchoolItem>
                )
            })} items={sortedStudents}/>

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <UpdateUserForm
                            password={CommonHelper.getPwd()}
                            loading={loading}
                            avatarVisible={true}
                            passwordVisible={true}
                            emailVisible={true}
                            allCenters={allCenters}
                            canChangeCenter={(currentUser != undefined && currentUser.userRole == 'admin')}
                            centerId={(currentUser != undefined && currentUser.userRole == 'teacher') ? currentUser.centerId : undefined}
                            onSave={async data => {
                                if (data.centerId == undefined) {
                                    window.alert('Не выбран центр');
                                    return;
                                }
                                let pld = await dispatch(usersActions.createUser({
                                    ...data,
                                    userRole: 'student'
                                }));
                                if (pld.error != undefined) {
                                    // window.alert(JSON.stringify(pld.error));
                                    if (pld.error != undefined && pld.error.message != undefined && pld.error.message.indexOf('already exists') > -1) {
                                        window.alert('Аккаунт с данной почтой уже существует!');
                                    }
                                } else {
                                    setAddModalVisible(false);
                                }
                            }}
                        />
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SchoolsList = styled.div`

`;

const SimpleSchoolItem = styled.div`
  margin-bottom: 40px;
`;

const SchoolItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

const Heading = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopSearchPlaceholder = styled.div`
  margin-bottom: 10px;
`;
