import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ReactHelper from "../../../helpers/ReactHelper";
import {API_ENDPOINT} from "../../../constants/config";

const getLazyViewId = () => {
    let key = `os_view_video_u_id`;
    if (window.localStorage.getItem(key) == undefined) {
        window.localStorage.setItem(key, makeid(12));
    }
    return window.localStorage.getItem(key);
}

const updateLazilyVideoStatsLocally = (videoId, dt = 2) => { // dt is in seconds
    if (videoId == undefined) {
        return;
    }
    let uId = getLazyViewId();
    let key = `v_stat_${uId}_${videoId}`;
    let defaultValue = {
        videoId: videoId,
        viewUserId: uId,
        duration: 0
    }
    if (window.localStorage.getItem(key) == undefined) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue))
    }
    let v = JSON.parse(window.localStorage.getItem(key));
    v.duration = +v.duration + +dt;
    window.localStorage.setItem(key, JSON.stringify(v));
}

async function saveStatsOnServer(videoId) {
    let uId = getLazyViewId();
    let key = `v_stat_${uId}_${videoId}`;
    let defaultValue = {
        videoId: videoId,
        viewUserId: uId,
        duration: 0
    }
    if (window.localStorage.getItem(key) == undefined) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue))
    }
    let v = JSON.parse(window.localStorage.getItem(key));
    await axios.post(`${API_ENDPOINT}/video/${videoId}/view`, {
        duration: v.duration,
        viewUserId: v.viewUserId
    });
}

export default function VideoViewStatsDaemon(props) {
    const {
        interval = 2, // in seconds
        videoId = 'sabir',
        isPlaying = true,

        serverInterval = 10

    } = props;

    const [dirty, setDirty] = useState(false);

    ReactHelper.useInterval(() => {
        if (isPlaying == false || videoId == undefined) {
            return;
        }
        console.log(`tracking +${interval} seconds`);
        updateLazilyVideoStatsLocally(videoId, interval);
        setDirty(true);
    }, interval * 1000);

    ReactHelper.useInterval(() => {
        if (dirty == false) {
            return;
        }
        saveStatsOnServer(videoId).then(() => {
            setDirty(false);
        });
    }, 1.2 * interval * 1000);


    return (
        <Wrapper>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: none;
`;

function makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
