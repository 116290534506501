import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

// https://drive.google.com/file/d/1CiTiivZeWFgimtStiodO-zmAbtklQYw9/preview

export default function FullScreenEmbedVideoModal(props) {
    const {
        embedUrl = undefined,
        onClose = () => {

        }
    } = props;

    return (
        <Wrapper>
            <ClosePlaceholder>
                <CloseImage src={require('../../../assets/images/cancel_s.svg')}
                            onClick={() => {
                                onClose();
                            }}
                />
            </ClosePlaceholder>

            <Inner>
                <VideoPlaceholder>
                    <Iframe src={embedUrl}/>
                </VideoPlaceholder>
            </Inner>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;  
    left: 0px;  
    right: 0px;  
    z-index: 1000;
    background: rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
`;

const Iframe = styled.iframe`
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
`;

const Inner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const VideoPlaceholder = styled.div`
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    background: rgba(0, 0, 0, 0.8);
`;

const CloseImage = styled.img`
    cursor: pointer;
    opacity: 0.75;
    width: 30px;
    height: 30px;
    :hover{
      opacity: 1;
    }
`;

const ClosePlaceholder = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    width: 60px; 
    height: 60px; 
    z-index: 1001;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const InnerPlaceholder = styled.div`
    
`;
