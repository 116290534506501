import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {BLUE, YELLOW} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";

const eq = (a, b) => {
    if (a == undefined || b == undefined) {
        return false;
    }
    return ((a.replace('https', '').replace('http', '') == b.replace('https', '').replace('http', '')))
}

export default function QualitySwitcher(props) {
    const {
        videos = [], selectedVideoUrl = undefined, onChange = url => {

        }
    } = props;
    let opts = videos.sort((a, b) => (+a.type - +b.type));
    console.log('QualitySwitcher: render: opts = ', opts);
    console.log('QualitySwitcher: render: selectedVideoUrl = ', selectedVideoUrl);

    if (videos.length < 2) {
        return null;
    }

    return (
        <Wrapper>
            <DropdownHeaderItem>
                <DropdownLabel>
                    <GearImage src={require('../../../assets/images/settings-gears.svg')}/>
                </DropdownLabel>
                <DropdownContent>
                    {opts.map((a, k) => {
                        let selected = eq(a.url, selectedVideoUrl);
                        return (
                            <DropdownItem selected={selected} key={`${k}_${a.type}`} onClick={() => {
                                onChange(a.url);
                            }}>
                                {`${a.type}p`}
                            </DropdownItem>
                        )
                    })}
                </DropdownContent>

            </DropdownHeaderItem>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const headerHeight = 20;

const DropdownLabel = styled.div`
    color: ${BLUE};
    :hover{
      
    }
    @media(max-width: 920px){
      font-size: 16px;
    }
    @media(max-width: 720px){
      font-size: 12px;
    }
`;

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    top: ${headerHeight}px;
    left: -13px;
    right: 0px;
    z-index: 100;
    padding-top: 15px;
`;

const DropdownItem = styled.div`
    text-align: center;
    color: white;
    height: ${headerHeight * 0.9}px;
    line-height: ${headerHeight * 0.9}px;
    border-bottom: ${props => (props.selected == true ? '2px solid white' : 'none')};
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    :hover{
      text-decoration: underline;
      color: ${YELLOW};
    }
    @media(max-width: 720px){
      font-size: 12px;
    }
`;

const DropdownHeaderItem = styled.div`
    text-align: center;
    color: ${BLUE};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    :hover{
      text-decoration: underline;
    }
    :hover{
      & > ${DropdownContent}{
       display: block;
      }
    }
`;

const GearImage = styled.img`
    width: 24px;
    height: 24px;
`;
