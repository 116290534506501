import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import useComponentSize from '@rehooks/component-size'
import sizeOf from 'browser-image-size'
import {YELLOW} from "../../ui/Colors";


export default function CentersMapView(props) {
    let {imageSource = require('../../../assets/images/russia_clear.png')} = props;
    const {
        x, y, onChange = (pt) => {
            console.log(pt);
        },
        selectedId = undefined,
        pointSize = 10,
        points = [],
        onCenterClick = id => {

        }
    } = props;
    const [imageSize, setImageSize] = useState();
    const [selectedPoint, setSelectedPoint] = useState((props.x == undefined || props.y == undefined) ? undefined : {
        x: +props.x,
        y: +props.y
    });
    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;
    console.log(selectedPoint);

    useEffect(() => {
        async function getSize() {
            let imSize = await sizeOf(imageSource);
            setImageSize(imSize);
            return imSize;
        }

        getSize();
    }, []);
    const imageWidth = size.width;
    const imageHeight = (imageSize == undefined) ? undefined : (imageSize.height / imageSize.width * size.width);
    // const pointSize = imageWidth * radius / 100.0;

    return (
        <Wrapper ref={ref} style={{height: (imageHeight == undefined) ? 'auto' : `${imageHeight}px`}}>
            {imageSize == undefined ? null :
                <Image src={imageSource}/>
            }
            {points.map((pt, k) => {
                let selected = (selectedId == pt.id);
                return (
                    <React.Fragment key={`${pt.id}_${k}`} >
                        <Point
                            selected={selected}
                            onClick={() => {
                                onCenterClick(pt.id);
                            }}
                            style={{
                                width: pointSize,
                                height: pointSize,
                                left: `calc(${pt.x}% - ${pointSize / 2}px)`,
                                top: `calc(${pt.y}% - ${pointSize / 2}px)`
                            }}
                        />
                    </React.Fragment>
                )
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    position: relative;
`;

const Image = styled.img`
    width: 100%;
`;


const Point = styled.div`
    border-radius: 1000px;
    background: ${props => (props.selected == true ? 'red' : YELLOW)};
    border: 2px solid ${YELLOW};
    position: absolute;
    cursor: pointer;
    opacity: 0.99;
    :hover{
      background: red;
      border-color: white;
    }
`;
