import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios'
import * as serviceWorker from './serviceWorker';
import ls from "local-storage";

// import 'normalize.css/normalize.css'
// import '@blueprintjs/core/lib/css/blueprint.css'
// import '@blueprintjs/icons/lib/css/blueprint-icons.css'

const getCurrentUserId = () => {
    let tk = window.localStorage.getItem('os_current_user');
    if (tk == undefined || tk == '') {
        return;
    }
    return tk;
};


const getToken = () => {
    let d = ls('Parse/h1QhtsSjeoyQSa8RDQBDPvgbnI7Ix6nadHTsepwN/currentUser');
    if (d == undefined || d == '' || d.sessionToken == undefined || d.sessionToken == '') {
        return;
    }
    return d.sessionToken;
};

// axios.interceptors.request.use((config) => {
//     let token = getToken();
//     if (token != undefined) {
//         let url = new URL(config.url);
//         // url.searchParams.set('token', token);
//         config.url = url.toString();
//         config.headers['Authorization'] = token;
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

axios.interceptors.request.use((config) => {
    let currentUserId = getCurrentUserId();
    if (currentUserId != undefined) {
        let url = new URL(config.url);
        url.searchParams.set('z', currentUserId);
        config.url = url.toString();
    }
    let token = getToken();
    if (token != undefined) {
        let url = new URL(config.url);
        // url.searchParams.set('token', token);
        config.url = url.toString();
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
