import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";

export default function AttachmentsEditor(props) {
    const {
        onChange = newAttachments => {
        },
        attachments = []
    } = props;
    console.log('AttachmentsEditor: render: attachments = ', attachments);

    return (
        <Wrapper>

            <AttachmentsList>
                {attachments.map((a, k) => {
                    return (
                        <AttachmentItem key={a.id}>
                            <InputPlaceholder>
                                <Input placeholder={'Название'} value={a.name} onChange={evt => {
                                    let s = evt.target.value;
                                    let newAttachments = attachments.map(aa => (aa.id == a.id ? ({
                                        ...a,
                                        name: s
                                    }) : aa));
                                    onChange(newAttachments);
                                }}/>
                            </InputPlaceholder>
                            <DeletePlaceholder>
                                <Img src={require('../../../assets/images/cross_cancel.svg')} onClick={() => {
                                    let newAttachments = attachments.filter(aa => (aa.id != a.id));
                                    onChange(newAttachments);
                                }}/>
                            </DeletePlaceholder>
                            <UploadPlaceholder>
                                <SimpleUploadPanel onUploaded={url => {
                                    let newAttachments = attachments.map(aa => (aa.id == a.id ? ({
                                        ...a,
                                        url: url
                                    }) : aa));
                                    onChange(newAttachments);
                                }}>
                                    <Img src={require('../../../assets/images/cloud-computing.svg')}/>
                                </SimpleUploadPanel>
                            </UploadPlaceholder>
                            <DownPlaceholder>
                                <a href={a.url} target={'_blank'}>
                                    скачать
                                </a>
                            </DownPlaceholder>

                        </AttachmentItem>
                    )
                })}
            </AttachmentsList>

            <AddNewPlaceholder>
                <SimpleUploadPanel onUploaded={url => {
                    console.log('onUploaded: old attachments = ', props.attachments);
                    let newAttachments = props.attachments.concat([{
                        id: uuid(),
                        url: url,
                        name: `файл ${props.attachments.length + 1}`,
                        t: +new Date()
                    }]);
                    console.log('new attachments = ', newAttachments);
                    onChange(JSON.parse(JSON.stringify(newAttachments)));
                }}>
                    <UploaderInner>
                        Добавить еще файл
                    </UploaderInner>
                </SimpleUploadPanel>
            </AddNewPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const AttachmentsList = styled.div`
    
`;

const AttachmentItem = styled.div`
    display: flex;
    flex-direction: row;
`;

const InputPlaceholder = styled.div`
    
`;

const DeletePlaceholder = styled.div`
    width: 80px;
    text-align: center;
`;

const UploadPlaceholder = styled.div`
    width: 120px;
    text-align: center;
    background: whitesmoke;
    display: flex;
    align-items: center;
`;

const Img = styled.img`
    height: 16px;
    display: inline-block;
`;

const AddNewPlaceholder = styled.div`
    margin-top: 10px;
`;

const UploaderInner = styled.div`
    padding: 15px;
    background: whitesmoke;
    border: 2px dashed lightgrey;
    text-align: center;
`;

const DownPlaceholder = styled.div`
    padding: 5px;
`;
