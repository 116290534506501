import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import InputMask from 'react-input-mask'
import Tabs, {TabItem} from "../../ui/Tabs";
import AchievementsListEditor from "../../achievements/tools/AchievementsListEditor";

import {GRADES_OPTIONS} from "../../courses/forms/UpdateCourseForm";

import Select from 'react-select'

export default function UpdateUserInfoForm(props) {

    const {
        userRole
    } = props;

    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [classNumber, setClassNumber] = useState(props.classNumber == undefined ? 8 : props.classNumber);

    let hasAchievments = (userRole != undefined && userRole != 'teacher' && userRole != 'observer');

    const {
        onSave = () => {
        },
        loading = false,
        extraContent = undefined
    } = props;

    return (
        <Wrapper>


            <Tabs tabs={[
                {
                    label: 'Общая информация',
                    content: (
                        <TabItem>
                            <Field>
                                <Label>
                                    Имя
                                </Label>
                                <Input value={firstName} onChange={evt => setFirstName(evt.target.value)}/>
                            </Field>

                            <Field>
                                <Label>
                                    Фамилия
                                </Label>
                                <Input value={lastName} onChange={evt => setLastName(evt.target.value)}/>
                            </Field>

                            <Field>
                                <Label>
                                    Класс
                                </Label>
                                <Select placeholder={'Выберите класс'}
                                        options={GRADES_OPTIONS}
                                        value={GRADES_OPTIONS.filter(a => (+a.value == +classNumber))[0]}
                                        onChange={a => {
                                            setClassNumber(a.value);
                                        }}
                                />
                            </Field>


                            <Field>
                                <Label>
                                    Телефон
                                </Label>
                                <InputMask className={'my_phone'}
                                           mask="+7 (999) 999-99-99"
                                           value={phone} onChange={evt =>
                                    setPhone(evt.target.value)}/>
                            </Field>

                            <Field>
                                <Button onClick={() => {
                                    onSave({phone, firstName, lastName, classNumber});
                                }}>
                                    <Spin visible={loading}/>
                                    {loading == true ? null :
                                        <span>
                                      Сохранить
                                </span>
                                    }
                                </Button>

                                {extraContent}

                            </Field>
                        </TabItem>
                    )
                },
                hasAchievments == false ? undefined :
                    {
                        label: 'Достижения',
                        content: (
                            <TabItem>
                                <AchievementsListEditor {...props} onSave={arr => {
                                    onSave({achievements: arr})
                                }}/>
                            </TabItem>
                        )
                    }
            ].filter(x => (x != undefined))}/>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;

