import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ReactSlider from 'react-slider'

import './style.css'

export default function VolumeControl(props) {
    const {volume = 1.0, onChange = x => {

    }} = props;

    console.log('render: VolumeControl: volume = ', volume);

    return (
        <Wrapper>
            <HoverInnerPlaceholder>
                <ReactSlider orientation={'vertical'} value={volume} onChange={newVal => {
                    onChange(newVal);
                }} min={0} max={1.0} invert step={0.01} defaultValue={1.0} className="vertical-slider" thumbClassName="os-thumb" trackClassName="os-track" />
            </HoverInnerPlaceholder>
            <Cont>
                <SpeakerImg src={require('../images/speaker.svg')} />
            </Cont>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const SpeakerImg = styled.img`
    height: 16px;
`;

const Cont = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Wrapper}:hover &{
      display: none;
    }
`;

const HoverInnerPlaceholder = styled.div`
    visibility: hidden;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    height: 120px;
    
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    ${Wrapper}:hover &{
      display: block;
      visibility: visible;
    }
`;
