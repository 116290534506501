import NewsItemsAPI from '../../api/NewsItemsAPI'

import * as types from '../ActionTypes'

let loadNewsItems_ = () => {
    return {
        type: types.LOAD_NEWS_ITEMS
    }
}
let loadNewsItemsSuccess = (newsItems) => {
    return {
        type: types.LOAD_NEWS_ITEMS_SUCCESS,
        newsItems: newsItems
    }
}
let loadNewsItemsFailed = (error) => {
    return {
        type: types.LOAD_NEWS_ITEMS_FAIL,
        error: error
    }
}

//thunk
export function loadAllNewsItems() {
    return (dispatch, getState) => {
        dispatch(loadNewsItems_());
        return NewsItemsAPI.getAllNewsItems().then(
            newsItems => dispatch(loadNewsItemsSuccess(newsItems)),
            error => dispatch(loadNewsItemsFailed(error))
        )
    }
}

export function loadNewsItemById(id) {
    return (dispatch, getState) => {
        dispatch(loadNewsItems_());
        return NewsItemsAPI.getNewsItem(id).then(
            newsItem => dispatch(loadNewsItemsSuccess([newsItem])),
            error => dispatch(loadNewsItemsFailed(error))
        )
    }
}

let createNewsItem_ = () => {
    return {
        type: types.CREATE_NEWS_ITEM
    }
}
let createNewsItemSuccess = (newsItem) => {
    return {
        type: types.CREATE_NEWS_ITEM_SUCCESS,
        newsItem: newsItem
    }
}
let createNewsItemFailed = (error) => {
    return {
        type: types.CREATE_NEWS_ITEM_FAIL,
        error: error
    }
}

//thunk
export function createNewsItem(data) {
    return (dispatch, getState) => {
        dispatch(createNewsItem_());
        return NewsItemsAPI.createNewsItem(data).then(
            newsItem => dispatch(createNewsItemSuccess(newsItem)),
            error => dispatch(createNewsItemFailed(error))
        )
    }
}

let updateNewsItem_ = () => {
    return {
        type: types.UPDATE_NEWS_ITEM
    }
}
let updateNewsItemSuccess = (newsItem) => {
    return {
        type: types.UPDATE_NEWS_ITEM_SUCCESS,
        newsItem: newsItem
    }
}
let updateNewsItemFailed = (error) => {
    return {
        type: types.UPDATE_NEWS_ITEM_FAIL,
        error: error
    }
}

//thunk
export function updateNewsItem(data) {
    return (dispatch, getState) => {
        dispatch(updateNewsItem_());
        return NewsItemsAPI.updateNewsItem(data).then(
            newsItem => dispatch(updateNewsItemSuccess(newsItem)),
            error => dispatch(updateNewsItemFailed(error))
        )
    }
}

let deleteNewsItem_ = () => {
    return {
        type: types.DELETE_NEWS_ITEM
    }
}
let deleteNewsItemSuccess = (id) => {
    return {
        type: types.DELETE_NEWS_ITEM_SUCCESS,
        id: id
    }
}
let deleteNewsItemFailed = (error) => {
    return {
        type: types.DELETE_NEWS_ITEM_FAIL,
        error: error
    }
}

//thunk
export function deleteNewsItem(id) {
    return (dispatch, getState) => {
        dispatch(deleteNewsItem_());
        return NewsItemsAPI.deleteNewsItem(id).then(
            () => dispatch(deleteNewsItemSuccess(id)),
            error => dispatch(deleteNewsItemFailed(error))
        )
    }
}
