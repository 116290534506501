import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import FullScreenEmbedVideoModal from "./FullScreenEmbedVideoModal";
import FullScreenVideoModal from "./FullScreenVideoModal";
import VideoViewStatsDaemon from "../../players/tools/VideoViewStatsDaemon";

const ie = a => (a == undefined || a.trim() == '');

const getInitialVideoUrl = (props) => {
    let url = ['videoUrl', 'videoUrl720p', 'videoUrl480p', 'videoUrl1080p', 'videoUrl360p'].map(a => props[a]).filter(a => !ie(a))[0];
    return url;
};

export default function SmartCustomVideoModal(props) {
    let {
        videoUrl,
        videoUrl360p,
        videoUrl720p,
        videoUrl480p,
        videoUrl1080p,
        videoEmbedUrl,
        onClose = () => {

        },
        id
    } = props;
    const [selectedUrl, setSelectedUrl] = useState(getInitialVideoUrl(props));
    // console.log('SmartVideoModal: render: props = ', props);

    const dVideosOptions = [
        {
            url: videoUrl360p,
            type: 360
        },
        {
            url: videoUrl480p,
            type: 480
        },
        {
            url: videoUrl720p,
            type: 720
        },
        {
            url: videoUrl1080p,
            type: 1080
        }
    ].filter(a => (a.url != undefined && a.url.trim() != ''));


    console.log('SmartVideoModal: render: props = ', props);

    let url = ['videoUrl720p', 'videoUrl480p', 'videoUrl1080p', 'videoUrl360p', 'videoUrl'].map(a => props[a]).filter(a => !ie(a))[0];
    let isEmbed = (url == undefined);

    if (ie(videoEmbedUrl) == true && ie(url) == true) {
        return null;
    }
    if (selectedUrl == undefined) {
        return null;
    }

    return (
        <React.Fragment>
            <FullScreenVideoModal
                dVideosOptions={dVideosOptions}
                {...props}
                url={selectedUrl.replace('http', 'https')}
                onQualityChange={newUrl => {
                    setSelectedUrl(newUrl);
                }}
            />
            <VideoViewStatsDaemon videoId={props.id} />
        </React.Fragment>
    );
}

const Wrapper = styled.div`
    
`;
