import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'


export default function QualitySwitcher(props) {
    const {
        hdUrl = undefined,
        sdUrl = undefined,
        // = undefined,
        videoUrl1080p = undefined,

        selectedUrl = undefined,

        onSelect = u => {

        }

    } = props;

    let OPTIONS = [{label: '480p', value: sdUrl}, {label: '720p', value: hdUrl}, {label: '1080p', value: videoUrl1080p}];
    if (videoUrl1080p == undefined || videoUrl1080p == ''){
        OPTIONS = OPTIONS.filter(x => (x.label != '1080p'));
    }

    let selOption = (selectedUrl == videoUrl1080p) ? OPTIONS[2]: ((selectedUrl == hdUrl) ? OPTIONS[1] : OPTIONS[0]);
    let restOptions = OPTIONS.filter(xx => (xx.value != selOption.value));

    let selectedLabel = (selectedUrl == hdUrl) ? 'HD' : 'SD';

    let firstUrl = (selectedUrl == hdUrl) ? hdUrl : sdUrl;
    let secondUrl = (selectedUrl == hdUrl) ? sdUrl : hdUrl;

    let firstLabel = (selectedUrl == hdUrl) ? 'HD' : 'SD';
    let secondLabel = (selectedUrl == hdUrl) ? 'SD' : 'HD';

    return (
        <Wrapper>
            <HoverInnerPlaceholder>
                {OPTIONS.map((xx, i) => {
                    return (
                        <Item key={xx.label} selected={xx.value == selOption.value}
                            onClick={() => {
                                onSelect(xx.value);
                            }}
                        >
                            {xx.label}
                        </Item>
                    )
                })}
                {/*<Item selected={(secondUrl == selectedUrl)} onClick={() => {*/}
                {/*    onSelect(secondUrl);*/}
                {/*}}>*/}
                {/*    {secondLabel}*/}
                {/*</Item>*/}
                {/*<Item selected={(firstUrl == selectedUrl)} onClick={() => {*/}
                {/*    onSelect(firstUrl);*/}
                {/*}}>*/}
                {/*    {firstLabel}*/}
                {/*</Item>*/}
            </HoverInnerPlaceholder>
            <Cont>
                {selOption.label}
            </Cont>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Item = styled.div`
  background: ${props => (props.selected == true ? '#6a8baf' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
    background: #6a8baf;
    color: white;
  }
`;

const Cont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HoverInnerPlaceholder = styled.div`
  display: none;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  background: white;

  ${Wrapper}:hover & {
    display: block;
  }
`;
