import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ChessTemplate from '../templates/ChessTemplate'
import TeacherPersonalPanel from "../teachers/panels/TeacherPersonalPanel";
import TeacherEducationPanel from "../education/panels/TeacherEducationPanel";

export default function TeacherProgressApp(props) {
    const dispatch = useDispatch();
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    return (
        <ChessTemplate active={'progress'}>

            <TeacherEducationPanel/>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
