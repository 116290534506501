import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import CustomPlayer from "../players/CustomPlayer";
import {DARK_SIDE} from "../ui/Colors";


export default function VideoApp(props) {
    let {hash} = props.match.params;
    let url = window.atob(hash);

    const [shouldPlay, setShouldPlay] = useState(false);

    if (url.indexOf('embed') > -1) {
        return (
            <div style={{
                boxSizing: 'border-box',
                width: '100vw',
                height: '100vh'
            }}>
                <iframe style={{border: 'none', width: '100%', height: '100%'}} src={url}
                        frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen
                        allowFullScreen></iframe>
            </div>
        )
    }

    return (
        <Wrapper>

            {shouldPlay == false ?

                <div style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>

                    <ImagePlaceholder style={{marginBottom: 50}}>
                        <Img src={require('../../assets/images/top_logo_chev.svg')}/>
                    </ImagePlaceholder>

                    <Button onClick={() => {
                        setShouldPlay(true);
                    }}>
                        Посмотреть видео
                    </Button>
                </div>

                :
                <div style={{width: window.innerWidth, height: window.innerHeight}}>
                    <CustomPlayer id={'ddf-df'}
                                  sdUrl={url}
                                  hdUrl={url}
                    />
                </div>

            }


        </Wrapper>
    );
}


const ImagePlaceholder = styled.div`
  height: 70px;
  text-align: center;
`;

const SubImagePlaceholder = styled.div`
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 18px;
  color: #474d53;
  margin-top: 40px;
`;

const Img = styled.img`
  height: 100%;
  display: inline-block;
`;


export const Button = styled.div`
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
  background: ${props => (props.disabled == true ? 'lightgrey' : DARK_SIDE)};
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
  margin: 4px;

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
