import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import DocumentsPanel from "../documents/panels/DocumentsPanel";

export default function CoursesApp(props) {

    return (
        <ChessTemplate active={'documents'}>

            <DocumentsPanel/>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
