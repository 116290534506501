import ThemesAPI from '../../api/ThemesAPI'

import * as types from '../ActionTypes'

let loadThemes_ = () => {
    return {
        type: types.LOAD_THEMES
    }
}
let loadThemesSuccess = (themes) => {
    return {
        type: types.LOAD_THEMES_SUCCESS,
        themes: themes
    }
}
let loadThemesFailed = (error) => {
    return {
        type: types.LOAD_THEMES_FAIL,
        error: error
    }
}

//thunk
export function loadAllThemes() {
    return (dispatch, getState) => {
        dispatch(loadThemes_());
        return ThemesAPI.getAllThemes().then(
            themes => dispatch(loadThemesSuccess(themes)),
            error => dispatch(loadThemesFailed(error))
        )
    }
}

export function loadThemeById(id) {
    return (dispatch, getState) => {
        dispatch(loadThemes_());
        return ThemesAPI.getTheme(id).then(
            theme => dispatch(loadThemesSuccess([theme])),
            error => dispatch(loadThemesFailed(error))
        )
    }
}

let createTheme_ = () => {
    return {
        type: types.CREATE_THEME
    }
}
let createThemeSuccess = (theme) => {
    return {
        type: types.CREATE_THEME_SUCCESS,
        theme: theme
    }
}
let createThemeFailed = (error) => {
    return {
        type: types.CREATE_THEME_FAIL,
        error: error
    }
}

//thunk
export function createTheme(data) {
    return (dispatch, getState) => {
        dispatch(createTheme_());
        return ThemesAPI.createTheme(data).then(
            theme => dispatch(createThemeSuccess(theme)),
            error => dispatch(createThemeFailed(error))
        )
    }
}

let updateTheme_ = () => {
    return {
        type: types.UPDATE_THEME
    }
}
let updateThemeSuccess = (theme) => {
    return {
        type: types.UPDATE_THEME_SUCCESS,
        theme: theme
    }
}
let updateThemeFailed = (error) => {
    return {
        type: types.UPDATE_THEME_FAIL,
        error: error
    }
}

//thunk
export function updateTheme(data) {
    return (dispatch, getState) => {
        dispatch(updateTheme_());
        return ThemesAPI.updateTheme(data).then(
            theme => dispatch(updateThemeSuccess(theme)),
            error => dispatch(updateThemeFailed(error))
        )
    }
}

let deleteTheme_ = () => {
    return {
        type: types.DELETE_THEME
    }
}
let deleteThemeSuccess = (id) => {
    return {
        type: types.DELETE_THEME_SUCCESS,
        id: id
    }
}
let deleteThemeFailed = (error) => {
    return {
        type: types.DELETE_THEME_FAIL,
        error: error
    }
}

//thunk
export function deleteTheme(id) {
    return (dispatch, getState) => {
        dispatch(deleteTheme_());
        return ThemesAPI.deleteTheme(id).then(
            () => dispatch(deleteThemeSuccess(id)),
            error => dispatch(deleteThemeFailed(error))
        )
    }
}
