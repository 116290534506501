import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import {BLUE, DARK_SIDE} from "../../ui/Colors";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

import {Map} from 'immutable'

const getTaskId = x => {
    if (x == undefined) {
        return undefined;
    }
    if (x.task == undefined) {
        return undefined
    }
    return x.task.id;
}

const getStudentId = x => {
    if (x == undefined) {
        return undefined;
    }
    if (x.student == undefined) {
        return undefined
    }
    return x.student.id;
}

const getSortedStudents = (students) => {
    return students.sort((a, b) => {
        let s1 = CommonHelper.getUserName(a);
        let s2 = CommonHelper.getUserName(b);
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    })
}

export default function SubmissionsTable(props) {
    let {
        enhancedSubmissions = [],
        selectedId = undefined,
        onSelect = id => {

        },
        hasBdzFilter = false,
        usersMap = Map(),
        tasks = []
    } = props;

    const sortedItems = enhancedSubmissions;
    let tasksMap = sortedItems.reduce((mp, x) => mp.set(getTaskId(x), x.task), Map());
    let allTasks = tasksMap.toArray().sort((a, b) => (+a.timestamp - +b.timestamp));
    let allStudents = sortedItems.reduce((mp, x) => mp.set(getStudentId(x), x.student), Map()).toArray().sort((a, b) => (+a.timestamp - +b.timestamp));
    allStudents = getSortedStudents(allStudents);
    let studentSubmissionsMap = useMemo(() => {
        let mp = Map();
        for (let i in enhancedSubmissions) {
            let sub = enhancedSubmissions[i];
            let studentId = getStudentId(sub);
            if (mp.get(studentId) == undefined) {
                mp = mp.set(studentId, []);
            }
            mp = mp.set(studentId, mp.get(studentId).concat([sub]));
        }
        return mp;
    }, []);


    return (
        <Wrapper>

            <Table>
                <Thead>
                    <Tr>
                        <Th style={{width: 200}}>
                            Имя
                        </Th>
                        {allTasks.map(((a, i) => {
                            return (
                                <Th key={i}>
                                    {a.name}
                                </Th>
                            )
                        }))}
                    </Tr>
                </Thead>


                <Tbody>
                    {allStudents.map((a, k) => {
                        let selected = (selectedId == a.id);
                        let submissions = studentSubmissionsMap.get(a.id);
                        return (
                            <Tr selected={selected} key={a.id} onClick={() => {

                            }}>
                                <Td style={{cursor: 'pointer'}} onClick={() => {
                                    CommonHelper.linkTo(`/student/${a.id}`, true);
                                }}>
                                    {CommonHelper.getUserName(a)}
                                </Td>

                                {allTasks.map(((a, i) => {
                                    let subm = submissions.filter(x => (a.id == getTaskId(x)))[0];
                                    return (
                                        <ItemTd key={i}
                                                hasSomething={subm != undefined}
                                                hasMark={subm != undefined && subm.mark != undefined}

                                                style={{cursor: (subm == undefined ? 'default' : 'pointer')}}
                                                onClick={() => {
                                                    if (subm == undefined) {
                                                        return;
                                                    }
                                                    onSelect(subm.id);
                                                }}>
                                            {/*{JSON.stringify(subm)}*/}
                                            {subm == undefined || subm.mark == undefined ? '-' : subm.mark}
                                        </ItemTd>
                                    )
                                }))}

                            </Tr>
                        )
                    })}
                </Tbody>


            </Table>

        </Wrapper>
    );
}

const ItemTd = styled.td`
  padding: 5px;
  border: 1px solid lightgrey;
  font-size: 14px;
  background: ${props => (props.hasSomething == true ? (props.hasMark == true ? '#00800024' : '#f5deb38a') : 'white')};
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;

const Tr = styled.tr`
  background: ${props => (props.selected == true ? BLUE : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};

  :hover {

  }
`;

const Th = styled.th`
  padding: 5px;
  border: 1px solid lightgrey;
`;

const Td = styled.td`
  padding: 5px;
  border: 1px solid lightgrey;
  font-size: 14px;
  background: ${props => (props.hasSomething == true ? '#f5deb38a' : 'white')};
`;

const AddressTd = styled.td`
  padding: 5px;
  border: 1px solid lightgrey;
  font-size: 14px;
  min-width: 300px;
`;


const Wrapper = styled.div`

`;


const SubmissionsListPlaceholder = styled.div`

`;

const SubItem = styled.div`
  margin-bottom: 10px;
  background: white;
  padding: 10px;
  border-radius: 4px;
  background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: pointer;
`;

const SubTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

const Left = styled.div`
  max-width: 80%;
`;

const Right = styled.div`
  text-align: right;
`;

const StudentInfo = styled.div`

`;

const StudentName = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

const CenterName = styled.div`
  opacity: 0.5;
  font-size: 11px;
  font-style: italic;
`;

const ClassSpan = styled.span`
  background-color: ${DARK_SIDE};
  color: white;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  margin-left: 5px;
  opacity: 0.85;
  font-weight: normal;
  font-size: 12px;
`;

const TaskName = styled.div`
  text-align: right;
  font-weight: bold;
`;

const MarkPlaceholder = styled.div`
  display: inline-block;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  border: 1px dashed lightgrey;
`;

const StatusImg = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;
