import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import RussiaAPI from "../../../api/RussiaAPI";
import CenterForm from "../forms/CenterForm";

import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";

import Tabs, {TabItem} from "../../ui/Tabs";
import * as usersActions from "../../../redux/actions/UsersActions";
import PointSelector from "../../tools/PointSelector";
import CentersMapView from "../view/CentersMapView";
import CentersViewPanel from "./CentersViewPanel";
import CenterInfoTool from "../view/CenterInfoTool";
import * as programsActions from "../../../redux/actions/ProgramsActions";

import FullPagePreloader from '../../preloader/FullPagePreloader'
import CenterProgressTool from "../../education/tools/CenterProgressTool";
import CommonHelper from "../../../helpers/CommonHelper";
import UpdateUserForm from "../../users/forms/UpdateUserForm";

export default function CentersPanel(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    let {currentUser, getUser, allCenters} = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            getUser: x => state.users.usersMap.get(x),
            allCenters: state.centers.centersMap.toArray()
        }
    }, []));
    const dispatch = useDispatch();
    const [updT, setUpdT] = useState(0);

    const load = useCallback(() => {
        setLoading(true);
        RussiaAPI.getItems().then(arr => {
            setItems(arr.sort((a, b) => (+a.number - +b.number)));
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        load();
    }, []);

    const [selectedUserId, setSelectedUserId] = useState(undefined);
    let selectedStudent = (selectedUserId == undefined) ? undefined : getUser(selectedUserId);

    let selectedItem = (selectedId == undefined) ? undefined : items.filter(a => (a.id == selectedId))[0];
    if (items.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }



    return (
        <Wrapper>

            <Tabs
                tabs={[
                    {
                        label: 'Редактирование',
                        content: (
                            <TabItem>
                                <ListPlaceholder>
                                    {items.map((it, k) => {
                                        return (
                                            <ItemOuter key={it.id}>
                                                <Item selected={(it.id == selectedId)} onClick={() => {
                                                    setSelectedId(it.id);
                                                }}>
                                                    <CenterInfoTool {...it} />

                                                </Item>
                                                <ItemControlsPlaceholder>
                                                    {k == 0 ? null :
                                                        <ArrowImg
                                                            style={{transform: `rotate(180deg)`}}
                                                            src={require('../../../assets/images/arrow-point-to-down.svg')}
                                                            onClick={async () => {
                                                                setLoading(true);
                                                                let i1 = k - 1, i2 = k;
                                                                let id1 = items[i1].id;
                                                                let id2 = items[i2].id;
                                                                let number1 = items[i2].number;
                                                                let number2 = items[i1].number;
                                                                await RussiaAPI.saveItem({id: id1, number: number1});
                                                                await RussiaAPI.saveItem({id: id2, number: number2});
                                                                load();
                                                            }}
                                                        />
                                                    }
                                                    {k == items.length - 1 ? null :
                                                        <ArrowImg
                                                            src={require('../../../assets/images/arrow-point-to-down.svg')}
                                                            onClick={async () => {
                                                                setLoading(true);
                                                                let i1 = k, i2 = k + 1;
                                                                let id1 = items[i1].id;
                                                                let id2 = items[i2].id;
                                                                let number1 = items[i2].number;
                                                                let number2 = items[i1].number;
                                                                await RussiaAPI.saveItem({id: id1, number: number1});
                                                                await RussiaAPI.saveItem({id: id2, number: number2});
                                                                load();
                                                            }}
                                                        />
                                                    }

                                                </ItemControlsPlaceholder>
                                            </ItemOuter>

                                        )
                                    })}
                                </ListPlaceholder>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Превью',
                        content: (
                            <TabItem>
                                <CentersViewPanel/>
                            </TabItem>
                        )
                    }

                ]}
            />

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить центр
                </Button>
            </TopPlaceholder>


            <Sidebar width={Math.min(1200, window.innerWidth)} visible={(selectedItem != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>
                {selectedItem == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Информация',
                                content: (
                                    <TabItem>
                                        <CenterForm {...selectedItem} loading={loading} onSave={async d => {
                                            setLoading(true);
                                            await RussiaAPI.saveItem({...d, id: selectedItem.id});
                                            setItems(await RussiaAPI.getItems());
                                            setLoading(false);
                                        }}
                                                    onDelete={async () => {
                                                        setLoading(true);
                                                        setItems(await RussiaAPI.deleteItem(selectedItem.id));
                                                        setSelectedId(undefined);
                                                        setLoading(false);
                                                    }}
                                                    canDelete={true}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Точка на карте',
                                content: (
                                    <TabItem>
                                        <PointSelector {...selectedItem}
                                                       radius={100.0 * 12 / 920}
                                                       onChange={async pt => {
                                                           setLoading(true);
                                                           await RussiaAPI.saveItem({...pt, id: selectedItem.id});
                                                           setItems(await RussiaAPI.getItems());
                                                           setLoading(false);
                                                       }}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Успеваемость',
                                content: (
                                    <TabItem>
                                        <CenterProgressTool centerId={selectedItem.id} onStudentSelect={x => {
                                            setSelectedUserId(x);
                                        }} />
                                    </TabItem>
                                )
                            }
                        ]}/>

                    </div>
                }
            </Sidebar>

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>
                {addModalVisible == false ? null :
                    <div>

                        <CenterForm loading={loading} onSave={async d => {
                            setLoading(true);
                            await RussiaAPI.saveItem(d);
                            setItems(await RussiaAPI.getItems());
                            setLoading(false);
                            setAddModalVisible(false);
                        }}
                        />
                    </div>
                }
            </Sidebar>

            <FullPagePreloader visible={loading}/>

            <Sidebar visible={(selectedStudent != undefined)} onCloserClick={() => {
                setSelectedUserId(undefined);
                // dispatch(usersActions.loadAllUsers()); // todo: optimize
            }}>
                {selectedUserId == undefined ? null :
                    <div>
                        <UpdateUserForm
                            {...selectedStudent}
                            loading={loading}
                            showStudentInfo={true}
                            showParentInfo={true}
                            avatarVisible={true}
                            passwordVisible={false}
                            emailVisible={false}
                            allCenters={allCenters}
                            canChangeCenter={(currentUser != undefined && currentUser.userRole == 'admin')}
                            onSave={async data => {
                                await dispatch(usersActions.updateUser({
                                    ...data,
                                    id: selectedStudent.id
                                }));
                                setSelectedUserId(undefined);
                                setUpdT(+new Date());
                            }}
                            onDelete={async () => {
                                await dispatch(usersActions.deleteUser(selectedStudent.id));
                                CommonHelper.linkTo(`/students`);
                            }}
                            canDelete={(currentUser != undefined && (currentUser.userRole == 'admin'))}
                        />
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    flex: 1;
`;

const ItemOuter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ItemControlsPlaceholder = styled.div`
    width: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
`;


const Name = styled.div`
    font-weight: bold;
`;

const TopPlaceholder = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ArrowImg = styled.img`
    display: inline-block;
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0.45;
    :hover{
      opacity: 1;
    }
`;
