import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";

import Spin from 'arui-feather/spin'

export default function UpdateAttachmentsTool(props) {
    const {
        items = [],
        onChange = newitems => {

        },
        loading = false,
        hasInstruction = true
    } = props;

    return (
        <Wrapper>

            {items.map((it, k) => {
                return (
                    <Row key={`${k}_${it.id}`}>
                        <Left>
                            <a href={`${it.url}`} target={'_blank'}>
                                {`файл ${+k + 1}`}
                            </a>
                        </Left>
                        <Right>
                            <ControlsPlaceholder>
                                <DeleteImage src={require('../../../assets/images/delete_.svg')} onClick={() => {
                                    if (CommonHelper.uSure(['вы уверены?']) == false) {
                                        return;
                                    }
                                    onChange(items.filter(a => (a.id != it.id)));
                                }}/>
                            </ControlsPlaceholder>
                        </Right>
                    </Row>
                )
            })}

            <BottomPlaceholder>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <SimpleUploadPanel onUploaded={url => {
                        // let newItems = items.concat([
                        //     {
                        //         id: uuid(),
                        //         url: url,
                        //         t: +new Date()
                        //     }
                        // ]);
                        let newItems = [{
                            id: uuid(),
                            url: url,
                            t: +new Date()
                        }];
                        onChange(newItems);
                    }}>
                        <UploadInner>
                            {items.length == 0 ? 'загрузить файл с решением' : 'перезалить файл с решением'}
                        </UploadInner>
                    </SimpleUploadPanel>
                }
            </BottomPlaceholder>

            {hasInstruction == false ? null :
                <BottomInstructionsPlaceholder>

                    {items.length == 0 ? null :
                        <p>
                            Вы уже отправили свое решение. Но до истечения срока сдачи вы можете загрузить решение
                            заново.
                        </p>
                    }

                    <p>
                        Домашнее задание необходимо выполнить от руки на листах А4 или в тетрадке в клеточку,
                        затем сфотографировать
                        или отсканировать. Получившиеся файлы соединить и переконвертировать <b>в один файл
                        pdf</b>.
                    </p>
                    <p>
                        Убедиться (желательно на другом устройстве), что файл читается и текст можно разобрать.
                    </p>
                </BottomInstructionsPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
`;

const Left = styled.div`
    
`;

const Right = styled.div`
    
`;

const ControlsPlaceholder = styled.div`
    
`;

const DeleteImage = styled.img`
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;

const BottomPlaceholder = styled.div`
    
`;

const BottomInstructionsPlaceholder = styled.div`
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid lightgrey;
`;

const UploadInner = styled.div`
    background-color: whitesmoke;
    border: 1px dashed grey;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    padding: 15px;
    :hover{
      opacity: 1;
    }
`;
