import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import SimpleUploadPanel from "../upload/SimpleUploadPanel";
import MonthCalendar from "../calendars/MonthCalendar";
import TimePicker from "../ui/TimePicker";
import DateTimePicker from "../ui/DateTimePicker";

import axios from 'axios'
import {API_ENDPOINT} from "../../constants/config";
import WeekCalendar from "../calendars/WeekCalendar";
import TexViewer from "../katex/tools/TexViewer";
import RealtimeTestBoard from "../realtime/RealtimeTestBoard";
import YoutubePlayer from "../players/YoutubePlayer";
import RussiaMap from "../maps/tools/RussiaMap";
import VideoBackground from "../video/tools/VideoBackground";
import PointSelector from "../tools/PointSelector";
import OsUploadPanel from "../upload/OsUploadPanel";
import BulkUsersCreationTool from "../automatic/tools/BulkUsersCreationTool";
import QualitySwitcher from "../video/tools/QualitySwitcher";
import VideoViewStatsDaemon from "../players/tools/VideoViewStatsDaemon";
import CustomPlayer from "../players/CustomPlayer";


export default function DevApp() {
    const [pieces, setPieces] = useState([]);
    const [t, setT] = useState(undefined);


    return (
        <Wrapper>

            <div style={{width: 1200, height: 800}}>
                <CustomPlayer id={'88306f49-0c5a-439a-8eb6-2d868a67a808'}
                              sdUrl={`https://osmedia.mipt.ru/s/bHmfHOuf46Yejip/download`}
                              hdUrl={`https://osmedia.mipt.ru/s/49q0OcfqwTv7gfC/download`}
                />
            </div>

            {/*<VideoViewStatsDaemon/>*/}

            {/*<QualitySwitcher/>*/}

            {/*<BulkUsersCreationTool/>*/}

            {/*<SimpleUploadPanel onUploaded={url => {*/}
            {/*console.log('url = ', url);*/}
            {/*}} >*/}
            {/*<div>*/}
            {/*upload file*/}
            {/*</div>*/}
            {/*</SimpleUploadPanel>*/}

            {/*<PointSelector  />*/}


            {/*<OsUploadPanel>*/}
            {/*<div>*/}
            {/*test upload*/}
            {/*</div>*/}
            {/*</OsUploadPanel>*/}
            {/*<VideoBackground>*/}
            {/*<div>*/}
            {/*test*/}
            {/*</div>*/}
            {/*</VideoBackground>*/}

            {/*<div style={{width: 240, height: 300}} >*/}
            {/*<DateTimePicker timestamp={t} onChange={setT} />*/}
            {/*</div>*/}

            {/*<div style={{backgroundColor: 'white', height: 400, width: 720, margin: '0 auto', marginTop: 10}} >*/}
            {/*<MonthCalendar/>*/}
            {/*</div>*/}

            {/*<div style={{backgroundColor: 'white', width: 920, margin: '0 auto', marginTop: 10}} >*/}
            {/*/!*<WeekCalendar/>*!/*/}
            {/*</div>*/}

            {/*<RealtimeTestBoard/>*/}

            {/*<YoutubePlayer/>*/}

            {/*<RussiaMap/>*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
