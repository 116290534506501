import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'

import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import './style.css'

import Select from 'react-select'

import isEmail from 'validator/lib/isEmail'

export default function StyledSignupForm(props) {
    const {
        onSubmit = () => {

        },
        onLoginClick = () => {
        },
        onLostPasswordClick = () => {
        }, loading = false
    } = props;
    const [errorMessages, setErrorMessages] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [schools, setSchools] = useState([]);
    const [phone, setPhone] = useState('');
    const [schoolId, setSchoolId] = useState(undefined);
    const [human, setHuman] = useState(false);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/schools`).then(d => d.data).then(arr => {
            setSchools(arr);
        })
    }, []);
    if (schools.length == 0) {
        return null;
    }
    let canSubmit = (human != false);
    let getFldError = fld => {
        let fs = errorMessages.filter(a => (a.field.indexOf(fld) > -1));
        if (fs.length == 0) {
            return undefined;
        }
        return fs[0].message;
    };
    console.log('render: errorMessages = ', errorMessages);

    return (
        <Wrapper>

            <TopPlaceholder>

                <LogoPlaceholder>
                    <Logo src={require('../../../assets/images/hv_s_no_bg.png')}/>
                </LogoPlaceholder>
                <LinedText text={'Регистрация'}/>
            </TopPlaceholder>

            <ContentPlaceholder>

                <Field>
                    <Left>
                        <Input value={firstName} placeholder={'Имя'} onChange={e => {
                            setFirstName(e.target.value);
                        }}/>
                        {getFldError('firstName') == undefined ? null :
                            <ErrorMessage>
                                {getFldError('firstName')}
                            </ErrorMessage>
                        }
                    </Left>
                    <Right>
                        <Input value={lastName} placeholder={'Фамилия'} onChange={e => {
                            setLastName(e.target.value);
                        }}/>
                        {getFldError('lastName') == undefined ? null :
                            <ErrorMessage>
                                {getFldError('lastName')}
                            </ErrorMessage>
                        }
                    </Right>
                </Field>

                <Field>

                    <Left>
                        <Input value={email} type={'email'} placeholder={'Ваш E-Mail'} onChange={e => {
                            setEmail(e.target.value);
                        }}/>
                        {getFldError('email') == undefined ? null :
                            <ErrorMessage>
                                {getFldError('email')}
                            </ErrorMessage>
                        }
                        {/*{(isEmail(email) == true || email.trim().length < 5) ? null :*/}
                        {/*<ErrorMessage>*/}
                        {/*Введен некорректный e-mail*/}
                        {/*</ErrorMessage>*/}
                        {/*}*/}
                    </Left>
                    <Right>
                        <Input value={phone} placeholder={'Телефон'} onChange={e => {
                            setPhone(e.target.value);
                        }}/>
                        {getFldError('phone') == undefined ? null :
                            <ErrorMessage>
                                {getFldError('phone')}
                            </ErrorMessage>
                        }
                    </Right>
                </Field>

                {schools == undefined || schools.length == 0 ? null :
                    <Field>
                        <Select className={'school_selector'} classNamePrefix={'school'} options={schools.map(sc => {
                            return {
                                label: `${sc.city}, ${sc.name}`,
                                value: sc.id
                            }
                        })} onChange={a => {
                            setSchoolId(a.value);
                        }} placeholder={'Школа'} isSearchable={true}
                                noOptionsMessage={() => `Школа не найдена`}
                        />
                        {getFldError('schoolId') == undefined ? null :
                            <ErrorMessage>
                                {getFldError('schoolId')}
                            </ErrorMessage>
                        }
                    </Field>
                }


                <Field>
                    <Left>
                        <Input value={password} type={'password'} placeholder={'Пароль'} onChange={e => {
                            setPassword(e.target.value.trim());
                        }}/>
                        {getFldError('password') == undefined ? null :
                            <ErrorMessage>
                                {getFldError('password')}
                            </ErrorMessage>
                        }
                    </Left>
                    <Right>
                        <Input value={passwordConfirmation} type={'password'} placeholder={'Повторить пароль'}
                               onChange={e => {
                                   setPasswordConfirmation(e.target.value);
                               }}/>
                        {getFldError('passwordConfirmation') == undefined ? null :
                            <ErrorMessage>
                                {getFldError('passwordConfirmation')}
                            </ErrorMessage>
                        }
                    </Right>
                </Field>

                <Field style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    height: 50
                }}>
                    <span style={{marginRight: 5}}>
                        <SquareCheckImage
                            onClick={() => {
                                setHuman(!human);
                            }}
                            src={(human == true) ? require('../../../assets/images/check.svg') : require('../../../assets/images/check-box-empty.svg')}/>
                    </span>
                    <span>
                        Я даю согласие на обработку своих персональных данных
                    </span>
                </Field>

                <Field>
                    <Button disabled={!canSubmit} onClick={() => {
                        const ie = a => (a == undefined || a.trim() == '');
                        let errorMessages_ = [];
                        if (schoolId == undefined) {
                            errorMessages_.push({field: 'schoolId', message: 'Не выбрана школа'});
                        }
                        if (isEmail(email) == false) {
                            errorMessages_.push({field: 'email', message: 'Введен некорректный e-mail'});
                        }
                        if (password.length < 6) {
                            errorMessages_.push({field: 'password', message: 'Длина пароля должна быть от 6 символов'});
                        }
                        if (ie(firstName) == true) {
                            if (password.length < 6) {
                                errorMessages_.push({field: 'firstName', message: 'Не задано имя'});
                            }
                        }
                        if (ie(lastName) == true) {
                            if (password.length < 6) {
                                errorMessages_.push({field: 'lastName', message: 'Не задана фамилия'});
                            }
                        }
                        if (ie(phone) == true || phone.length < 6) {
                            if (password.length < 6) {
                                errorMessages_.push({field: 'phone', message: 'Введен некорректный телефон'});
                            }
                        }
                        if (password != passwordConfirmation) {
                            errorMessages_.push({
                                field: 'password,passwordConfirmation',
                                message: 'Пароли не совпадают'
                            });
                        }
                        setErrorMessages(errorMessages_);
                        // if (errorMessages_.length > 0) {
                        //     return window.alert(errorMessages_.map(a => a.message).join(', '));
                        // }
                        // if (password != passwordConfirmation || human == false || (ie(firstName) == true) || ie(lastName) == true || password.length < 4) {
                        //     return;
                        // }
                        if (errorMessages_.length > 0) {
                            return;
                        }
                        onSubmit(
                            {
                                email: email.trim().toLowerCase(),
                                password: password.trim(),
                                phone: phone.trim(),
                                schoolId,
                                firstName: firstName.trim(),
                                lastName: lastName.trim()
                            })
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Зарегистрироваться'
                        }
                    </Button>
                </Field>

                <VeryBottomPlaceholder>
                    <PersonalDataPlaceholder>
                        <div style={{opacity: 0.5}}>
                            Нажимая кнопку «Зарегистрироваться», вы принимаете
                        </div>
                        <div style={{fontWeight: 'bold'}}>
                            <BrLink href={'https://google.com'} target={'_blank'}>
                                условия обработки персональных данныx
                            </BrLink>
                        </div>
                    </PersonalDataPlaceholder>
                </VeryBottomPlaceholder>

            </ContentPlaceholder>

            <BottomPlaceholder>
                <Link onClick={() => {
                    onLostPasswordClick();
                }}>
                    Забыли пароль?
                </Link>
                <Link onClick={() => {
                    onLoginClick();
                }}>
                    Вход
                </Link>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    //width: 100%;
    //height: 100%;
    padding: 40px;
    box-sizing: border-box;
    width: 620px;
    @media(max-width: 720px){
      width: 100vw;
    }
`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

const ContentPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;


const LogoPlaceholder = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: -30px;
`;

const Logo = styled.img`
    display: inline-block;
    width: 100px;
`;

const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    padding-right: 5px;
    width: 50%;
`;

const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: 5px;
    width: 50%;
`;

const VeryBottomPlaceholder = styled.div`
    
`;

const PersonalDataPlaceholder = styled.div`
    text-align: center;
    font-size: 12px;  
    color: #999999;
`;

const SquareCheckImage = styled.img`
    height: 18px;
    width: 18px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const BrLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: black;
`;

const ErrorMessage = styled.div`
    font-size: 12px;
    color: red;
    text-align: center;
`;
