import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import Spin from 'arui-feather/spin'

import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

export default function ClassDescriptionForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);


    return (
        <Wrapper>

            <Field>
                <Textarea
                        style={{minHeight: '20em'}}
                            placeholder={'Программа'}
                          value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({description: description})
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;
