import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TransactionsAPI = {

    getAllTransactions() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/transactions/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getStudentTransactions(studentId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/money_transactions/user/${studentId}`).then(d => d.data).then(transactions => {
                console.log('loaded transactions of user: transactions = ', transactions);
                if (transactions.error != undefined) {
                    return reject(transactions.error);
                }
                resolve(transactions);
            }).catch(err => reject(err));
        });
    },

    getStudentsTransactionsByIdsAndStatus(usersIds, status) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/transactions_of_users/status/${status}`, {
                usersIds: usersIds
            }).then(d => d.data).then(transactions => {
                console.log('loaded transactions of user: transactions = ', transactions);
                if (transactions.error != undefined) {
                    return reject(transactions.error);
                }
                resolve(transactions);
            }).catch(err => reject(err));
        });
    },

    buyCashSubscription(studentId, subscriptionId) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/cash_transactions/${studentId}/buy_subscription/${subscriptionId}`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getLatestTransactions() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/money_transactions/latest/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getTransactionById(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/money_transaction/${id}`).then(d => d.data).then(transaction => {
                if (transaction.error != undefined) {
                    return reject(transaction.error);
                }
                resolve(transaction);
            }).catch(err => reject(err));
        });
    },


    createTransaction(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/money_transactions/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateTransaction(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/money_transactions/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    acceptCashTransaction(transactionId) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/money_transactions/accept_cash_transaction/${transactionId}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteTransaction(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/money_transactions/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default TransactionsAPI;
