import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

export default function RealtimeTestBoard(props) {
    const {id = 'sabir'} = props;
    const eventSource = useRef();
    const savedMessages = useRef([]);
    const [lastActivityTimestamp, setLastActivityTimestamp] = useState(+new Date());

    const handleNewMessage = useCallback((msg) => {
        console.log('got msg = ', msg);
        savedMessages.current.push(msg);
        console.log('savedMessages.current = ', savedMessages.current);
        setLastActivityTimestamp(+new Date());
    }, [id]);

    useEffect(() => {
        eventSource.current = new window.EventSource(`https://physics-api.sabir.pro/sub?id=${id}`);
        savedMessages.current = [];
        eventSource.current.addEventListener('message', e => {
            console.log('setMessagesEvent occured! e = ', e);
            handleNewMessage(e.data);
        });
        return () => {
            try {
                console.log('closing event source!');
                eventSource.current.close();
            } catch (exc) {
                console.log('can not close event source');
            }
        }
    }, [id]);

    return (
        <Wrapper>
            <div>
                RealtimeTestBoard
            </div>
            {JSON.stringify(savedMessages.current)}
            <br/>
            {JSON.stringify(lastActivityTimestamp)}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
