import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import {GRADES_OPTIONS} from "../../courses/forms/UpdateCourseForm";

import Select from 'react-select'
import CommonHelper from "../../../helpers/CommonHelper";
import ViewSubmissionTool from "../../submissions/tools/ViewSubmissionTool";
import * as usersActions from "../../../redux/actions/UsersActions";
import UpdateUserForm from "../../users/forms/UpdateUserForm";
import SubjectCommentsPanel from "../../comments/panels/SubjectCommentsPanel";

import {CR_OPTS, CR_OPTS_FULL} from "../../templates/MySidebar";

const YEARS_OPTIONS = [
    {
        label: '2019/2020',
        value: 2020
    },
    {
        label: '2020/2021',
        value: 2021
    },
    {
        label: '2021/2022',
        value: 2022
    }
]


export default function CenterProgressTool(props) {
    const {
        centerId, onStudentSelect = x => {

        }
    } = props;
    const [data, setData] = useState(undefined);
    const [currentYearTimestamp, setCurrentYearTimestamp] = useState(+new Date());
    const [classNumber, setClassNumber] = useState(8);
    const [discipline, setDiscipline] = useState('phys');

    const [selectedUserId, setSelectedUserId] = useState(undefined);

    const [selectedSubmission, setSelectedSubmission] = useState(undefined);

    const [rankingsMap, setRankingsMap] = useState({});

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/learning/stats`, {
            params: {
                centerId: centerId
            }
        }).then(d => d.data.result).then(pld => {
            setData(pld);
            axios.get(`${API_ENDPOINT}/students_rankings_map`).then(d => d.data.result).then(z => {
                setRankingsMap(z);
            });
        })
    }, [classNumber, centerId]);

    if (data == undefined) {
        return (
            <Code/>
        )
    }

    let {students = [], teachers = [], tasks = [], subsMap = {}} = data;
    let fromTimestamp = moment(currentYearTimestamp).startOf('year').add(-1, 'years').startOf('year').add(8, 'months').startOf('day');
    let toTimestamp = moment(currentYearTimestamp).startOf('year').add(6, 'months').startOf('day');

    let filteredStudents = students
        .filter(a => (a.classNumber == classNumber && a.status != 'archived'))
        .map(a => ({...a, name: CommonHelper.getShortNameByUser(a)}))
        .sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });

    let filteredTasks = tasks.filter(a => (+a.startTimestamp >= +fromTimestamp && +a.startTimestamp <= +toTimestamp && a.classNumber == classNumber))
        .sort((a, b) => (+a.startTimestamp - +b.startTimestamp));

    let selectedStudent = (selectedUserId == undefined) ? undefined : students.filter(a => (a.id == selectedUserId))[0];


    return (
        <Wrapper>

            <TopPlaceholder>
                <TopLeft>
                    <div style={{width: 200}} >
                        <Select options={GRADES_OPTIONS}
                                value={GRADES_OPTIONS.filter(a => (a.value == classNumber))[0]}
                                onChange={a => {
                                    setClassNumber(a.value);
                                }}/>
                    </div>


                    <div style={{width: 200}} >
                        <Select options={CR_OPTS_FULL}
                                value={CR_OPTS_FULL.filter(a => (a.value == discipline))[0]}
                                onChange={a => {
                                    setDiscipline(a.value);
                                }}/>
                    </div>

                </TopLeft>



                {/*<TopRight>*/}
                {/*    <Select options={YEARS_OPTIONS}*/}
                {/*            value={YEARS_OPTIONS.filter(a => (a.value == +moment(currentYearTimestamp).format('YYYY')))[0]}*/}
                {/*            onChange={a => {*/}
                {/*                let t = moment(`${a.value}`, 'YYYY').startOf('year');*/}
                {/*                setCurrentYearTimestamp(t);*/}
                {/*            }}/>*/}
                {/*</TopRight>*/}
            </TopPlaceholder>

            <ContentPlaceholder>

                <Table>
                    <Thead>
                        <Tr>
                            <Th>
                                Имя
                            </Th>
                            {filteredTasks.map((tsk, i) => {
                                return (
                                    <Th key={tsk.id}>
                                        {tsk.name}
                                    </Th>
                                )
                            })}
                            <Th>
                                Общий бал
                            </Th>
                            {/*<Th>*/}
                            {/*    Место в общем зачете*/}
                            {/*</Th>*/}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredStudents.map((st, i) => {
                            let subs = (subsMap[st.id] == undefined) ? [] : subsMap[st.id];
                            let stTasksSubsMap = subs.reduce((mp, x) => ({...mp, [x.taskId]: x}), {});
                            let rankObj = rankingsMap[st.id];
                            let rankingString = (rankObj == undefined ? '-' : rankObj.ranking);
                            let scoreString = (rankObj == undefined ? '-' : rankObj.score.toFixed(1));

                            let studentTotalScore = subs.reduce((ssm, ssb) => (+ssm + +((ssb.mark == undefined || window.isNaN(ssb.mark) == true) ? 0 : ssb.mark)), 0);

                            return (
                                <Tr key={st.id}>
                                    <UserTd>
                                    <span style={{cursor: 'pointer'}} onClick={() => {
                                        onStudentSelect(st.id);
                                    }}>
                                        {st.name}
                                    </span>
                                    </UserTd>
                                    {filteredTasks.map((tsk, i) => {
                                        let sub = stTasksSubsMap[tsk.id];
                                        let scoreString = (sub == undefined || sub.mark == undefined) ? `-` : `${sub.mark}`;
                                        let canSelect = (sub != undefined);
                                        return (
                                            <ScoreTd key={tsk.id} canSelect={canSelect} onClick={() => {
                                                if (canSelect == false) {
                                                    return;
                                                }
                                                setSelectedSubmission(sub);
                                            }}>
                                                {scoreString}
                                            </ScoreTd>
                                        )
                                    })}
                                    <ScoreTd>
                                        {/*{scoreString}*/}
                                        {studentTotalScore}
                                    </ScoreTd>
                                    {/*<ScoreTd>*/}
                                    {/*    {window.isNaN(rankingString) == true ? '' : ((rankingString == undefined) ? '' : (+rankingString + 1))}*/}
                                    {/*</ScoreTd>*/}
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>

            </ContentPlaceholder>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={(selectedSubmission != undefined)}
                     onCloserClick={() => {
                         setSelectedSubmission(undefined);
                     }}>

                {selectedSubmission == undefined ? null :
                    <div>
                        <ViewSubmissionTool {...selectedSubmission} />
                        <div>
                            <SubjectCommentsPanel subjectId={selectedSubmission.id}/>
                        </div>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TopLeft = styled.div`
  //width: 200px;
  display: flex;
`;

const TopRight = styled.div`
  width: 200px;
`;

const ContentPlaceholder = styled.div`

`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  width: 100%;
`;

const Td = styled.td`
  border: 1px solid whitesmoke;
  padding: 5px;
`;

const UserTd = styled(Td)`
  width: 200px;
`;

const ScoreTd = styled(Td)`
  text-align: center;
  cursor: ${props => (props.canSelect == true ? 'pointer' : 'default')};

  :hover {
    opacity: ${props => (props.canSelect == true ? 0.8 : 1)};
    background: ${props => (props.canSelect == true ? 'whitesmoke' : 'transparent')};
  }
`;

const Th = styled.th`
  padding: 5px;
  text-align: left;
  font-size: 12px;
`;

const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;
