import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import AdminAllSchoolsPanel from "../admin/panels/AdminAllSchoolsPanel";

export default function AdminSchoolsApp(props) {

    return (
        <ChessTemplate active={'schools'}>

            <AdminAllSchoolsPanel/>

        </ChessTemplate>
    );
}

