import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

const getFranchisee = (frId, allFranchisees) => {
    let arr = allFranchisees.filter(a => (a.id == frId));
    if (arr.length == 0) {
        return null;
    }
    return arr[0];
}


export default function AdminStatsPanel(props) {
    const dispatch = useDispatch();
    let {currentUser, loading, schools, userRole, franchisees, teachers, students, lessons} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let franchisees = state.users.usersMap.toArray().filter(u => (u.userRole == 'franchisee'));
        let students = state.users.usersMap.toArray().filter(a => (a.userRole == 'student'))
        let teachers = state.users.usersMap.toArray().filter(a => (a.userRole == 'teacher'))
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            schools: state.schools.schoolsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            franchisees: franchisees,
            teachers: teachers,
            students: students,
            lessons: state.lessons.lessonsMap.toArray().sort((a, b) => (+b.startTimestamp - +b.startTimestamp)),
        }
    }, []));
    if (currentUser == undefined || userRole != 'admin') {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    return (
        <Wrapper>

            <Row>

                <Box>
                    <BoxInner onClick={() => {
                        CommonHelper.linkTo(`/students`);
                    }} hoverable={true}>
                        <ParamValue>
                            {students.length}
                        </ParamValue>
                        <ParamName>
                            Ученики
                        </ParamName>
                    </BoxInner>
                </Box>

                <Box>
                    <BoxInner onClick={() => {
                        CommonHelper.linkTo(`/teachers`);
                    }} hoverable={true}>
                        <ParamValue>
                            {teachers.length}
                        </ParamValue>
                        <ParamName>
                            Преподаватели
                        </ParamName>
                    </BoxInner>
                </Box>

                {/*<Box>*/}
                {/*<BoxInner onClick={() => {*/}
                {/*CommonHelper.linkTo(`/schools`);*/}
                {/*}} hoverable={true}>*/}
                {/*<ParamValue>*/}
                {/*{schools.length}*/}
                {/*</ParamValue>*/}
                {/*<ParamName>*/}
                {/*Школ*/}
                {/*</ParamName>*/}
                {/*</BoxInner>*/}
                {/*</Box>*/}

                {/*<Box>*/}
                {/*<BoxInner>*/}
                {/*<ParamValue>*/}
                {/*{lessons.length}*/}
                {/*</ParamValue>*/}
                {/*<ParamName>*/}
                {/*Занятий*/}
                {/*</ParamName>*/}
                {/*</BoxInner>*/}
                {/*</Box>*/}


            </Row>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Box = styled.div`
    flex: 1;
    padding: 5px;
    flex-basis: 25%;
`;

const ParamName = styled.div`
    font-size: 24px;
    line-height: 32px;
`;

const ParamValue = styled.div`
    font-size: 64px;
    line-height: 64px;
`;

const BoxInner = styled.div`
    background: white;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    align-content: space-between;
    cursor: ${props => (props.hoverable == true ? 'pointer' : 'default')};
    :hover{
      opacity: ${props => (props.hoverable == true ? 0.7 : 1)};
    }
`;
