import DocumentsAPI from '../../api/DocumentsAPI'

import * as types from '../ActionTypes'

let loadDocuments_ = () => {
    return {
        type: types.LOAD_DOCUMENTS
    }
}
let loadDocumentsSuccess = (documents) => {
    return {
        type: types.LOAD_DOCUMENTS_SUCCESS,
        documents: documents
    }
}
let loadDocumentsFailed = (error) => {
    return {
        type: types.LOAD_DOCUMENTS_FAIL,
        error: error
    }
}

//thunk
export function loadAllDocuments() {
    return (dispatch, getState) => {
        dispatch(loadDocuments_());
        return DocumentsAPI.getAllDocuments().then(
            documents => dispatch(loadDocumentsSuccess(documents)),
            error => dispatch(loadDocumentsFailed(error))
        )
    }
}

export function loadDocumentById(id) {
    return (dispatch, getState) => {
        dispatch(loadDocuments_());
        return DocumentsAPI.getDocument(id).then(
            document => dispatch(loadDocumentsSuccess([document])),
            error => dispatch(loadDocumentsFailed(error))
        )
    }
}

let createDocument_ = () => {
    return {
        type: types.CREATE_DOCUMENT
    }
}
let createDocumentSuccess = (document) => {
    return {
        type: types.CREATE_DOCUMENT_SUCCESS,
        document: document
    }
}
let createDocumentFailed = (error) => {
    return {
        type: types.CREATE_DOCUMENT_FAIL,
        error: error
    }
}

//thunk
export function createDocument(data) {
    return (dispatch, getState) => {
        dispatch(createDocument_());
        return DocumentsAPI.createDocument(data).then(
            document => dispatch(createDocumentSuccess(document)),
            error => dispatch(createDocumentFailed(error))
        )
    }
}

let updateDocument_ = () => {
    return {
        type: types.UPDATE_DOCUMENT
    }
}
let updateDocumentSuccess = (document) => {
    return {
        type: types.UPDATE_DOCUMENT_SUCCESS,
        document: document
    }
}
let updateDocumentFailed = (error) => {
    return {
        type: types.UPDATE_DOCUMENT_FAIL,
        error: error
    }
}

//thunk
export function updateDocument(data) {
    return (dispatch, getState) => {
        dispatch(updateDocument_());
        return DocumentsAPI.updateDocument(data).then(
            document => dispatch(updateDocumentSuccess(document)),
            error => dispatch(updateDocumentFailed(error))
        )
    }
}

let deleteDocument_ = () => {
    return {
        type: types.DELETE_DOCUMENT
    }
}
let deleteDocumentSuccess = (id) => {
    return {
        type: types.DELETE_DOCUMENT_SUCCESS,
        id: id
    }
}
let deleteDocumentFailed = (error) => {
    return {
        type: types.DELETE_DOCUMENT_FAIL,
        error: error
    }
}

//thunk
export function deleteDocument(id) {
    return (dispatch, getState) => {
        dispatch(deleteDocument_());
        return DocumentsAPI.deleteDocument(id).then(
            () => dispatch(deleteDocumentSuccess(id)),
            error => dispatch(deleteDocumentFailed(error))
        )
    }
}
