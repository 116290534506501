import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import QualitySwitcher from "./QualitySwitcher";
import CustomPlayer from "../../players/CustomPlayer";


export default function FullScreenVideoModal(props) {
    const {
        url = undefined,
        onClose = () => {

        },
        onQualityChange = newUrl => {

        },
        dVideosOptions = []
    } = props;
    const [expanded, setExpanded] = useState(false);

    // const videoRef = useRef(null);
    //
    // useEffect(() => {
    //     console.log('url has been changed: url = ', url);
    //     setTimeout(() => {
    //         try {
    //             videoRef.current.load();
    //         } catch (exc) {
    //
    //         }
    //     }, 300);
    // }, [url]);

    console.log('FullScreenVideoModal: render: url = ', url);

    return (
        <Wrapper>
            <ClosePlaceholder expanded={expanded} >
                {expanded == true ? null :
                    <CloseImage src={require('../../../assets/images/cancel_s.svg')}
                                onClick={() => {
                                    onClose();
                                }}
                    />
                }
            </ClosePlaceholder>

            <Inner>
                <VideoPlaceholder expanded={expanded} >
                    <CustomPlayer {...props} expanded={expanded} onExpandChange={a => {
                        setExpanded(a);
                    }}/>
                </VideoPlaceholder>
            </Inner>

        </Wrapper>
    );
}

const SwitcherPlaceholder = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0.5;
    :hover{
      opacity: 0.9;
    }  
`;

const InnerSwitcher = styled.div`
    
`;

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;  
    left: 0px;  
    right: 0px;  
    z-index: 1000;
    background: rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
`;

const Iframe = styled.iframe`
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
`;

const Video = styled.video`
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
`;


const Inner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const VideoPlaceholder = styled.div`
    width: calc(100vw - ${props => (props.expanded == true ? 0 : 100)}px);
    height: calc(100vh - ${props => (props.expanded == true ? 0 : 100)}px);
    background: rgba(0, 0, 0, 0.8);
    position: relative;
`;

const CloseImage = styled.img`
    cursor: pointer;
    opacity: 0.75;
    width: 30px;
    height: 30px;
    :hover{
      opacity: 1;
    }
`;

const ClosePlaceholder = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    width: ${props => (props.expanded == true ? 0 : 60)}px; 
    height: ${props => (props.expanded == true ? 0 : 60)}px; 
    z-index: 1001;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const InnerPlaceholder = styled.div`
    
`;
