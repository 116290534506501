import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import Tabs from '../../ui/Tabs'

import CourseUnitsPanel from './CourseUnitsPanel'
import * as actions from '../../../redux/actions/CoursesActions'

import NameDescriptionForm from '../../common/forms/NameDescriptionForm'

import FullPagePreloader from '../../preloader/FullPagePreloader'
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import NameDescriptionImageForm from "../../common/forms/NameDescriptionImageForm";
import UpdateCourseForm from "../forms/UpdateCourseForm";

export default function CoursePanel(props) {
    let {id} = props;

    const {course, loading, isAdmin} = useMappedState(useCallback(state => ({
        course: state.courses.coursesMap.get(id),
        loading: state.courses.loading,
        isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state))
    }), [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadCourseById(id));
    }, [id]);

    if (course == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    if (course == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: 'Модули',
                    content: (
                        <TabContent>
                            <CourseUnitsPanel courseId={id}/>
                        </TabContent>
                    )
                },
                {
                    label: 'О курсе',
                    content: (
                        <TabContent>
                            <FormPlaceholder>
                                <UpdateCourseForm {...course} onSubmit={data => {
                                    dispatch(actions.updateCourse({
                                        id: id,
                                        ...data
                                    }));
                                }} canEdit={isAdmin}/>
                            </FormPlaceholder>
                        </TabContent>
                    )
                }
            ]} safeMode={true} />

            {/*<FullPagePreloader visible={loading} />*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const TabContent = styled.div`
    width: 100%;
`;

const FormPlaceholder = styled.div`
    text-align: left;
    padding-top: 10px;
`;
