export const BROWN = '#AC7D59';
export const LIGHT_BROWN = '#d49e7a';
export const RED = 'red';

export const DARK_SIDE = '#444951';

// export const BLUE = '#035694';
export const BLUE = '#005F9B';
export const LIGHT_BLUE = '#F2F7FB';
// export const YELLOW = '#F8A824';
export const YELLOW = '#FBA730';
