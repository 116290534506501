import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'


export default function TeacherSelectorTool(props) {
    const {
        teachers = [], selectedTeachersIds = [], onChange = () => {

        },
        singleMode = false
    } = props;
    if (teachers.length == 0) {
        return null;
    }

    return (
        <Wrapper>

            {teachers.map((tch, k) => {
                let {id, firstName, lastName} = tch;
                let isSelecgted = (selectedTeachersIds.indexOf(id) > -1);
                return (
                    <TeacherItem key={k} selected={isSelecgted} onClick={() => {
                        let newIds = (isSelecgted == true) ? selectedTeachersIds.filter(aId => (aId != id))
                            : selectedTeachersIds.concat([id]);
                        if (singleMode == true && newIds.length > 1) {
                            return;
                        }
                        onChange(newIds);
                    }}>
                        <Name>
                            {`${lastName} ${firstName}`}
                        </Name>
                    </TeacherItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TeachersList = styled.div`
    
`;

const TeacherItem = styled.div`
    display: inline-flex;
    margin: 3px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 1000px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const Name = styled.div`
    
`;
