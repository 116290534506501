import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import * as submissionsActions from "../../../redux/actions/SubmissionsActions";
import * as tasksActions from "../../../redux/actions/TasksActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import * as centersActions from "../../../redux/actions/CentersActions";
import CommonHelper from "../../../helpers/CommonHelper";
import {DARK_SIDE, LIGHT_BLUE} from "../../ui/Colors";
import ViewTaskTool from "../tools/ViewTaskTool";
import ViewSubmissionTool from "../tools/ViewSubmissionTool";
import SubmissionsList from "../lists/SubmissionsList";

import {Map, Set} from 'immutable'

import Tabs, {TabItem} from "../../ui/Tabs";

import Select from 'react-select'
import ObserverSubmissionPanel from "./ObserverSubmissionPanel";
import SubjectCommentsPanel from "../../comments/panels/SubjectCommentsPanel";
import SubmissionsTable from "../lists/SubmissionsTable";

const getClassSubmissions = (allSubs, classNumber) => {
    return allSubs.filter(a => (a.classNumber == classNumber));
}

const STATUS_OPTIONS = [
    {
        label: 'Проверено',
        value: 'checked'
    },
    {
        label: 'Ожидает проверки',
        value: 'not_checked'
    },
]

export default function AllSubmissionsPanel(props) {
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(undefined);
    const [selectedCenterId, setSelectedCenterId] = useState(undefined);

    const [selectedTaskId, setSelectedTaskId] = useState(undefined);

    const [status, setStatus] = useState(undefined);
    let {
        currentUser,
        loading,
        usersMap,
        enhancedSubmissions,
        tasksMap,
        centersMap,
        allCenters
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        // let submissions = state.submissions.submissionsMap.toArray();
        let allCenters = state.centers.centersMap.toArray();
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId || state.tasks.loading || state.submissions.loading,
            loading: state.tasks.loading || state.submissions.loading || state.users.loading,
            enhancedSubmissions: CommonHelper.getAllEnhancedSubmissionsByState(state),
            usersMap: state.users.usersMap,
            tasksMap: state.tasks.tasksMap,
            centersMap: state.centers.centersMap,
            allCenters: allCenters
        }
    }, []));

    console.log('allSubmissionsPanel: currentUser = ', currentUser);

    useEffect(() => {
        dispatch(centersActions.loadAllCenters());
        dispatch(usersActions.loadAllUsers());
        dispatch(tasksActions.loadAllTasks());
        dispatch(submissionsActions.loadAllSubmissions()); // todo: optimize it!
    }, []);

    if (loading == true && enhancedSubmissions.length == 0) {
        return (
            <Code/>
        );
    }

    let filteredEnhancedSubmissions = enhancedSubmissions.filter(a => (selectedCenterId == undefined || selectedCenterId == a.center.id));

    console.log('render: enhancedSubmissions = ', enhancedSubmissions);


    let selectedSubmission = (selectedId == undefined) ? undefined : enhancedSubmissions.filter(a => (a.id == selectedId))[0];
    let selectedSubmissionTask = (selectedSubmission == undefined) ? undefined : tasksMap.get(selectedSubmission.taskId);

    let checkedSubmissions = filteredEnhancedSubmissions.filter(a => (a.observer != undefined && a.mark != undefined));
    let notCheckedSubmissions = filteredEnhancedSubmissions.filter(a => (a.observer == undefined || a.mark == undefined));

    let shownSubmissions = (status == undefined) ? filteredEnhancedSubmissions : (status == 'checked' ? checkedSubmissions : notCheckedSubmissions);

    let filterCentersOptions = allCenters.map(a => ({label: `${a.city}, ${a.name}`, value: a.id})).sort((a, b) => {
        if (a.label > b.label) {
            return 1;
        }
        if (a.label < b.label) {
            return -1;
        }
        return 0;
    });


    return (
        <Wrapper>

            <FilterPlaceholder>
                <Select options={filterCentersOptions}
                        placeholder={'Фильтр по центру'}
                        value={filterCentersOptions.filter(a => (a.value == selectedCenterId))[0]}
                        onChange={a => {
                            if (a == undefined) {
                                setSelectedCenterId(undefined);
                            } else {
                                setSelectedCenterId(a.value);
                            }
                        }}
                        isClearable={true}
                />

                <div style={{marginTop: 10}}>
                    <Select options={STATUS_OPTIONS}
                            placeholder={'Фильтр по статусу'}
                            value={STATUS_OPTIONS.filter(a => (a.value == status))[0]}
                            onChange={a => {
                                if (a == undefined) {
                                    setStatus(undefined);
                                } else {
                                    setStatus(a.value);
                                }
                            }}
                            isClearable={true}
                    />
                </div>

            </FilterPlaceholder>

            <TopStatsPlaceholder>
                <div>
                    <span style={{marginRight: 10}}>
                        {`Проверено: ${checkedSubmissions.length}`}
                    </span>
                    {' | '}
                    <span style={{marginLeft: 10}}>
                        {`Ожидает проверки: ${filteredEnhancedSubmissions.length - checkedSubmissions.length}`}
                    </span>
                </div>
            </TopStatsPlaceholder>

            <SubmissionsListPlaceholder>
                {/*<Tabs tabs={[7, 8, 9, 10, 11].map(a => {*/}
                <Tabs
                    tabs={(currentUser != undefined && currentUser.userRole == 'admin' ? [7, 8, 9, 10, 11] : [currentUser == undefined ? 7 : currentUser.classNumber]).map(a => {
                        let clSubs = getClassSubmissions(shownSubmissions, a);
                        let allTasks = clSubs.map(c => c.task).filter(x => (x != undefined)).reduce((mp, c) => mp.set(c.id, c), Map()).toArray();
                        let tasksOptions = allTasks.map(c => ({label: c.name, value: c.id}));
                        for (let r in tasksOptions) {
                            let opt = tasksOptions[r];
                            let xx = clSubs.filter(x => (x.task != undefined && x.task.id == opt.value)).length;
                            tasksOptions[r].label = `${tasksOptions[r].label} (${xx})`
                        }

                        let shownClSubs = (selectedTaskId == undefined) ? clSubs : clSubs.filter(x => (x.task != undefined && x.task.id == selectedTaskId));

                        return {
                            label: `${a} класс (${clSubs.length})`,
                            content: (
                                <TabItem>
                                    <div style={{marginBottom: 5}}>
                                        <Select options={tasksOptions}
                                                placeholder={'Фильтр по БДЗ'}
                                                isClearable={true} onChange={x => {
                                            if (x == undefined) {
                                                setSelectedTaskId(undefined);
                                            } else {
                                                setSelectedTaskId(x.value);
                                            }

                                        }}/>
                                    </div>
                                    <Tabs tabs={[
                                        {
                                            label: 'Список',
                                            content: (
                                                <TabItem>
                                                    <SubmissionsList selectedId={selectedId}
                                                                     hasBdzFilter={true}
                                                                     enhancedSubmissions={shownClSubs} onSelect={id => {
                                                        setSelectedId(id);
                                                    }}/>
                                                </TabItem>
                                            )
                                        },
                                        {
                                            label: 'Таблица',
                                            content: (
                                                <TabItem>
                                                    <SubmissionsTable selectedId={selectedId}
                                                                      hasBdzFilter={true}
                                                                      enhancedSubmissions={shownClSubs}
                                                                      onSelect={id => {
                                                                          console.log('onSelect: id = ', id);
                                                                          setSelectedId(id);
                                                                      }}/>
                                                </TabItem>
                                            )
                                        }
                                    ]}/>


                                </TabItem>
                            )
                        }
                    })} onTabSwitch={() => {
                    setSelectedTaskId(undefined);
                }}/>
            </SubmissionsListPlaceholder>


            <Sidebar width={Math.min(720, window.innerWidth)}
                     visible={(selectedSubmission != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedSubmission == undefined ? null :
                    <div>

                        <SelStudName>
                            {`${selectedSubmission.student.lastName} ${selectedSubmission.student.firstName} ${selectedSubmission.student.middleName}`}
                            <sup style={{fontWeight: 'normal', opacity: 0.5, fontSize: 12, marginLeft: 5}}>
                                {`${selectedSubmission.student.classNumber} класс`}
                            </sup>
                        </SelStudName>
                        <SelStudCenterName>
                            {`${selectedSubmission.center.city}, ${selectedSubmission.center.name}`}
                            <br/>
                            {`Почта ученика: ${selectedSubmission.student.email}`}
                        </SelStudCenterName>

                        {selectedSubmissionTask == undefined ? null :
                            <ViewTaskTool {...selectedSubmissionTask} />
                        }

                        <ViewSubmissionTool {...selectedSubmission}
                                            hasTeacherSection={false}
                                            task={selectedSubmissionTask} currentUser={currentUser}
                        />

                        <ObserverSection>
                            {/*<SubHeading>*/}
                            {/*Раздел проверяющего*/}
                            {/*</SubHeading>*/}
                            <ObserverSubmissionPanel id={selectedSubmission.id}/>
                        </ObserverSection>

                        <div>
                            <SubjectCommentsPanel subjectId={selectedSubmission.id}/>
                        </div>

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SubmissionsListPlaceholder = styled.div`
  padding: 10px;
`;


const TopStatsPlaceholder = styled.div`
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
`;


const SelStudName = styled.div`
  font-size: 24px;
  font-weight: bold;
`;


const SelStudCenterName = styled.div`
  font-size: 13px;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
  padding-bottom: 10px;
  opacity: 0.5;
`;


const FilterPlaceholder = styled.div`
  padding: 0px 10px;
  margin-bottom: 10px;
`;

const SubHeading = styled.div`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const ObserverSection = styled.div`
  padding: 10px;
  background: ${LIGHT_BLUE};
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid lightgrey;
`;
