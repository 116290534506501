import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Sidebar from 'arui-feather/sidebar'
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";

import * as actions from '../../../redux/actions/GroupsActions'

import Tabs from '../../ui/Tabs'

import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";
import GroupLessonsPanel from "./GroupLessonsPanel";
import GroupStudentsPanel from "./GroupStudentsPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";
import UpdateGroupForm from "../forms/UpdateGroupForm";
import StudentsTransactionsPanel from "../../transactions/panels/StudentsTransactionsPanel";


export default function SchoolGroupsPanel(props) {
    const {schoolId} = props;
    let {currentUser, loading, groups, school, franchisee, canEdit, schoolTeachers, usersMap} = useMappedState(useCallback(state => {
        let sc = state.schools.schoolsMap.get(schoolId);
        let tchrs = state.users.usersMap.toArray().filter(u => ((u.userRole == 'teacher')))
        tchrs = tchrs.filter(t => (sc.teachersIds != undefined && sc.teachersIds.indexOf(t.id) > -1));
        let groups = state.groups.groupsMap.toArray().filter(u => ((u.schoolId == schoolId)));
        const canEdit = ('admin' == CommonHelper.getCurrentUserRole(state) || (sc != undefined && sc.franchiseeId == state.users.currentUserId));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            school: sc,
            groups: groups,
            franchisee: (sc == undefined) ? undefined : state.users.usersMap.get(sc.franchiseeId),
            canEdit: canEdit,
            schoolTeachers: tchrs,
            usersMap: state.users.usersMap
        }
    }, [schoolId]));
    if (school == undefined || franchisee == undefined) {
        return null;
    }
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(false);
    const dispatch = useDispatch();
    const selectedGroup = (selectedId == undefined) ? undefined : groups.filter(g => (g.id == selectedId))[0];

    const sortedGroups = groups.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        return 0;
    });

    const selectedStudents = (selectedGroup == undefined) ? [] : selectedGroup.studentsIds.map(a => usersMap.get(a)).filter(a => (a != undefined));
    console.log('render: selectedStudents = ', selectedStudents);


    return (
        <Wrapper>

            {canEdit == false ? null :
                <TopPlaceholder>
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }} style={{marginLeft: 0}}>
                        Добавить группу
                    </Button>
                </TopPlaceholder>
            }

            <GroupsPlaceholder>
                <PaginatorWrapper items={sortedGroups} renderItems={items => {
                    return (
                        <GroupsList>
                            {items.map((gr, k) => {
                                let {name, description} = gr;
                                return (
                                    <GroupItem key={gr.id} onClick={() => {
                                        setSelectedId(gr.id);
                                    }} selected={(gr.id == selectedId)}>
                                        <GroupTop>
                                            <Name>
                                                {name}
                                            </Name>
                                            <GroupStatsPlaceholder>
                                                <InfoImg src={require('../../../assets/images/group.svg')}/>
                                                <span style={{marginLeft: 5}}>
                                            {gr.studentsIds.length}
                                        </span>
                                            </GroupStatsPlaceholder>
                                        </GroupTop>

                                        <Description>
                                            {description}
                                        </Description>
                                    </GroupItem>
                                )
                            })}
                        </GroupsList>
                    )
                }}/>

            </GroupsPlaceholder>


            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <Heading>
                            Создание группы
                        </Heading>
                        <UpdateGroupForm loading={loading} onSubmit={d => {
                            dispatch(actions.createGroup({
                                ...d,
                                schoolId: school.id,
                                franchiseeId: franchisee.id
                            })).then(() => {
                                setAddModalVisible(false);
                            });
                        }} canEdit={canEdit} allTeachers={schoolTeachers}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(750, window.innerWidth)} visible={(selectedGroup != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedGroup == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Редактирование',
                                content: (
                                    <TabItem>
                                        <UpdateGroupForm {...selectedGroup} loading={loading} onSubmit={d => {
                                            dispatch(actions.updateGroup({id: selectedId, ...d})).then(() => {
                                                setSelectedId(undefined);
                                            });
                                        }} allTeachers={schoolTeachers}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Ученики',
                                content: (
                                    <TabItem>
                                        <GroupStudentsPanel groupId={selectedId}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Занятия группы',
                                content: (
                                    <TabItem>
                                        <GroupLessonsPanel groupId={selectedId}/>
                                    </TabItem>
                                )
                            }
                        ]} safeMode={true}/>

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const GroupsPlaceholder = styled.div`
    
`;

const GroupsList = styled.div`
    
`;

const GroupItem = styled.div`
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const GroupTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const GroupStatsPlaceholder = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;

const Description = styled.div`
    opacity: 0.7;
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const TabItem = styled.div`
    width: 100%;
    padding-top: 5px;
`;

const InfoImg = styled.img`
    height: 18px;
`;
