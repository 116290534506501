import ProgramsAPI from '../../api/ProgramsAPI'

import * as types from '../ActionTypes'

let loadPrograms_ = () => {
    return {
        type: types.LOAD_PROGRAMS
    }
}
let loadProgramsSuccess = (programs) => {
    return {
        type: types.LOAD_PROGRAMS_SUCCESS,
        programs: programs
    }
}
let loadProgramsFailed = (error) => {
    return {
        type: types.LOAD_PROGRAMS_FAIL,
        error: error
    }
}

//thunk
export function loadAllPrograms() {
    return (dispatch, getState) => {
        dispatch(loadPrograms_());
        return ProgramsAPI.getAllPrograms().then(
            programs => dispatch(loadProgramsSuccess(programs)),
            error => dispatch(loadProgramsFailed(error))
        )
    }
}

export function loadProgramById(id) {
    return (dispatch, getState) => {
        dispatch(loadPrograms_());
        return ProgramsAPI.getProgram(id).then(
            program => dispatch(loadProgramsSuccess([program])),
            error => dispatch(loadProgramsFailed(error))
        )
    }
}

let createProgram_ = () => {
    return {
        type: types.CREATE_PROGRAM
    }
}
let createProgramSuccess = (program) => {
    return {
        type: types.CREATE_PROGRAM_SUCCESS,
        program: program
    }
}
let createProgramFailed = (error) => {
    return {
        type: types.CREATE_PROGRAM_FAIL,
        error: error
    }
}

//thunk
export function createProgram(data) {
    return (dispatch, getState) => {
        dispatch(createProgram_());
        return ProgramsAPI.createProgram(data).then(
            program => dispatch(createProgramSuccess(program)),
            error => dispatch(createProgramFailed(error))
        )
    }
}

let updateProgram_ = () => {
    return {
        type: types.UPDATE_PROGRAM
    }
}
let updateProgramSuccess = (program) => {
    return {
        type: types.UPDATE_PROGRAM_SUCCESS,
        program: program
    }
}
let updateProgramFailed = (error) => {
    return {
        type: types.UPDATE_PROGRAM_FAIL,
        error: error
    }
}

//thunk
export function updateProgram(data) {
    return (dispatch, getState) => {
        dispatch(updateProgram_());
        return ProgramsAPI.updateProgram(data).then(
            program => dispatch(updateProgramSuccess(program)),
            error => dispatch(updateProgramFailed(error))
        )
    }
}

let deleteProgram_ = () => {
    return {
        type: types.DELETE_PROGRAM
    }
}
let deleteProgramSuccess = (id) => {
    return {
        type: types.DELETE_PROGRAM_SUCCESS,
        id: id
    }
}
let deleteProgramFailed = (error) => {
    return {
        type: types.DELETE_PROGRAM_FAIL,
        error: error
    }
}

//thunk
export function deleteProgram(id) {
    return (dispatch, getState) => {
        dispatch(deleteProgram_());
        return ProgramsAPI.deleteProgram(id).then(
            () => dispatch(deleteProgramSuccess(id)),
            error => dispatch(deleteProgramFailed(error))
        )
    }
}
