import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

import Select from 'react-select'

import Spin from 'arui-feather/spin'
import CommonHelper from "../../../helpers/CommonHelper";

export default function UpdateGroupForm(props) {
    let {
        onSubmit = () => {
        }, loading = false,
        canEdit = true,
        allTeachers = []
    } = props;
    console.log('render: UpdateGroupForm props = ', props);
    const [teachersIds, setTeachersIds] = useState(props.teachersIds == undefined ? [] : props.teachersIds);
    const [name, setName] = useState((props.name == undefined) ? '' : props.name);
    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    console.log('name, description = ', name, description);
    const getTeacherById = useCallback((tId) => {
        let arr = allTeachers.filter(a => (a.id == tId));
        if (arr.length == 0) {
            return undefined;
        }
        return arr[0];
    }, []);
    const selectedTeachers = teachersIds.map(tId => getTeacherById(tId)).filter(a => (a != undefined)).map(a => ({
        value: a.id,
        label: CommonHelper.getShortNameByUser(a)
        // label: `${a.firstName} ${a.lastName}`
    }))

    return (
        <Wrapper>

            <Field>
                <Label>
                    Преподаватели
                </Label>
                <Select options={CommonHelper.getDropDownItemsByUsers(allTeachers)}
                        value={selectedTeachers}
                        isMulti={true}
                        isSearchable={true}
                        placeholder={'Выбрать Преподавателей'}
                        onChange={(a, b) => {
                            console.log('onChange: a, b = ', a, b);
                            setTeachersIds(a.map(aa => aa.value));
                        }}
                />
            </Field>

            <Field>
                <Label>
                    Название
                </Label>
                {canEdit == true ?
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value);
                    }}/> :
                    <div>
                        {name}
                    </div>
                }
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                {canEdit == true ?
                    <Textarea value={description} onChange={evt => {
                        setDescription(evt.target.value);
                    }}/> : <div dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}></div>
                }

            </Field>

            {canEdit == false ? null :
                <Field>
                    <Button onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSubmit({name, description, teachersIds});
                    }}>
                        {loading == true ?
                            <Spin visible={true}/>
                            :
                            <span>
                                Сохранить
                            </span>
                        }

                    </Button>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
