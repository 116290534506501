import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'

import AllCoursesPanel from '../courses/panels/AllCoursesPanel'

export default function CoursesApp(props) {

    return (
        <ChessTemplate active={'courses'}>

            <Container style={{width: '100%'}}>

                <AllCoursesPanel />

            </Container>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
