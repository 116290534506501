import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import * as actions from "../../../redux/actions/TasksActions";
import TaskForm from "../forms/TaskForm";

import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";
import {getSelCr} from "../../programs/panels/ClassProgramsPanel";

export default function ClassTasksPanel(props) {
    const {classNumber, subj = getSelCr()} = props;
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    const dispatch = useDispatch();
    let {currentUser, loading, tasks} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.tasks.loading,
        tasks: state.tasks.tasksMap.toArray().filter(u => (u.classNumber == classNumber)).filter(x => (x.discipline == subj))
    }), [classNumber]));
    useEffect(() => {
        dispatch(actions.loadAllTasks());
    }, [classNumber]);

    const selectedItem = (selectedId == undefined) ? undefined : tasks.filter(a => (a.id == selectedId))[0];

    if (tasks.length == 0 && loading == true) {
        return (
            <Code/>
        );
    }

    let sortedTasks = tasks.sort((a, b) => {
        let s1 = (a == undefined || a.name == undefined) ? '' : a.name;
        let s2 = (b == undefined || b.name == undefined) ? '' : b.name;
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    })

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить БДЗ
                </Button>
            </TopPlaceholder>

            <ListPlaceholder>
                {sortedTasks.map((task, k) => {
                    let {name, description, taskUrl, startTimestamp, deadlineTimestamp, status} = task;
                    return (
                        <Item key={task.id} status={status}>
                            <Left>
                                <Name>
                                    {name}
                                </Name>
                                <DatesPlaceholder>
                                    {`${moment(startTimestamp).format('DD.MM.YYYY')} - ${moment(deadlineTimestamp).format('DD.MM.YYYY')}`}
                                </DatesPlaceholder>
                                <Info>
                                    <div>
                                        {description}
                                    </div>
                                    {(taskUrl == undefined || taskUrl.trim() == '') ? null :
                                        <div>
                                            <a href={CommonHelper.getSafeUrl(taskUrl)} target={'_blank'}>
                                                скачать задание
                                            </a>
                                        </div>
                                    }
                                </Info>
                            </Left>
                            <ControlsPlaceholder>
                                <EditSpan onClick={() => {
                                    setSelectedId(task.id);
                                }}>
                                    редактировать
                                </EditSpan>
                            </ControlsPlaceholder>
                        </Item>
                    )
                })}
            </ListPlaceholder>

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <TaskForm loading={loading} onSave={async d => {
                            await dispatch(actions.createTask({...d, classNumber: classNumber, discipline: getSelCr()}));
                            setAddModalVisible(undefined);
                        }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar visible={(selectedItem != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedItem == undefined ? null :
                    <div>
                        <TaskForm {...selectedItem} canDelete={true}
                                  loading={loading}
                                  onSave={async d => {
                                      console.log('TaskForm: onSave: d = ', d);
                                      await dispatch(actions.updateTask({...d, id: selectedItem.id}));
                                      setSelectedId(undefined);
                                  }} onDelete={async () => {
                            await dispatch(actions.deleteTask(selectedItem.id));
                            setSelectedId(undefined)
                        }}/>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
`;

const ListPlaceholder = styled.div`

`;

const Item = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  display: flex;
  flex-direction: row;
  opacity: ${props => (props.status == 'archived' ? 0.3 : 1)};
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

const Info = styled.div`
  font-style: italic;
  opacity: 0.7;
  font-size: 12px;
`;

const ControlsPlaceholder = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;

const Left = styled.div`
  flex: 1;
`;

const EditSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const DatesPlaceholder = styled.div`
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 7px;
`;
