const isDev = (window.location.href.indexOf('dev') > -1);

export const DEFAULT_TIMEZONE = 'Europe/Moscow';

export const PARSE_APP_ID = 'h1QhtsSjeoyQSa8RDQBDPvgbnI7Ix6nadHTsepwN';
export const PARSE_JS_KEY = 'Ci34OXCgbv7TuVOiUJFOmoSwULbC7JRnxvFaT1ZI';
// export const PARSE_SERVER_URL = isDev ? 'https://physics-parse.sabir.pro/parse' : 'https://physics-parse.sabir.pro/parse';
export const PARSE_SERVER_URL = 'https://os.mipt.ru/parse/parse';
export const API_ENDPOINT = 'https://os.mipt.ru/api';

export const COMPANY_NAME = 'Hawk ERP';
export const FACELESS_AVATAR = 'https://www.englishpatient.org/storage/e7d099fe-653b-488e-9970-f693ab585f05.png';

export const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';
// export const UPLOAD_DO_ENDPOINT_URL = 'https://os.mipt.ru/api/upload';

export const LOGIN_BACKGROUNR_IMAGE = 'https://www.englishpatient.org/english-patient-files/ef6bc133-1827-4669-b2f4-644fb1f25723.jpg';

export const MIN_MARK = 0;
export const MAX_MARK = 50;
