import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Map, Set} from 'immutable'
import {BLUE} from "../../ui/Colors";
import CentersTable from "./CentersTable";


export default function GrouppedCentersTable(props) {
    const {
        points = [], onPointClick = id => {
        },
        selectedId = undefined
    } = props;

    const [selectedRegion, setSelectedRegion] = useState(undefined);

    let xPoints = points.map(xx => ({
        ...xx,
        region: `${xx.region}`.replace(/ \n/g, ' ')
    }))

    let sortedPoints = xPoints.sort((a, b) => {
        let s1 = (a.region == undefined) ? '' : a.region.toLowerCase();
        let s2 = (b.region == undefined) ? '' : b.region.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    let sortedRegions = sortedPoints.reduce((st, a) => st.add(a.region), Set()).toArray().sort((a, b) => {
        let s1 = (a == undefined) ? '' : a.toLowerCase();
        let s2 = (b == undefined) ? '' : b.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    let selectedPoints = (selectedRegion == undefined) ? [] : sortedPoints.filter(x => (x.region == selectedRegion));

    return (
        <Wrapper>

            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            Регион
                        </Th>

                    </Tr>
                </Thead>
                <Tbody>
                    {sortedRegions.map((a, k) => {
                        let selected = (selectedRegion == a);
                        return (
                            <React.Fragment key={a}>

                                <Tr selected={selected} key={a} onClick={() => {
                                    setSelectedRegion(a);
                                    // onPointClick(a);
                                }}>
                                    <Td>
                                        {a}
                                    </Td>
                                </Tr>

                                {selected == false ? null :
                                    <Tr selected={selected}>
                                        <Td>
                                            <CentersTable
                                                hasHead={false}
                                                hasRegionColumn={false}
                                                points={selectedPoints}
                                                selectedId={selectedId}
                                                onPointClick={onPointClick}
                                            />
                                        </Td>
                                    </Tr>
                                }

                            </React.Fragment>
                        )
                    })}
                </Tbody>

            </Table>

            <TopPlaceholder>
                {`Количество регионов: ${sortedRegions.length}, количество центров: ${xPoints.length}`}
            </TopPlaceholder>

        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
`;

const Wrapper = styled.div`
  text-align: left;
  font-weight: normal;
  background: white;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;

const Tr = styled.tr`
  cursor: pointer;
  background: ${props => (props.selected == true ? BLUE : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};

  :hover {
    opacity: 0.8;
  }
`;

const Th = styled.th`
  padding: 5px;
  border: 1px solid lightgrey;
`;

const Td = styled.td`
  padding: 5px;
  border: 1px solid lightgrey;
  font-size: 14px;
`;

const AddressTd = styled.td`
  padding: 5px;
  border: 1px solid lightgrey;
  font-size: 14px;
  min-width: 300px;
`;
