import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {DARK_SIDE} from "../ui/Colors";

import CommonHelper from '../../helpers/CommonHelper'

import Spin from 'arui-feather/spin'
import BottomUserPanel from "./BottomUserPanel";

import ls from 'local-storage'

// import Sidebar from 'arui-feather/sidebar'

export const CR_OPTS = [
    {
        label: 'Физ.',
        ext_label: 'Физика',
        value: 'phys'
    },
    {
        label: 'Алг.',
        ext_label: 'Алгебра',
        value: 'alg'
    },
    {
        label: 'Геом.',
        ext_label: 'Геометрия',
        value: 'geom'
    },
    {
        label: 'Астр.',
        ext_label: 'Астрономия',
        value: 'astro'
    }
]

export const CR_OPTS_FULL = [
    {
        label: 'Физика',
        ext_label: 'Физика',
        value: 'phys'
    },
    {
        label: 'Алгебра',
        ext_label: 'Алгебра',
        value: 'alg'
    },
    {
        label: 'Геометрия',
        ext_label: 'Геометрия',
        value: 'geom'
    },
    {
        label: 'Астрономия',
        ext_label: 'Астрономия',
        value: 'astro'
    }
]

export default function MySidebar(props) {
    let {
        currentUser, loading, active, onLogout = () => {
        },
        onCurrentUserClick = () => {

        },
        onUserUpdate = updData => {

        },
        extraLinksContent = {
            afterIndex: 'students',
            links: []
        },
        isModerator
    } = props;
    const [selCr, setSelCr] = useState(ls('sel_discipline') || 'phys');
    useEffect(() => {
        if (selCr != undefined) {
            ls('sel_discipline', selCr);
        }
    }, [selCr]);

    if (currentUser == undefined) {
        return null;
    }
    let {userRole} = currentUser;
    let options = [];
    if (userRole == 'admin') {
        options = adminOptions;
    }
    if (userRole == 'methodist') {
        options = methodistOptions;
    }
    if (userRole == 'student') {
        options = studentsOptions;
    }
    if (userRole == 'teacher') {
        if (isModerator == true) {
            options = moderatorOptions;
        } else {
            options = teacherOptions;
        }
    }
    if (userRole == 'observer') {
        options = observerOptions;
    }

    return (
        <Wrapper>

            {userRole == 'admin' ? null :
                <LogoPlaceholder onClick={() => {
                    ls('force_landing', true);
                    window.location.reload();
                }}>
                    <LogoImage src={require('../../assets/images/chev_fav.png')}/>
                    <LogoText>
                        Физтех регионам
                    </LogoText>
                </LogoPlaceholder>
            }

            {userRole != 'admin' ? null :
                <CrOptPlaceholder>
                    {CR_OPTS.map((a, i) => {
                        return (
                            <CrOptItem key={a.value} selected={(a.value == selCr)} onClick={() => {
                                setSelCr(a.value);
                                ls('sel_discipline', a.value);
                                window.location.reload();
                            }}>
                                {a.label}
                            </CrOptItem>
                        )
                    })}
                </CrOptPlaceholder>
            }

            <OptionsList>
                {options.map((opt, k) => {
                    let {label, icon, url, name, isExtra} = opt;
                    let isSelected = (active == name);
                    let mTop = (isExtra || k == 0 || ((k > 0) && (options[k - 1].group == opt.group)) || options[k - 1].isExtra == true) ? 0 : 30;
                    return (
                        <OptionItem
                            style={{marginTop: mTop}}
                            extra={isExtra}
                            selected={isSelected} key={k} onClick={() => {
                            CommonHelper.linkTo(url);
                        }}>
                            {label}
                        </OptionItem>
                    )
                })}

                <OptionItem style={{position: 'absolute', bottom: 60, left: 0, right: 0, height: 'auto'}}
                            onClick={() => {
                                // onLogout();
                            }}>
                    {/*{loading == false ?*/}
                    {/*<span>*/}
                    {/*Выход*/}
                    {/*</span>*/}
                    {/*:*/}
                    {/*<Spin visible={true} />*/}
                    {/*}*/}

                    <BottomUserPanel user={currentUser} onClick={() => {
                        onCurrentUserClick();
                    }} canUpdateAvatar={true} onUpdate={updData => {
                        onUserUpdate(updData);
                    }}/>

                </OptionItem>

                <OptionItem style={{
                    position: 'absolute', bottom: 5,
                    left: 0, right: 0, display: 'flex', flexDirect: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    paddingRight: 15,
                    fontSize: 12,
                    cursor: 'default'

                }}>

                    {currentUser == undefined ? null :
                        <span style={{opacity: 0.5}}>
                            {CommonHelper.getRuNameOfRole(currentUser.userRole, currentUser.isModerator)}
                        </span>
                    }

                    {loading == false ?
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            onLogout();
                        }}>
                        Выйти
                    </span>
                        :
                        <Spin visible={true}/>
                    }

                </OptionItem>

            </OptionsList>

        </Wrapper>
    );
}

const topHeight = 30;

const CrOptPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
`;

const CrOptItem = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  background: ${props => (props.selected == true ? '#E19927' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 10px;
`;

const LogoPlaceholder = styled.div`
  height: ${topHeight}px;
  line-height: ${topHeight}px;
  box-sizing: border-box;
  padding-left: 20px;
  cursor: pointer;
  :hover{
    opacity: 0.75;
  }
`;

const LogoImage = styled.img`
  display: inline-block;
  vertical-align: top;
  height: 100%;
  margin-right: 10px;
`;

const LogoText = styled.div`
  display: inline-block;
  vertical-align: top;
  color: white;
  opacity: 0.9;
  font-size: 20px;
  line-height: ${topHeight}px;
  height: ${topHeight}px;
`;

const OptionsList = styled.div`
  position: relative;
  height: calc(100% - ${topHeight}px);
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
`;

const OptionItem = styled.div`
  line-height: 32px;
  height: 32px;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  color: white;
  opacity: 0.9;
  width: 100%;
  padding-left: ${props => (props.extra == true ? 30 : 20)}px;
  background: ${props => (props.selected == true ? DARK_SIDE : 'transparent')};
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  :hover {
    opacity: 1;
  }
`;

const adminOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'users',
        label: 'Пользователи',
        url: '/users'
    },
    // {
    //     name: 'students',
    //     label: 'Ученики',
    //     url: '/students'
    // },
    // {
    //     name: 'teachers',
    //     label: 'Преподаватели',
    //     url: '/teachers'
    // },
    // {
    //     name: 'observers',
    //     label: 'Проверяющие',
    //     url: '/observers'
    // },
    {
        name: 'tasks',
        label: 'БДЗ',
        url: '/tasks'
    },
    {
        name: 'submissions',
        label: 'Проверка заданий',
        url: '/submissions'
    },
    {
        name: 'news',
        label: 'Новости',
        url: '/news'
    },
    {
        name: 'stats',
        label: 'Статистика',
        url: '/stats'
    },
    {
        name: 'settings',
        label: 'Настройки',
        url: '/settings'
    },
    {
        name: 'programs/number/7',
        label: '7 класс',
        url: '/programs/number/7'
    },
    {
        name: 'programs/number/8',
        label: '8 класс',
        url: '/programs/number/8'
    },
    {
        name: 'programs/number/9',
        label: '9 класс',
        url: '/programs/number/9'
    },
    {
        name: 'programs/number/10',
        label: '10 класс',
        url: '/programs/number/10'
    },
    {
        name: 'programs/number/11',
        label: '11 класс',
        url: '/programs/number/11'
    },
    {
        name: 'programs/number/0',
        // label: 'Общее',
        label: 'ВсОШ',
        url: '/programs/number/0'
    },
    {
        name: 'programs/number/1',
        label: 'Эксперимент',
        url: '/programs/number/1'
    }
];

const methodistOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'students',
        label: 'Ученики',
        url: '/students'
    },
    {
        name: 'teachers',
        label: 'Преподаватели',
        url: '/teachers'
    },
    {
        name: 'observers',
        label: 'Проверяющие',
        url: '/observers'
    },
    {
        name: 'tasks',
        label: 'БДЗ',
        url: '/tasks'
    },
    {
        name: 'submissions',
        label: 'Проверка заданий',
        url: '/submissions'
    },
    {
        name: 'stats',
        label: 'Статистика',
        url: '/stats'
    },
    {
        name: 'settings',
        label: 'Настройки',
        url: '/settings'
    },
    {
        name: 'programs/number/7',
        label: '7 класс',
        url: '/programs/number/7'
    },
    {
        name: 'programs/number/8',
        label: '8 класс',
        url: '/programs/number/8'
    },
    {
        name: 'programs/number/9',
        label: '9 класс',
        url: '/programs/number/9'
    },
    {
        name: 'programs/number/10',
        label: '10 класс',
        url: '/programs/number/10'
    },
    {
        name: 'programs/number/11',
        label: '11 класс',
        url: '/programs/number/11'
    },
    {
        name: 'programs/number/0',
        // label: 'Общее',
        label: 'ВсОШ',
        url: '/programs/number/0'
    },
    {
        name: 'programs/number/1',
        label: 'Эксперимент',
        url: '/programs/number/1'
    }
];

const observerOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'students',
        label: 'Ученики',
        url: '/students'
    }
];

const franchiseeOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/',
        group: 1
    },
    {
        name: 'staff',
        label: 'Персонал',
        url: '/staff',
        group: 1
    },
    // {
    //     name: 'schools',
    //     label: 'Школы и кружки',
    //     url: '/schools',
    //     group: 2
    // },
    {
        name: 'students',
        label: 'Ученики',
        url: '/students',
        group: 2
    },
    // {
    //     name: 'courses',
    //     label: 'Курсы',
    //     url: '/courses',
    //     group: 2
    // },
    // {
    //     name: 'documents',
    //     label: 'Документы',
    //     url: '/documents',
    //     group: 2
    // }
];

const teacherOptions = [
    {
        name: 'index',
        label: 'Ученики',
        url: '/'
    },
    {
        name: 'progress',
        label: 'Успеваемость',
        url: '/progress'
    },
    // {
    //     name: 'students',
    //     label: 'Ученики',
    //     url: '/students'
    // },

    // {
    //     name: 'courses',
    //     label: 'Курсы',
    //     url: '/courses'
    // },
    // {
    //     name: 'documents',
    //     label: 'Документы',
    //     url: '/documents'
    // }
    // {
    //     name: 'settings',
    //     label: 'Настройки',
    //     url: '/settings'
    // },
];

const studentsOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    }
    // {
    //     name: 'settings',
    //     label: 'Настройки',
    //     url: '/settings'
    // },
];

const moderatorOptions = [
    {
        name: 'index',
        label: 'Ученики',
        url: '/'
    },
    {
        name: 'teachers',
        label: 'Преподаватели',
        url: '/teachers'
    },
    {
        name: 'progress',
        label: 'Успеваемость',
        url: '/progress'
    },
    // {
    //     name: 'students',
    //     label: 'Ученики',
    //     url: '/students'
    // },

    // {
    //     name: 'courses',
    //     label: 'Курсы',
    //     url: '/courses'
    // },
    // {
    //     name: 'documents',
    //     label: 'Документы',
    //     url: '/documents'
    // }
    // {
    //     name: 'settings',
    //     label: 'Настройки',
    //     url: '/settings'
    // },
];
