import SubscriptionsAPI from '../../api/SubscriptionsAPI'

import * as types from '../ActionTypes'

let loadSubscriptions_ = () => {
    return {
        type: types.LOAD_SUBSCRIPTIONS
    }
}
let loadSubscriptionsSuccess = (subscriptions) => {
    return {
        type: types.LOAD_SUBSCRIPTIONS_SUCCESS,
        subscriptions: subscriptions
    }
}
let loadSubscriptionsFailed = (error) => {
    return {
        type: types.LOAD_SUBSCRIPTIONS_FAIL,
        error: error
    }
}

//thunk
export function loadAllSubscriptions() {
    return (dispatch, getState) => {
        dispatch(loadSubscriptions_());
        return SubscriptionsAPI.getAllSubscriptions().then(
            subscriptions => dispatch(loadSubscriptionsSuccess(subscriptions)),
            error => dispatch(loadSubscriptionsFailed(error))
        )
    }
}

export function loadSubscriptionById(id) {
    return (dispatch, getState) => {
        dispatch(loadSubscriptions_());
        return SubscriptionsAPI.getSubscription(id).then(
            subscription => dispatch(loadSubscriptionsSuccess([subscription])),
            error => dispatch(loadSubscriptionsFailed(error))
        )
    }
}

let createSubscription_ = () => {
    return {
        type: types.CREATE_SUBSCRIPTION
    }
}
let createSubscriptionSuccess = (subscription) => {
    return {
        type: types.CREATE_SUBSCRIPTION_SUCCESS,
        subscription: subscription
    }
}
let createSubscriptionFailed = (error) => {
    return {
        type: types.CREATE_SUBSCRIPTION_FAIL,
        error: error
    }
}

//thunk
export function createSubscription(data) {
    return (dispatch, getState) => {
        dispatch(createSubscription_());
        return SubscriptionsAPI.createSubscription(data).then(
            subscription => dispatch(createSubscriptionSuccess(subscription)),
            error => dispatch(createSubscriptionFailed(error))
        )
    }
}

let updateSubscription_ = () => {
    return {
        type: types.UPDATE_SUBSCRIPTION
    }
}
let updateSubscriptionSuccess = (subscription) => {
    return {
        type: types.UPDATE_SUBSCRIPTION_SUCCESS,
        subscription: subscription
    }
}
let updateSubscriptionFailed = (error) => {
    return {
        type: types.UPDATE_SUBSCRIPTION_FAIL,
        error: error
    }
}

//thunk
export function updateSubscription(data) {
    return (dispatch, getState) => {
        dispatch(updateSubscription_());
        return SubscriptionsAPI.updateSubscription(data).then(
            subscription => dispatch(updateSubscriptionSuccess(subscription)),
            error => dispatch(updateSubscriptionFailed(error))
        )
    }
}

let deleteSubscription_ = () => {
    return {
        type: types.DELETE_SUBSCRIPTION
    }
}
let deleteSubscriptionSuccess = (id) => {
    return {
        type: types.DELETE_SUBSCRIPTION_SUCCESS,
        id: id
    }
}
let deleteSubscriptionFailed = (error) => {
    return {
        type: types.DELETE_SUBSCRIPTION_FAIL,
        error: error
    }
}

//thunk
export function deleteSubscription(id) {
    return (dispatch, getState) => {
        dispatch(deleteSubscription_());
        return SubscriptionsAPI.deleteSubscription(id).then(
            () => dispatch(deleteSubscriptionSuccess(id)),
            error => dispatch(deleteSubscriptionFailed(error))
        )
    }
}
