import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ChessTemplate from '../templates/ChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import DistengTemplate from "../templates/DistengTemplate";

import * as actions from '../../redux/actions/CoursesActions'
import CommonHelper from "../../helpers/CommonHelper";

export default function StudentCoursesApp(props) {
    const dispatch = useDispatch();
    const {currentUserId, loading, courses} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        courses: state.courses.coursesMap.toArray().sort((a, b) => (+a.timestamp - +b.timestamp)),
        loading: state.courses.loading
    }), []));

    useEffect(() => {
        dispatch(actions.loadAllCourses());
    }, []);

    return (
        <DistengTemplate active={'courses'}>

            <Container>

                <CoursesList>
                    {courses.map((c, k) => {
                        return (
                            <CourseItem key={c.id} onClick={() => {
                                CommonHelper.linkTo(`/course/${c.id}`);
                            }} >
                                <CourseName>
                                    {c.name}
                                </CourseName>
                                <CourseDescription>
                                    {c.description}
                                </CourseDescription>
                            </CourseItem>
                        )
                    })}
                </CoursesList>

            </Container>

        </DistengTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const Container = styled.div`
    background: white;
    padding: 10px;
    border-radius: 4px
`;

const CoursesList = styled.div`
    
`;

const CourseItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid whitesmoke;
    margin-bottom: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const CourseName = styled.div`
    font-weight: bold;
`;

const CourseDescription = styled.div`
    
`;
