import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SubmissionsAPI = {

    async getLazySubmission(studentId, taskId) {
        return (await axios.get(`${API_ENDPOINT}/submissions/student/${studentId}/task/${taskId}/lazy`)).data;
    },

    getAllSubmissions() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/submissions/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getSubmission(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/submission/${id}`).then(d => d.data).then(sub => {
                if (sub.error != undefined) {
                    return reject(sub.error);
                }
                resolve(sub);
            }).catch(err => reject(err));
        });
    },

    createSubmission(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/submissions/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateSubmission(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/submissions/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteSubmission(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/submissions/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default SubmissionsAPI;
