import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {useDispatch, useMappedState} from 'redux-react-hook'

import * as announcementsActions from '../../../redux/actions/AnnouncementsActions'

import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'
import AnnouncementForm from "../forms/AnnouncementForm";

import Tabs, {TabItem} from "../../ui/Tabs";
import AnnouncementViewedUsersTool from "../tools/AnnouncementViewedUsersTool";

let getFriendlyRoleName = a => (a == 'teacher' ? 'Преподаватель' : 'Ученик');

export default function AnnouncementsPanel() {
    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);

    const {loading, announcements, currentUser, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin'));
        return {
            loading: state.announcements.loading,
            announcements: state.announcements.announcementsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(announcementsActions.loadAllAnnouncements());
    }, []);

    if (announcements.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    let selectedItem = (selectedId == undefined) ? undefined : announcements.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить уведомление
                </Button>
            </TopPlaceholder>

            <CoursesList>
                {announcements.map((c, k) => {
                    let {text, startTimestamp, endTimestamp, roles} = c;
                    return (
                        <CourseItem key={c.id} onClick={() => {
                            setSelectedId(c.id);
                        }}>

                            <Name>
                                <div dangerouslySetInnerHTML={{__html: text}} ></div>
                            </Name>

                            <Description>
                                {`${moment(startTimestamp).format('DD.MM.YYYY')} - ${moment(endTimestamp).format('DD.MM.YYYY')}, ${roles.map(a => getFriendlyRoleName(a)).join(', ')}`}
                            </Description>

                        </CourseItem>
                    )
                })}
            </CoursesList>

            <Sidebar width={Math.min(920, window.innerWidth)} visible={(selectedItem != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                <Tabs tabs={[
                    {
                        label: 'Информация',
                        content: (
                            <TabItem>
                                {selectedItem == undefined ? null :
                                    <AnnouncementForm {...selectedItem}
                                                      loading={loading}
                                                      canDelete={true}
                                                      onSave={async d => {
                                                          await dispatch(announcementsActions.updateAnnouncement({
                                                              ...d,
                                                              id: selectedItem.id
                                                          }))
                                                      }}
                                                      onDelete={async () => {
                                                          await dispatch(announcementsActions.deleteAnnouncement(selectedItem.id));
                                                      }}
                                    />
                                }
                            </TabItem>
                        )
                    },
                    {
                        label: 'Просмотры',
                        content: (
                            <TabItem>
                                {selectedItem == undefined ? null :
                                    <AnnouncementViewedUsersTool id={selectedItem.id}/>
                                }
                            </TabItem>
                        )
                    },
                    {
                        label: 'Предпросмотр уведомления',
                        content: (
                            <TabItem>
                                {selectedItem == undefined ? null :
                                    <div dangerouslySetInnerHTML={{__html: selectedItem.text}} ></div>
                                }
                            </TabItem>
                        )
                    },

                ]}/>

            </Sidebar>

            <Sidebar width={Math.min(920, window.innerWidth)} visible={addVisible} onCloserClick={() => {
                setAddVisible(undefined);
            }}>

                {addVisible == false ? null :
                    <AnnouncementForm loading={loading}
                                      onSave={async d => {
                                          await dispatch(announcementsActions.createAnnouncement(d));
                                          setAddVisible(false);
                                      }}/>
                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Description = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 15px;
`;

const CoursesList = styled.div`
    
`;

const CourseItem = styled.div`
    padding: 10px;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    
    background: white;
    
    :hover{
      opacity: 0.8;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;
