import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const DocumentsAPI = {

    getAllDocuments() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/documents/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getDocument(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/document/${id}`).then(d => d.data).then(doc => {
                if (doc.error != undefined) {
                    return reject(doc.error);
                }
                resolve(doc);
            }).catch(err => reject(err));
        });
    },

    createDocument(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/documents/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateDocument(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/documents/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteDocument(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/documents/delete`, {id: id}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default DocumentsAPI;
