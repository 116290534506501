import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import {Map, Set} from 'immutable';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import * as actions from "../../../redux/actions/LessonsActions";
import * as roomsActions from "../../../redux/actions/RoomsActions";
import {DARK_SIDE} from "../../ui/Colors";

import Sidebar from 'arui-feather/sidebar'
import {Button} from "../../ui/Button";
import UpdateLessonForm from "../forms/UpdateLessonForm";

import Tabs from '../../ui/Tabs'
import LessonsCalendar from "../tools/LessonsCalendar";
import LessonAttendancePanel from "./LessonAttendancePanel";
import CommonHelper from "../../../helpers/CommonHelper";

import SimpleLessonsList from '../tools/SimpleLessonsList'
import LessonsWeekCalendar from "../tools/LessonsWeekCalendar";
import LessonsFilterPanel from "../tools/LessonsFilterPanel";
import RoomsAPI from "../../../api/RoomsAPI";

const now = +new Date();

export default function SchoolLessonsPanel(props) {
    const {schoolId, teacherId, roomId, groupId, hasFilter = false} = props;
    let {
        currentUser, loading, lessons, school, currentUserIsTeacher, currentUserIsFranchisee, currentUserIsAdmin,
        franchisee, teachers, rooms, groups
    } = useMappedState(useCallback(state => {
        let sc = state.schools.schoolsMap.get(schoolId);
        let lessons = state.lessons.lessonsMap.toArray().filter(u => ((u.schoolId == schoolId)));
        let tchrs = state.users.usersMap.toArray().filter(u => ((u.userRole == 'teacher')))
        tchrs = tchrs.filter(t => (sc.teachersIds != undefined && sc.teachersIds.indexOf(t.id) > -1));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            school: sc,
            lessons: lessons.filter(l => {
                if ((teacherId != undefined && (l.teachersIds.indexOf(teacherId) == -1))
                    || (groupId != undefined && l.groupId != groupId)
                    || (roomId != undefined && l.roomId != roomId)
                ) {
                    return false;
                }
                return true;
            }).sort((a, b) => (+a.startTimestamp - +b.startTimestamp)),
            teachers: tchrs,
            rooms: CommonHelper.getSortedArray(state.rooms.roomsMap.toArray().filter(a => (a.schoolId == schoolId))),
            groups: state.groups.groupsMap.toArray().filter(a => (a.schoolId == schoolId)),
            franchisee: (sc == undefined) ? undefined : state.users.usersMap.get(sc.franchiseeId),
            currentUserIsTeacher: ('teacher' == CommonHelper.getCurrentUserRole(state)),
            currentUserIsFranchisee: ('franchisee' == CommonHelper.getCurrentUserRole(state)),
            currentUserIsAdmin: ('admin' == CommonHelper.getCurrentUserRole(state)),
        }
    }, [schoolId]));
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [filterTeacherId, setFilterTeacherId] = useState((currentUserIsTeacher == true) ? currentUser.id : undefined);
    const [filterRoomId, setFilterRoomId] = useState((hasFilter == false) ? undefined : ((rooms == undefined || rooms.length == 0) ? undefined : rooms[0].id));
    const [selectedId, setSelectedId] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (hasFilter == false) {
            return;
        }
        dispatch(roomsActions.loadAllRooms()).then(pld => {
            if (pld.rooms == undefined || pld.rooms.length == 0) {
                return;
            }
            let fRooms = CommonHelper.getSortedArray(pld.rooms.filter(a => (a.schoolId == schoolId)), 'name');
            console.log('useEffect: got filtered rooms = ', fRooms);
            if (fRooms.length > 0) {
                setFilterRoomId(fRooms[0].id);
            }
        });
    }, []);

    if (school == undefined || franchisee == undefined || currentUser == undefined) {
        return null;
    }
    const selectedLesson = (selectedId == undefined) ? undefined : lessons.filter(g => (g.id == selectedId))[0];
    console.log('selectedLesson = ', selectedLesson);
    console.log('SchoolLessonsPanel: render: teachers = ', teachers);
    const canDelete = (currentUserIsTeacher || currentUserIsFranchisee || currentUserIsAdmin);
    console.log('filterTeacherId, filterRoomId = ', filterTeacherId, filterRoomId);

    const filteredLessons = lessons.filter(a => {
        let f = true;
        if (filterRoomId != undefined) {
            if (a.roomId != filterRoomId) {
                f = false;
            }
        }
        if (filterTeacherId != undefined) {
            if (a.teachersIds.indexOf(filterTeacherId) == -1) {
                f = false;
            }
        }
        return f;
    });

    return (
        <Wrapper>


            <Tabs tabs={[
                {
                    label: 'Календарь',
                    content: (
                        <TabItem>
                            {hasFilter == false ? null :
                                <FilterPlaceholder>
                                    <LessonsFilterPanel
                                        teacherId={filterTeacherId}
                                        roomId={filterRoomId}
                                        allRooms={rooms}
                                        allTeachers={teachers}
                                        onTeacherChange={a => {
                                            setFilterTeacherId(a);
                                        }}
                                        onRoomChange={a => {
                                            setFilterRoomId(a);
                                        }}
                                        canFilterTeacher={(currentUserIsTeacher == false)}
                                    />
                                </FilterPlaceholder>
                            }
                            <LessonsWeekCalendarPlaceholder id={'section-to-print'}>
                                <LessonsWeekCalendar
                                    getRoom={rId => CommonHelper.getItemById(rooms, rId)}
                                    getGroup={rId => CommonHelper.getItemById(groups, rId)}
                                    selectedId={selectedId}
                                    shouldShowRoom={(filterRoomId == undefined)}
                                    lessons={filteredLessons} onLessonClick={(lId, l) => {
                                    setSelectedId(lId);
                                }}/>
                            </LessonsWeekCalendarPlaceholder>
                        </TabItem>
                    )
                },
                {
                    label: 'Предстоящие занятия списком',
                    content: (
                        <TabItem>
                            {hasFilter == false ? null :
                                <FilterPlaceholder>
                                    <LessonsFilterPanel
                                        teacherId={filterTeacherId}
                                        roomId={filterRoomId}
                                        allRooms={rooms}
                                        allTeachers={teachers}
                                        onTeacherChange={a => {
                                            setFilterTeacherId(a);
                                        }}
                                        onRoomChange={a => {
                                            setFilterRoomId(a);
                                        }}
                                        canFilterTeacher={(currentUserIsTeacher == false)}
                                    />
                                </FilterPlaceholder>
                            }
                            <SimpleLessonsList
                                {...{
                                    lessons: filteredLessons.filter(a => (+a.startTimestamp > +now)).sort((a, b) => (+a.startTimestamp - +b.startTimestamp)),
                                    teachers, rooms,
                                    groups, selectedId, setSelectedId,
                                }}
                            />
                        </TabItem>
                    )
                },

                {
                    label: 'Все занятия списком',
                    content: (
                        <TabItem>
                            {hasFilter == false ? null :
                                <FilterPlaceholder>
                                    <LessonsFilterPanel
                                        teacherId={filterTeacherId}
                                        roomId={filterRoomId}
                                        allRooms={rooms}
                                        allTeachers={teachers}
                                        onTeacherChange={a => {
                                            setFilterTeacherId(a);
                                        }}
                                        onRoomChange={a => {
                                            setFilterRoomId(a);
                                        }}
                                        canFilterTeacher={(currentUserIsTeacher == false)}
                                    />
                                </FilterPlaceholder>
                            }
                            <SimpleLessonsList
                                {...{
                                    lessons: filteredLessons.sort((a, b) => (+b.startTimestamp - +a.startTimestamp)),
                                    teachers, rooms,
                                    groups, selectedId, setSelectedId,
                                }}
                            />
                        </TabItem>
                    )
                }

            ]} extraTopButton={
                (
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }} style={{marginLeft: 0}}>
                        Добавить занятие
                    </Button>
                )
            }/>


            <Sidebar width={Math.min(window.innerWidth, 920)} visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <Heading>
                            Создание занятия
                        </Heading>
                        <UpdateLessonForm allTeachers={teachers}
                                          allGroups={groups}
                                          allRooms={rooms}
                                          loading={loading}

                            // shouldSelectTeacher={(teacherId == undefined)}
                                          shouldSelectGroup={groupId == undefined}
                                          shouldSelectRoom={roomId == undefined}

                                          roomId={roomId}
                                          groupId={groupId}
                                          teachersIds={(teacherId == undefined) ? [] : [teacherId]}

                                          onSave={d => {
                                              d.schoolId = schoolId;
                                              dispatch(actions.createLesson(d)).then(pld => {
                                                  if (pld.error != undefined) {
                                                      window.alert(JSON.stringify(pld.error));
                                                  } else {
                                                      setAddModalVisible(false);
                                                  }
                                              });
                                          }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(window.innerWidth, 920)} visible={(selectedLesson != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedLesson == undefined ? null :
                    <div style={{marginTop: -30}}>
                        <Tabs tabs={[
                            {
                                label: 'Информация о занятии',
                                content: (
                                    <TabItem>
                                        <UpdateLessonForm
                                            canDelete={canDelete}
                                            allTeachers={teachers}
                                            allGroups={groups}
                                            allRooms={rooms}
                                            {...selectedLesson} loading={loading} onSave={d => {
                                            dispatch(actions.updateLesson({id: selectedId, ...d})).then(pld => {
                                                if (pld.error != undefined) {
                                                    window.alert(JSON.stringify(pld.error));
                                                } else {
                                                    setSelectedId(undefined);
                                                }
                                            });
                                        }}
                                            onDelete={() => {
                                                dispatch(actions.deleteLesson(selectedLesson.id)).then(pld => {
                                                    if (pld.error != undefined) {
                                                        window.alert(JSON.stringify(pld.error));
                                                    } else {
                                                        setSelectedId(undefined);
                                                    }
                                                });
                                            }}
                                            // shouldSelectTeacher={(teacherId == undefined)}
                                            shouldSelectGroup={groupId == undefined}
                                            shouldSelectRoom={roomId == undefined}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Посещаемость',
                                content: (
                                    <TabItem>
                                        <LessonAttendancePanel id={selectedId}/>
                                    </TabItem>
                                )
                            }
                        ]} safeMode={true}/>


                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
`;


const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;

const LessonsCalendarPlaceholder = styled.div`
    //height: 80vh;
    height: 500px;
    width: 100%;
    background: white;
    //padding: 5px;
    //box-sizing: border-box;
`;

const LessonsWeekCalendarPlaceholder = styled.div`
    width: 100%;
    background: white;
`;

const FilterPlaceholder = styled.div`
    margin-bottom: 10px;
`;
