import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import {DARK_SIDE} from "../../ui/Colors";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

export default function SubmissionsList(props) {
    let {
        enhancedSubmissions = [], selectedId = undefined, onSelect = id => {

        },
        hasBdzFilter = false
    } = props;

    const sortedItems = enhancedSubmissions;

    return (
        <Wrapper>

            <SubmissionsListPlaceholder>

                <PaginatorWrapper renderItems={items => items.map((sub, k) => {
                    let {student, task, id, center, attachments, observer, mark, isChecked} = sub;
                    let t = (attachments == undefined || attachments.length == 0) ? undefined : attachments[0].t;
                    return (
                        <SubItem selected={(sub.id == selectedId)} key={sub.id} onClick={() => {
                            onSelect(sub.id);
                        }}>
                            <SubTopRow>
                                <Left>
                                    <StudentInfo>
                                        <StudentName>
                                            {isChecked == true ?
                                                <StatusImg src={require('../../../assets/images/green_checked.svg')}/>
                                                :
                                                <StatusImg src={require('../../../assets/images/pending_.svg')}/>
                                            }
                                            <span>
                                                {`${CommonHelper.getFullName(student)}`}
                                            </span>
                                            <ClassSpan>
                                                {`${student.classNumber} класс`}
                                            </ClassSpan>
                                        </StudentName>
                                        <CenterName>
                                            {`${center.city}, ${center.name}`}
                                            <span style={{marginLeft: 10}}>
                                                {`Дата отправки: ${moment(t).format('DD.MM.YYYY HH:mm:ss')}`}
                                            </span>
                                        </CenterName>
                                        {/*<AttachmentsInfoPlaceholder>*/}
                                        {/*{`Прикреплено файлов: ${attachments.length}`}*/}
                                        {/*</AttachmentsInfoPlaceholder>*/}
                                    </StudentInfo>
                                </Left>
                                <Right>
                                    <TaskName>
                                        {task.name}
                                    </TaskName>
                                    {sub.mark == undefined ? null :
                                        <MarkPlaceholder>
                                            {`Оценка: ${mark}`}
                                            <span style={{marginLeft: 4, fontSize: 10, opacity: 0.6, color: 'black'}}>из 50</span>
                                        </MarkPlaceholder>
                                    }
                                    {observer == undefined ? null :
                                        <CenterName style={{fontWeight: 'bold'}}>
                                            {`${CommonHelper.getFullName(observer)}`}
                                        </CenterName>
                                    }
                                </Right>
                            </SubTopRow>
                        </SubItem>
                    )
                })} items={sortedItems}/>

                {/*{sortedItems.map((sub, k) => {*/}
                    {/*let {student, task, id, center, attachments, observer, mark, isChecked} = sub;*/}
                    {/*let t = (attachments == undefined || attachments.length == 0) ? undefined : attachments[0].t;*/}
                    {/*return (*/}
                        {/*<SubItem selected={(sub.id == selectedId)} key={sub.id} onClick={() => {*/}
                            {/*onSelect(sub.id);*/}
                        {/*}}>*/}
                            {/*<SubTopRow>*/}
                                {/*<Left>*/}
                                    {/*<StudentInfo>*/}
                                        {/*<StudentName>*/}
                                            {/*{isChecked == true ?*/}
                                                {/*<StatusImg src={require('../../../assets/images/green_checked.svg')}/>*/}
                                                {/*:*/}
                                                {/*<StatusImg src={require('../../../assets/images/pending_.svg')}/>*/}
                                            {/*}*/}
                                            {/*<span>*/}
                                                {/*{`${CommonHelper.getFullName(student)}`}*/}
                                            {/*</span>*/}
                                            {/*<ClassSpan>*/}
                                                {/*{`${student.classNumber} класс`}*/}
                                            {/*</ClassSpan>*/}
                                        {/*</StudentName>*/}
                                        {/*<CenterName>*/}
                                            {/*{`${center.city}, ${center.name}`}*/}
                                            {/*<span style={{marginLeft: 10}}>*/}
                                                {/*{`Дата отправки: ${moment(t).format('DD.MM.YYYY HH:mm:ss')}`}*/}
                                            {/*</span>*/}
                                        {/*</CenterName>*/}
                                        {/*/!*<AttachmentsInfoPlaceholder>*!/*/}
                                        {/*/!*{`Прикреплено файлов: ${attachments.length}`}*!/*/}
                                        {/*/!*</AttachmentsInfoPlaceholder>*!/*/}
                                    {/*</StudentInfo>*/}
                                {/*</Left>*/}
                                {/*<Right>*/}
                                    {/*<TaskName>*/}
                                        {/*{task.name}*/}
                                    {/*</TaskName>*/}
                                    {/*{sub.mark == undefined ? null :*/}
                                        {/*<MarkPlaceholder>*/}
                                            {/*{`Оценка: ${mark}`}*/}
                                            {/*<span style={{marginLeft: 4, fontSize: 10, opacity: 0.6, color: 'black'}}>из 50</span>*/}
                                        {/*</MarkPlaceholder>*/}
                                    {/*}*/}
                                    {/*{observer == undefined ? null :*/}
                                        {/*<CenterName style={{fontWeight: 'bold'}}>*/}
                                            {/*{`${CommonHelper.getFullName(observer)}`}*/}
                                        {/*</CenterName>*/}
                                    {/*}*/}
                                {/*</Right>*/}
                            {/*</SubTopRow>*/}
                        {/*</SubItem>*/}
                    {/*)*/}
                {/*})}*/}
            </SubmissionsListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const SubmissionsListPlaceholder = styled.div`
    
`;

const SubItem = styled.div`
    margin-bottom: 10px;
    background: white;
    padding: 10px;
    border-radius: 4px;
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
`;

const SubTopRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    :hover{
      opacity: 0.5;
    }
`;

const Left = styled.div`
    max-width: 80%;
`;

const Right = styled.div`
    text-align: right;
`;

const StudentInfo = styled.div`
    
`;

const StudentName = styled.div`
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
`;

const CenterName = styled.div`
    opacity: 0.5;
    font-size: 11px;
    font-style: italic;
`;

const ClassSpan = styled.span`
    background-color: ${DARK_SIDE};
    color: white;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    margin-left: 5px;
    opacity: 0.85;
    font-weight: normal;
    font-size: 12px;
`;

const TaskName = styled.div`
    text-align: right;
    font-weight: bold;
`;

const MarkPlaceholder = styled.div`
    display: inline-block;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    border: 1px dashed lightgrey;
`;

const StatusImg = styled.img`
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
`;
