import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import StudentTasksPanel from "../submissions/panels/StudentTasksPanel";

export default function LandingProfileApp(props) {
    let {id, subj} = props.match.params;
    const bgImage = undefined; //todo: change

    return (
        <LandingTemplate active={'programms'} subj={subj}>

            <FullPageImageSection height={`220px`} hasTopLogo={false} image={bgImage}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel motto={(
                        <div>
                            ДОМАШНИЕ ЗАДАНИЯ
                        </div>
                    )}
                                       hasTopLogo={false}
                    />
                </div>
            </FullPageImageSection>

            <BottomContentPlaceholder>

                <RespContainer>
                    <StudentTasksPanel
                        subj={subj}
                        canFeelProfile={true} />
                </RespContainer>

            </BottomContentPlaceholder>

        </LandingTemplate>
    );
}


const BottomContentPlaceholder = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    background: white;
`;

const RespContainer = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-left: 24px;
    box-sizing: border-box;
    @media(max-width: 1200px){
      width: 100%;
    }
`;
