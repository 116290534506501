import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommentsAPI from "../../../api/CommentsAPI";

import CommentsList from '../tools/CommentsList'
import SendMessageControl from "../tools/SendMessageControl";

export default function SubjectCommentsPanel(props) {
    const {subjectId = undefined} = props;
    const [comments, setComments] = useState([]);
    const [usersMap, setUsersMap] = useState({});
    const [loading, setLoading] = useState(false);

    let {currentUser, currentUserId} = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, [subjectId]));

    useEffect(() => {
        setLoading(true);
        CommentsAPI.getSubjectCommentsWithUsersMap(subjectId).then(d => {
            setUsersMap(d.usersMap);
            setComments(d.comments);
            setLoading(false);
        })
    }, [subjectId]);

    return (
        <Wrapper>

            <Heading>
                Сообщения
            </Heading>

            <FormPlaceholder>
                <SendMessageControl loading={loading}
                                    sendPlaceholder={'Ваше сообщение'}
                                    onSend={async txt => {
                                        setLoading(true);
                                        await CommentsAPI.createComment({
                                            userId: currentUserId,
                                            subjectId: subjectId,
                                            text: txt
                                        });
                                        let d = await CommentsAPI.getSubjectCommentsWithUsersMap(subjectId);
                                        setUsersMap(d.usersMap);
                                        setComments(d.comments);
                                        setLoading(false);
                                    }}/>
            </FormPlaceholder>

            <ListPlaceholder>
                <CommentsList comments={comments}
                              usersMap={usersMap}
                              loading={loading}/>
            </ListPlaceholder>

        </Wrapper>
    );
}

const Heading = styled.div`
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
`;

const Wrapper = styled.div`
    margin-top: 10px;
`;

const ListPlaceholder = styled.div`
    
`;

const FormPlaceholder = styled.div`
    height: 80px;
    width: 100%;
`;
