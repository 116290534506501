import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'

export default function CenterForm(props) {
    const {
        onSave = () => {

        },
        loading = false,
        canDelete = false,
        onDelete = () => {

        }
    } = props;
    // let {name, address, region, city, contactName, contacts, contactNameInfo} = it;

    const [formData, setFormData] = useState({
        name: props.name,
        address: props.address,
        region: props.region,
        city: props.city,
        contactName: props.contactName,
        contacts: props.contacts,
        teacherName: props.teacherName,
        phone: props.phone,
        email: props.email,
        curator: props.curator,
        website: props.website,
    });

    return (
        <Wrapper>
            <Field>
                <Label>
                    Название
                </Label>
                <Textarea value={formData.name} onChange={evt => {
                    setFormData({...formData, name: evt.target.value})
                }}/>
            </Field>
            <Field>
                <Label>
                    Адрес
                </Label>
                <Textarea value={formData.address} onChange={evt => {
                    setFormData({...formData, address: evt.target.value})
                }}/>
            </Field>
            <Field>
                <Label>
                    Регион
                </Label>
                <Textarea value={formData.region} onChange={evt => {
                    setFormData({...formData, region: evt.target.value})
                }}/>
            </Field>
            <Field>
                <Label>
                    Город
                </Label>
                <Textarea value={formData.city} onChange={evt => {
                    setFormData({...formData, city: evt.target.value})
                }}/>
            </Field>
            <Field>
                <Label>
                    Контактное лицо
                </Label>
                <Textarea value={formData.contactName} onChange={evt => {
                    setFormData({...formData, contactName: evt.target.value})
                }}/>
            </Field>
            <Field>
                <Label>
                    Контактные данные
                </Label>
                <Textarea value={formData.contacts} onChange={evt => {
                    setFormData({...formData, contacts: evt.target.value})
                }}/>
            </Field>
            <Field>
                <Label>
                    ФИО учителя
                </Label>
                <Textarea value={formData.teacherName} onChange={evt => {
                    setFormData({...formData, teacherName: evt.target.value})
                }}/>
            </Field>
            {/*<Field>*/}
                {/*<Label>*/}
                    {/*Телефон*/}
                {/*</Label>*/}
                {/*<Textarea value={formData.phone} onChange={evt => {*/}
                    {/*setFormData({...formData, phone: evt.target.value})*/}
                {/*}}/>*/}
            {/*</Field>*/}
            {/*<Field>*/}
                {/*<Label>*/}
                    {/*Куратор*/}
                {/*</Label>*/}
                {/*<Textarea value={formData.curator} onChange={evt => {*/}
                    {/*setFormData({...formData, curator: evt.target.value})*/}
                {/*}}/>*/}
            {/*</Field>*/}
            {/*<Field>*/}
                {/*<Label>*/}
                    {/*Почта*/}
                {/*</Label>*/}
                {/*<Textarea value={formData.email} onChange={evt => {*/}
                    {/*setFormData({...formData, email: evt.target.value})*/}
                {/*}}/>*/}
            {/*</Field>*/}

            <Field>
                <Label>
                    Ссылка на сайт
                </Label>
                <Input value={formData.website} onChange={evt => {
                    setFormData({...formData, website: evt.target.value})
                }}/>
            </Field>



            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave(formData);
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

            {canDelete == false ? null :
                <div>
                    <DeleteSpan onClick={() => {
                        if (window.confirm('Вы уверены?') == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        удалить
                    </DeleteSpan>
                </div>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const DeleteSpan = styled.span`
    color: red;
    cursor: pointer;
    :hover{
      opacity: 0.5;
    }
`;
