import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Button} from '@blueprintjs/core'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import katex from 'katex';

const DEFAULT_TEX = `c = \\pm\\sqrt{a^2 + b^2}`;

const getHtml = (tex = ``) => {
    let html = katex.renderToString(tex, {
        throwOnError: false
    });
    return html;
}

export default function TexViewer(props) {
    const ref = useRef(null);
    let {tex = ''} = props;
    let html = getHtml(tex);
    console.log('TexViewer: render: html = ', html);
    useEffect(() => {
        try {
            katex.render(tex, ref.current, {
                displayMode: true,
                macros: {
                    "\\RR": "\\mathbb{R}"
                }
            });
        } catch (exc) {
            console.log('--->>>  can not render tex = ', tex);
        }
    }, [tex]);

    return (
        <Wrapper ref={ref}>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
