import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";


import {Container} from "../ui/Container";
import AdminAllStudentsPanel from "../admin/panels/AdminAllStudentsPanel";
import AdminAllTeachersPanel from "../admin/panels/AdminAllTeachersPanel";
import AdminAllObserversPanel from "../admin/panels/AdminAllObserversPanel";

const ROLES = [
    {
        value: 'students',
        label: 'Ученики'
    },
    {
        value: 'teachers',
        label: 'Преподаватели'
    },
    {
        value: 'coordinators',
        label: 'Координаторы'
    },
    {
        value: 'observers',
        label: 'Проверяющие'
    }
];

export default function AdminUsersApp(props) {
    const [mode, setMode] = useState('students');

    return (
        <ChessTemplate active={'users'}>

            <RolesPlaceholder>
                {ROLES.map((a, i) => {
                    return (
                        <RoleItem key={i} selected={(mode == a.value)} onClick={() => {
                            setMode(a.value);
                        }}>
                            {a.label}
                        </RoleItem>
                    );
                })}
            </RolesPlaceholder>

            <div key={mode} >
                {mode != 'students' ? null :
                    <AdminAllStudentsPanel/>
                }

                {mode != 'teachers' ? null :
                    <AdminAllTeachersPanel isModeraor={false} />
                }

                {mode != 'coordinators' ? null :
                    <AdminAllTeachersPanel isModerator={true} />
                }

                {mode != 'observers' ? null :
                    <AdminAllObserversPanel/>
                }
            </div>


        </ChessTemplate>
    );
}

const RolesPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const RoleItem = styled.div`
    margin-right: 20px;
    border-bottom: ${props => (props.selected == true ? '2px solid #151B25' : '2px solid transparent')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    
`;
