import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";

export default function LandingContactsApp(props) {
    let {id, subj} = props.match.params;
    const bgImage = undefined; //todo: change

    return (
        <LandingTemplate active={'contacts'} subj={subj} >

            <FullPageImageSection height={`220px`} hasTopLogo={false} image={bgImage}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel motto={(
                        <div>
                            КОНТАКТЫ
                        </div>
                    )}
                                       hasTopLogo={false}
                    />
                </div>
            </FullPageImageSection>

            <BottomContentPlaceholder>

                <RespContainer>
                    <TripletsPlaceholder>

                        <Triplet>
                            <div>
                                <TrImage src={require('../../assets/images/telephone.svg')}/>
                            </div>
                            <TrName>
                                Телефон
                            </TrName>
                            <TrText>
                                +7 (498) 713-91-73
                            </TrText>
                        </Triplet>

                        <Triplet>
                            <div>
                                <TrImage src={require('../../assets/images/close-envelope.svg')}/>
                            </div>
                            <TrName>
                                E-mail
                            </TrName>
                            <TrText>
                                <ContactLink href="mailto:osop@mipt.ru" target={"blank"}>
                                    osop@mipt.ru
                                </ContactLink>
                            </TrText>
                        </Triplet>

                        <Triplet>
                            <div>
                                <TrImage src={require('../../assets/images/maps-and-flags.svg')}/>
                            </div>
                            <TrName>
                                Адрес
                            </TrName>
                            <TrText>
                                <ContactLink
                                    target={"blank"}
                                    href="https://yandex.ru/maps/214/dolgoprudniy/house/institutskiy_pereulok_7/Z04YcwZpTkMDQFtvfXVycXRjYg==/?ll=37.519763%2C55.930806&sll=37.519763%2C55.930806&sspn=0.014576%2C0.004610&z=17.08">
                                    Московская область, г. Долгопрудный
                                    <br/>
                                    {/*Научный пер. 4, УЛК-1 "Цифра"*/}
                                    Институтский переулок 7, Аудиторный корпус
                                </ContactLink>
                            </TrText>
                        </Triplet>

                    </TripletsPlaceholder>
                </RespContainer>

            </BottomContentPlaceholder>

        </LandingTemplate>
    );
}


const size = 100;

const BottomContentPlaceholder = styled.div`
    padding-top: 20px;
    padding-bottom: 50px;
    background: white;
`;

const RespContainer = styled.div`
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1200px){
      width: 100%;
    }
`;

const TripletsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    @media(max-width: 720px){
      display: block;
    }
`;

const Triplet = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    @media(max-width: 720px){
      margin-bottom: 30px;
    }
`;

const TrImage = styled.img`
    width: 40px;
    height: 40px;
`;

const TrName = styled.div`
    text-align: center;
    font-size: 18px;
    color: ${BLUE};
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
`;

const TrText = styled.div`
    color: black;
    text-align: center;
`;

const ContactLink = styled.a`
    text-decoration: none;
    color: black;
    :hover{
      text-decoration: underline;
      color: ${YELLOW};
    }
`;
