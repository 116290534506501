import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import UsersList from "../../users/lists/UsersList";
import * as usersActions from "../../../redux/actions/UsersActions";
import UpdateUserForm from "../../users/forms/UpdateUserForm";

import {Button} from "../../ui/Button";
import UpdateStaffForm from "../../users/forms/UpdateStaffForm";

export default function FranchiseeStaffPanel(props) {
    const {franchiseeId} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, users, schools} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let frId = (franchiseeId == undefined) ? state.users.currentUserId : franchiseeId;
        return {
            currentUser: currentUser,
            loading: state.users.loading,
            users: state.users.usersMap.toArray().filter(u => ((u.franchiseeId == frId) && (u.userRole != 'student'))),
            schools: state.schools.schoolsMap.toArray().filter(a => (a.franchiseeId == frId)).sort((a, b) => (+b.timestamp - +a.timestamp))
        }
    }, [franchiseeId]));
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(undefined);
    const selectedUser = (setSelectedUserId == undefined) ? undefined : users.filter(u => (u.id == selectedUserId))[0];

    const sortedUsers = users.sort((a, b) => {
        let s1 = `${a.lastName} ${a.firstName}`;
        let s2 = `${b.lastName} ${b.firstName}`;
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    return (
        <Wrapper>

            <BottomPlaceholder hasTopBorder={(users.length > 0)}>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить
                </Button>
            </BottomPlaceholder>

            <UsersList users={sortedUsers}
                       selectedUserId={selectedUserId}
                       onUserClick={(u, uId) => {
                           setSelectedUserId(uId);
                       }}
            />

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <Heading>
                            Добавление
                        </Heading>
                        <UpdateStaffForm emailVisible={true}
                                        passwordVisible={true}
                                        roleSelectorVisible={true}
                                        avatarVisible={true}
                                        allSchools={schools}
                                        loading={loading} onSave={data => {
                            dispatch(usersActions.createUser({
                                ...data,
                            })).then(pld => {
                                setAddModalVisible(false);
                            })
                        }}/>
                    </div>
                }

            </Sidebar>


            <Sidebar visible={(selectedUser != undefined)} onCloserClick={() => {
                setSelectedUserId(undefined);
            }}>

                {selectedUser == undefined ? null :
                    <div>
                        <Heading>
                            Редактирование
                        </Heading>
                        <UpdateStaffForm emailVisible={false}
                                        passwordVisible={false}
                                         allSchools={schools}
                                        {...selectedUser}
                                        avatarVisible={true}
                                        loading={loading} onSave={data => {
                            dispatch(usersActions.updateUser({
                                ...data,
                                id: selectedUserId
                            })).then(pld => {
                                setSelectedUserId(undefined);
                            })
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const BottomPlaceholder = styled.div`
    margin-top: ${props => (props.hasTopBorder == true ? '10px' : 0)};
    padding-top: ${props => (props.hasTopBorder == true ? '10px' : 0)};
    border-top: ${props => (props.hasTopBorder == true ? '1px solid whitesmoke' : 'none')};
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
`;
