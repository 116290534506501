import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import Tabs, {TabItem} from "../../ui/Tabs";

const renderItems = items => {
    return (
        <Table>
            <THead>
            <Tr>
                <Th>
                    Класс
                </Th>
                <Th>
                    ФИО
                </Th>
                <Th>
                    Email
                </Th>
                <Th>
                    Пароль
                </Th>
            </Tr>
            </THead>

            <TBody>

            {items.map(a => {
                let {firstName, lastName, middleName, userPassword, email, id, classNumber} = a;
                return (
                    <Tr key={id}>
                        <Td>
                            {classNumber}
                        </Td>
                        <Td>
                            {`${lastName} ${firstName} ${middleName}`}
                        </Td>
                        <Td>
                            {email}
                        </Td>
                        <Td>
                            {userPassword}
                        </Td>
                    </Tr>
                )
            })}
            </TBody>

        </Table>
    )
}

export default function UsersPassPanel(props) {
    let {centerId, userRole = 'student'} = props;
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/users/all`, {
            headers: {
                
            }
        }).then(d => d.data).then(users => {
            users = users.filter(a => ((a.userRole == userRole) && a.userPassword != undefined));
            users = users.filter(a => (a.centerId == centerId)).sort((a, b) => {
                let s1 = `${a.classNumber} ${a.lastName.toLowerCase()}`;
                let s2 = `${b.classNumber} ${b.lastName.toLowerCase()}`;
                if (s1 < s2) {
                    return -1;
                }
                if (s1 > s2) {
                    return 1;
                }
                return 0;
            });
            setUsers(users);
        })
    }, [centerId]);

    return (
        <Wrapper>

            {renderItems(users)}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    margin-top: 20px;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    border: 1px solid lightgrey;
`;

const Tr = styled.tr`
    
`;

const Td = styled.td`
    padding: 5px;
    border: 1px solid lightgrey;
`;

const Th = styled.th`
    padding: 5px;
    border: 1px solid lightgrey;
    text-align: left;
`;

const THead = styled.thead`
    
`;

const TBody = styled.tbody`
    
`;
