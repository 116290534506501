import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import * as submissionsActions from "../../../redux/actions/SubmissionsActions";
import * as tasksActions from "../../../redux/actions/TasksActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import * as centersActions from "../../../redux/actions/CentersActions";
import CommonHelper from "../../../helpers/CommonHelper";
import {DARK_SIDE, LIGHT_BLUE} from "../../ui/Colors";
import ViewTaskTool from "../tools/ViewTaskTool";
import ViewSubmissionTool from "../tools/ViewSubmissionTool";
import SubmissionsList from "../lists/SubmissionsList";
import ObserverSubmissionPanel from "./ObserverSubmissionPanel";
import SubjectCommentsPanel from "../../comments/panels/SubjectCommentsPanel";

import Tabs, {TabItem} from "../../ui/Tabs";

export default function AdminUserSubmissionsPanel(props) {
    const dispatch = useDispatch();
    const {studentId} = props;
    const [selectedId, setSelectedId] = useState(undefined);
    let {currentUser, loading, usersMap, enhancedSubmissions, tasksMap} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let stId = (studentId == undefined) ? state.users.currentUserId : studentId;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId || state.tasks.loading || state.submissions.loading,
            loading: state.tasks.loading || state.submissions.loading || state.users.loading,
            enhancedSubmissions: CommonHelper.getAllEnhancedSubmissionsByState(state).filter(a => (a.studentId == stId)),
        }
    }, []));

    useEffect(() => {
        dispatch(centersActions.loadAllCenters());
        dispatch(usersActions.loadAllUsers()); // todo: optimize it!
        dispatch(tasksActions.loadAllTasks());
        dispatch(submissionsActions.loadAllSubmissions()); // todo: optimize it!
    }, []);

    if (loading == true && enhancedSubmissions.length == 0) {
        return (
            <Code/>
        );
    }

    let filteredEnhancedSubmissions = enhancedSubmissions;

    let selectedSubmission = (selectedId == undefined) ? undefined : enhancedSubmissions.filter(a => (a.id == selectedId))[0];
    let selectedSubmissionTask = (selectedSubmission == undefined) ? undefined : selectedSubmission.task;
    let checkedSubmissions = filteredEnhancedSubmissions.filter(a => (a.observer != undefined && a.mark != undefined));
    let notCheckedNumber = filteredEnhancedSubmissions.length - checkedSubmissions.length;

    if (filteredEnhancedSubmissions.length == 0) {
        return (
            <Wrapper>
                Ученик еще ничего не отправлял
            </Wrapper>
        )
    }

    console.log('render: selectedSubmissionTask = ', selectedSubmissionTask);

    return (
        <Wrapper>

            <TopStatsPlaceholder>
                <div>
                    <span style={{marginRight: 10}}>
                        {`Отправлено заданий: ${checkedSubmissions.length}`}
                    </span>
                    {' | '}
                    <span style={{marginLeft: 10}}>
                        {notCheckedNumber == 0 ? 'Все задания проверены!' : `Ожидает проверки: ${notCheckedNumber}`}
                    </span>
                </div>
            </TopStatsPlaceholder>

            <SubmissionsListPlaceholder>
                <SubmissionsList selectedId={selectedId}
                                 enhancedSubmissions={enhancedSubmissions} onSelect={id => {
                    setSelectedId(id);
                }}/>
            </SubmissionsListPlaceholder>


            <Sidebar width={Math.min(720, window.innerWidth)}
                     visible={(selectedSubmission != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedSubmission == undefined ? null :
                    <div>

                        <SelStudName>
                            {`${selectedSubmission.student.lastName} ${selectedSubmission.student.firstName} ${selectedSubmission.student.middleName}`}
                            <sup style={{fontWeight: 'normal', opacity: 0.5, fontSize: 12, marginLeft: 5}}>
                                {`${selectedSubmission.student.classNumber} класс`}
                            </sup>
                        </SelStudName>
                        <SelStudCenterName>
                            {`${selectedSubmission.center.city}, ${selectedSubmission.center.name}`}
                        </SelStudCenterName>

                        {selectedSubmissionTask == undefined ? null :
                            <ViewTaskTool {...selectedSubmissionTask} />
                        }

                        <ViewSubmissionTool {...selectedSubmission} hasTeacherSection={false}
                                            task={selectedSubmissionTask} currentUser={currentUser}
                        />

                        <ObserverSection>
                            <ObserverSubmissionPanel id={selectedSubmission.id}/>
                        </ObserverSection>

                        <div>
                            <SubjectCommentsPanel subjectId={selectedSubmission.id}/>
                        </div>

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const CommentsSection = styled.div`
    
`;

const CommentsHeading = styled.div`
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
`;


const SubmissionsListPlaceholder = styled.div`
    padding: 0px;
`;


const TopStatsPlaceholder = styled.div`
    margin-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
`;


const SelStudName = styled.div`
    font-size: 24px;
    font-weight: bold;
`;


const SelStudCenterName = styled.div`
    font-size: 13px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
    padding-bottom: 10px;
    opacity: 0.5;
`;


const ObserverSection = styled.div`
    padding: 10px;
    background: ${LIGHT_BLUE};
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid lightgrey;
`;
