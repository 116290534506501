import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import isEmail from 'validator/lib/isEmail'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'
import CommonHelper from "../../../helpers/CommonHelper";

export default function StyledLoginForm(props) {
    const {
        onSubmit = () => {

        },
        onSignUpClick = () => {
        },
        onLostPasswordClick = () => {
        }, loading = false
    } = props;
    const [errorMessages, setErrorMessages] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    let getFldError = fld => {
        let fs = errorMessages.filter(a => (a.field.indexOf(fld) > -1));
        if (fs.length == 0) {
            return undefined;
        }
        return fs[0].message;
    };

    return (
        <Wrapper>

            <TopPlaceholder>
                <LinedText text={'Вход'}/>
            </TopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <Input value={email} type={'email'} placeholder={'Ваш E-Mail'} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                    {getFldError('email') == undefined ? null :
                        <ErrorMessage>
                            {getFldError('email')}
                        </ErrorMessage>
                    }
                </Field>
                <Field>
                    <Input value={password} type={'password'} placeholder={'Пароль'} onChange={e => {
                        setPassword(e.target.value);
                    }}
                           onKeyDown={e => {
                               if (e.key == 'Enter') {
                                   onSubmit({email, password});
                               }
                           }}
                    />
                </Field>
                <Field>
                    <Button onClick={() => {
                        let errorMessages_ = [];
                        if (isEmail(email) == false) {
                            errorMessages_.push({field: 'email', message: 'Введен некорректный e-mail'});
                        }
                        setErrorMessages(errorMessages_);
                        if (errorMessages_.length > 0) {
                            return;
                        }
                        onSubmit({email, password})
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Войти'
                        }
                    </Button>
                </Field>
            </ContentPlaceholder>

            <BottomPlaceholder>
                <Link onClick={() => {
                    onLostPasswordClick();
                }}>
                    Забыли пароль?
                </Link>
                <Link onClick={() => {
                    CommonHelper.linkTo(`/about`);
                    setTimeout(() => {
                        try {
                            let elmnt = document.getElementById("f_how_to_signup");
                            elmnt.scrollIntoView({behavior: 'smooth'});
                        } catch (e) {

                        }
                    }, 250);

                }}>
                    Как зарегистрироваться?
                </Link>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
                //width: 100%;
                //height: 100%;
                padding: 20px;
                box-sizing: border-box;
                width: 400px;
                @media(max-width: 920px){
                width: 100%;
            }
                `;

const TopPlaceholder = styled.div`
                width: 100%;
                margin-bottom: 40px;
                `;

const ContentPlaceholder = styled.div`

                `;

const BottomPlaceholder = styled.div`
                display: flex;
                flex-direction: row;
                align-items: center;
                //justify-content: center;
                justify-content: space-between;
                margin-top: 20px;
                `;

const Field = styled.div`
                margin-bottom: 10px;
                width: 100%;
                `;

const Link = styled.div`
                color: #999999;
                text-decoration: underline;
                cursor: pointer;
                font-size: 14px;
                :hover{
                opacity: 0.8;
            }
                `;

const ErrorMessage = styled.div`
                font-size: 12px;
                color: red;
                text-align: center;
                `;
