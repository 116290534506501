import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import useComponentSize from "@rehooks/component-size";

export default function PlayerProgress(props) {
    const {
        duration = 10,

        onSeek = t => {

        }

    } = props;

    const wrapperRef = useRef();
    const currTimeRef = useRef();

    let size = useComponentSize(wrapperRef);

    return (
        <Wrapper ref={wrapperRef} className={'os_player_progress'} id={'os_player_progress'}
        >
            <RulerOverlay onClick={e => {
                let rect = e.target.getBoundingClientRect();
                let x = e.clientX - rect.left; //x position within the element.
                onSeek(x / size.width);
            }}
                          onMouseMove={e => {
                              try {
                                  document.getElementById('hov_time_vert').style.display = 'block';
                                  let rect = e.target.getBoundingClientRect();
                                  let x = e.clientX - rect.left; //x position within the element.
                                  document.getElementById('hov_time_vert').style.left = `${x}px`;
                                  let t = (1.0 * (+x) / (+size.width)) * window.currVideoDuration;
                                  let fracSec = Math.round((+t) * 100.0) % 100;
                                  let sec = Math.floor(+t);
                                  let minutes = Math.floor(+sec / 60.0);
                                  sec = +sec - (+minutes) * 60;
                                  document.getElementById('hov_curr_bubble').innerText = `${`${minutes}`.padStart(2, '0')}:${`${sec}`.padStart(2, '0')}.${`${fracSec}`.padStart(2, '0')}`;
                              } catch (e) {

                              }
                          }}
                          onMouseLeave={evt => {
                              try {
                                  document.getElementById('hov_time_vert').style.display = 'none';
                              } catch (e) {

                              }
                          }}
                          onMouseOver={evt => {

                          }}>
            </RulerOverlay>

            <HoverTimeHandle height={20} id={'hov_time_vert'}>
                <HoverTimeHandleInner>
                    <HoverTimeHandleBubble id={'hov_curr_bubble'}>

                    </HoverTimeHandleBubble>
                </HoverTimeHandleInner>
            </HoverTimeHandle>
            <CurrentTimeHandle ref={currTimeRef} style={{height: 20}} id={'current_time_bar'} >
                <CurrentTimeHandleInner>
                    {/*<TickHandImg src={require('../../../assets/images/pol_qw.svg')}/>*/}
                </CurrentTimeHandleInner>
            </CurrentTimeHandle>

        </Wrapper>
    );
}

const RulerOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: ${props => props.height}px;
    z-index: 10;
    background: rgba(255, 255, 255, 0.03);
    height: 20px;
`;

const TickHandImg = styled.img`
    height: 14px;
    width: 22px;
    position: absolute;
    top: -2px;
    left: -10.5px;
`;


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: grey;
    position: relative;
`;

const CurrentTimeHandle = styled.div`
    width: 2px;
    position: absolute;
    bottom: 0px;
    z-index: 1000;
    left: 0px;
    background: rgba(255,172,48,0.7);
`;

const HoverTimeHandle = styled.div`
    width: 2px;
    position: absolute;
    top: 0px;
    height: ${props => props.height}px;
    z-index: 9;
    left: 20px;
    background: rgba(255,172,48,0.75);
    display: none;
`;

const HoverTimeHandleInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const HoverTimeHandleBubble = styled.div`
    position: absolute;
    height: 15px;
    line-height: 15px;
    box-sizing: border-box;
    top: -14px;
    font-size: 13px;
    width: 60px;
    left: -30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFAC30;
    font-weight: bold;
    border-radius: 4px;
    z-index: 100000;
`;


const CurrentTimeHandleInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;
