import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Sidebar from 'arui-feather/sidebar'
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";

import * as actions from '../../../redux/actions/RoomsActions'

import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";

import Tabs from '../../ui/Tabs'

import RoomLessonsPanel from './RoomLessonsPanel'
import CommonHelper from "../../../helpers/CommonHelper";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

export default function SchoolRoomsPanel(props) {
    const {schoolId} = props;
    let {currentUser, loading, rooms, school, franchisee, canEdit} = useMappedState(useCallback(state => {
        let sc = state.schools.schoolsMap.get(schoolId);
        let rooms = state.rooms.roomsMap.toArray().filter(u => ((u.schoolId == schoolId)));
        const canEdit = ('admin' == CommonHelper.getCurrentUserRole(state) || (sc != undefined && sc.franchiseeId == state.users.currentUserId));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            school: sc,
            rooms: rooms,
            franchisee: (sc == undefined) ? undefined : state.users.usersMap.get(sc.franchiseeId),
            canEdit: canEdit
        }
    }, [schoolId]));
    if (school == undefined || franchisee == undefined) {
        return null;
    }
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(false);
    const dispatch = useDispatch();
    const selectedRoom = (selectedId == undefined) ? undefined : rooms.filter(g => (g.id == selectedId))[0];
    console.log('selectedRoom = ', selectedRoom);

    const sortedRooms = rooms.sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    })

    return (
        <Wrapper>

            {canEdit == false ? null :
                <TopPlaceholder>
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }} style={{marginLeft: 0}}>
                        Добавить помещение
                    </Button>
                </TopPlaceholder>
            }

            <RoomsPlaceholder>
                <PaginatorWrapper items={sortedRooms} renderItems={items => {
                    return (
                        <RoomsList>
                            {items.map((gr, k) => {
                                let {name, description} = gr;
                                return (
                                    <RoomItem key={gr.id} onClick={() => {
                                        setSelectedId(gr.id);
                                    }} selected={(gr.id == selectedId)}>
                                        <Name>
                                            {name}
                                        </Name>
                                        <Description>
                                            {description}
                                        </Description>
                                    </RoomItem>
                                )
                            })}
                        </RoomsList>
                    )
                }}/>

            </RoomsPlaceholder>


            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <Heading>
                            Создание помещения
                        </Heading>
                        <NameDescriptionForm
                            loading={loading}
                            onSubmit={d => {
                                dispatch(actions.createRoom({
                                    ...d,
                                    schoolId: school.id,
                                    franchiseeId: franchisee.id
                                })).then(() => {
                                    setAddModalVisible(false);
                                });
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(750, window.innerWidth)}
                     visible={(selectedRoom != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedRoom == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: (canEdit == true ? 'Редактирование' : 'Информация'),
                                content: (
                                    <TabItem>
                                        <NameDescriptionForm name={selectedRoom.name}
                                                             description={selectedRoom.description}
                                                             loading={loading}
                                                             onSubmit={d => {
                                                                 dispatch(actions.updateRoom({id: selectedId, ...d})).then(() => {
                                                                     setSelectedId(undefined);
                                                                 });
                                                             }} canEdit={canEdit}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Занятия в помещении',
                                content: (
                                    <TabItem>
                                        <RoomLessonsPanel roomId={selectedRoom.id}/>
                                    </TabItem>
                                )
                            }
                        ]} safeMode={true}  />

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const RoomsPlaceholder = styled.div`
    
`;

const RoomsList = styled.div`
    
`;

const RoomItem = styled.div`
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;

const Description = styled.div`
    opacity: 0.7;
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const TabItem = styled.div`
    width: 100%;
    padding-top: 10px;
`;
