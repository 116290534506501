import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import * as themesActions from '../../../redux/actions/ThemesActions'

import {BLUE, YELLOW} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'

import Tabs, {TabItem} from "../../ui/Tabs";
import UpdateItemsTool from "../tools/UpdateItemsTool";
import UpdateThemeForm from "../forms/UpdateThemeForm";

export default function ThemePanel(props) {
    let {id} = props;
    console.log('ThemePanel: render: id = ', id);

    const {program, theme, classNumber, loading} = useMappedState(useCallback(state => {
        let theme = state.themes.themesMap.get(id);
        let program = (theme == undefined) ? undefined : state.programs.programsMap.get(theme.programId);
        let classNumber = (program == undefined) ? undefined : program.classNumber;
        return {
            theme: theme,
            program: program,
            loading: state.programs.loading || state.themes.loading,
            classNumber: classNumber
        }
    }, [id]));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(programsActions.loadAllPrograms());
        dispatch(themesActions.loadAllThemes());
    }, [id]);

    if (theme == undefined || program == undefined) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <TopPlaceholder onClick={() => {
                CommonHelper.linkTo(`programs/number/${classNumber}`);
            }}>
                <BackA>
                    <ControlImage src={require('../../../assets/images/left-arrows-couple.png')}/>
                    назад
                </BackA>

                <span style={{marginLeft: 20}}>
                    {`${program.classNumber} класс / ${program.name} / ${theme.name}`}
                </span>
            </TopPlaceholder>

            <ContentPlaceholder>

                <Tabs tabs={[
                    {
                        label: 'Материалы',
                        content: (
                            <TabItem>
                                <UpdateItemsTool items={theme.items}
                                                 loading={loading}
                                                 onChange={async newItems => {
                                                     let d = {id: theme.id, items: newItems};
                                                     await dispatch(themesActions.updateTheme({...d, id: id}))
                                                 }}/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Информация',
                        content: (
                            <TabItem>
                                <UpdateThemeForm loading={loading} {...theme} onSubmit={async d => {
                                    await dispatch(themesActions.updateTheme({...d, id: id}))
                                }}/>
                            </TabItem>
                        )
                    }

                ]}/>

            </ContentPlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;   
    display: flex;
    flex-direction: row;
    align-items: center; 
`;

const BackA = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover{
      opacity: 0.5;
    }
`;

const ContentPlaceholder = styled.div`

`;

const ControlImage = styled.img`
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.7;
    margin-right: 5px;
    :hover{
      opacity: 1;
    }
`;
