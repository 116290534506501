import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';


export default function LessonShortInfo(props) {
    let {lesson, group, teachers, room} = props;
    if (lesson == undefined || group == undefined || teachers == undefined || room == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <span>
                {`Занятие `}
            </span>
            {group == undefined ? null :
                <span>
                    {`c группой `}
                    <B>
                        {group.name}
                    </B>
                </span>
            }

            {(teachers == undefined || teachers.length == 0) ? null :
                <span>{` ${teachers.length > 1 ? 'ведут' : 'ведет '} `}
                    <B>{teachers.map(aa => `${aa.firstName} ${aa.lastName}`).join(', ')}</B> {' '}
                </span>
            }

            {room == undefined ? null :
                <span>{`в помещении `}<B>{room.name}</B></span>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const B = styled.span`
    font-weight: bold;
    opacity: 0.7;
`;
