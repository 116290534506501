import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";

import AdminAllObserversPanel from "../admin/panels/AdminAllObserversPanel";

export default function AdminObserversApp(props) {

    return (
        <ChessTemplate active={'observers'}>

            <AdminAllObserversPanel/>

        </ChessTemplate>
    );
}

