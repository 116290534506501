import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'


export default function DocumentView(props) {
    let {name, description, avatar, attachments} = props;

    return (
        <Wrapper>
            <Name>
                {name}
            </Name>
            {description == undefined ? null :
                <Description>
                    {description}
                </Description>
            }
            <AttachmentsList>
                {attachments.map((a, k) => {
                    let {id, name, url} = a;
                    return (
                        <AttachmentItem key={id}>
                            <a href={url} target={'_blank'}>
                                {name}
                            </a>
                        </AttachmentItem>
                    )
                })}
            </AttachmentsList>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Name = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;

const Description = styled.div`
    opacity: 0.7;
    font-style: italic;
`;

const AttachmentsList = styled.div`
    margin-top: 20px;
`;

const AttachmentItem = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
`;
