import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import {Map, Set} from 'immutable'

import useDebouncedCallback from 'use-debounce/lib/callback'

import {Input} from "../../ui/Input";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

import {Button} from "../../ui/Button";

import tImg from '../../../assets/images/telegram_2.svg'

import Sidebar from 'arui-feather/sidebar'
import * as usersActions from "../../../redux/actions/UsersActions";
import UpdateUserForm from "../../users/forms/UpdateUserForm";

import Select from 'react-select'
import BulkUsersCreationTool from "../../automatic/tools/BulkUsersCreationTool";

import {CLASSES_OPTIONS} from "../../users/forms/UpdateUserForm";
import UsersPassPanel from "./UsersPassPanel";
import ls from "local-storage";
import axios from "axios";
import {API_ENDPOINT} from "../../../constants/config";


export const ARCH_OPTIONS = [
    {
        label: 'Архивный',
        value: 'archived'
    },
    {
        label: 'Активный',
        value: 'active'
    }
];

export default function AdminAllStudentsPanel(props) {

    const {
        showOnlyMyClass = false
    } = props;

    const dispatch = useDispatch();
    const [centerId, setCenterId] = useState(ls('centerId'));
    const [status, setStatus] = useState(ls('is_active_student') == undefined ? 'active' : ls('is_active_student'));


    const [filterClassNumber, setFilterClassNumber] = useState(ls('filterClassNumber'));
    const [searchString, setSearchString] = useState(ls('cc-searchString') == undefined ? '' : ls('cc-searchString'));
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [bulkAddModalVisible, setBulkAddModalVisible] = useState(false);
    const [passAddModalVisible, setPassAddModalVisible] = useState(false);

    const [allOlympiadsOptions, setAllOlympiadsOptions] = useState([]);
    const [allAchievementsOptions, setAllAchievementsOptions] = useState([]);
    const [allYearsOptions, setYearsOptions] = useState([]);

    const [selectedOlympiadId, setSelectedOlympiadId] = useState();
    const [selectedOlympiadYear, setSelectedOlympiadYear] = useState();
    const [selectedOlympiadAchievementName, setSelectedOlympiadAchievementName] = useState();

    let {
        currentUser,
        loading,
        userRole,
        students,
        centersMap,
        allCenters,
        readyEmailsSet
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let students = state.users.usersMap.toArray().filter(a => (a.userRole == 'student'))
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            students: students,
            centersMap: state.centers.centersMap,
            allCenters: state.centers.centersMap.toArray(),
            readyEmailsSet: students.reduce((st, a) => st.add(a.email.toLowerCase()), Set())
        }
    }, []));
    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('will set ', a);
        setSearchString(a);
    }, 300, []);

    useEffect(() => {
        ls('cc-is_active_student', status);
    }, [status]);

    useEffect(() => {
        ls('cc-searchString', searchString);
    }, [searchString]);

    useEffect(() => {
        ls('cc-selectedOlympiadId', selectedOlympiadId);
    }, [selectedOlympiadId]);

    useEffect(() => {
        if (centerId != undefined) {
            ls('centerId', centerId);
        } else {
            ls.remove('centerId');
        }
    }, [centerId]);

    useEffect(() => {
        if (filterClassNumber != undefined) {
            ls('filterClassNumber', filterClassNumber);
        } else {
            ls.remove('filterClassNumber');
        }
    }, [filterClassNumber]);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/key/all_olympiads`).then(d => d.data.value).then(arr => {
            setAllOlympiadsOptions(arr.map(x => ({label: x.name, value: x.name})));
            axios.get(`${API_ENDPOINT}/key/all_achievements`).then(d => d.data.value).then(arr => {
                setAllAchievementsOptions(arr.map(x => ({label: x.name, value: x.name})));
                axios.get(`${API_ENDPOINT}/key/all_olympiads_years`).then(d => d.data.value).then(arr => {
                    setYearsOptions(arr.map(x => ({label: x.name, value: x.name})));
                });
            });
        })
    }, [])

    if (currentUser == undefined || (userRole != 'admin' && userRole != 'observer')) {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    const centersOptions = allCenters.map(a => ({value: a.id, label: `${a.region}, ${a.name}`})).sort((a, b) => {
        if (a.label > b.label) {
            return 1;
        }
        if (a.label < b.label) {
            return -1;
        }
        return 0;
    });

    console.log('qqq -> students = ', students);

    let realFilterClassNumber = filterClassNumber;
    if (showOnlyMyClass == true && currentUser != undefined || currentUser.userRole == 'observer'){
        realFilterClassNumber = currentUser.classNumber;
    }

    const sortedStudents = students.filter(a => {
        let {firstName, lastName, email} = a;
        let s = `${lastName} ${firstName} ${email.toLowerCase()}`;
        let aChList = (a.achievements == undefined) ? [] : a.achievements.map(z => z.name);
        let yeList = (a.achievements == undefined) ? [] : a.achievements.map(z => `${z.name}${z.year}`);
        let honList = (a.achievements == undefined) ? [] : a.achievements.map(z => `${z.name}${z.year}${z.honor}`);

        // console.log('awwww ', aChList);
        if ((selectedOlympiadId != undefined) && (aChList.indexOf(selectedOlympiadId) == -1)) {
            return false;
        }
        if ((selectedOlympiadYear != undefined) && (yeList.indexOf(`${selectedOlympiadId}${selectedOlympiadYear}`) == -1)) {
            return false;
        }
        if ((selectedOlympiadAchievementName != undefined) && (honList.indexOf(`${selectedOlympiadId}${selectedOlympiadYear}${selectedOlympiadAchievementName}`) == -1)) {
            return false;
        }


        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }).sort((a, b) => {
        let s1 = `${a.lastName} ${a.firstName}`.toLowerCase();
        let s2 = `${b.lastName} ${b.firstName}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    }).filter(a => (a.centerId == centerId || centerId == undefined))
        .filter(a => (a.classNumber == realFilterClassNumber || realFilterClassNumber == undefined))
        .filter(a => (a.status == status));

    let isAdmin = (userRole == 'admin');

    console.log('render: !!! => selectedOlympiadId = ', selectedOlympiadId);



    return (
        <Wrapper>

            <Heading>
                <b>
                    Ученики ({sortedStudents.length})
                </b>
                {(userRole != 'admin' && (currentUser != undefined && currentUser.isModerator == false)) ? null :
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        Добавить ученика
                    </Button>
                }
            </Heading>


            {userRole != 'admin' ? null :
                <div>
                    {centerId == undefined ? null :
                        <div>
                <span onClick={() => {
                    setBulkAddModalVisible(true);
                }}>
                    массовая добавка учеников
                </span>
                            <span style={{marginLeft: 10}} onClick={() => {
                                setPassAddModalVisible(true);
                            }}>
                        таблица с паролями
                    </span>
                        </div>
                    }
                </div>
            }


            <TopSearchPlaceholder>
                <div>
                    <Input defaultValue={ls('cc-searchString') == undefined ? '' : ls('cc-searchString')}
                           placeholder={'Поиск'} style={{
                        height: 36,
                        lineHeight: `36px`,
                        fontSize: 16
                    }} onChange={evt => {
                        debouncedSearch(evt.target.value);
                    }}/>
                </div>
                <div style={{marginTop: 10}}>
                    <Select options={centersOptions} value={centersOptions.filter(a => (a.value == centerId))[0]}
                            isClearable={true}
                            placeholder={'Фильтр по центру'}
                            onChange={a => {
                                if (a == undefined) {
                                    setCenterId(undefined);
                                } else {
                                    setCenterId(a.value);
                                }
                            }}/>
                </div>
                <div style={{marginTop: 10}}>
                    <Select options={ARCH_OPTIONS} value={ARCH_OPTIONS.filter(a => (a.value == status))[0]}
                            isClearable={true}
                            placeholder={'Фильтр "Активный/Архивный"'}
                            onChange={a => {
                                if (a == undefined) {
                                    setStatus(undefined);
                                } else {
                                    setStatus(a.value);
                                }
                            }}/>
                </div>

                {showOnlyMyClass == true ? null :
                    <div style={{marginTop: 10}}>
                        <Select options={CLASSES_OPTIONS}
                                value={CLASSES_OPTIONS.filter(a => (a.value == realFilterClassNumber))[0]}
                                isClearable={true}
                                placeholder={'Фильтр по классу'}
                                onChange={a => {
                                    if (a == undefined) {
                                        setFilterClassNumber(undefined);
                                    } else {
                                        setFilterClassNumber(a.value);
                                    }
                                }}/>
                    </div>
                }


                {(isAdmin == false || allOlympiadsOptions.length == 0) ? null :
                    <div style={{marginTop: 10}}>
                        <Select options={allOlympiadsOptions}
                                isClearable={true}
                                value={allOlympiadsOptions.filter(xxx => (xxx.value == selectedOlympiadId))[0]}
                                placeholder={'Фильтр по олимпиадам'}
                                onChange={a => {
                                    if (a == undefined) {
                                        setSelectedOlympiadId(undefined);
                                        setSelectedOlympiadAchievementName(undefined);
                                        setSelectedOlympiadYear(undefined);
                                    } else {
                                        setSelectedOlympiadId(a.value);
                                    }
                                }}/>
                    </div>
                }

                {selectedOlympiadId == undefined ? null :
                    <div>
                        <div style={{marginTop: 10}}>
                            <Select options={allYearsOptions}
                                    isClearable={true}
                                    value={allYearsOptions.filter(xxx => (xxx.value == selectedOlympiadYear))[0]}
                                    placeholder={'Год'}
                                    onChange={a => {
                                        if (a == undefined) {
                                            setSelectedOlympiadYear(undefined);
                                            setSelectedOlympiadAchievementName(undefined);
                                        } else {
                                            setSelectedOlympiadYear(a.value);
                                        }
                                    }}/>
                        </div>

                        {selectedOlympiadYear == undefined ? null :
                            <div style={{marginTop: 10}}>
                                <Select options={allAchievementsOptions}
                                        isClearable={true}
                                        value={allAchievementsOptions.filter(xxx => (xxx.value == selectedOlympiadAchievementName))[0]}
                                        placeholder={'Достижение на олимпиаде'}
                                        onChange={a => {
                                            if (a == undefined) {
                                                setSelectedOlympiadAchievementName(undefined);
                                            } else {
                                                setSelectedOlympiadAchievementName(a.value);
                                            }
                                        }}/>
                            </div>
                        }

                    </div>
                }


            </TopSearchPlaceholder>

            <PaginatorWrapper renderItems={items => items.map((c, k) => {
                let {id, firstName, lastName, centerId, telegramChannelsIds} = c;
                let center = centersMap.get(centerId);
                let hasTelegram = (telegramChannelsIds != undefined && telegramChannelsIds.length > 0);

                return (
                    <SchoolItem key={id} onClick={() => {
                        CommonHelper.linkTo(`/student/${id}`);
                    }}>
                        <Name>
                            {hasTelegram == false ? null :
                                <TeleImg src={tImg} style={{marginRight: 10}}/>
                            }
                            {`${lastName} ${firstName}`}
                            <sup style={{opacity: 0.4, fontSize: 10, marginLeft: 5}}>
                                {`${c.classNumber} класс`}
                            </sup>
                        </Name>
                        {center == undefined ? null :
                            <div>
                                {`${center.city}, ${(center.name == undefined) ? '' : center.name.slice(0, 30)}...`}
                            </div>
                        }
                    </SchoolItem>
                )
            })} items={sortedStudents}/>


            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <UpdateUserForm
                            password={CommonHelper.getPwd()}
                            loading={loading}
                            avatarVisible={true}
                            passwordVisible={true}
                            emailVisible={true}
                            allCenters={allCenters}
                            canChangeCenter={(currentUser != undefined && currentUser.userRole == 'admin')}
                            centerId={(currentUser != undefined && currentUser.userRole == 'teacher') ? currentUser.centerId : undefined}
                            onSave={async data => {
                                if (data.centerId == undefined) {
                                    window.alert('Не выбран центр');
                                    return;
                                }
                                let pld = await dispatch(usersActions.createUser({
                                    ...data,
                                    userRole: 'student'
                                }));
                                if (pld.error != undefined) {
                                    // window.alert(JSON.stringify(pld.error));
                                    if (pld.error != undefined && pld.error.message != undefined && pld.error.message.indexOf('already exists') > -1) {
                                        window.alert('Аккаунт с данной почтой уже существует!');
                                    }
                                } else {
                                    setAddModalVisible(false);
                                }
                            }}
                        />
                    </div>
                }

            </Sidebar>


            <Sidebar width={1020} visible={bulkAddModalVisible && centerId != undefined} onCloserClick={() => {
                setBulkAddModalVisible(false);
            }}>

                {setBulkAddModalVisible == false || centerId == undefined ? null :
                    <div>
                        <BulkUsersCreationTool
                            readyEmailsSet={readyEmailsSet}
                            onAdd={async data => {
                                let pld = await dispatch(usersActions.createUser({
                                    ...data,
                                    userRole: 'student',
                                    centerId: centerId,
                                    password: CommonHelper.getPwd()
                                }));
                                if (pld.error != undefined) {
                                    // window.alert(JSON.stringify(pld.error));
                                    if (pld.error != undefined && pld.error.message != undefined && pld.error.message.indexOf('already exists') > -1) {
                                        window.alert('Аккаунт с данной почтой уже существует!');
                                    }
                                } else {
                                    // setBulkAddModalVisible(false);
                                }
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={1020} visible={passAddModalVisible && centerId != undefined} onCloserClick={() => {
                setPassAddModalVisible(false);
            }}>

                <UsersPassPanel centerId={centerId} userRole={'student'}/>

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TeleImg = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
`;

const SchoolsList = styled.div`

`;

const SchoolItem = styled.div`
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  :hover {
    opacity: 0.75;
  }
`;

const Name = styled.div`
  font-weight: bold;
`;

const Heading = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopSearchPlaceholder = styled.div`
  margin-bottom: 10px;
`;
