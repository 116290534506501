import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";


import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import AllFranchiseesPanel from "../users/panels/AllFranchiseesPanel";

export default function AdminFranchiseesApp(props) {

    return (
        <ChessTemplate active={'franchisees'}>

            <AllFranchiseesPanel/>

            {/*<Container style={{width: '100%', height: '100%'}}>*/}
            {/*</Container>*/}

        </ChessTemplate>
    );
}

