import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ChessTemplate from '../templates/ChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import StudentSubscriptionsPanel from "../subscriptions/panels/StudentSubscriptionsPanel";

export default function StudentSubscriptionsApp(props) {
    const dispatch = useDispatch();
    const {currentUserId, currentUser} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    return (
        <ChessTemplate active={'subscriptions'}>

            {/*{JSON.stringify(currentUser)}*/}

            <StudentSubscriptionsPanel/>

        </ChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
