import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import ls from 'local-storage'

import * as programsActions from '../../../redux/actions/ProgramsActions'
import * as themesActions from '../../../redux/actions/ThemesActions'

import {BLUE, YELLOW} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'
import UpdateProgramForm from "../forms/UpdateProgramForm";
import ExpItem from "../../landing/tools/ExpItem";
import KeyValueAPI from "../../../api/KeyValueAPI";
import UpdateItemsTool from "../tools/UpdateItemsTool";
import ThemeItemViewTool from "../tools/ThemeItemViewTool";
import SmartCustomVideoModal from "../../video/tools/SmartCustomVideoModal";

const DEFAULT_VALUE = {
    description: '',
    programImageUrl: undefined
}

export const getSelCr = () => {
    let d = ls('sel_discipline');
    if (d == undefined || d == '') {
        return 'phys';
    }
    return d;
}

export default function ClassProgramsPanel(props) {

    let {classNumber, canEdit = false, safeMode = false, isGuestMode = false, subj} = props;
    const [addProgramVisible, setAddProgramVisible] = useState(false);
    const [addThemeVisible, setAddThemeVisible] = useState(false);
    const [addProgramItemVisible, setAddProgramItemVisible] = useState(false);

    const [selectedVideo, setSelectedVideo] = useState();

    const [selectedProgramId, setSelectedProgramId] = useState(undefined);
    const [selectedThemeId, setSelectedThemeId] = useState(undefined);
    const [classInfo, setClassInfo] = useState(DEFAULT_VALUE);
    let discipline = subj || getSelCr();
    console.log('ClassProgramsPanel: render: discipline = ', discipline);

    const {programs, allThemes, loading, meIsAdmin} = useMappedState(useCallback(state => {
        let currentUser = (state.users.currentUserId == undefined) ? undefined : state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = (currentUser != undefined && currentUser.userRole == 'admin');
        return {
            programs: state.programs.programsMap.toArray().filter(a => (
                a.classNumber == classNumber &&
                (((a.discipline == undefined || a.discipline == '') && (isAdmin ? getSelCr() : discipline) == 'phys') || a.discipline == discipline)
            )).sort((a, b) => (+a.number - +b.number)),
            loading: state.programs.loading || state.themes.loading,
            allThemes: state.themes.themesMap.toArray().sort((a, b) => (+a.timestamp - +b.timestamp)),
            meIsAdmin: isAdmin
        }
    }, [classNumber]));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(programsActions.loadAllPrograms());
        dispatch(themesActions.loadAllThemes());
        KeyValueAPI.getParam(`class_info_${classNumber}`, DEFAULT_VALUE).then(d => {
            setClassInfo(d);
        })

    }, [classNumber]);

    let selectedTheme = (selectedThemeId == undefined) ? undefined : allThemes.filter(a => (a.id == selectedThemeId))[0];
    let selectedProgram = (selectedProgramId == undefined) ? undefined : programs.filter(a => (a.id == selectedProgramId))[0];

    console.log('!! programs = ', programs);

    let shPrograms = programs.filter(prog => {
        let pThemes = allThemes.filter(a => (a.name != undefined && a.name.trim() != '')).filter(a => (a.programId == prog.id));
        console.log('prog, pThemes = ', prog, pThemes);
        if (safeMode == false) {
            return true;
        }
        return (pThemes.length > 0 || (prog.items != undefined && prog.items.length > 0));
    });

    console.log('!! shPrograms = ', shPrograms);

    if (shPrograms.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    if (shPrograms.length > 2 && classNumber > 0 && canEdit == false) {
        shPrograms = [shPrograms[0], shPrograms[1]].concat([{type: 'program'}]).concat(shPrograms.slice(2));
    }

    return (
        <Wrapper>

            {/*{JSON.stringify(shPrograms)}*/}

            {canEdit == false ? null :
                <TopPlaceholder>
                    <Button onClick={() => {
                        setAddProgramVisible(true);
                    }}>
                        Добавить раздел
                    </Button>
                </TopPlaceholder>
            }

            <ProgramsList>
                {shPrograms.map((prog, k) => {

                    if (isGuestMode == true && `${classNumber}` == '1' && prog.type == 'program') {
                        return null;
                    }

                    if (prog.type == 'program') {
                        return (
                            <ProgramItem key={'prog'}>
                                {classInfo == undefined ? null :
                                    <ExpItem
                                        avatar={(classInfo == undefined) ? '' : classInfo.programImageUrl}
                                        text={<div>Программа <br/> {classNumber} класса</div>} onItemClick={() => {
                                        CommonHelper.linkTo(`/${discipline}/class/${classNumber}/program`);
                                    }}/>
                                }
                            </ProgramItem>
                        );
                    }

                    let pThemes = allThemes.filter(a => (a.programId == prog.id));
                    if (safeMode == true) {
                        pThemes = pThemes.filter(a => (a.name != undefined && a.name.trim() != ''));
                        if (pThemes.length == 0 && (prog.items == undefined || prog.items.length == 0)) {
                            return null;
                        }
                    }
                    return (
                        <ProgramItem key={`prog_${prog.id}_${k}`}>
                            <ProgramName>
                                <span>
                                    {prog.name}
                                </span>
                                {canEdit == false ? null :
                                    <span style={{color: 'grey', fontSize: 12, cursor: 'pointer'}}>
                                        {k == 0 ? null :
                                            <span onClick={async () => {
                                                let i1 = k - 1, i2 = k;
                                                let id1 = shPrograms[i1].id;
                                                let id2 = shPrograms[i2].id;
                                                let number1 = shPrograms[i2].number;
                                                let number2 = shPrograms[i1].number;
                                                await dispatch(programsActions.updateProgram({
                                                    id: id1,
                                                    number: number1
                                                }));
                                                await dispatch(programsActions.updateProgram({
                                                    id: id2,
                                                    number: number2
                                                }));
                                            }}>
                                            {` < `}
                                        </span>
                                        }
                                        {k == shPrograms.length - 1 ? null :
                                            <span onClick={async () => {
                                                let i1 = k, i2 = k + 1;
                                                let id1 = shPrograms[i1].id;
                                                let id2 = shPrograms[i2].id;
                                                let number1 = shPrograms[i2].number;
                                                let number2 = shPrograms[i1].number;
                                                await dispatch(programsActions.updateProgram({
                                                    id: id1,
                                                    number: number1
                                                }));
                                                await dispatch(programsActions.updateProgram({
                                                    id: id2,
                                                    number: number2
                                                }));
                                            }}>
                                                {` > `}
                                            </span>
                                        }

                                    </span>

                                }
                                {canEdit == false ? null :
                                    <EditPencilImg src={require('../../../assets/images/edit.svg')} onClick={() => {
                                        setSelectedProgramId(prog.id);
                                    }}/>
                                }
                            </ProgramName>
                            <ThemesList>
                                {pThemes.map((th, j) => {
                                    return (
                                        <ThemeItem key={`theme_${th.id}`}>
                                            {meIsAdmin == true ?
                                                <ThemeItemName2
                                                    onClick={() => {
                                                        CommonHelper.linkTo(`/${discipline}/theme/${th.id}`);
                                                    }}>
                                                    {th.name}
                                                </ThemeItemName2>
                                                :
                                                <ThemeItemName
                                                    href={`/#/${discipline}/theme/${th.id}`}
                                                    onClick={() => {
                                                        // CommonHelper.linkTo(`/theme/${th.id}`);
                                                        // CommonHelper.linkTo(`/theme/${th.id}`);
                                                    }}>
                                                    {th.name}
                                                </ThemeItemName>
                                            }
                                        </ThemeItem>
                                    )
                                })}

                                {/*{(prog.items || []).map(x => {*/}
                                {/*    return (*/}
                                {/*        <div>*/}
                                {/*            */}
                                {/*        </div>*/}
                                {/*    )*/}
                                {/*})}*/}




                                {canEdit == false ? null :
                                    <ThemeAddPlaceholder>
                                        <PlusImage style={{marginRight: 10}}
                                                   src={require('../../../assets/images/plus_d.svg')} onClick={() => {
                                            setAddThemeVisible(true);
                                            setSelectedProgramId(prog.id);
                                        }}/> добавить тему
                                    </ThemeAddPlaceholder>
                                }

                                {canEdit == false ?
                                    <>
                                        {(prog.items || []).map((it, k) => {
                                            return (
                                                <React.Fragment key={`k_${k}_${it.id}`}>
                                                    <ThemeItemViewTool {...it} canEdit={false}
                                                                       onVideoClick={() => {
                                                                           console.log('onVideoClick: it = ', it);
                                                                           setSelectedVideo(it);
                                                                       }}
                                                                       onButtonClick={() => {
                                                                           console.log('onButtonClick: it = ', it);
                                                                           setSelectedVideo(it);
                                                                       }}
                                                    />
                                                </React.Fragment>
                                            )
                                        })}
                                    </>
                                    :
                                    <div>
                                        <UpdateItemsTool items={prog.items || []}
                                                         loading={loading}
                                                         onChange={async newItems => {
                                                             let d = {id: prog.id, items: newItems};
                                                             await dispatch(programsActions.updateProgram({...d, id: prog.id}));
                                                         }}/>
                                    </div>
                                    // <ThemeAddPlaceholder>
                                    //     <PlusImage style={{marginRight: 10}}
                                    //                src={require('../../../assets/images/plus_d.svg')} onClick={() => {
                                    //         // setAddThemeVisible(true);
                                    //         setSelectedProgramId(prog.id);
                                    //         setAddProgramItemVisible(true);
                                    //
                                    //         // let newItems = items.concat([
                                    //         //     {
                                    //         //         id: uuid(),
                                    //         //         number: +new Date(),
                                    //         //         name: moment().format('DD.MM.YYYY HH:mm:ss'),
                                    //         //         buttonName: 'Текст кнопки'
                                    //         //     }
                                    //         // ]);
                                    //         // console.log('newItems = ', newItems);
                                    //         // setItems(newItems);
                                    //         // setHasNotSaved(true);
                                    //
                                    //     }}/> добавить плашку
                                    // </ThemeAddPlaceholder>
                                }

                            </ThemesList>
                        </ProgramItem>
                    )
                })}
            </ProgramsList>

            <Sidebar visible={addProgramVisible} onCloserClick={() => {
                setAddProgramVisible(false);
            }}>

                {addProgramVisible == false ? null :
                    <div>
                        <UpdateProgramForm canDelete={false} onSubmit={async d => {
                            d.number = +new Date();
                            d.classNumber = classNumber;
                            d.discipline = getSelCr();
                            await dispatch(programsActions.createProgram(d))
                            setAddProgramVisible(false);
                        }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar visible={(selectedProgram != undefined && addThemeVisible == false)} onCloserClick={() => {
                setSelectedProgramId(undefined);
            }}>

                {!(selectedProgram != undefined && addThemeVisible == false) ? null :
                    <div>
                        <UpdateProgramForm {...selectedProgram}
                                           canDelete={true}
                                           onSubmit={async d => {
                                               let data = {
                                                   id: selectedProgram.id,
                                                   ...d
                                               };
                                               await dispatch(programsActions.updateProgram(data));
                                               setSelectedProgramId(false);
                                           }}
                                           onDelete={async () => {
                                               let dThemes = allThemes.filter(a => (a.programId == selectedProgram.id));
                                               for (let i in dThemes) {
                                                   let th = dThemes[i];
                                                   await dispatch(themesActions.deleteTheme(th.id));
                                               }
                                               await dispatch(programsActions.deleteProgram(selectedProgram.id));
                                               setSelectedProgramId(undefined);
                                           }}
                        />
                    </div>
                }

            </Sidebar>

            <Sidebar visible={addThemeVisible} onCloserClick={() => {
                setAddThemeVisible(false);
                setSelectedProgramId(undefined);
            }}>

                {(addThemeVisible == false || selectedProgram == undefined) ? null :
                    <div>
                        <NameDescriptionForm onSubmit={async d => {
                            d.number = +new Date();
                            d.programId = selectedProgram.id;
                            d.discipline = getSelCr();
                            await dispatch(themesActions.createTheme(d));
                            setAddThemeVisible(false);
                            setSelectedProgramId(undefined);
                        }}/>
                    </div>
                }

            </Sidebar>

            {(selectedVideo == undefined) ? null :
                <SmartCustomVideoModal
                    {...selectedVideo}
                    sdUrl={selectedVideo.videoUrl480p || selectedVideo.videoUrl720p || selectedVideo.videoUrl}
                    hdUrl={selectedVideo.videoUrl720p || selectedVideo.videoUrl480p || selectedVideo.videoUrl}
                    onClose={() => {
                        setSelectedVideo(undefined);
                    }}/>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const EditPencilImg = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const TopPlaceholder = styled.div`

`;

const ProgramsList = styled.div`

`;

const ProgramItem = styled.div`
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  margin-bottom: 20px;
  @media (max-width: 920px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ProgramName = styled.div`
  color: ${BLUE};
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`;

const ThemesList = styled.div`
  padding-left: 20px;
`;

const ThemeItem = styled.div`
  margin-bottom: 5px;
  font-size: 17px;
  line-height: 24px;
  // :first-of-type{
    //   color: ${BLUE};
  // }
`;

const Item = styled.div`
    display: inline-block;
    vertical-align: top;
    //width: 50%;
    width: 33.33%;
    box-sizing: border-box;
    padding: 10px;
    //:nth-of-type(2n - 1){
    :nth-of-type(3n - 2){
      padding-left: 0px;
    }
    @media(max-width: 1020px){
      width: 50%;
      :nth-of-type(2n - 1){
        padding-left: 0px;
      }
    }
    @media(max-width: 920px){
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
`;

const ThemeItemName = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;

  :hover {
    text-decoration: underline;
    color: ${YELLOW};
  }
`;

const ThemeItemName2 = styled.span`
  cursor: pointer;
  text-decoration: none;
  color: black;

  :hover {
    text-decoration: underline;
    color: ${YELLOW};
  }
`;

const PlusImage = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const ThemeAddPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
`;
