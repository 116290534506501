import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Code} from 'react-content-loader'

import {Button} from "../../ui/Button";
import {DARK_SIDE, BROWN} from "../../ui/Colors";


import * as actions from '../../../redux/actions/CoursesActions'

import UpdateUnitPanel from './UpdateUnitPanel'
import CommonHelper from "../../../helpers/CommonHelper";

export default function CourseUnitsPanel(props) {
    const {courseId} = props;
    const [selectedId, setSelectedId] = useState(undefined);

    const {units, loading, userRole, isAdmin} = useMappedState(useCallback(state => ({
        units: state.courses.unitsMap.toArray().filter(u => (u.courseId == courseId)).sort((a, b) => (+a.timestamp - +b.timestamp)),
        loading: state.courses.loading,
        userRole: CommonHelper.getCurrentUserRole(state),
        isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state))
    }), [courseId]));
    const dispatch = useDispatch();
    let selectedUnit = (selectedId == undefined) ? undefined : units.filter(u => (u.id == selectedId))[0];
    useEffect(() => {
        dispatch(actions.loadCourseUnits(courseId));
    }, [courseId]);

    if (units.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }
    if (units.length == 0) {
        return (
            <div>
                {isAdmin == false ? null :
                    <Button onClick={() => {
                        dispatch(actions.createUnit({
                            courseId: courseId,
                            name: `Новый модуль ${moment().format('HH:mm:ss')}`,
                            description: `Без описания`
                        })).then(pld => setSelectedId(pld.unit.id));
                    }}>
                        Добавить первый модуль
                    </Button>
                }
            </div>
        )
    }


    return (
        <Wrapper>

            <Left>
                <UnitsList>
                    {units.map((u, k) => {
                        let {name, description} = u;
                        let isSelected = (selectedId == u.id);
                        return (
                            <UnitItem selected={isSelected} key={u.id} onClick={() => {
                                setSelectedId(u.id);
                            }}>
                                {name}
                            </UnitItem>
                        )
                    })}
                </UnitsList>

                {isAdmin == false ? null :
                    <BottomPlaceholder>
                        <Button onClick={() => {
                            dispatch(actions.createUnit({
                                courseId: courseId,
                                name: `Новый модуль ${moment().format('HH:mm:ss')}`,
                                description: `Без описания`
                            })).then(pld => setSelectedId(pld.unit.id));
                        }}>
                            Добавить модуль
                        </Button>
                    </BottomPlaceholder>
                }
                
            </Left>

            <Right>
                {selectedUnit == undefined ?
                    <SelectedUnitPlaceholder>
                        ⬅ выберите модуль из списка слева
                    </SelectedUnitPlaceholder>
                    :
                    <SelectedUnitPlaceholder key={selectedUnit.id} >
                        <UpdateUnitPanel id={selectedId}/>
                    </SelectedUnitPlaceholder>
                }
            </Right>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const leftWidth = 240;

const Left = styled.div`
    width: ${leftWidth}px;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;

const Right = styled.div`
    width: calc(100% - ${leftWidth}px);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
`;

const UnitsList = styled.div`
    
`;

const UnitItem = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 7px;
    border-bottom: 1px solid whitesmoke;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    background-color: ${props => (props.selected == true ? BROWN : 'transparent')};
    :hover{
      opacity: ${props => (props.selected == true ? 1 : 0.8)};
    }
    
`;

const BottomPlaceholder = styled.div`
    padding-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid whitesmoke;
`;

const SelectedUnitPlaceholder = styled.div`
    padding: 10px;
    box-sizing: border-box;
`;
