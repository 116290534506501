import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";
import KeyValueAPI from './KeyValueAPI'
import uuid from 'uuid'

const ITEMS_KEY = `russia_items`;

const RussiaAPI = {

    async getItems() {
        return (await axios.get(`${API_ENDPOINT}/centers/all`)).data;
    },

    async saveItem(data) {
        if (data == undefined) {
            return;
        }
        if (data.id != undefined) {
            await axios.post(`${API_ENDPOINT}/centers/update`, data);
        } else {
            await axios.post(`${API_ENDPOINT}/centers/create`, {...data, number: +new Date()});
        }
        return (await axios.get(`${API_ENDPOINT}/centers/all`)).data;
    },

    async deleteItem(id) {
        await axios.post(`${API_ENDPOINT}/centers/delete`, {id: id});
        return (await axios.get(`${API_ENDPOINT}/centers/all`)).data;
    }

}

export default RussiaAPI;
