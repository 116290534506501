import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import KeyValueAPI from "../../../api/KeyValueAPI";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

export default function UpdateAboutPanel(props) {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        KeyValueAPI.getParam('os_about').then(x => {
            setText(x);
            setLoading(false);
        });
    }, []);

    if (loading == true) {
        return <Code/>;
    }

    return (
        <Wrapper>

            <ContentPlaceholder>
                <Textarea value={text} onChange={evt => {
                    setText(evt.target.value);
                }}></Textarea>
            </ContentPlaceholder>

            <div>
                <Button onClick={async () => {
                    setLoading(true);
                    await KeyValueAPI.saveParam('os_about', text);
                    setLoading(false);
                }} >
                    Сохранить
                </Button>
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const ContentPlaceholder = styled.div`
  margin-bottom: 10px;
`;