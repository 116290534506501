import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";
import ClassProgramsPanel from "../programs/panels/ClassProgramsPanel";
import Tabs, {TabItem} from "../ui/Tabs";

export default function AdminClassProgramsApp(props) {
    let {number} = props.match.params;

    console.log('AdminClassProgramsApp: render: number = ', number);

    return (
        <ChessTemplate active={`programs/number/${number}`}>

            <div key={`num_${number}`}>

                <Tabs
                    tabs={[
                        {
                            label: `Контент`,
                            content: (
                                <TabItem>
                                    <ClassProgramsPanel classNumber={number} canEdit={true}/>
                                </TabItem>
                            )
                        },
                        {
                            label: `Задачи`,
                            content: (
                                <TabItem>
                                    Тут можно будет создавать задачи (от Виталия Шевченко)
                                </TabItem>
                            )
                        },
                        {
                            label: `Теги`,
                            content: (
                                <TabItem>
                                    Тут можно будет редактировать теги (для пометки всех контентных единиц)
                                </TabItem>
                            )
                        }
                    ]}
                />

            </div>

        </ChessTemplate>
    );
}
