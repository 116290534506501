import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import CommonHelper from "../../helpers/CommonHelper";

// import logo_left_img from '../../assets/images/logo_left.jpeg';
import logo_left_img from '../../assets/images/land/new/logo_shev.png';

import about_img from '../../assets/images/land/new/about_us.png';
import alg_img from '../../assets/images/land/new/algebra.png';
import geom_img from '../../assets/images/land/new/geomety.png';
import phys_img from '../../assets/images/land/new/physycs.png';
import astro_img from '../../assets/images/land/new/astronomy.png';

const ITEMS = [
    {
        url: '/phys/',
        logo: phys_img,
        classes: [
            {
                label: '7 КЛАСС',
                url: '/phys/class/7'
            },
            {
                label: '8 КЛАСС',
                url: '/phys/class/8'
            },
            {
                label: '9 КЛАСС',
                url: '/phys/class/9'
            },
            {
                label: '10 КЛАСС',
                url: '/phys/class/10'
            },
            {
                label: '11 КЛАСС',
                url: '/phys/class/11'
            },
            {
                // label: 'ОБЩЕЕ',
                label: 'ВсОШ',
                url: '/phys/class/0'
            },
            {
                label: 'ЭКСПЕРИМЕНТ',
                url: '/phys/class/1'
            }

        ]
    },
    {
        url: '/astro/',
        logo: astro_img,
        classes: [
            {
                label: 'БАЗОВЫЙ УРОВЕНЬ',
                url: '/astro/class/8'
            },
            {
                label: 'ОЛИМПИАДНЫЙ УРОВЕНЬ',
                url: '/astro/class/9'
            },
            {
                label: 'ПРОДВИНУТЫЙ УРОВЕНЬ',
                url: '/astro/class/10'
            }
        ]
    },
    {
        url: '/alg/',
        logo: alg_img,
        classes: [
            {
                label: '8 КЛАСС',
                url: '/alg/class/8'
            },
            {
                label: '9 КЛАСС',
                url: '/alg/class/9'
            },
            {
                label: '10 КЛАСС',
                url: '/alg/class/10'
            },
            {
                label: '11 КЛАСС',
                url: '/alg/class/11'
            },
            {
                label: 'ПОДГОТОВКА К РСОШ',
                url: '/alg/class/0'
            }
        ]
    },
    {
        url: '/geom/',
        logo: geom_img,
        classes: [
            {
                label: '8 КЛАСС',
                url: '/geom/class/8'
            },
            {
                label: '9 КЛАСС',
                url: '/geom/class/9'
            },
            {
                label: '10 КЛАСС',
                url: '/geom/class/10'
            },
            {
                label: '11 КЛАСС',
                url: '/geom/class/11'
            },
            {
                label: 'ПОДГОТОВКА К РСОШ',
                url: '/geom/class/0'
            }
        ]
    },
    {
        url: '/about/',
        logo: about_img
    }
];

export default function PreLandingApp(props) {
    let {id} = props.match.params;

    return (
        <MainWrapper>
            <Left>
                <LogoLeft src={logo_left_img}/>
            </Left>
            <Right>
                {ITEMS.map((a, i) => {
                    let {classes = []} = a;
                    return (
                        <Item key={i} onClick={() => {

                        }}>
                            <ItemImg src={a.logo} onClick={() => {
                                CommonHelper.linkTo(a.url);
                            }} />
                            <DropDownPlaceholder className={'dd_placeholder'}>
                                {classes.map((b, j) => {
                                    return (
                                        <ClassItem key={j} onClick={() => {
                                            CommonHelper.linkTo(b.url);
                                        }}>
                                            {b.label}
                                        </ClassItem>
                                    )
                                })}
                            </DropDownPlaceholder>
                        </Item>
                    )
                })}
            </Right>
        </MainWrapper>
    );
}

const ClassItem = styled.div`
  padding: 15px;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: ${`#0D70B8`};
  font-weight: 800;
  :hover{
    text-decoration: underline;
    color: ${YELLOW};
  }
`;

const DropDownPlaceholder = styled.div`
  display: none;
  position: absolute;
  left: 240px;
  top: 0px;
  background: white;
  z-index: 2;
  width: 180px;
  padding-top: 15px;
  padding-left: 40px;
`;

const Item = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  position: relative;
  
  //:hover {
  //  opacity: 0.7;
  //}
  :hover .dd_placeholder {
    display: block !important;
  }
`;

const ItemImg = styled.img`
  height: 80px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
`;

const LogoLeft = styled.img`
  height: 120px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-right: 50px;
  margin-left: -200px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  padding-left: 50px;
`;