import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment-timezone';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Map, Set} from 'immutable'

import * as actions from '../../../redux/actions/LessonsActions'

import Spin from 'arui-feather/spin'
import CommonHelper from "../../../helpers/CommonHelper";
import SimpleScoreTool from "../tools/SimpleScoreTool";

import {Input} from "../../ui/Input";

export default function LessonAttendancePanel(props) {
    let {id} = props;
    const [loadingStudentId, setLoadingStudentId] = useState(undefined);
    const [searchString, setSearchString] = useState('');
    let {
        currentUser, loading, lesson, school,
        franchisee, teacher, room, group, studentsIds,
        groupStudents, attendees, attendeesMap,
        canEdit
    } = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        let sc = (lesson == undefined) ? undefined : state.schools.schoolsMap.get(lesson.schoolId);
        let teacher = (lesson == undefined) ? undefined : state.users.usersMap.get(lesson.teacherId);
        let group = (lesson == undefined) ? undefined : state.groups.groupsMap.get(lesson.groupId);
        let groupStudents = (group == undefined) ? [] : group.studentsIds.map(stId => state.users.usersMap.get(stId));
        let attendees = (lesson == undefined) ? [] : lesson.studentsIds.map(stId => state.users.usersMap.get(stId));
        let attendeesMap = attendees.reduce((mp, a) => mp.set(a.id, a), Map());
        let canEdit = ('admin' == CommonHelper.getCurrentUserRole(state) || (sc != undefined && sc.franchiseeId == state.users.currentUserId));
        if ('teacher' == CommonHelper.getCurrentUserRole(state) && teacher != undefined && state.users.currentUserId == teacher.id) {
            canEdit = true;
        }
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            school: sc,
            teacher: teacher,
            lesson: lesson,
            groupStudents: groupStudents,
            attendees: attendees,
            attendeesMap: attendeesMap,
            studentsIds: lesson.studentsIds == undefined ? [] : lesson.studentsIds,
            room: (lesson == undefined) ? undefined : state.rooms.roomsMap.get(lesson.roomId),
            group: group,
            franchisee: (sc == undefined) ? undefined : state.users.usersMap.get(sc.franchiseeId),
            canEdit: canEdit
        }
    }, [id]));
    const dispatch = useDispatch();
    if (loading == true && lesson == undefined) {
        return (
            <Code/>
        )
    }
    if (id == undefined || lesson == undefined) {
        return null;
    }
    let {studentsScoresMap} = lesson;
    let sortedGroupStudents = groupStudents.filter(a => {
        let {firstName, lastName} = a;
        let s = `${lastName} ${firstName}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }).sort((a, b) => {
        if (a.lastName > b.lastName) {
            return 1;
        }
        if (a.lastName < b.lastName) {
            return -1;
        }
        return 0;
    });

    return (
        <Wrapper>

            <TopPlaceholder>
                {`Занятие ${moment(lesson.startTimestamp).format('DD.MM.YYYY')} ${room == undefined ? '' : `в ${room.name}`} ${group == undefined ? '' : ` c группой "${group.name}`}"`}
            </TopPlaceholder>

            <SearchPlaceholder>
                <Input value={searchString} onChange={e => {
                    setSearchString(e.target.value);
                }} placeholder={'Поиск'} />
            </SearchPlaceholder>


            <ListHeading>
                <ColName>
                    Фамилия, Имя
                </ColName>

                <ColName>
                    Посещаемость и оценка
                </ColName>
            </ListHeading>

            <StudentsList>
                {sortedGroupStudents.map((student, k) => {
                    let {firstName, lastName, avatar} = student;
                    let isAttendee = attendeesMap.has(student.id);
                    let isLoading = (loadingStudentId == student.id);
                    let studentScore = studentsScoresMap[student.id];
                    return (
                        <StudentItem key={`${student.id}_${k}`}>
                            <StudentTop>
                                <StudentName>
                                    {`${lastName} ${firstName} `}
                                    <Spin visible={isLoading}/>
                                </StudentName>
                                <StudentControls>
                                    <Spin visible={isLoading}/>
                                    <CheckImg
                                        src={(isAttendee == true ? require('../../../assets/images/check.svg') : require('../../../assets/images/check-box-empty.svg'))}
                                        onClick={() => {
                                            if (canEdit == false) {
                                                return;
                                            }
                                            let newStudentsIds = (isAttendee == true) ? studentsIds.filter(aId => (aId != student.id)) : studentsIds.concat([student.id]);
                                            setLoadingStudentId(student.id);
                                            dispatch(actions.updateLesson({
                                                id: props.id,
                                                studentsIds: newStudentsIds
                                            })).then(pld => {
                                                setLoadingStudentId(undefined);
                                            });
                                        }}
                                    />
                                    {isAttendee == false ? null :
                                        <div style={{marginLeft: 10}}>
                                            <SimpleScoreTool score={studentScore} onChange={newScore => {
                                                let newStudentsScoresMap = {
                                                    ...studentsScoresMap,
                                                    [student.id]: newScore
                                                };
                                                if (newScore == undefined) {
                                                    delete newStudentsScoresMap[student.id];
                                                }
                                                setLoadingStudentId(student.id);
                                                dispatch(actions.updateLesson({
                                                    id: props.id,
                                                    studentsScoresMap: newStudentsScoresMap
                                                })).then(pld => {
                                                    setLoadingStudentId(undefined);
                                                });
                                            }}/>
                                        </div>
                                    }
                                </StudentControls>
                            </StudentTop>
                        </StudentItem>
                    )
                })}
            </StudentsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0.75;
    border-bottom: 1px solid whitesmoke;
    padding-bottom: 10px;
    
`;

const ColName = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
`;

const StudentsList = styled.div`
    
`;

const StudentItem = styled.div`
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
`;

const StudentTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StudentName = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
`;

const StudentControls = styled.div`
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

const CheckImg = styled.img`
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-left: 5px;
`;

const TopPlaceholder = styled.div`
    margin-bottom:  10px;
    padding-bottom: 10px;
`;

const SearchPlaceholder = styled.div`
    margin-bottom: 10px;
`;
