import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import UsersList from "../lists/UsersList";

import * as usersActions from '../../../redux/actions/UsersActions'
import * as schoolsActions from '../../../redux/actions/SchoolsActions'

import Sidebar from 'arui-feather/sidebar'
import UpdateUserForm from "../forms/UpdateUserForm";

import {Button} from "../../ui/Button";
import CommonHelper from "../../../helpers/CommonHelper";

import Tabs from '../../ui/Tabs'
import SchoolLessonsPanel from "../../lessons/panels/SchoolLessonsPanel";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";
import TeachersSortingTool from "../../teachers/tools/TeachersSortingTool";
import {DARK_SIDE} from "../../ui/Colors";
import useDebouncedCallback from "use-debounce/lib/callback";

import {Input} from "../../ui/Input";

export default function SchoolTeachersPanel(props) {
    const {franchiseeId, schoolId} = props;
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState('');
    const [sortingSettings, setSortingSettings] = useState({type: 'lastName', mode: 'asc'});
    let {currentUser, loading, teachers, school, canEdit} = useMappedState(useCallback(state => {
        let sc = state.schools.schoolsMap.get(schoolId);
        let tchrs = state.users.usersMap.toArray().filter(u => ((u.userRole == 'teacher')))
        tchrs = tchrs.filter(t => (sc.teachersIds != undefined && sc.teachersIds.indexOf(t.id) > -1));
        const canEdit = ('admin' == CommonHelper.getCurrentUserRole(state) || (sc != undefined && sc.franchiseeId == state.users.currentUserId));
        let allGroups = state.groups.groupsMap.toArray();
        let weekStart = +moment().startOf('isoweek');
        let lessonsFromWeekStart = state.lessons.lessonsMap.toArray().filter(l => (+l.startTimestamp > +weekStart));
        tchrs = tchrs.map(tchr => {
            return {
                ...tchr,
                groupsNumber: allGroups.filter(gr => (gr.teachersIds.indexOf(tchr.id) > -1)).length,
                lessonsNumber: lessonsFromWeekStart.filter(gr => (gr.teachersIds.indexOf(tchr.id) > -1)).length
            }
        });
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            school: sc,
            teachers: tchrs,
            canEdit: canEdit
        }
    }, [franchiseeId]));
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(undefined);
    const selectedUser = (setSelectedUserId == undefined) ? undefined : teachers.filter(u => (u.id == selectedUserId))[0];
    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('will set ', a);
        setSearchString(a);
    }, 300, []);

    if (school == undefined) {
        return null;
    }

    const sortedTeachers = CommonHelper.sortArrayBySortingSettings(teachers.filter(a => {
        let {firstName, lastName, email} = a;
        let s = `${lastName} ${firstName} ${email}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }), sortingSettings);

    return (
        <Wrapper>

            {canEdit == false ? null :
                <TopPlaceholder hasBottomBorder={(sortedTeachers.length > 0)}>
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        Добавить Преподавателя
                    </Button>
                </TopPlaceholder>
            }

            <TopSearchPlaceholder>
                <Input placeholder={'Поиск'} style={{
                    height: 36,
                    lineHeight: `36px`,
                    fontSize: 16
                }} onChange={evt => {
                    debouncedSearch(evt.target.value);
                }}/>
            </TopSearchPlaceholder>

            <TopOrderPlaceholder>
                <TeachersSortingTool {...sortingSettings} onChange={d => {
                    let da = {...sortingSettings, ...d};
                    console.log('setSortingSettings: da = ', da);
                    setSortingSettings(da);
                }}/>
            </TopOrderPlaceholder>

            <PaginatorWrapper items={sortedTeachers} renderItems={items => {
                return (
                    <div>
                        {items.map((c, k) => {
                            let {id, firstName, lastName, lessonsNumber, groupsNumber} = c;
                            return (
                                <TeacherItem selected={(selectedUserId == id)} key={id} onClick={() => {
                                    setSelectedUserId(id);
                                }}>

                                    <Name>
                                        {`${lastName} ${firstName}`}
                                    </Name>

                                    <StatsInfo>
                                        {`${groupsNumber} групп`}
                                        {' '}
                                        {`${lessonsNumber} занятий`}
                                    </StatsInfo>

                                </TeacherItem>
                            )
                        })}
                    </div>
                )
            }}/>


            <Sidebar visible={addModalVisible}
                     onCloserClick={() => {
                         setAddModalVisible(false);
                     }}
            >
                {addModalVisible == false ? null :
                    <div>

                        <Heading>
                            Добавление Преподавателя
                        </Heading>

                        <UpdateUserForm loading={loading} onSave={data => {
                            let d = {
                                ...data,
                                franchiseeId: franchiseeId,
                                userRole: 'teacher'
                            }
                            dispatch(usersActions.createUser(d)).then(pld => {
                                dispatch(schoolsActions.updateSchool({
                                    id: schoolId,
                                    teachersIds: school.teachersIds.concat([pld.user.id])
                                })).then(p2 => {
                                    setAddModalVisible(false);
                                });
                            })
                        }}/>
                    </div>
                }
            </Sidebar>

            <Sidebar width={Math.min(750, window.innerWidth)} visible={(selectedUser != undefined)}
                     onCloserClick={() => {
                         setSelectedUserId(undefined);
                     }}>

                {selectedUser == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Информация',
                                content: (
                                    <TabItem>
                                        <UpdateUserForm emailVisible={false}
                                                        passwordVisible={false}
                                                        {...selectedUser}
                                                        avatarVisible={true}
                                                        canChangeCenter={(currentUser != undefined && currentUser.userRole == 'admin')}
                                                        loading={loading} onSave={data => {
                                            dispatch(usersActions.updateUser({
                                                ...data,
                                                id: selectedUserId
                                            })).then(pld => {
                                                setSelectedUserId(undefined);
                                            })
                                        }} canEdit={canEdit}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Занятия Преподавателя',
                                content: (
                                    <TabItem>
                                        <SchoolLessonsPanel
                                            schoolId={schoolId} teacherId={selectedUser.id}
                                        />
                                    </TabItem>
                                )
                            }
                        ]} safeMode={true}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: ${props => (props.hasBottomBorder == true ? '10px' : 0)};
    padding-bottom: ${props => (props.hasBottomBorder == true ? '10px' : 0)};
    border-bottom: ${props => (props.hasBottomBorder == true ? '1px solid whitesmoke' : 'none')};
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
`;

const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;

const TopOrderPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const TeacherItem = styled.div`
    margin-bottom: 10px;
    background-color: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const StatsInfo = styled.div`
    
`;


const TopSearchPlaceholder = styled.div`
    margin-bottom: 10px;
`;

