/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    programsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const ProgramsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_PROGRAM:
        case types.UPDATE_PROGRAM:
        case types.DELETE_PROGRAM:
        case types.LOAD_PROGRAMS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_PROGRAM_FAIL:
        case types.UPDATE_PROGRAM_FAIL:
        case types.DELETE_PROGRAM_FAIL:
        case types.LOAD_PROGRAMS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_PROGRAM_SUCCESS:
        case types.UPDATE_PROGRAM_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap.set(action.program.id, action.program)
            }


        case types.DELETE_PROGRAM_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap.delete(action.id),
            }


        case types.LOAD_PROGRAMS_SUCCESS:
            return {
                ...state,
                loading: false,
                programsMap: state.programsMap
                    .merge(action.programs.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ProgramsReducer;
