import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import LandingTemplate from "../templates/landing/LandingTemplate";
import FullPageImageSection from "../landing/sections/FullPageImageSection";
import {BLUE, YELLOW} from "../ui/Colors";
import MainNewsSection from "../landing/sections/MainNewsSection";
import ImTopSectionPanel from "../landing/sections/ImTopSectionPanel";
import CommonHelper from "../../helpers/CommonHelper";

import phys_img from '../../assets/images/back/back_physics.jpg';
import geom_img from '../../assets/images/back/back_geometry.jpg';
import astro_img from '../../assets/images/back/back_astronomy.jpg';
import alg_img from '../../assets/images/back/back_algebra.jpg';

// import phys_img from '../../assets/images/top_top3.jpg';
// import geom_img from '../../assets/images/land/geom_back.jpeg';
// import astro_img from '../../assets/images/land/astro_b.jpg';
// import alg_img from '../../assets/images/land/alg_back.jpg';
//


const NAMES_MAP = {
    phys: {
        name: 'Физика',
        classes: [7, 8, 9, 10, 11, {value: 0, label: 'ВсОШ'}, {value: 1, label: 'Эксперимент'}],
        background: phys_img
    },

    alg: {
        name: 'Алгебра',
        classes: [8, 9, 10, 11, {value: 0, label: 'Подготовка к РСОШ'}],
        background: alg_img
    },

    geom: {
        name: 'Геометрия',
        classes: [8, 9, 10, 11, {value: 0, label: 'Подготовка к РСОШ'}],
        background: geom_img
    },

    astro: {
        name: 'Астрономия',
        // classes: [8, 9, 10],
        // classes: [8, 9, 10],
        classes: [
            {value: 8, label: 'Базовый <br/> уровень'},
            {value: 9, label: 'Олимпиадный <br/> уровень'},
            {value: 10, label: 'Продвинутый <br/> уровень'}
        ],
        background: astro_img
    },
    // базовый уровень, олимпиадный уровень, продвинутый уровень
}

export function getClassesLabelsForSubject(subj){
    let xObj = NAMES_MAP[subj];
    let classes = (xObj.classes || []);
    let labels = classes.map((clNumber, j) => {
        let xKey = clNumber;
        let label = ``
        if (window.isNaN(clNumber)){
            xKey = clNumber.value;
            label = `${clNumber.label}`
        }else{
            label = `${clNumber} КЛАСС`;
        }
        return label;
    });
    return labels;
}

export function getLabelBySubjAndClassNumber(subj, clNumber){
    let xObj = NAMES_MAP[subj];
    let classes = (xObj.classes || []);
    let label = `${clNumber} КЛАСС`;
    for (let i in classes){
        let cl = classes[i];
        if (window.isNaN(cl)){
            if (cl.value == clNumber){
                label = `${cl.label}`
            }
        }else{
            if (+cl == +clNumber){
                label = `${cl} КЛАСС`;
            }

        }
    }
    label = label.replace('<br/>', '')
    return label;
}

export default function LandingApp(props) {
    let {id} = props.match.params;
    let subj = window.location.hash.replace('#/', '').replace('/', '');
    let xObj = NAMES_MAP[subj];
    let name = (xObj == undefined) ? '' : xObj.name;
    let background = (xObj == undefined) ? phys_img : xObj.background;
    let classes = (xObj.classes || []);

    return (
        <LandingTemplate active={'home-'} xObj={xObj}>

            <FullPageImageSection
                image={background}
                height={`90vh`} isVideo={false}>
                <div style={{width: '100%'}}>
                    <ImTopSectionPanel
                        hasGreyOverlay={false}
                        subMotto={(
                            <div>
                                {/*СЕТЕВАЯ ОЛИМПИАДНАЯ ШКОЛА*/}
                                {name}
                            </div>
                        )}
                    />
                    <ImBottomSection>
                        {/*{classes.filter(x => (+x > 5)).map((clNumber, k) => {*/}
                        {classes.map((clNumber, k) => {
                            let xKey = clNumber;
                            let label = ``
                            if (window.isNaN(clNumber)){
                                xKey = clNumber.value;
                                label = `${clNumber.label}`
                            }else{
                                label = `${clNumber} КЛАСС`;
                            }
                            return (
                                <ClassBox onClick={() => {
                                    CommonHelper.linkTo(`/${subj}/class/${xKey}`);
                                }}>
                                    {/*{JSON.stringify(clNumber)}*/}
                                    <span dangerouslySetInnerHTML={{__html: label}} ></span>
                                    {/*<NumberPlaceholder>*/}
                                    {/*    {clNumber}*/}
                                    {/*</NumberPlaceholder>*/}
                                    {/*<LabelPlaceholder>*/}
                                    {/*    класс*/}
                                    {/*</LabelPlaceholder>*/}
                                </ClassBox>
                            )
                        })}
                    </ImBottomSection>
                </div>
            </FullPageImageSection>


            <BottomContentPlaceholder>

                <RespContainer>
                    <ContentHeading>
                        НОВОСТИ
                    </ContentHeading>
                    <div style={{paddingLeft: 9}}>
                        <MainNewsSection/>
                    </div>
                </RespContainer>

            </BottomContentPlaceholder>

        </LandingTemplate>
    );
}

const ImTopSection = styled.div`
  width: 100%;
`;

const ImBottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  //margin-top: 80px;
  flex-direction: column;
  margin-top: -190px;
  margin-left: 300px;
`;

const size = 100;

const ClassBox = styled.div`
    // width: ${size}px;
    // height: ${size}px;
  background: ${`#005F9B`};
  margin: 10px;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  padding: 7px;
  padding-left: 30px;
  padding-right: 30px;
  width: 260px;
  margin-left: 50px;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;

  :hover {
    background: ${YELLOW};
    border-radius: 8px;
  }

  @media (max-height: 900px) {
    width: auto !important;
  }
`;

const NumberPlaceholder = styled.div`
  text-align: center;
  height: ${size * 0.8}px;
  line-height: ${size * 0.9}px;
  font-size: ${size * 0.7}px;
  font-weight: bold;
`;

const LabelPlaceholder = styled.div`
  height: ${size * 0.2}px;
  line-height: ${size * 0.13}px;
  text-align: center;
  font-size: 18px;
`;

const BottomContentPlaceholder = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  background: white;
`;

const ContentHeading = styled.div`
  color: ${BLUE};
  font-size: 32px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: -10px;
  padding-left: 24px;
  margin-top: 35px;
`;

const RespContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
