import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import BottomUserPanel from "../../templates/BottomUserPanel";

import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";


export default function UsersList(props) {
    const {
        users = [], onUserClick = (user, userId) => {
        },
        selectedUserId
    } = props;
    if (users.length == 0) {
        return null;
    }


    return (
        <Wrapper>

            <List>

                {users.map((user, k) => {
                    return (
                        <UserItem key={`${user.id}_${k}`}
                                  selected={(user.id == selectedUserId)}
                                  onClick={() => {
                                      onUserClick(user, user.id);
                                  }}>
                            <BottomUserPanel user={user}
                                             canUpdateAvatar={false}/>
                        </UserItem>
                    )
                })}

            </List>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const List = styled.div`
    
`;

const UserItem = styled.div`
    //display: inline-block;
    //width: calc(33.333% - 10px);
    width: 100%;
    margin: 5px;
    vertical-align: top;
    box-sizing: border-box;
    cursor: pointer;
    
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    
    border: 1px solid whitesmoke;
    border-radius: 4px;
    padding: 10px;
    
    :hover{
      opacity: 0.7;
    }
    @media(max-width: 900px){
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }
`;
