import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import SchoolPanel from "../../schools/panels/SchoolPanel";

import Tabs from '../../ui/Tabs'
import LessonsCalendar from "../../lessons/tools/LessonsCalendar";
import * as actions from "../../../redux/actions/LessonsActions";

import Sidebar from 'arui-feather/sidebar'
import SimpleLessonsList from "../../lessons/tools/SimpleLessonsList";
import LessonShortInfo from "../../lessons/tools/LessonShortInfo";

const now = +new Date();

export default function StudentPersonalPanel(props) {
    let {id} = props;
    const [selectedId, setSelectedId] = useState(undefined);
    let {currentUser, loading, student, franchisee, school, rooms, groups, lessons, teachers} = useMappedState(useCallback(state => {
        let student = state.users.usersMap.get(id);
        let groups = state.groups.groupsMap.toArray().filter(g => (g.studentsIds.indexOf(id) > -1));
        let lessons = state.lessons.lessonsMap.toArray().filter(lesson => {
            let gr = CommonHelper.getItemById(groups, lesson.groupId);
            return ((gr == undefined) ? false : (gr.studentsIds.indexOf(id) > -1));
        });
        let franchisee = (student == undefined) ? undefined : state.users.usersMap.get(student.franchiseeId);
        let franchiseeId = (student == undefined) ? undefined : student.franchiseeId;
        let schoolId = (student == undefined) ? undefined : student.schoolId;
        let teachers = state.users.usersMap.toArray().filter(a => (franchiseeId == a.franchiseeId));

        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            student: student,
            groups: groups,
            teachers: teachers,
            lessons: lessons,
            rooms: state.rooms.roomsMap.toArray().filter(a => (a.schoolId == schoolId)),
            school: (student == undefined) ? undefined : state.schools.schoolsMap.get(student.schoolId),
            franchisee: franchisee
        }
    }, [id]));

    console.log('StudentPersonalPanel: render: student, school, franchisee = ', student, school, franchisee);
    if (school == undefined || franchisee == undefined) {
        return null;
    }
    const selectedLesson = (selectedId == undefined) ? undefined : lessons.filter(g => (g.id == selectedId))[0];
    let selTeachers = (selectedLesson == undefined) ? undefined : selectedLesson.teachersIds.map(aa => CommonHelper.getItemById(teachers, aa)).filter(a => (a != undefined));
    let selRoom = (selectedLesson == undefined) ? undefined : CommonHelper.getItemById(rooms, selectedLesson.roomId);
    let selGroup = (selectedLesson == undefined) ? undefined : CommonHelper.getItemById(groups, selectedLesson.groupId);

    return (
        <Wrapper>

            <Heading>
                Расписание занятий
            </Heading>

            <TabsPlaceholder>
                <Tabs tabs={[
                    {
                        label: 'В виде календаря',
                        content: (
                            <TabItem>
                                <LessonsCalendarPlaceholder>
                                    <LessonsCalendar
                                        getRoom={rId => CommonHelper.getItemById(rooms, rId)}
                                        selectedId={selectedId}
                                        lessons={lessons} onLessonClick={(lId, l) => {
                                        setSelectedId(lId);
                                    }}/>
                                </LessonsCalendarPlaceholder>
                            </TabItem>
                        )
                    },

                    // {
                    //     label: 'Предстоящие занятия списком',
                    //     content: (
                    //         <TabItem>
                    //             <SimpleLessonsList
                    //                 {...{
                    //                     lessons: lessons.filter(a => (+a.startTimestamp > +now)).sort((a, b) => (+a.startTimestamp - +b.startTimestamp)),
                    //                     teachers, rooms,
                    //                     groups, selectedId, setSelectedId,
                    //                 }}
                    //             />
                    //         </TabItem>
                    //     )
                    // },
                    //
                    // {
                    //     label: 'Все занятия списком',
                    //     content: (
                    //         <TabItem>
                    //             <SimpleLessonsList
                    //                 {...{
                    //                     lessons: lessons.sort((a, b) => (+b.startTimestamp - +a.startTimestamp)),
                    //                     teachers, rooms,
                    //                     groups, selectedId, setSelectedId,
                    //                 }}
                    //             />
                    //         </TabItem>
                    //     )
                    // }

                ]}/>
            </TabsPlaceholder>

            <Sidebar visible={(selectedLesson != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>
                <LessonShortInfo lesson={selectedLesson} room={selRoom} teachers={selTeachers} group={selGroup}/>

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TabsPlaceholder = styled.div`
    
`;

const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;

const LessonsCalendarPlaceholder = styled.div`
    height: 500px;
    width: 100%;
    background: white;
`;

const B = styled.span`
    font-weight: bold;
    opacity: 0.7;
`;


const Heading = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;
