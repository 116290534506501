import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {BLUE, YELLOW} from "../../ui/Colors";
import CommonHelper from "../../../helpers/CommonHelper";
import * as actions from "../../../redux/actions/UsersActions";
import UsersAPI from "../../../api/UsersAPI";
import ls from "local-storage";
import {CR_OPTS} from "../MySidebar";

export default function LandingTemplate(props) {
    let {active = 'home', hasProfileLink = false, xObj = {}, subj = 'phys'} = props;
    const dispatch = useDispatch();

    const [selCr, setSelCr] = useState(ls('sel_discipline') || 'phys');
    useEffect(() => {
        if (selCr != undefined) {
            ls('sel_discipline', selCr);
        }
    }, [selCr]);

    let {currentUser, switchMode} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser == undefined) ? undefined : currentUser.userRole;
        let switchMode = (userRole != 'student' && userRole != undefined);
        return {
            currentUser: currentUser,
            switchMode: switchMode
        }
    }, []));

    const [botVisible, setBotVisible] = useState(false);

    return (
        <Wrapper>

            <InnerPlaceholder>

                <VeryTopPlaceholder>
                    <Container style={{
                        display: 'flex',
                        flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%'
                    }}>
                        {hasProfileLink == false ? null :
                            <ProfileLink onClick={() => {
                                CommonHelper.linkTo(`/login`);
                            }}>
                                <UserImage src={require('../../../assets/images/user_s.svg')}/>
                                <UserLinkSpan>
                                    Личный кабинет
                                </UserLinkSpan>
                            </ProfileLink>
                        }
                    </Container>
                </VeryTopPlaceholder>


                <Header>
                    <Container style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'stretch',
                        justifyContent: 'space-between',
                        height: '100%',
                    }}>
                        {HEADER_ITEMS.map((a, k) => {
                            if (a.name == 'programms') {
                                return (
                                    <DropdownHeaderItem key={`${a.name}_${k}`}>
                                        <DropdownLabel>
                                            {a.label}
                                        </DropdownLabel>
                                        <DropdownContent>
                                            {[7, 8, 9, 10, 11].map(a => (
                                                <DropdownItem key={a} onClick={() => {
                                                    CommonHelper.linkTo(`/class/${a}`);
                                                }}>
                                                    {`${a} класс`}
                                                </DropdownItem>
                                            ))}
                                            <DropdownItem key={a} onClick={() => {
                                                CommonHelper.linkTo(`/class/0`);
                                            }}>
                                                {/*{'Общее'}*/}
                                                {'ВсОШ'}
                                            </DropdownItem>
                                            <DropdownItem key={a} onClick={() => {
                                                CommonHelper.linkTo(`/class/1`);
                                            }}>
                                                {'Эксперимент'}
                                            </DropdownItem>
                                        </DropdownContent>

                                    </DropdownHeaderItem>
                                )
                            }
                            return (
                                <HeaderItem key={a.name} selected={(active == a.name)} onClick={() => {
                                    if (a.name == 'news') {
                                        // try {
                                        //     setTimeout(() => {
                                        //         let elmnt = document.getElementById("f_news");
                                        //         elmnt.scrollIntoView({behavior: 'smooth'});
                                        //     }, 300);
                                        // } catch (e) {
                                        //
                                        // }
                                    }
                                    if (a.name == 'home') {
                                        CommonHelper.linkTo(`/`);
                                        return;
                                    }
                                    CommonHelper.linkTo(`/${subj}${a.url}`);
                                }}>
                                    {a.label}
                                </HeaderItem>
                            )
                        })}

                        {/*<CrOptPlaceholder>*/}
                        {/*    {CR_OPTS.map((a, i) => {*/}
                        {/*        return (*/}
                        {/*            <CrOptItem key={a.value} selected={(a.value == selCr)} onClick={() => {*/}
                        {/*                setSelCr(a.value);*/}
                        {/*                ls('sel_discipline', a.value);*/}
                        {/*                window.location.href = '/';*/}
                        {/*                window.location.reload();*/}
                        {/*            }}>*/}
                        {/*                {a.ext_label}*/}
                        {/*            </CrOptItem>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</CrOptPlaceholder>*/}

                        {currentUser == undefined ?
                            <HeaderLoginItem onClick={() => {
                                CommonHelper.linkTo(`/login`);
                            }}>
                                Вход
                            </HeaderLoginItem> :
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <HeaderLoginItem onClick={() => {
                                    if (switchMode == true ){
                                        ls('force_landing', false);
                                        // window.location.href = '/';
                                        CommonHelper.linkTo('/');
                                        window.location.reload();
                                    }
                                    CommonHelper.linkTo(`/${subj}/profile`);
                                }}>
                                    {`${CommonHelper.getShortNameByUser(currentUser)}${currentUser.classNumber == undefined ? `` : ` (${currentUser.classNumber} кл.)`}`}
                                </HeaderLoginItem>
                                <LogoutImg src={require('../../../assets/images/logout_1.svg')}
                                           onClick={() => {
                                               UsersAPI.forceLogout();
                                               window.location.reload();
                                           }}
                                />
                            </div>
                        }


                    </Container>
                </Header>

                <ContentPlaceholder>
                    {props.children}
                </ContentPlaceholder>

                <FooterPlaceholder>
                    <Container style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '100%'
                    }}>
                        <MiptBottomPlaceholder>
                            <MiptLogo src={require('../../../assets/images/logo_sm_mipt.svg')}
                                      onClick={() => {
                                          window.location.href = 'https://mipt.ru';
                                      }}
                            />
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                window.location.href = 'https://mipt.ru';
                            }}>
                                    Московский Физико-Технический Институт
                            </span>
                        </MiptBottomPlaceholder>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <span
                                style={{
                                    marginRight: 10,
                                    fontSize: 12,
                                    opacity: 0.75,
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setBotVisible(true);
                                }}>
                                телеграм-бот
                            </span>
                            {currentUser == undefined ? null :
                                <LogoutPlaceholder>
                                    <LogoutSpan onClick={async () => {
                                        await dispatch(actions.logOut());
                                        CommonHelper.linkTo(`/`);
                                    }}>
                                        выход
                                    </LogoutSpan>
                                </LogoutPlaceholder>
                            }
                        </div>

                    </Container>
                </FooterPlaceholder>

            </InnerPlaceholder>

            <Sidebar width={820} visible={botVisible} onCloserClick={() => {
                setBotVisible(false);
            }}>

                {botVisible == false ? null :
                    <div>
                        <h3>
                            Telegram-бот для уведомлений
                        </h3>
                        <p>
                            {'Для того, чтобы получать уведомления о новых сообщениях, добавьте телеграм-бота '}
                            <a href={'https://t.me/os_mipt_ru_bot'} target={'_blank'}>
                                https://t.me/os_mipt_ru_bot
                            </a>
                        </p>
                        <p>
                            Далее необходимо авторизоваться у бота.
                        </p>
                        <p>
                            Для этого напишите <b>/login ВАША_ПОЧТА ВАШ_ПАРОЛЬ</b>
                        </p>
                        <p>
                            Пример: <i>/login ivan@test.com 12345678</i>
                        </p>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const HEADER_ITEMS = [
    {
        label: 'Главная',
        name: 'home',
        url: '/'
    },
    {
        label: 'О проекте',
        name: 'about',
        url: '/about'
    },
    {
        label: 'Новости',
        name: 'news',
        url: '/news'
    },
    // {
    //     label: 'Программы',
    //     name: 'programms',
    //     url: '/programms',
    //     isClass: true
    // },
    {
        label: 'Участники',
        name: 'centers',
        url: '/centers'
    },
    {
        label: 'Контакты',
        name: 'contacts',
        url: '/contacts'
    }
];

const CrOptPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
`;

const CrOptItem = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  background: ${props => (props.selected == true ? '#E19927' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;
`;

const LogoutImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 24px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  background: white;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  font-family: 'PT Sans', sans-serif;
`;

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const InnerPlaceholder = styled.div`
  width: 100%;
`;

const headerHeight = 50;

const Header = styled.div`
  width: 100%;
  background: white;
  height: ${headerHeight}px;
`;

const HeaderItem = styled.div`
  text-align: center;
  color: ${props => (props.selected == false ? BLUE : YELLOW)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;

  :hover {
    opacity: 0.75;
    text-decoration: underline;
    color: ${YELLOW};
  }

  @media (max-width: 920px) {
    font-size: 16px;
  }
  @media (max-width: 720px) {
    font-size: 11px;
  }
`;

const HeaderLoginItem = styled.div`
  background: ${BLUE};
  color: white;
  margin-top: 7px;
  margin-bottom: 7px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  height: 32px;
  cursor: pointer;
  @media (max-width: 920px) {
    font-size: 16px;
  }
  @media (max-width: 720px) {
    font-size: 13px;
  }

  :hover {
    opacity: 0.75;
  }
`;

const DropdownLabel = styled.div`
  color: ${BLUE};

  :hover {

  }

  @media (max-width: 920px) {
    font-size: 16px;
  }
  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  top: ${headerHeight}px;
  left: -13px;
  right: 0px;
  background: white;
  z-index: 100;
  width: 120px;
`;

const DropdownItem = styled.div`
  text-align: center;
  height: ${headerHeight * 0.9}px;
  line-height: ${headerHeight * 0.9}px;
  color: ${BLUE};

  :hover {
    text-decoration: underline;
    color: ${YELLOW};
  }

  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

const DropdownHeaderItem = styled.div`
  text-align: center;
  color: ${BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  position: relative;

  :hover {
    text-decoration: underline;
  }

  :hover {
    & > ${DropdownContent} {
      display: block;
    }
  }
`;


const VeryTopPlaceholder = styled.div`
  background: ${BLUE};
  //height: 32px;
  height: 2px;
`;

const ProfileLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;
  cursor: pointer;

  :hover {
    opacity: 0.75;
  }
`;

const UserImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

const UserLinkSpan = styled.div`
  color: white;
`;

const ContentPlaceholder = styled.div`
  min-height: calc(100vh - ${34 + headerHeight}px);
`;

const FooterPlaceholder = styled.div`
  background: ${BLUE};
  height: 32px;
`;

const MiptBottomPlaceholder = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;

const MiptLogo = styled.img`
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
`;

const LogoutPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LogoutSpan = styled.span`
  opacity: 0.8;
  cursor: pointer;
  font-size: 14px;
  color: white;

  :hover {
    opacity: 1;
  }
`;
