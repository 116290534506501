import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Spin from 'arui-feather/spin'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from '../../ui/Button'
import AttachmentsEditor from "../tools/AttachmentsEditor";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";

export default function UpdateDocumentForm(props) {
    let {
        onSave = (newData) => {

        },
        loading = false,
        canEdit = true,
        canDelete = false,
        onDelete = () => {

        }
    } = props;
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [avatar, setAvatar] = useState(props.avatar);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [attachments, setAttachments] = useState(props.attachments == undefined ? [] : props.attachments);
    console.log('UpdateDocumentForm: render: attachments = ', attachments);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={name} onChange={evt => setName(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={description} onChange={evt => setDescription(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Обложка (аватарка)
                </Label>
                {avatar == undefined ? null :
                    <div>
                        <Avatar src={avatar}/>
                    </div>
                }
                <SimpleUploadPanel onUploaded={url => setAvatar(url)}>
                    <UploaderPlaceholder>
                        Загрузить обложку
                    </UploaderPlaceholder>
                </SimpleUploadPanel>
            </Field>

            <Field>
                <Label>
                    Аттачменты
                </Label>
                <AttachmentsEditor key={`spike_${attachments.length}`} attachments={attachments} onChange={newArr => {
                    console.log('onChange: newArr = ', newArr);
                    setAttachments(newArr);
                }}/>
            </Field>

            {canEdit == false ? null :
                <Field>
                    <Button onClick={() => {
                        onSave({
                            name, description, avatar, attachments
                        });
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Сохранить'
                        }
                    </Button>
                </Field>
            }

            {canDelete == false ? null :
                <Field style={{marginTop: 30}}>
                    <DangerSpan onClick={() => {
                        if (window.confirm('Вы уверены?') == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        Удалить
                    </DangerSpan>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const UploaderPlaceholder = styled.div`
    padding: 5px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Avatar = styled.img`
    height: 120px;
`;

const DangerSpan = styled.span`
    color: red;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;
