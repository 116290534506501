import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Select from 'react-select'
import {DARK_SIDE} from "../ui/Colors";

import ReactPaginate from 'react-paginate';
// import 'react-paginate/demo/styles/style.css'
import './style.css'

const PAGE_SIZE_OPTIONS = [
    {
        value: 25,
        label: '25'
    },
    {
        value: 50,
        label: '50'
    },
    {
        value: 100,
        label: '100'
    },
    {
        value: 1000000,
        label: 'Все'
    },
]

export default function PaginatorWrapper(props) {
    let {items, renderItems = arr => null} = props;
    let [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0].value);

    const from = (page * pageSize);
    const to = ((+page + 1) * pageSize);
    const slicedItems = items.slice(from, to);

    const maxPageNumber = Math.ceil(1.0 * items.length / pageSize);
    const pages = Array.from({length: maxPageNumber}, (v, i) => i);

    return (
        <Wrapper>

            <ContentPlaceholder>
                {renderItems(slicedItems)}
            </ContentPlaceholder>

            <BottomPlaceholder>
                {/*{pages.length == 1 || items.length < PAGE_SIZE_OPTIONS[0].value ? null :*/}
                {/*<PageBoxes>*/}
                {/*{pages.map((a, k) => {*/}
                {/*let selected = (+page == +a);*/}
                {/*return (*/}
                {/*<Box selected={selected} key={`${pageSize}_${k}`} onClick={() => {*/}
                {/*setPage(+a);*/}
                {/*}}>*/}
                {/*{+a + 1}*/}
                {/*</Box>*/}
                {/*)*/}
                {/*})}*/}
                {/*</PageBoxes>*/}
                {/*}*/}

                {pages.length == 1 || items.length < PAGE_SIZE_OPTIONS[0].value ? null :
                    <div className={'paginator_placeholder'}>
                        <ReactPaginate pageCount={pages.length}
                                       marginPagesDisplayed={2}
                                       pageRangeDisplayed={3}
                                       previousLabel={(<div>Назад</div>)}
                                       nextLabel={(<div>Вперед</div>)}
                                       onPageChange={a => {
                                           console.log('onPageChange: a = ', a);
                                           setPage(+a.selected);
                                       }}
                        />
                    </div>
                }

                {items.length < PAGE_SIZE_OPTIONS[0].value ? null :
                    <PageSizePlaceholder>
                        <div style={{fontSize: 12, marginRight: 7}}>
                            На странице:
                        </div>
                        <PageSizeSelectorPlaceholder>
                            <Select options={PAGE_SIZE_OPTIONS}
                                    value={PAGE_SIZE_OPTIONS.filter(p => (p.value == pageSize))[0]}
                                    onChange={a => {
                                        setPageSize(a.value);
                                        setPage(0);
                                    }}
                            />
                        </PageSizeSelectorPlaceholder>
                    </PageSizePlaceholder>
                }

                {items.length < PAGE_SIZE_OPTIONS[0].value ? null :
                    <BottomInfoPlaceholder>
                        {`Записи ${page * pageSize + 1} - ${Math.min(items.length, (+page + 1) * pageSize)} из ${items.length}`}
                    </BottomInfoPlaceholder>
                }

            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
`;

const BottomPlaceholder = styled.div`
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    //align-items: flex-start;
    align-items: center;
`;

const PageBoxes = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const PageSizePlaceholder = styled.div`
    width: 200px;
    background: whitesmoke;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const PageSizeSelectorPlaceholder = styled.div`
    width: 80px;
`;

const BottomInfoPlaceholder = styled.div`
    width: 180px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    align-self: stretch;
`;

const Box = styled.div`
    padding: 5px;
    margin: 3px;
    border: 1px solid whitesmoke;
    border-radius: 3px;
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 30px;
    text-align: center;
`;

const ContentPlaceholder = styled.div`
    
`;
