import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Button} from '@blueprintjs/core'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {DARK_SIDE} from "../../ui/Colors";

export default function SimpleScoreTool(props) {
    let {
        score = undefined, minScore = 1, maxScore = 5, onChange = newScore => {

        }
    } = props;
    let scoreOptions = Array.from({length: (+maxScore - +minScore + 1)}).map((a, j) => (+j + +minScore));

    return (
        <Wrapper>

            <ScoresList>
                {scoreOptions.map((sc, j) => {
                    let isSelected = (sc == score);
                    return (
                        <ScoreItem selected={isSelected} key={sc} onClick={() => {
                            if (isSelected == true){
                                onChange(undefined);
                            }else {
                                onChange(+sc);
                            }
                        }} >
                            {sc}
                        </ScoreItem>
                    )
                })}
            </ScoresList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ScoresList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const scoreSize = 36;
const miniScoreSize = 24;

const ScoreItem = styled.div`
    width: ${scoreSize}px;
    height: ${scoreSize}px;
    font-size: ${scoreSize * 0.75}px;
    
    @media(min-width: 920px){
      width: ${miniScoreSize}px;
      height: ${miniScoreSize}px;
      font-size: ${miniScoreSize * 0.75}px;
    }
    
    border: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 3px;
    background-color: ${props => (props.selected == true ? DARK_SIDE : 'transparent')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    
    :hover{
      opacity: 0.5;
    }
`;
