import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";
import AllSubmissionsPanel from "../submissions/panels/AllSubmissionsPanel";


export default function AdminSubmissionsApp(props) {

    return (
        <ChessTemplate active={'submissions'}>

            <AllSubmissionsPanel/>

        </ChessTemplate>
    );
}

