import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'

import {Map} from 'immutable'

import axios from 'axios'
import useDebouncedCallback from "use-debounce/lib/callback";

import * as usersActions from '../../../redux/actions/UsersActions'
import * as tasksActions from '../../../redux/actions/TasksActions'
import * as subActions from '../../../redux/actions/SubmissionsActions'
import {BLUE} from "../../ui/Colors";
import ViewTaskTool from "../tools/ViewTaskTool";
import ViewSubmissionTool from "../tools/ViewSubmissionTool";
import StudentTaskSubmissionPanel from "./StudentTaskSubmissionPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import AchievementsListEditor from "../../achievements/tools/AchievementsListEditor";

import ls from 'local-storage'
import SubjectCommentsPanel from "../../comments/panels/SubjectCommentsPanel";

export default function StudentTasksPanel(props) {
    const dispatch = useDispatch();
    const {studentId, subj = 'phys'} = props;
    const {
        canFeelProfile = false
    } = props;

    const [saving, setSaving] = useState(false);

    const [mode, setMode] = useState((ls('user_pers_profile_mode') == undefined) ? 'tasks' : ls('user_pers_profile_mode'));

    const [botVisible, setBotVisible] = useState(false);

    const [selectedId, setSelectedId] = useState(undefined);
    let {
        currentUser,
        loading,
        userRole,
        centersMap,
        allCenters,
        submissions,
        student,
        tasks,
        classNumber,
        stId
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let stId = (studentId == undefined) ? state.users.currentUserId : studentId;
        let submissions = state.submissions.submissionsMap.toArray().filter(a => (a.studentId == stId));
        let student = state.users.usersMap.get(stId);
        let classNumber = (student == undefined) ? undefined : student.classNumber;
        let tasks = state.tasks.tasksMap.toArray().filter(a => (a.classNumber == classNumber)).filter(x => (+x.startTimestamp > 1628850635000));
        tasks = tasks.filter(x => (x.discipline == subj))
        let myTasksIds = submissions.map(x => x.taskId).filter(x => (x != undefined));
        let myTasks = state.tasks.tasksMap.toArray().filter(xx => (myTasksIds.indexOf(xx.id) > -1));
        console.log('myTasks = ', myTasks);

        let newTasks = state.tasks.tasksMap.toArray().filter(a => (a.classNumber == classNumber)).filter(x => (x.status != 'archived'));
        let xTasks = myTasks.concat(newTasks);
        let uniqueTasks = xTasks.reduce((mp, tsk) => mp.set(tsk.id, tsk), Map()).toArray();

        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId || state.tasks.loading || state.submissions.loading,
            loading: state.users.loading || state.tasks.loading || state.submissions.loading,
            student: student,
            centersMap: state.centers.centersMap,
            submissions: submissions,
            classNumber: classNumber,
            tasks: uniqueTasks,
            stId: stId
        }
    }, []));

    useEffect(() => {
        ls('user_pers_profile_mode', mode);
    }, [mode]);

    useEffect(() => {
        dispatch(usersActions.loadUserById(stId));
        dispatch(tasksActions.loadAllTasks());
        dispatch(subActions.loadAllSubmissions()); // todo: load only user's submissions
    }, [stId]);

    if (currentUser == undefined) {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }


    let sortedTasks = tasks.sort((a, b) => (+a.startTimestamp - +b.startTimestamp));
    let selectedTask = (selectedId == undefined) ? undefined : tasks.filter(a => (a.id == selectedId))[0];
    let selectedTaskSubmission = (selectedId == undefined) ? undefined : submissions.filter(a => (a.taskId == selectedId))[0];

    let selStObject = CommonHelper.getTaskSubmissionStatusObject(selectedTask, selectedTaskSubmission);

    if (loading == true && stId == undefined || classNumber == undefined) {
        return (
            <Code/>
        )
    }

    console.log('StudentTasksPanel: render: stId = ', stId);
    console.log('StudentTasksPanel: render: submissions = ', submissions);
    console.log('StudentTasksPanel: render: tasks = ', tasks);

    return (
        <Wrapper>

            {classNumber == undefined ? null :
                <Heading>
                    {mode != 'tasks' ? null :
                        <span>
                            Большие домашние задания для {` ${classNumber} `} класса
                        </span>
                    }

                    {mode != 'profile' ? null :
                        <span>
                            Редактирование достижений
                        </span>
                    }

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                        <img src={require('../../../assets/images/telegram.svg')}
                             style={{height: 24, marginRight: 20, cursor: 'pointer'}} onClick={() => {
                            setBotVisible(true);
                        }}/>

                        {canFeelProfile == false ? null :
                            <FillProfileSpan style={{marginRight: 10}} onClick={() => {
                                setMode(
                                    {
                                        'tasks': 'profile',
                                        'profile': 'tasks'
                                    }[mode]
                                );
                            }}>
                                {
                                    {
                                        'tasks': 'Заполнить профиль',
                                        'profile': 'вернуться к заданиям'
                                    }[mode]
                                }

                            </FillProfileSpan>
                        }


                        <TopLogoutSpan onClick={async () => {
                            await dispatch(usersActions.logOut());
                            CommonHelper.linkTo(`/`);
                        }}>
                            выход
                        </TopLogoutSpan>
                    </div>
                </Heading>
            }

            {mode != 'profile' ? null :
                <div>
                    <AchievementsListEditor {...currentUser} saving={saving} onSave={async arr => {
                        console.log('onSave: arr = ', arr);
                        setSaving(true);
                        await dispatch(usersActions.updateUser({
                            id: currentUser.id,
                            achievements: arr
                        }));
                        setSaving(false);
                        // onSave({achievements: arr})
                    }}/>
                </div>
            }

            {mode != 'tasks' ? null :
                <TasksList>
                    {sortedTasks.map((tsk, k) => {
                        let {startTimestamp, deadlineTimestamp, name, description, avatar, taskUrl} = tsk;
                        let mySubmissions = submissions.filter(a => (a.taskId == tsk.id));
                        let sub = (mySubmissions.length == 0) ? null : mySubmissions[0];
                        let {
                            isFromFuture,
                            isFromPast,
                            isCurrent,
                            isChecked,
                            isSubmitted,
                            isMissed
                        } = CommonHelper.getTaskSubmissionStatusObject(tsk, sub);

                        return (
                            <TaskItem selected={(selectedId == tsk.id)} key={tsk.id} submitted={isSubmitted}
                                      onClick={() => {
                                          setSelectedId(tsk.id);
                                      }}>

                                <Left>
                                    <Name>
                                        {isSubmitted == false ? null :
                                            <StatusImg src={require('../../../assets/images/green_checked.svg')}/>
                                        }
                                        {isMissed == false ? null :
                                            <StatusImg src={require('../../../assets/images/sad_smile.svg')}/>
                                        }
                                        {isFromFuture == false ? null :
                                            <StatusImg src={require('../../../assets/images/calendar.svg')}/>
                                        }
                                        <span>
                                        {name}
                                    </span>
                                    </Name>
                                    <Description>
                                        {description}
                                    </Description>
                                    <InfoPlaceholder>
                                        <a href={taskUrl} target={'_blank'}>
                                            скачать задание
                                        </a>
                                    </InfoPlaceholder>
                                </Left>
                                <Right>
                                    <DatePlaceholder>
                                        <SiRow>
                                            {`Дата сдачи: ${`${moment(startTimestamp).format('DD.MM.YYYY')} - ${moment(deadlineTimestamp).format('DD.MM.YYYY')}`}`}
                                        </SiRow>
                                        <SiRow>
                                        <span>
                                            {!(isFromPast == true && isSubmitted == false) ? null :
                                                <StatusSpan color={'red'}>
                                                    Просрочено, решение не отправлено
                                                </StatusSpan>
                                            }
                                            {!(isFromPast == true && isSubmitted == true) ? null :
                                                <StatusSpan color={'green'}>
                                                    {'Выполнено '}
                                                    {isChecked == true ? ' и проверено преподавателем' : ', ожидает проверки'}
                                                </StatusSpan>
                                            }
                                            {!(isFromFuture == true) ? null :
                                                <StatusSpan color={'orange'}>
                                                    Время еще не пришло
                                                </StatusSpan>
                                            }
                                            {!(isCurrent == true && isSubmitted == true) ? null :
                                                <StatusSpan color={'lightgreen'}>
                                                    {isChecked == true ? null :
                                                        <MiniStatusImg
                                                            src={require('../../../assets/images/hourglass_.svg')}/>
                                                    }
                                                    {'Выполнено'}
                                                    {isChecked == true ? ', проверено преподавателем' : ' и ожидает проверки'}
                                                </StatusSpan>
                                            }
                                            {!(isCurrent == true && isSubmitted == false) ? null :
                                                <StatusSpan color={'orange'}>
                                                    Ожидает выполнения
                                                </StatusSpan>
                                            }
                                        </span>
                                        </SiRow>
                                        {isChecked == false ? null :
                                            <div>
                                                <div>
                                                    {`Оценка: ${sub.mark} баллов`}
                                                    <span
                                                        style={{
                                                            marginLeft: 4,
                                                            fontSize: 10,
                                                            opacity: 0.6,
                                                            color: 'black'
                                                        }}>из 50</span>
                                                </div>
                                            </div>
                                        }
                                    </DatePlaceholder>
                                </Right>

                            </TaskItem>
                        )
                    })}
                </TasksList>
            }

            <Sidebar width={Math.min(720, window.innerWidth)} visible={(selectedTask != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedTask == undefined ? null :
                    <SelTaskPlaceholder>

                        <div style={{marginBottom: 30}}>
                            <ViewTaskTool {...selectedTask} />
                        </div>

                        {selectedTaskSubmission == undefined || selectedTaskSubmission.mark == undefined ?
                            <div>
                                {selStObject.studentCanUpdate == false ? null :
                                    <div>
                                        <H2>
                                            Файлы с решением ученика
                                        </H2>
                                        <div style={{marginBottom: 30}}>
                                            {`Загрузите файлы с выполненным заданием до ${moment(selectedTask.deadlineTimestamp).format('DD.MM.YYYY')}`}
                                        </div>
                                        <StudentTaskSubmissionPanel studentId={stId} taskId={selectedTask.id}/>
                                    </div>
                                }
                                {selStObject.isMissed == false ? null :
                                    <div style={{color: 'red'}}>
                                        ученик просрочил выполнение задания
                                    </div>
                                }
                                {selStObject.isFromFuture == false ? null :
                                    <div>
                                        Еще не пришла пора сдавать это задание.
                                    </div>
                                }
                            </div>
                            :
                            <>
                                <ViewSubmissionTool {...selectedTaskSubmission} task={selectedTask}
                                                    currentUser={currentUser}/>
                                <div>
                                    <SubjectCommentsPanel subjectId={selectedTaskSubmission.id}/>
                                </div>
                            </>

                        }

                    </SelTaskPlaceholder>
                }

            </Sidebar>

            <Sidebar width={820} visible={botVisible} onCloserClick={() => {
                setBotVisible(false);
            }}>

                {botVisible == false ? null :
                    <div>
                        <h3>
                            Telegram-бот для уведомлений
                        </h3>
                        <p>
                            {'Для того, чтобы получать уведомления о новых сообщениях, добавьте телеграм-бота '}
                            <a href={'https://t.me/os_mipt_ru_bot'} target={'_blank'}>
                                https://t.me/os_mipt_ru_bot
                            </a>
                        </p>
                        <p>
                            Далее необходимо авторизоваться у бота.
                        </p>
                        <p>
                            Для этого напишите <b>/login ВАША_ПОЧТА ВАШ_ПАРОЛЬ</b>
                        </p>
                        <p>
                            Пример: <i>/login ivan@test.com 12345678</i>
                        </p>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const FillProfileSpan = styled.span`
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  margin-right: 20px;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  font-size: 14px;
  background: #005F9B;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Heading = styled.div`
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLogoutSpan = styled.div`
  font-size: 14px;
  opacity: 0.5;
  font-weight: normal;
  cursor: pointer;
  display: inline-block;

  :hover {
    opacity: 1;
    text -decoration: underline;
  }
`;

const Wrapper = styled.div`

`;

const TasksList = styled.div`

`;

const TaskItem = styled.div`
  cursor: pointer;
  background: ${props => (props.selected == true ? BLUE : (props.submitted == true ? 'white' : 'white'))};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  padding: 10px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  :hover {
    opacity: 0.7;
  }`;

const Left = styled.div`

`;

const Right = styled.div`
  text-align: right;
`;

const Name = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const DatePlaceholder = styled.div`

`;

const SiRow = styled.div`
  margin-bottom: 5px;
`;

const InfoPlaceholder = styled.div`

`;

const Description = styled.div`
  opacity: 0.5;
  font-size: 13px;
`;

const SelTaskPlaceholder = styled.div`
  margin-top: 30px;
`;

const StatusSpan = styled.span`
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  font-weight: bold;
  border: 1px solid whitesmoke;
  border-color: ${props => props.color == undefined ? 'white' : props.color};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const H2 = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const StatusImg = styled.img`
  display: inline-block;
  margin-right: 7px;
  width: 18px;
  height: 18px;
`;

const MiniStatusImg = styled.img`
  display: inline-block;
  margin-right: 7px;
  width: 13px;
  height: 13px;
`;
