import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

import {Button} from "../../ui/Button";
import KvdbSelect from "../panels/KvdbSelect";

export default function UpdateTagForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [honor, setHonor] = useState(props.honor);
    const [year, setYear] = useState(props.year);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Название олимпиады
                </Label>
                <KvdbSelect listName={'all_olympiads'}
                            value={name}
                            onChange={a => {
                                setName(a);
                            }}/>
            </Field>

            <Field>
                <Label>
                    Достижение
                </Label>
                <KvdbSelect listName={'all_achievements'}
                            value={honor}
                            onChange={a => {
                                setHonor(a);
                            }}/>
            </Field>

            <Field>
                <Label>
                    Год
                </Label>
                <KvdbSelect listName={'all_olympiads_years'}
                            value={year}
                            onChange={a => {
                                setYear(a);
                            }}/>
            </Field>

            <Field>

                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            name: name,
                            honor: honor,
                            year: year
                        });
                    }}>
                        Сохранить
                    </Button>
                }

            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
