import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment-timezone';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import MonthCalendar from "../../calendars/MonthCalendar";
import {DEFAULT_TIMEZONE} from "../../../constants/config";
import {DARK_SIDE} from "../../ui/Colors";


export default function LessonsCalendar(props) {
    let {
        lessons = [], selectedId, onLessonClick = (lId, l) => {

        }, getRoom = a => undefined
    } = props;
    const getDayLessons = t => {
        const from = +moment(t).tz(DEFAULT_TIMEZONE).startOf('day');
        const to = +moment(t).tz(DEFAULT_TIMEZONE).endOf('day');
        return lessons.filter(a => (+a.startTimestamp >= from && a.startTimestamp < to))
    }

    return (
        <Wrapper>

            <MonthCalendar dayContent={t => {
                let lsns = getDayLessons(t);
                return (
                    <Cell>
                        <LessonsList>
                            {lsns.map((l, k) => {
                                let {name, description, startTimestamp} = l;
                                let dateString = moment(startTimestamp).tz(DEFAULT_TIMEZONE).format('HH:mm');
                                let room = getRoom(l.roomId);
                                return (
                                    <LessonItem selected={(selectedId == l.id)} key={l.id} onClick={() => {
                                        onLessonClick(l.id, l);
                                    }}>
                                        {dateString} {room == undefined ? null : ` ${room.name}`}
                                    </LessonItem>
                                )
                            })}
                        </LessonsList>
                    </Cell>
                )
            }}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const Cell = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-left: 12px;
    padding-top: 12px;
    padding-right: 5px;
`;

const LessonsList = styled.div`
    
`;

const LessonItem = styled.div`
    padding: 3px;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    background: ${props => (props.selected == true ? `#EB131F` : DARK_SIDE)};
    color: white;
    margin: 3px;
    display: inline-block;
    font-size: 12px;
    :hover{
      opacity: 0.7;
    }
`;
