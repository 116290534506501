import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";


export default function FranchiseeStatsView(props) {
    let {lessons = [], rooms = [], teachers = [], students = [], groups = [], schools = []} = props;

    return (
        <Wrapper>

            <Row>

                <Box>
                    <BoxInner onClick={() => {
                        CommonHelper.linkTo(`/schools`);
                    }} >
                        <ParamValue>
                            {schools.length}
                        </ParamValue>
                        <ParamName>
                            Школы
                        </ParamName>
                    </BoxInner>
                </Box>

                <Box>
                    <BoxInner >
                        <ParamValue>
                            {students.length}
                        </ParamValue>
                        <ParamName>
                            Ученики
                        </ParamName>
                    </BoxInner>
                </Box>

                <Box>
                    <BoxInner >
                        <ParamValue>
                            {teachers.length}
                        </ParamValue>
                        <ParamName>
                            Преподаватели
                        </ParamName>
                    </BoxInner>
                </Box>

                <Box>
                    <BoxInner>
                        <ParamValue>
                            {lessons.length}
                        </ParamValue>
                        <ParamName>
                            Занятия
                        </ParamName>
                    </BoxInner>
                </Box>

                <Box>
                    <BoxInner>
                        <ParamValue>
                            {rooms.length}
                        </ParamValue>
                        <ParamName>
                            Помещения
                        </ParamName>
                    </BoxInner>
                </Box>

                <Box>
                    <BoxInner>
                        <ParamValue>
                            {groups.length}
                        </ParamValue>
                        <ParamName>
                            Группы
                        </ParamName>
                    </BoxInner>
                </Box>


            </Row>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Box = styled.div`
    flex: 1;
    padding: 5px;
    flex-basis: 25%;
`;

const ParamName = styled.div`
    font-size: 24px;
    line-height: 32px;
`;

const ParamValue = styled.div`
    font-size: 64px;
    line-height: 64px;
`;

const BoxInner = styled.div`
    background: white;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    align-content: space-between;
`;
