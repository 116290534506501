import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import VideoBackground from "../../video/tools/VideoBackground";
import CommonHelper from "../../../helpers/CommonHelper";

export default function FullPageImageSection(props) {
    let {
        height = '80vh',
        isVideo = false,
        image = require('../../../assets/images/not_opt_arms.jpg')
    } = props;

    const [ready, setReady] = useState(false);
    let isReallyVideo = (CommonHelper.isMobile() == false) && isVideo;

    if (isReallyVideo == true) {
        return (
            <OuterWrapper height={`${window.innerHeight * 0.9}px`}>
                <VideoBgPlaceholder>
                    <VideoBackground height={window.innerHeight * 0.9} onReady={() => {
                        setReady(true)
                    }}>

                    </VideoBackground>
                </VideoBgPlaceholder>
                <Wrapper height={height}
                         key={`r_${ready == true ? 'dd' : 'ff'}`}
                         src={(ready == true ? undefined : image)}>
                    <Container>
                        <Inner>
                            {props.children}
                        </Inner>
                    </Container>
                </Wrapper>
            </OuterWrapper>
        )
    }

    return (
        <OuterWrapper height={height}>
            <Wrapper src={image}>
                <Container>
                    <Inner>
                        {props.children}
                    </Inner>
                </Container>
            </Wrapper>
        </OuterWrapper>

    );
}

const OuterWrapper = styled.div`
  width: 100%;
  height: ${props => (props.height)};
  position: relative;
`;

const VideoBgPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => (props.src)});
`;

const Container = styled.div`
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
`;

