import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Container} from "../../ui/Container";

import {useMappedState, useDispatch} from "redux-react-hook";
import BottomUserPanel from "../../templates/BottomUserPanel";

import * as usersActions from '../../../redux/actions/UsersActions'

import Sidebar from 'arui-feather/sidebar'

import {Code} from 'react-content-loader';

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

import {Button} from "../../ui/Button";

import {Spinner} from '@blueprintjs/core'

export default function FranchiseePaymentInstructionsPanel(props) {
    let {id, canUpdate = true} = props;
    const textarea = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);
    const {franchisee, loading, isMe, franchiseeId} = useMappedState(useCallback(state => {
        let {currentUserId, usersMap} = state.users;
        let currentUser = usersMap.get(currentUserId);
        let isAdmin = (currentUser != undefined && currentUser.userRole == 'admin');
        let uId = (id == undefined) ? currentUserId : id;
        return {
            franchisee: state.users.usersMap.get(id),
            currentUser: currentUser,
            isAdmin: isAdmin,
            isMe: (currentUserId == id),
            loading: state.users.loading,
            franchiseeId: uId
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        if (franchiseeId != undefined) {
            dispatch(usersActions.loadUserById(franchiseeId));
        }
    }, [franchiseeId]);

    if (franchisee == undefined && loading == true) {
        return (
            <Code/>
        )
    }
    if (id == undefined || franchisee == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <Field>
                <Label>
                    Общая информация по абонементам для клиентов
                </Label>
                {canUpdate == false ? null :
                    <Textarea ref={textarea}
                              defaultValue={(franchisee == undefined) ? '' : franchisee.franchiseePaymentInformation}/>
                }
                {canUpdate == true ? null :
                    <div>
                        <p dangerouslySetInnerHTML={{__html: (franchisee.franchiseePaymentInformation == undefined ? '' : franchisee.franchiseePaymentInformation).replace(/\n/g, '<br/>')}}></p>
                    </div>
                }
            </Field>
            {canUpdate == false ? null :
                <Field>
                    {loading == false ? null : <Spinner/>}
                    {loading == true ? null :
                        <Button onClick={() => {
                            try {
                                let franchiseePaymentInformation = textarea.current.value;
                                dispatch(usersActions.updateUser({
                                    id: franchiseeId,
                                    franchiseePaymentInformation
                                }))
                            } catch (exc) {
                                console.log('can not update payment info');
                            }
                        }}>
                            Сохранить
                        </Button>
                    }
                </Field>
            }
        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;


const Wrapper = styled.div`
    
`;

const Label = styled.div`
    font-weight: bold;
`;
