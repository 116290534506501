import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const NewsItemsAPI = {

    getAllNewsItems() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/news_items/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createNewsItem(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/news_items/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },
    
    updateNewsItem(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/news_items/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },


    getNewsItem(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/news_item/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteNewsItem(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/news_items/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default NewsItemsAPI;
