import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {BROWN, DARK_SIDE} from "./Colors";


export default function Tabs(props) {
    const [selectedTabNumber, setSelectedTabNumber] = useState(0);
    let tabs = (props.tabs == undefined) ? [] : props.tabs;
    const {
        extraTopButton = null, safeMode = false, onTabSwitch = () => {

        }
    } = props;
    const goodTabs = tabs.filter(a => (a != undefined));
    let selectedTab = (goodTabs.length == 0) ? undefined : goodTabs[selectedTabNumber];

    if (goodTabs.length == 0) {
        return null;
    }
    if (goodTabs.length == 1) {
        return goodTabs[0].content;
    }

    return (
        <Wrapper>

            <TabNamesItems>
                {goodTabs.map((tb, k) => {
                    let {label, content} = tb;
                    return (
                        <TabName selected={(+selectedTabNumber == +k)} key={k} onClick={() => {
                            setSelectedTabNumber(k);
                            onTabSwitch();
                        }}>
                            {label}
                        </TabName>
                    )
                })}
                {extraTopButton == undefined ? null :
                    <ExtraPlaceholder>
                        {extraTopButton}
                    </ExtraPlaceholder>
                }
            </TabNamesItems>

            {safeMode == true ? null :
                <div>
                    {selectedTab == undefined ? null :
                        <ContentPlaceholder key={`num_${selectedTabNumber}`}>
                            {selectedTab.content}
                        </ContentPlaceholder>
                    }
                </div>
            }

            {safeMode == false ? null :
                <div>
                    {tabs.map((a, k) => {
                        const isVisible = (selectedTabNumber == k);
                        return (
                            <ContentPlaceholder key={k} style={{display: (isVisible == true) ? 'block' : 'none'}}>
                                {a.content}
                            </ContentPlaceholder>
                        )
                    })}
                </div>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
`;

const TabNamesItems = styled.div`
    border-bottom: 1px solid whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
`;

const TabName = styled.div`
    display: inline-block;
    vertical-align: top;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    margin-right: 12px;
    border-bottom: ${props => (props.selected == true ? `2px solid ${DARK_SIDE}` : 'none')};
    //font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    :hover{
      opacity: 0.8;
    }
`;

const ExtraPlaceholder = styled.div`
    margin-left: auto;
`;

const ContentPlaceholder = styled.div`
    padding-top: 5px;
    width: 100%;
`;

export const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;
