import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {useMappedState, useDispatch} from "redux-react-hook";

import ChessTemplate from "../templates/ChessTemplate";


import FranchiseeSchoolsPanel from "../schools/panels/FranchiseeSchoolsPanel";


export default function FranchiseeSchoolsApp(props) {
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId
    }), []));

    return (
        <ChessTemplate active={'schools'}>

            <FranchiseeSchoolsPanel id={currentUserId}/>

        </ChessTemplate>
    );
}

