import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {BLUE, YELLOW} from "../../ui/Colors";

export default function ImTopSectionPanel(props) {
    let {
        subMotto = undefined,
        hasTopLogo = true,
        motto = undefined,
        hasGreyOverlay = false
    } = props;

    return (
        <ImTopSection hasGreyOverlay={hasGreyOverlay} >
            {hasTopLogo == false ? null :
                <TopBrandedLogoPlaceholder>
                    <TopBrandedLogo src={require('../../../assets/images/fr_dark_blue.png')}/>
                </TopBrandedLogoPlaceholder>
            }
            {motto == undefined ? null :
                <Motto>
                    {motto}
                </Motto>
            }
            {subMotto == undefined ? null :
                <SubMotto>
                    {subMotto}
                </SubMotto>
            }
        </ImTopSection>
    );
}

const Wrapper = styled.div`
    
`;


const ImTopSection = styled.div`
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    background: ${props => (props.hasGreyOverlay == true ? '#ffffffa3' : 'none')};
    padding: ${props => (props.hasGreyOverlay == true ? '20px 10px' : '0px')};
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 100px;
`;

const ImBottomSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 40px;
`;

const TopBrandedLogoPlaceholder = styled.div`
    margin-bottom: 30px;
    padding-left: 24px;
    @media(max-width: 720px){
      display: none;
    }
`;

const TopBrandedLogo = styled.img`
    //height: 160px;
    height: 140px;
`;

const Motto = styled.div`
    font-size: 54px;
    color: ${BLUE};
    font-weight: bold;
    padding-left: 24px;
    //text-shadow: 0px 0px 24px rgba(255,255,255,0.99);
`;


const SubMotto = styled.div`
    font-size: 44px;
    color: ${YELLOW};
    font-weight: bold;
    padding-left: 24px;
    //text-shadow: 0px 0px 24px rgba(255,255,255, 1);
`;

