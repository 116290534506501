import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import UsersList from "../lists/UsersList";

import * as usersActions from '../../../redux/actions/UsersActions'
import ChangeUserEmailForm from "../forms/ChangeUserEmailForm";

export default function ChangeUserEmailPanel(props) {
    const {
        userId, onSuccess = () => {
        }
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading, user} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading,
        user: state.users.usersMap.get(userId)
    }), [userId]));

    if (user == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <ChangeUserEmailForm loading={loading} oldEmail={user.email} onSubmit={newEmail => {
                dispatch(usersActions.changeUserEmail({
                        id: userId,
                        email: newEmail
                    })
                ).then(pld => {
                    if (pld.type.indexOf('SUCCESS') > -1) {
                        window.alert('Почта успешно изменена!');
                        onSuccess();
                    }
                });
            }}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    margin-top: ${props => (props.hasTopBorder == true ? '10px' : 0)};
    padding-top: ${props => (props.hasTopBorder == true ? '10px' : 0)};
    border-top: ${props => (props.hasTopBorder == true ? '1px solid whitesmoke' : 'none')};
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
`;
