import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import ChessTemplate from "../templates/ChessTemplate";


import {Container} from "../ui/Container";

import AdminAllStudentsPanel from "../admin/panels/AdminAllStudentsPanel";

export default function ObserverStudentsApp(props) {

    return (
        <ChessTemplate active={'students'}>

            <AdminAllStudentsPanel
                showOnlyMyClass={true}
            />

        </ChessTemplate>
    );
}

