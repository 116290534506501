import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import CommonHelper from "../../../helpers/CommonHelper";
import Select from "react-select";
import {ARCH_OPTIONS} from "../../admin/panels/AdminAllStudentsPanel";

export default function TaskForm(props) {
    let {
        onSave = () => {
        }, loading = false,
        canEdit = true,
        onDelete = () => {

        },
        canDelete = false

    } = props;
    console.log('render: TaskForm props = ', props);
    const [name, setName] = useState((props.name == undefined) ? '' : props.name);
    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    const [taskUrl, setTaskUrl] = useState(props.taskUrl);
    const [deadlineTimestamp, setDeadlineTimestamp] = useState(props.deadlineTimestamp);
    const [startTimestamp, setStartTimestamp] = useState(props.startTimestamp == undefined ? +moment().startOf('day') : +props.startTimestamp);
    const [status, setStatus] = useState((props.status == undefined) ? 'active' : props.status);

    console.log('name, description = ', name, description);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Файл с заданием
                </Label>
                {taskUrl == undefined ? null :
                    <div>
                        <a href={taskUrl} target={'_blank'}>
                            скачать
                        </a>
                    </div>
                }
                <div>
                    <SimpleUploadPanel onUploaded={url => {
                        setTaskUrl(url);
                    }}>
                        загрузить файл
                    </SimpleUploadPanel>
                </div>
            </Field>

            <Field>
                <Label>
                    Дата начала сдачи
                </Label>
                <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={moment(startTimestamp).toDate()}
                    onChange={newDate => {
                        setStartTimestamp(+new Date(newDate));
                    }}
                />
            </Field>

            <Field>
                <Label>
                    Дата окончания сдачи
                </Label>
                <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={moment(deadlineTimestamp).toDate()}
                    onChange={newDate => {
                        setDeadlineTimestamp(+moment(+new Date(newDate)).endOf('day'));
                    }}
                />
            </Field>

            <Field>
                <Label>
                    Статус
                </Label>
                <Select options={ARCH_OPTIONS} value={ARCH_OPTIONS.filter(a => (a.value == status))[0]}
                        placeholder={'"Активный/Архивный"'}
                        onChange={a => {
                            if (a == undefined) {
                                setStatus(undefined);
                            } else {
                                setStatus(a.value);
                            }
                        }}/>
            </Field>




            <Field>
                <Button onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onSave({name, description, taskUrl, deadlineTimestamp, startTimestamp, status});
                }}>
                    {loading == true ?
                        <Spin visible={true}/>
                        :
                        <span>
                                Сохранить
                            </span>
                    }

                </Button>
            </Field>

            {canDelete == false ? null :
                <Field style={{marginTop: 50}}>
                    <DeleteSpan onClick={() => {
                        if (CommonHelper.uSure(['Вы уверены, что хотите удалить БДЗ?', 'Данные будут удалены безвозвратно. Вы точно уверены?']) == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        удалить
                    </DeleteSpan>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const DeleteSpan = styled.span`
    color: red;
    opacity: 0.5;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;
