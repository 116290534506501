import moment from 'moment'

import React from 'react'

import generator from 'generate-password'


// import {DEFAULT_TIMEZONE} from "../constants/config";

export const RU_MONTHS_ARRAY = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

export const RU_MONTHS_ARRAY_S = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря'
];

const SIGNUM_MAP = {
    asc: 1,
    desc: -1
}


const CommonHelper = {

    linkTo(patientUrl, inNewTab = false) {
        let url = window.location.origin + window.location.pathname + '#' + patientUrl;
        if (inNewTab == false) {
            window.location.href = url;
        } else {
            let win = window.open(url, '_blank');
            win.focus();
        }
    },

    getRuMonthByTimestamp(t) {
        let m = +moment(t).format('M') - 1;
        return RU_MONTHS_ARRAY[+m];
    },

    getUserName(user) {
        if (user == undefined) {
            return 'Guest';
        }
        let {firstName, lastName, name} = user;
        return `${firstName} ${lastName}`;
    },

    getRuMonth_s_ByTimestamp(t) {
        let m = +moment(t).format('M') - 1;
        return RU_MONTHS_ARRAY_S[+m];
    },

    getCurrentUserRole(state) {
        if (state == undefined) {
            return false;
        }
        let {currentUserId, usersMap} = state.users;
        let currentUser = usersMap.get(currentUserId);
        if (currentUser == undefined) {
            return undefined;
        }
        return currentUser.userRole;
    },

    getRuNameOfRole(role, isModerator = false) {
        let map = {
            franchisee: 'Представитель',
            admin: 'Админ',
            teacher: 'Преподаватель',
            student: 'Ученик',
            observer: 'Проверяющий',
        }
        if (role == 'teacher' && isModerator){
            return `Координатор`;
        }
        return map[role];
    },

    getItemById(arr, id) {
        let aArr = arr.filter(a => (a.id == id));
        if (aArr.length == 0) {
            return undefined;
        }
        return aArr[0];
    },

    getSelectedOption(arr, id, trLabelFun = a => (a.name)) {
        let v = this.getItemById(arr, id);
        if (v == undefined) {
            return undefined;
        }
        return {
            label: trLabelFun(v),
            value: id
        }
    },

    getDropDownItemsByUsers(users, shortNameMode = false) {
        return users.map((tea, k) => {
            return {
                value: tea.id,
                label: (shortNameMode == true) ? this.getShortNameByUser(tea) : `${tea.lastName} ${tea.firstName}`,
                sortString: `${tea.lastName} ${tea.firstName}`
            }
        }).sort((a, b) => {
            if (a.sortString.toLowerCase() > b.sortString.toLowerCase()) {
                return 1;
            }
            if (a.sortString.toLowerCase() < b.sortString.toLowerCase()) {
                return -1;
            }
            return 0;
        })
    },

    getDropDownItemsByNamedList(arr) {
        return arr.map((a, k) => {
            return {
                value: a.id,
                label: `${a.name}`
            }
        }).sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }
            return 0;
        })
    },

    hourGn(a) {
        return (+a < 10 ? `0${a}` : `${a}`);
    },

    getHourString(h) {
        return `${this.hourGn(h)}:00`;
    },

    getHourSpanString(h) {
        return `${this.getHourString(h)}-${this.getHourString(+h + 1)}`;
    },

    sortArrayBySortingSettings(arr, sortingSettings) {
        let res = arr.sort((a, b) => {
            let s1 = a[sortingSettings.type];
            let s2 = b[sortingSettings.type];
            if (s1 > s2) {
                return SIGNUM_MAP[sortingSettings.mode];
            }
            if (s1 < s2) {
                return -SIGNUM_MAP[sortingSettings.mode];
            }
            return 0;
        })
        return res;
    },

    getShortNameByUser(u) {
        if (u == undefined) {
            return undefined;
        }
        let {firstName, lastName} = u;
        let sf = a => (a == undefined ? '' : a);
        let fn = sf(firstName), ln = sf(lastName);
        if (fn.split(' ').length > 1) {
            fn = fn.split(` `).map(ss => ((ss.length > 1) ? `${ss[0].toUpperCase()}.` : ss)).join(' ');
        }
        if (fn.trim() == '' && ln.trim() == '') {
            return u.email;
        }
        let name = (fn.trim() == '') ? ln : `${ln}${ln.trim() == '' ? '' : ` ${fn[0]}.`}`;
        return name;
    },

    getFullName(u) {
        if (u == undefined) {
            return undefined;
        }
        let {firstName, lastName, middleName} = u;
        let sf = a => (a == undefined ? '' : a);
        return `${sf(lastName) } ${sf(firstName)} ${sf(middleName)}`;
    },

    getSortedArray(arr, fieldName = 'name') {
        return arr.sort((a, b) => {
            let s1 = (a[fieldName] == undefined) ? '' : a[fieldName];
            let s2 = (b[fieldName] == undefined) ? '' : b[fieldName];
            if (s1 > s2) {
                return 1;
            }
            if (s1 < s2) {
                return -1;
            }
            return 0;
        });
    },

    isMobile() {
        // let w = window.innerWidth;
        // const breakWidth = 920;
        // return (w <= breakWidth);
        return (window.innerWidth < window.innerHeight);
    },

    downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
    },

    hasVideoUrl(props) {
        let url = ['videoUrl720p', 'videoUrl480p', 'videoUrl1080p', 'videoUrl360p', 'videoUrl'].map(a => props[a]).filter(a => !ie(a))[0];
        return (url != undefined)
    },

    getSafeUrl(url) {
        if (url == undefined) {
            return undefined;
        }
        let newUrl = url.replace('https://www.englishpatient.org/english-patient-files/', 'https://os.mipt.ru/s3-files/');
        return newUrl;
    },

    openInNewTab(url) {
        const win = window.open(url, '_blank');
        win.focus();
    },

    getPwd() {
        return generator.generateMultiple(1, {
            length: 10,
            uppercase: false
        })[0];
    },

    uSure(messages) {
        return messages.reduce((r, m) => (r && window.confirm(m)), true);
    },

    getTaskSubmissionStatusObject(task, sub) {
        if (task == undefined) {
            return {}
        }
        let {startTimestamp, deadlineTimestamp, name, description, avatar, taskUrl} = task;
        let isFromFuture = (+new Date() < +startTimestamp);
        let isFromPast = (+new Date() > +moment(deadlineTimestamp).endOf('day'));
        let isCurrent = (isFromFuture == false && isFromPast == false);
        let isChecked = (sub != undefined && sub.mark != undefined);
        let isSubmitted = (sub != undefined && sub.attachments != undefined && sub.attachments.length > 0);
        let studentCanUpdate = (isCurrent && !isChecked);
        let isMissed = (!isSubmitted && isFromPast);
        return {
            isFromFuture, isFromPast, isCurrent, isMissed,
            isChecked, isSubmitted, studentCanUpdate,

        }
    },

    getAllEnhancedSubmissionsByState(state) {
        return state.submissions.submissionsMap.toArray().map(a => {
            let st = state.users.usersMap.get(a.studentId);
            let tsk = state.tasks.tasksMap.get(a.taskId);
            let center = (st == undefined) ? undefined : state.centers.centersMap.get(st.centerId);
            let classNumber = (tsk == undefined) ? undefined : tsk.classNumber;
            return {
                ...a,
                student: st,
                task: tsk,
                center: center,
                observer: state.users.usersMap.get(a.observerId),
                classNumber: classNumber,
                ...CommonHelper.getTaskSubmissionStatusObject(tsk, a)
            }
        }).filter(a => !(a.student == undefined || a.center == undefined || a.task == undefined || a.attachments.length == 0))
            .sort((a, b) => {
                let d = (+a.attachments[0].t - +b.attachments[0].t);
                return d;
            });
    },

    gnc(a) {
        if (a == undefined) {
            return null;
        }
        return (<span dangerouslySetInnerHTML={{__html: a.trim().replace(/\n/g, '<br/>')}}></span>);
    },

    convertSecondstoTime(given_seconds) {
        let dateObj = new Date(given_seconds * 1000);
        let hours = dateObj.getUTCHours();
        let minutes = dateObj.getUTCMinutes();
        let seconds = dateObj.getSeconds();

        if (window.isNaN(seconds) == true) {
            return '';
        }

        let timeString = hours.toString().padStart(2, '0')
            + ':' + minutes.toString().padStart(2, '0')
            + ':' + seconds.toString().padStart(2, '0');
        return timeString;
    }


};

const ie = a => (a == undefined || a.trim() == '');

export default CommonHelper;
